import React, { useState } from 'react';
import { Form, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const SearchBar = ({SearchResut}) => {
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (e) => {
    e.preventDefault();
    const query = e.target.value;
    // console.log(query);
    setSearchQuery(query);
    SearchResut(query);
  };

  // const filteredData = data.filter(item =>
  //   item.toLowerCase().includes(searchQuery.toLowerCase())
  // );

  const SearchBarStyle = {
    input: {
      width: "100%",
      border: "1px solid rgb(78 78 78 / 55%)",
      borderRadius: "12px 0 0 12px",
      padding: "9px 11px"
    },
    div: {
      padding: "5px",
      paddingLeft: "30px",
      border: "1px solid rgb(78 78 78 / 55%)",
      borderRadius: "0px 12px 12px 3px",
      background: "#fff"
    },
    icon: {
      position: "relative",
      right: "17px",
      color: "#4E4E4E",
      cursor: "pointer",
      top: "2px"
    }
  };

  return (
    <div style={{ display: "flex", width: "60%", minWidth: "268px" }}>
      <FormControl
        style={SearchBarStyle.input}
        onChange={handleSearch}
        type="text"
        placeholder="Search..."
        name="query"
        className="mr-sm-2"
      />
      <div style={SearchBarStyle.div}>
        <FontAwesomeIcon style={SearchBarStyle.icon} icon={faSearch} className="search-icon" />
      </div>
    </div>
  );
};

export default SearchBar;
