import React, { useState, useEffect } from "react";
import Nav from "../Components/Nav";
import Footer from "../Components/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import AdminSidebar from "../Components/Sidebar";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import API_BASE_URL from "../apiConfig";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

function SentenceView() {
  const [isActive, setIsActive] = useState(false);
  const [userLearning, setUserLearning] = useState({ data: [], pagination: {} });
  const [updateCounter, setUpdateCounter] = useState(0);
  const [loginFalse, setLoginFalse] = useState(false);
  const [showlimit, setShowlimit] = useState(25);
  const [surrahnum, setSurrahnum] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [start, setStart] = useState(0);
  const [translation, setTranslation] = useState("");
  const [open, setOpen] = React.useState(false);
  const [showPractice, setShowPractice] = useState(false);
  const [practiceWords, setPracticeWords] = useState([]);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [practiceOptions, setPracticeOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [score, setScore] = useState(0);
  const [hasAnswered, setHasAnswered] = useState(false);
  const [correctAnswer, setCorrectAnswer] = useState(null);
  const [totalAvailableWords, setTotalAvailableWords] = useState(0);
  const [showPracticeSetup, setShowPracticeSetup] = useState(false);
  const [selectedWordCount, setSelectedWordCount] = useState(10);
  const [showCompletionModal, setShowCompletionModal] = useState(false);

  const handleOpen = (word) => {
    setOpen(true);
    setTranslation(word);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    localStorage.getItem("login");
    if (localStorage.getItem("login") == "true") {
      setLoginFalse(true);
    } else {
      navigate("/login");
    }
  }, [localStorage.getItem("login")]);

  // sidebar start
  const [sidebar, setsidebar] = useState(false);
  const [changestatus, setChangestatus] = useState(false);
  const sidemenufun = () => {
    setsidebar(!sidebar);
  };
  // sidebar end
  const [words, setWords] = useState({});
  useEffect(() => {
    console.log("In UseEffect");
    getLearningAPI("");
  }, [updateCounter]);
  const handleClick = () => {
    setChangestatus(true);
    console.log("userLearning==>>> ", userLearning);
    setIsActive(!isActive);

    const apiUrl = `${API_BASE_URL}/user_dashboard/set_word_status`;
    const requestData = words;
    const headers = {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("access_token"),
    };

    fetch(apiUrl, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log("API_Data", data);
        getLearningAPI("");
        localStorage.setItem("localdata", "");
        setChangestatus(false);
        setUpdateCounter(0);
      })
      .catch((error) => {
        console.error("Error fetching API:", error);
        setChangestatus(false);
      });
  };
  const navigate = useNavigate();
  const NavigatePages = (data, word) => {
    navigate(data, { state: word });
  };
  const getLearningAPI = (data) => {
    const apiUrl = `${API_BASE_URL}/user_dashboard/get_known_words_with_diacritics`;
    const requestData = {
      surah: data || surrahnum,
      page: currentPage,
      per_page: itemsPerPage,
    };
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((res) => {
        setUserLearning(res);
        setTotalPages(res.pagination.total_pages);
      })
      .catch((error) => {
        console.error("Error fetching API:", error);
      });
  };

  useEffect(() => {
    getLearningAPI("");
  }, [currentPage, itemsPerPage, surrahnum, updateCounter]);
  const isMobile = window.innerWidth <= 768;

  const SentenceView = {
    bg: {
      // background: "rgb(244, 245, 246)",
      padding: "33px 24px",
    },
    h4: {
      fontSize: "18px",
      fontWeight: "500",
      margin: "0 16px",
      border: "1px solid #ADADAD",
      padding: "8px 40px",
      background: "#fff",
      borderTopLeftRadius: "12px",
      borderTopRightRadius: "12px",
    },
    mainHeading: {
      width: "94%",
      marginBottom: "25px",
      display: "flex",
      justifyContent: "end",
    },
    mainBox: {
      borderRadius: "12px",
      border: "1px solid #ADADAD",
      padding: "30px 33px",
      background: "#fff",
      boxShadow: "rgba(0, 0, 0, 0.3) 0px 3px 5px 3px",
      width: "82%",
      margin: "0px auto",
      minWidth: isMobile ? "auto" : "513px",  // Mobile par minWidth hata diya
    },
    heading: {
      display: "flex",
      alignItems: "center",
      gap: "11px",
      color: "#3E3E3E",
      fontSize: "16px",
      fontWeight: "400",
      margin: "0",
    },
    ButtonsDiv: {
      borderRadius: "10px",
      background: "#D9D9D9",
      margin: "0 auto",
      width: "66%",
      padding: "13px",
      display: "flex",
      justifyContent: "space-around",
    },
    P: {
      color: "#000",
      fontSize: "23px",
      fontWeight: "500",
      margin: "0",
      cursor: "pointer",
    },
    ImgText: {
      color: "#394757",
      fontSize: "16px",
      fontWeight: "400",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      margin: "0",
    },
    button: {
      color: "#fff",
      borderRadius: "24px",
      border: isActive ? "1px solid transparent" : "1px solid #EDAF59",
      background: isActive ? "#000" : "#EDAF59",
      fontSize: "16px",
      fontWeight: "400",
      padding: "8px 11px 11px 11px",
      width: "18%",
      cursor: "pointer",
      height: "43px",
    },

    SentenceViewDetail: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderRadius: "12px",
      border: "1px solid #e0e0e0",
      background: "#FFF",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
      padding: "16px 20px",
      width: "100%",
      transition: "all 0.2s ease",
      "&:hover": {
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      }
    },
    SentenceViewDetailProfile: {
      display: "flex",
      gap: "15px",
      alignItems: "center",
      cursor: "pointer",
      width: "auto"
    },
    arrowstext: {
      borderRadius: "6px",
      border: "1px solid #091B2E",
      background: "#091B2E",
      padding: "1px",
      color: "#fff",
      width: "28px",
      height: "29px",
      textAlign: "center",
    },
    iconsDiv: {
      border: "1px solid",
      padding: "3px",
      borderRadius: "8px",
      height: "30px",
      minWidth: "60px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
    },
    SecondHeading: {
      display: "flex",
      justifyContent: "space-between",
      width: "81%",
      margin: "0 auto",
    },
  };
  const getColorByStatus = (status, index) => {
    if (status === "New") {
      return "#000"; // or any color you prefer
    } else if (status === "Learned") {
      return "#000"; // or any default color
    }
    // else if (status === 'Recognized' && index === 3) {
    //     return '#000'; // or any default color
    // }
    else if (status === "Familiar") {
      return "#000"; // or any default color
    } else if (status === "Known") {
      return "#fff"; // or any default color
    } else {
      return "#000"; // or any default color
    }
  };

  const getbgColorByStatus = (status, index) => {
    if (status === "New") {
      return "#A4C2F4"; // or any color you prefer
    } else if (status === "Learned") {
      return "#FBD866"; // or any default color
    } else if (status === "Familiar") {
      return "#8CF485"; // or any default color
    } else if (status === "Known") {
      return "#93C47D"; // or any default color
    } else {
      return "white"; // or any default color
    }
  };

  const getbrColorByStatus = (status, index) => {
    if (status === "New") {
      return "#000"; // or any color you prefer
    } else if (status === "Learned") {
      return "1px solid #02A723"; // or any default color
    } else if (status === "Familiar") {
      return "#000"; // or any default color
    }
    // else if (status === 'Learned' && index === 4) {
    //     return '1px solid #02A723'; // or any default color
    // }
    else if (status === "Known") {
      return "#02A723"; // or any default color
    } else {
      return "white"; // or any default color
    }
  };

  const addWord = (word, translation, index, mainindex) => {
    const newArray = [...userLearning.data];
    console.log(word);
    console.log(translation);
    console.log(index);
    console.log(mainindex);
    let status = "";
    if (index === 1) {
      status = "New";
    } else if (index === 2) {
      status = "Learn";
    } else if (index === 3) {
      status = "Familiar";
    } else if (index === 4) {
      status = "Known";
    }
    console.log(userLearning.data[mainindex].status == status);
    newArray[mainindex].status = status;

    setUserLearning({ ...userLearning, data: newArray });

    setWords((prevState) => {
      return {
        ...prevState,
        [word]: {
          translation: translation,
          status: status,
        },
      };
    });
  };

  // const [selectedSurah, setSelectedSurah] = useState("none");
  const handleChange1 = (event) => {
    setSurrahnum(event.target.value);
    getLearningAPI(event.target.value);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const surahs = [
    "Al-Fatihah",
    "Al-Baqarah",
    "Aal-E-Imran",
    "An-Nisa'",
    "Al-Ma'idah",
    "Al-An'am",
    "Al-A'raf",
    "Al-Anfal",
    "At-Tawbah",
    "Yunus",
    "Hud",
    "Yusuf",
    "Ar-Ra'd",
    "Ibrahim",
    "Al-Hijr",
    "An-Nahl",
    "Al-Isra'",
    "Al-Kahf",
    "Maryam",
    "Ta-Ha",
    "Al-Anbiya'",
    "Al-Hajj",
    "Al-Mu'minun",
    "An-Nur",
    "Al-Furqan",
    "Ash-Shu'ara'",
    "An-Naml",
    "Al-Qasas",
    "Al-Ankabut",
    "Ar-Rum",
    "Luqman",
    "As-Sajda",
    "Al-Ahzab",
    "Saba'",
    "Fatir",
    "Ya-Sin",
    "As-Saffat",
    "Sad",
    "Az-Zumar",
    "Ghafir",
    "Fussilat",
    "Ash-Shura",
    "Az-Zukhruf",
    "Ad-Dukhan",
    "Al-Jathiyah",
    "Al-Ahqaf",
    "Muhammad",
    "Al-Fath",
    "Al-Hujurat",
    "Qaf",
    "Adh-Dhariyat",
    "At-Tur",
    "An-Najm",
    "Al-Qamar",
    "Ar-Rahman",
    "Al-Waqi'ah",
    "Al-Hadid",
    "Al-Mujadila",
    "Al-Hashr",
    "Al-Mumtahina",
    "As-Saff",
    "Al-Jumu'ah",
    "Al-Munafiqun",
    "At-Taghabun",
    "At-Talaq",
    "At-Tahrim",
    "Al-Mulk",
    "Al-Qalam",
    "Al-Haqqah",
    "Al-Ma'arij",
    "Nuh",
    "Al-Jinn",
    "Al-Muzzammil",
    "Al-Muddathir",
    "Al-Qiyamah",
    "Al-Insan",
    "Al-Mursalat",
    "An-Naba'",
    "An-Nazi'at",
    "'Abasa",
    "At-Takwir",
    "Al-Infitar",
    "Al-Mutaffifin",
    "Al-Inshiqaq",
    "Al-Buruj",
    "At-Tariq",
    "Al-A'la",
    "Al-Ghashiyah",
    "Al-Fajr",
    "Al-Balad",
    "Ash-Shams",
    "Al-Lail",
    "Ad-Duha",
    "Ash-Sharh",
    "At-Tin",
    "Al-'Alaq",
    "Al-Qadr",
    "Al-Bayyinah",
    "Az-Zalzalah",
    "Al-'Adiyat",
    "Al-Qari'ah",
    "At-Takathur",
    "Al-'Asr",
    "Al-Humazah",
    "Al-Fil",
    "Quraish",
    "Al-Ma'un",
    "Al-Kawthar",
    "Al-Kafirun",
    "An-Nasr",
    "Al-Masad",
    "Al-Ikhlas",
    "Al-Falaq",
    "An-Nas",
  ];

  const modal_style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const btnchng = () => {
    setUpdateCounter(1);
  };

  const handlePractice = async () => {
    try {
      const apiUrl = `${API_BASE_URL}/user_dashboard/get_practice_words`;
      const requestData = {
        surah: parseInt(surrahnum),
        page: currentPage,
        per_page: itemsPerPage,
      };
      const headers = {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      };

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      
      if (!data || data.length === 0) {
        alert("No practice words available for this selection");
        return;
      }

      setPracticeWords(data);
      setCurrentWordIndex(0);
      generateOptions(data[0]);
      setShowPractice(true);
    } catch (error) {
      console.error("Error fetching practice words:", error);
      alert("Error loading practice words. Please try again.");
    }
  };

  const generateOptions = (word) => {
    if (!word) return;
    // Get 3 random translations from unique_words plus the correct one
    const allOptions = [...word.other_translations, word.translation];
    // Shuffle options
    const shuffledOptions = allOptions.sort(() => Math.random() - 0.5).slice(0, 4);
    setPracticeOptions(shuffledOptions);
  };

  const handleOptionSelect = (option) => {
    if (hasAnswered) return; // Prevent selecting after answering
    
    setSelectedOption(option);
    setHasAnswered(true);
    setCorrectAnswer(practiceWords[currentWordIndex].translation);
    
    if (option === practiceWords[currentWordIndex].translation) {
      setScore(score + 1);
    }
  };

  const handleNextWord = () => {
    setSelectedOption(null);
    setHasAnswered(false);
    setCorrectAnswer(null);
    
    if (currentWordIndex < practiceWords.length - 1) {
      setCurrentWordIndex(prev => prev + 1);
      generateOptions(practiceWords[currentWordIndex + 1]);
    } else {
      setShowCompletionModal(true); // Show completion modal instead of alert
    }
  };

  const handleClosePractice = () => {
    const confirmClose = window.confirm('Are you sure you want to end this practice session?');
    if (confirmClose) {
      setShowPractice(false);
      setScore(0);
      setCurrentWordIndex(0);
      setSelectedOption(null);
      setHasAnswered(false);
      setCorrectAnswer(null);
    }
  };

  const handleStartPractice = async () => {
    // Validate word count before starting
    if (!selectedWordCount || selectedWordCount < 1) {
      alert('Please enter at least 1 word to practice');
      return;
    }

    const apiUrl = `${API_BASE_URL}/user_dashboard/get_practice_words`;
    const requestData = {
      surah: parseInt(surrahnum),
      count: selectedWordCount,
      random: true // Tell backend to return random selection
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("access_token"),
    };

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      
      if (!data || data.length === 0) {
        alert("No practice words available for this selection");
        return;
      }

      setPracticeWords(data);
      setCurrentWordIndex(0);
      generateOptions(data[0]);
      setShowPractice(true);
      setShowPracticeSetup(false); // Hide setup modal
    } catch (error) {
      console.error("Error fetching practice words:", error);
      alert("Error loading practice words. Please try again.");
    }
  };

  const handleCheckAvailableWords = async () => {
    const apiUrl = `${API_BASE_URL}/user_dashboard/get_practice_words_count`;
    const requestData = {
      surah: parseInt(surrahnum)
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("access_token"),
    };

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setTotalAvailableWords(data.count);
      setSelectedWordCount(Math.min(10, data.count)); // Default to 10 or max available
      setShowPracticeSetup(true);
    } catch (error) {
      console.error("Error checking available words:", error);
      alert("Error checking available words. Please try again.");
    }
  };

  const handleCompletionClose = () => {
    setShowCompletionModal(false);
    setShowPractice(false);
    // Reset for next practice session
    setScore(0);
    setCurrentWordIndex(0);
  };

  const handleWordCountChange = (e) => {
    const value = e.target.value;
    
    // Allow empty value for better UX
    if (value === '') {
      setSelectedWordCount('');
      return;
    }

    // Convert to number and validate
    const numValue = parseInt(value);
    if (isNaN(numValue)) return;

    // If value is 0, set to empty to allow user to type new value
    if (numValue === 0) {
      setSelectedWordCount('');
      return;
    }

    // Ensure value doesn't exceed total available words
    setSelectedWordCount(Math.min(numValue, totalAvailableWords));
  };

  const practiceBtnStyle = {
    backgroundColor: "#edaf59",
    color: "#fff",
    padding: "12px 24px",
    borderRadius: "12px",
    border: "none",
    cursor: "pointer",
    marginLeft: "10px",
    fontWeight: "500",
  };

  return (
    <>
      {loginFalse && (
        <div>
          <Nav headervalue={"nav2"} sidemenufun={() => sidemenufun()} />
          <AdminSidebar sidebar={sidebar} sidebaracc={"Mobile-Sidebar"} />
          <div style={SentenceView.bg}>
            <div className="mobile-view-pages">
              {/* <div style={SentenceView.mainHeading}>
                            {updateCounter > 0 ?<button onClick={handleClick} style={SentenceView.button} className='edit-btn-reponsive'>
                                {changestatus ? "Please Wait..." : "Update Status"}
                            </button>
                            : <button className='edit-btn-reponsive' style={SentenceView.button} onClick={()=>{btnchng()}}>Edit Status</button>}
                        </div> */}
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                marginBottom: '20px',
                marginTop: '20px',
                gap: '10px'
              }}>
                <div style={{
                  position: 'relative',
                  width: '250px'
                }}>
                  <select
                    className="handleChange"
                    onChange={handleChange1}
                    value={surrahnum}
                    style={{
                      width: '100%',
                      padding: '12px 35px 12px 20px',
                      borderRadius: '12px',
                      border: '2px solid #ADADAD',
                      fontSize: '16px',
                      fontWeight: '500',
                      cursor: 'pointer',
                      appearance: 'none',
                      backgroundColor: '#fff',
                      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                      outline: 'none'
                    }}
                  >
                    <option value="0">All Surahs</option>
                    {surahs.map((surah, index) => (
                      <option key={index} value={index + 1}>
                        {surah}
                      </option>
                    ))}
                  </select>
                  <div style={{
                    position: 'absolute',
                    right: '15px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    pointerEvents: 'none',
                    borderLeft: '6px solid transparent',
                    borderRight: '6px solid transparent',
                    borderTop: '6px solid #666'
                  }} />
                </div>
                <button 
                  onClick={handleCheckAvailableWords}
                  style={practiceBtnStyle}
                >
                  Practice
                </button>
              </div>

              {/* Practice Setup Modal */}
              <Modal
                open={showPracticeSetup}
                onClose={() => setShowPracticeSetup(false)}
                aria-labelledby="practice-setup-modal"
              >
                <Box sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: window.innerWidth <= 480 ? '90%' : 400,
                  bgcolor: 'background.paper',
                  boxShadow: 24,
                  p: window.innerWidth <= 480 ? 2 : 4,
                  borderRadius: 2,
                  maxWidth: '400px'
                }}>
                  <h2 style={{ 
                    marginTop: 0, 
                    marginBottom: window.innerWidth <= 480 ? '15px' : '20px',
                    fontSize: window.innerWidth <= 480 ? '18px' : '24px'
                  }}>
                    Practice Setup
                  </h2>
                  <p style={{ fontSize: window.innerWidth <= 480 ? '14px' : '16px' }}>
                    Available words to practice: {totalAvailableWords}
                  </p>
                  <div style={{ marginBottom: window.innerWidth <= 480 ? '15px' : '20px' }}>
                    <label 
                      htmlFor="wordCount" 
                      style={{ 
                        display: 'block', 
                        marginBottom: '10px',
                        fontSize: window.innerWidth <= 480 ? '14px' : '16px'
                      }}
                    >
                      How many words would you like to practice?
                    </label>
                    <input
                      type="number"
                      id="wordCount"
                      value={selectedWordCount}
                      onChange={handleWordCountChange}
                      style={{
                        width: '100%',
                        padding: window.innerWidth <= 480 ? '6px' : '8px',
                        borderRadius: '4px',
                        border: '1px solid #ccc',
                        fontSize: window.innerWidth <= 480 ? '14px' : '16px'
                      }}
                      min="1"
                      max={totalAvailableWords}
                    />
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                    <button
                      onClick={() => setShowPracticeSetup(false)}
                      style={{
                        padding: window.innerWidth <= 480 ? '6px 12px' : '8px 16px',
                        borderRadius: '4px',
                        border: '1px solid #ccc',
                        background: 'white',
                        cursor: 'pointer',
                        fontSize: window.innerWidth <= 480 ? '14px' : '16px'
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleStartPractice}
                      style={{
                        ...practiceBtnStyle,
                        margin: 0,
                        padding: window.innerWidth <= 480 ? '6px 12px' : '8px 16px',
                        fontSize: window.innerWidth <= 480 ? '14px' : '16px'
                      }}
                    >
                      Start Practice
                    </button>
                  </div>
                </Box>
              </Modal>

              {/* Practice Modal */}
              <Modal
                open={showPractice}
                onClose={handleClosePractice}
                aria-labelledby="practice-modal"
              >
                <Box sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: window.innerWidth <= 480 ? '90%' : 400,
                  bgcolor: 'background.paper',
                  boxShadow: 24,
                  p: window.innerWidth <= 480 ? 2 : 4,
                  borderRadius: 2,
                  maxWidth: '400px'
                }}>
                  {practiceWords[currentWordIndex] && (
                    <>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: window.innerWidth <= 480 ? '10px' : '20px'
                      }}>
                        <div style={{
                          fontSize: window.innerWidth <= 480 ? '12px' : '14px',
                          color: '#666'
                        }}>
                          Card {currentWordIndex + 1} of {practiceWords.length}
                        </div>
                        <div style={{
                          fontSize: window.innerWidth <= 480 ? '12px' : '14px',
                          color: '#666'
                        }}>
                          Score: {score}/{currentWordIndex + (hasAnswered ? 1 : 0)}
                        </div>
                        <button
                          onClick={handleClosePractice}
                          style={{
                            background: 'none',
                            border: 'none',
                            cursor: 'pointer',
                            fontSize: window.innerWidth <= 480 ? '18px' : '20px',
                            color: '#666',
                            padding: '4px'
                          }}
                        >
                          ×
                        </button>
                      </div>
                      <div style={{
                        fontSize: window.innerWidth <= 480 ? "20px" : "24px",
                        textAlign: "right",
                        direction: "rtl",
                        fontFamily: "uth",
                        marginBottom: "20px",
                        lineHeight: 2,
                        backgroundColor: "#f8f9fa",
                        padding: "20px",
                        borderRadius: "8px",
                        wordSpacing: "4px"
                      }}>
                        {practiceWords[currentWordIndex].verse.split(' ').map((word, idx) => (
                          <span key={idx} style={{
                            color: word === practiceWords[currentWordIndex].word_uthmani ? '#edaf59' : 'inherit',
                            transition: 'color 0.3s ease',
                            fontFamily: "uth"
                          }}>
                            {word}{' '}
                          </span>
                        ))}
                      </div>
                      <div style={{
                        fontSize: "12px",
                        color: "#666",
                        marginBottom: "20px",
                        textAlign: "right",
                        fontStyle: "italic"
                      }}>
                        {practiceWords[currentWordIndex].verse_key}
                      </div>
                      <div style={{
                        fontSize: window.innerWidth <= 480 ? "36px" : "48px",
                        fontWeight: "500",
                        textAlign: "center",
                        fontFamily: "uth",
                        marginBottom: window.innerWidth <= 480 ? "15px" : "20px",
                        color: "#edaf59",
                        textShadow: "1px 1px 1px rgba(0,0,0,0.1)"
                      }}>
                        {practiceWords[currentWordIndex].word_uthmani}
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', gap: window.innerWidth <= 480 ? '8px' : '10px' }}>
                        {practiceOptions.map((option, index) => (
                          <button
                            key={index}
                            onClick={() => handleOptionSelect(option)}
                            disabled={hasAnswered}
                            style={{
                              padding: window.innerWidth <= 480 ? '8px' : '10px',
                              border: '1px solid #ccc',
                              borderRadius: '8px',
                              background: hasAnswered 
                                ? option === correctAnswer 
                                  ? '#4CAF50'
                                  : option === selectedOption && option !== correctAnswer
                                    ? '#f44336'
                                    : 'white'
                                : selectedOption === option 
                                  ? '#fff3e6' 
                                  : 'white',
                              cursor: hasAnswered ? 'default' : 'pointer',
                              textAlign: 'left',
                              color: hasAnswered && (option === correctAnswer || option === selectedOption) 
                                ? 'white' 
                                : 'black',
                              fontSize: window.innerWidth <= 480 ? '14px' : '16px'
                            }}
                          >
                            {option}
                          </button>
                        ))}
                      </div>
                      <div style={{ 
                        marginTop: window.innerWidth <= 480 ? '15px' : '20px',
                        textAlign: 'right',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center' 
                      }}>
                        {hasAnswered && (
                          <div style={{
                            color: selectedOption === correctAnswer ? '#4CAF50' : '#f44336',
                            fontWeight: 500,
                            fontSize: window.innerWidth <= 480 ? '14px' : '16px'
                          }}>
                            {selectedOption === correctAnswer ? 'Correct!' : 'Incorrect'}
                          </div>
                        )}
                        <button
                          onClick={handleNextWord}
                          style={{
                            ...practiceBtnStyle,
                            marginLeft: 'auto',
                            opacity: hasAnswered ? 1 : 0.5,
                            cursor: hasAnswered ? 'pointer' : 'not-allowed',
                            padding: window.innerWidth <= 480 ? '8px 16px' : '12px 24px',
                            fontSize: window.innerWidth <= 480 ? '14px' : '16px'
                          }}
                          disabled={!hasAnswered}
                        >
                          {currentWordIndex === practiceWords.length - 1 ? 'Finish' : 'Next'}
                        </button>
                      </div>
                    </>
                  )}
                </Box>
              </Modal>

              {/* Completion Modal */}
              <Modal
                open={showCompletionModal}
                onClose={handleCompletionClose}
                aria-labelledby="completion-modal"
              >
                <Box sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: window.innerWidth <= 480 ? '90%' : 400,
                  bgcolor: 'background.paper',
                  boxShadow: 24,
                  p: window.innerWidth <= 480 ? 3 : 4,
                  borderRadius: 2,
                  maxWidth: '400px',
                  textAlign: 'center'
                }}>
                  <div style={{
                    fontSize: window.innerWidth <= 480 ? '24px' : '32px',
                    marginBottom: '20px',
                    color: '#34C759'
                  }}>
                    Practice Complete! 🎉
                  </div>
                  <div style={{
                    fontSize: window.innerWidth <= 480 ? '16px' : '18px',
                    marginBottom: '20px',
                    color: '#666'
                  }}>
                    Your Score
                  </div>
                  <div style={{
                    fontSize: window.innerWidth <= 480 ? '36px' : '48px',
                    fontWeight: 'bold',
                    marginBottom: '20px',
                    color: score === practiceWords.length ? '#34C759' : '#666'
                  }}>
                    {score}/{practiceWords.length}
                  </div>
                  <div style={{
                    fontSize: window.innerWidth <= 480 ? '14px' : '16px',
                    marginBottom: '30px',
                    color: '#666'
                  }}>
                    {score === practiceWords.length 
                      ? "Perfect! Amazing work! 🌟"
                      : score >= practiceWords.length * 0.8
                      ? "Great job! Keep it up! 👏"
                      : score >= practiceWords.length * 0.6
                      ? "Good effort! Practice makes perfect! 💪"
                      : "Keep practicing! You'll improve! 📚"}
                  </div>
                  <button
                    onClick={handleCompletionClose}
                    style={{
                      ...practiceBtnStyle,
                      margin: '0 auto',
                      padding: window.innerWidth <= 480 ? '10px 20px' : '12px 24px',
                      fontSize: window.innerWidth <= 480 ? '14px' : '16px'
                    }}
                  >
                    Done
                  </button>
                </Box>
              </Modal>

              <div className="SentenceView-Scroll">
                <div style={{
                  ...SentenceView.mainBox,
                  width: "95%",
                  maxWidth: "800px",
                  minWidth: "unset",
                  padding: "20px 10px",
                  overflowX: "hidden",
                  margin: "0 auto"
                }}>
                  <Stack spacing={2} sx={{ alignItems: 'center', marginBottom: '20px' }}>
                    <Pagination
                      count={totalPages}
                      page={currentPage}
                      onChange={handlePageChange}
                      variant="outlined"
                      shape="rounded"
                      sx={{
                        "& .Mui-selected": {
                          backgroundColor: "rgb(9, 27, 46) !important",
                          color: "white",
                        },
                        "& .MuiPaginationItem-root:not(.Mui-selected)": {
                          border: "1px solid rgba(0, 0, 0, 0.50)",
                        },
                        "& .Mui-disabled": {
                          backgroundColor: "rgba(0, 0, 0, 0.12)",
                        },
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    />
                  </Stack>
                  <div className="sentence-container" style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "10px",
                    width: "100%",
                    borderBottom: "1px solid #eee"
                  }}>
                    <p className="sentece-heading" style={{ margin: 0 }}>WORDS & MEANINGS</p>
                  </div>

                  <div>
                    {userLearning.data?.map((word, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            padding: "12px 20px",
                            marginBottom: "8px"
                          }}
                        >
                          <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            background: "#fff",
                            borderRadius: "12px",
                            border: "1px solid #e0e0e0",
                            padding: "16px 24px",
                            transition: "all 0.2s ease-in-out",
                            '&:hover': {
                              boxShadow: "0 2px 4px rgba(0,0,0,0.1)"
                            }
                          }}>
                            <div style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              gap: "8px"
                            }}>
                              <p style={{
                                margin: 0,
                                fontFamily: "uth",
                                fontSize: "28px",
                                color: "#333",
                                textAlign: "center"
                              }}>
                                {word.word_uthmani}
                              </p>
                              <span style={{
                                fontSize: "15px",
                                color: "#333",
                                fontWeight: "500",
                                textAlign: "center"
                              }}>
                                {word.translation}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={modal_style}>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  {translation}
                </Typography>
              </Box>
            </Modal>
          </div>
          <Footer />
        </div>
      )}
    </>
  );
}

export default SentenceView;
