export const ImagePath = {
    logo: require(`../assets/logo.png`),
    googlebtn: require(`../assets/googlebtn.png`),
    applebtn: require(`../assets/applebtn.png`),
    fbbtn: require(`../assets/fbbtn.png`),
    listIcon: require(`../assets/listIcon.png`),
    downarrow: require(`../assets/down-arrow.png`),
    chalkarrow: require(`../assets/chalkarrow.png`),
    SubscriptionCardbg: require(`../assets/SubscriptionCardbg.png`),
    footerlogo: require(`../assets/footer-logo.png`),
    fbfoot: require(`../assets/fb-foot.png`),
    instafoot: require(`../assets/insta-foot.png`),
    twiterfoot: require(`../assets/twiter-foot.png`),
    youtubefoot: require(`../assets/youtube-foot.png`),
    UpgradePlanbg: require(`../assets/UpgradePlanbg.png`),
    fire: require(`../assets/fire.png`),
    nav2: require(`../assets/nav2.png`),
    nav3: require(`../assets/nav3.png`),
    nav4: require(`../assets/nav4.png`),
    nav5: require(`../assets/nav5.png`),
    ayatbanner: require(`../assets/ayat-banner.png`),
    Ellipse: require(`../assets/Ellipse.png`),
    Ellipse2: require(`../assets/Ellipse2.png`),
    Notification: require(`../assets/Notification-img.png`),
    country: require(`../assets/country.png`),
    profile: require(`../assets/profile.png`),
    vector1: require(`../assets/vector-b.png`),
    vector2: require(`../assets/vector-2.png`),
    vector3: require(`../assets/vector-s.png`),
    vector4: require(`../assets/Vector4.png`),
    vector5: require(`../assets/Vector3.png`),
    voice: require(`../assets/voice.png`),
    skipbtn: require(`../assets/skipbtn.png`),
    First: require(`../assets/icon quran-01.png`),
    leftArrow: require(`../assets/leftArrow.png`),
    RightArrow: require(`../assets/RightArrow.png`),
    PlayBtn: require(`../assets/PlayBtn.png`),
    PageView: require(`../assets/Page-View.png`),
    hamburger: require(`../assets/hamburger.png`),
    search: require(`../assets/search.png`),
    adminprofile: require(`../assets/admin-profile.png`),
    profileava: require(`../assets/profile-avatar.png`),
    sidebarIcon: require(`../assets/sider-icon.png`),
    user: require(`../assets/user.png`),
    optionsbtn: require(`../assets/options-btn.png`),
    PauseIcon: require(`../assets/Vector (3).png`),
    Paypal: require(`../assets/paypal.png`),
    Cross: require(`../assets/cross.png`),
    Move: require(`../assets/move.png`),
    Undo: require(`../assets/Undo.png`),
    UndoText:require(`../assets/UndoText.png`),
    arabicenglishicon: require(`../assets/arabicenglishicon.png`),
    Group1:require(`../assets/Group1.png`),
    Group2:require(`../assets/Group2.png`),
    Group3:require(`../assets/Group3.png`),
    Group4:require(`../assets/Group4.png`),
    Group5:require(`../assets/Group5.png`),
    Group6:require(`../assets/Group6.png`),
    Group7:require(`../assets/Group7.png`),
    Group8:require(`../assets/Group8.png`),
    Group9:require(`../assets/Group9.png`),
};