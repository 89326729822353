import React, { useEffect, useState } from "react";
import Nav from "../Components/Nav";
import Footer from "../Components/Footer";
import AyatComponent from "../Components/PageviewComponents/AyatComponent";
import { ImagePath } from "../Constant/ImgPath";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faCheck, faChevronLeft, faChevronRight, faFileLines } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
// import { useLocation } from 'react-router-dom';
import Tooltip from "@mui/material/Tooltip";
import AdminSidebar from "../Components/Sidebar";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Dialog, DialogContent } from "@mui/material";
import WordCard from "../Components/PageviewComponents/WordCard";
import ButtonGroup from '../Components/PageviewComponents/ButtonGroup';
import ArabicVerse from '../Components/PageviewComponents/ArabicVerse';

import API_BASE_URL from "../apiConfig";
const theme = createTheme({
  direction: "rtl",
});
function SurahPage() {
  const [surahDetail, setSurahDetail] = useState({});
  const [parahDetail, setParahDetail] = useState({});
  const [selectedWord, setSelectedWord] = useState({});
  const [selectedWordindex, setSelectedWordindex] = useState(0);
  const [selectedWordsubindex, setSelectedWordsubindex] = useState(0);
  const [showBox, setShowBox] = useState(false);
  const [isSurah, setIsSurah] = useState("");
  const [freetry, setFreetry] = useState(true);
  const [changesurah, setChangesurah] = useState(0);
  const [surahcurrent, setSurahcurrent] = useState([0, 0]);
  const [knowWords, setKnowWords] = React.useState(false);
  const [refreshHeader, setRefreshHeader] = useState(1);
  const [wordCount, setWordCount] = React.useState(3);
  const [surahpop, setSurahpop] = useState();
  // sidebar start
  const [sidebar, setsidebar] = useState(false);
  const sidemenufun = () => {
    setsidebar(!sidebar);
  };
  const [loginFalse, setLoginFalse] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 991);

  useEffect(() => {
    // Update isMobile state on window resize
    const handleResize = () => setIsMobile(window.innerWidth <= 991);
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    localStorage.getItem("login");
    if (
      localStorage.getItem("login") == "true" ||
      localStorage.getItem("demologin") == "true"
    ) {
      setLoginFalse(true);
    } else {
      navigate("/login");
    }
  }, [localStorage.getItem("login")]);

  const location = useLocation();
  const recievedData = location.state && location.state;
  const [audio] = useState(new Audio(""));

  // const togglePlay = (mp3) => {
  //   // const audioSrc = 'https://linkquran.instantsolutionslab.site/audio/' + mp3;
  //   const audioSrc =
  //     "https://storage.googleapis.com/link-quran-word-audio/final_audios" + mp3;
  //   console.log(audioSrc);
  //   audio.src = audioSrc;
  //   audio.play();
  // };

  const navigate = useNavigate();


  const handleButtonClick = async (payload, isUndoing = false) => {
    const updatedItems = payload.map((item) => {
        if (["New", "Unknown", "Known"].includes(item.status)) {
            let newStatus;
            
            if (!isUndoing) {
                newStatus = "Known";
            } else {
                newStatus = "Unknown";
            }
            
            return {
                key: item.word_key,
                word: item.word,
                translation: item.translation.toLowerCase(),
                status: newStatus,
            };
        }
        return null;
    }).filter(Boolean);
    
    if (updatedItems.length === 0) {
        console.log("No words to update.");
        return;
    }
    
    await handleclickcheck(updatedItems, isUndoing);
};

const handlesingleworkclick = (word, translation, status) => {
  if (!freetry) {
    console.log(surahDetail);

    let color;

    //New Requirement
    if (status === "New") {
      color = "#A4C2F4";
    } else if (status === "Learned") {
      color = "#FBD866";
    } else if (status === "Familiar")   {
      color = "#8CF485";
    } else if (status === "known") {
      color = "#93C47D";
    }

    var statusChange = surahDetail;
    statusChange.words[selectedWordindex][selectedWordsubindex]["status"] =
      status;
    statusChange.words[selectedWordindex][selectedWordsubindex]["color"] =
      color;
      // refreshStats()
    console.log(statusChange);
    localStorage.setItem("freeSurah", JSON.stringify(statusChange));
    setSurahDetail(statusChange);
    setShowBox(false);
    return;
  }

  const apiUrl = `${API_BASE_URL}/user_dashboard/set_word_status`;
  const requestData = {
    [word]: {
      translation: translation,
      status: status,
    },
  };
  const headers = {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("access_token"),
  };
  fetch(apiUrl, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(requestData),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      console.log("API_Data", data);
      setShowBox(false);
      setRefreshHeader((prev) => prev + 1);
      if (isSurah === "T") {
        getFullSurahAPI(surahcurrent[0], surahcurrent[1]);
      } else {
        getFullParahAPI(surahcurrent[0], surahcurrent[1]);
      }
    })
    .catch((error) => {
      console.error("Error fetching API:", error);
    });
};

  useEffect(() => {
    let surah = localStorage.getItem("surahData");
    let parah = localStorage.getItem("parahData");

    if (location.state?.id !== undefined) {
      setIsSurah("T");
      setSurahDetail(JSON.parse(surah));
      console.log("Sura Details", JSON.parse(surah));
    } else if (location.state?.parah !== undefined) {
      setIsSurah("F");
      setParahDetail(JSON.parse(parah));
    }
  }, []);

  useEffect(() => {
    var locadata = localStorage.getItem("freeSurah");
    locadata = JSON.parse(locadata);
    console.log(locadata);
    if (locadata) {
      setFreetry(false);
    }
    setSurahDetail(locadata);
  }, [localStorage.getItem("Demo")]);

  useEffect(() => {
    if (isSurah === "T") {
      getFullSurahAPI(location.state?.id, location.state?.page);
    } else if (isSurah === "F") {
      getFullParahAPI(location.state?.parah, location.state?.page);
    }
  }, [isSurah]);

  const getFullSurahAPI = (sura_id, page) => {
    setSurahcurrent([sura_id, page]);
    setKnowWords(false); // Reset state when changing surahs/pages
    console.log(sura_id, page);
    const apiUrl = `${API_BASE_URL}/user_dashboard/get_sura_detail`;
    var requestData;
    requestData = {
      sura_id: sura_id,
      page: page,
      qari: localStorage.getItem("selectedVoice"),
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("access_token"),
    };
    fetch(apiUrl, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        localStorage.setItem("surahData", JSON.stringify(data));
        setSurahDetail(data);
        setSliderVAlue(data?.current_page);
        console.log("this is my data -------->", data);
      })
      .catch((error) => {
        console.error("Error fetching API:", error);
      });
  };
  const getFullParahAPI = (parah_id, page) => {
    setSurahcurrent([parah_id, page]);
    console.log(parah_id, page);
    const apiUrl = `${API_BASE_URL}/user_dashboard/get_parah_detail`;
    console.log("====", recievedData);
    var requestData;
    console.log(localStorage.getItem("Profile_toggle"));
    requestData = {
      parah: parah_id,
      page: page,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("access_token"),
    };
    fetch(apiUrl, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        localStorage.setItem("parahData", JSON.stringify(data));
        setParahDetail(data);
        setSliderVAlue(data?.current_page);
        console.log("this is my data -------->", data);
      })
      .catch((error) => {
        console.error("Error fetching API:", error);
      });
  };

  const handleSelectedWord = (word, index, subIndex) => {
    setSelectedWord(word);
    setSelectedWordindex(index);
    setSelectedWordsubindex(subIndex);
    setShowBox(true);
    // togglePlay(word.audio);
  };

  const NavigateSurahPages = (data) => {
    setKnowWords(false); // Reset state when navigating
    navigate(data, { state: { id: surahcurrent[0], page: surahcurrent[1] } });
  };

  const NavigateParahPages = (data) => {
    setKnowWords(false); // Reset state when navigating
    navigate(data, {
      state: { parah: surahcurrent[0], page: surahcurrent[1] },
    });
  };
  const handleHover = (event) => {
    event.target.style.border = "1px solid black";
  };

  const handleLeave = (event) => {
    event.target.style.border = "1px solid transparent";
  };
  const [player, setPlayer] = useState(false);
  const [player1, setPlayer1] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [lines, setLines] = useState([]);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [pausedAt, setPausedAt] = useState(0);

  // useEffect(() => {
  //   const detail = isSurah === "T" ? surahDetail : parahDetail;
  //   const audioLines = detail ? detail?.ayah_audio : [];
  //   console.log(audioLines);
  //   setLines(audioLines);
  //   setCurrentIndex(0);
  //   return () => {
  //     if (currentAudio) {
  //       currentAudio.pause();
  //       currentAudio.removeEventListener("ended", handleAudioEnd);
  //     }
  //   };
  // }, [isSurah, surahDetail, parahDetail]);

  // useEffect(() => {
  //   return () => {
  //     if (currentAudio) {
  //       currentAudio.pause();
  //       currentAudio.removeEventListener("ended", handleAudioEnd);
  //     }
  //   };
  // }, [currentAudio]);

  const handleAudioEnd = () => {
    if (currentIndex < lines.length - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    } else {
      setCurrentIndex(0);
      setPlayer(false);
      setIsPlaying(false);
    }
  };

  const handleAudioEnd1 = () => {
    setPlayer1(false);
    setIsPlaying(false);
  };

  // useEffect(() => {
  //   if (
  //     player &&
  //     lines.length > 0 &&
  //     currentIndex < lines.length &&
  //     isSurah === "T"
  //   ) {
  //     let audio = new Audio(
  //       `https://storage.googleapis.com/link-quran-word-audio/${localStorage.getItem(
  //         "selectedVoice"
  //       )}/${lines[currentIndex]}`
  //     );
  //     setCurrentAudio(audio);
  //     audio
  //       .play()
  //       .then(() => {
  //         setIsPlaying(true);
  //         audio.addEventListener("ended", handleAudioEnd);
  //       })
  //       .catch((error) => {
  //         console.error("Error attempting to play audio:", error);
  //       });
  //   }
  // }, [player, currentIndex, lines]);

  const togglePlay1 = () => {
    if (!player) {
      setPlayer(true);
    } else {
      if (currentAudio && isPlaying) {
        currentAudio.pause();
        setIsPlaying(false);
      }
      setPlayer(false);
    }
  };
  // useEffect(() => {
  //   setPlayer1(false);
  //   setCurrentAudio(null);
  //   setIsPlaying(false);
  //   setPausedAt(0);
  // }, [surahcurrent]);
  
  const togglePlay2 = () => {
    if (!player1) {
      if (pausedAt !== 0) {
        currentAudio.currentTime = pausedAt;
        currentAudio.play();
        setIsPlaying(true);
      } else {
        let audio;
        if (isSurah === "T") {
          audio = new Audio(
            `https://storage.googleapis.com/link-quran-word-audio/page_audio_surah/${localStorage.getItem(
              "selectedVoice"
            )}/${String(surahcurrent[1])}_${String(surahcurrent[0])}.mp3`
          );
        } else {
          audio = new Audio(
            `https://storage.googleapis.com/link-quran-word-audio/page_audio/${localStorage.getItem(
              "selectedVoice"
            )}/Page${String(surahcurrent[1]).padStart(3, "0")}.mp3`
          );
        }
        setCurrentAudio(audio);
        audio
          .play()
          .then(() => {
            setIsPlaying(true);
            audio.addEventListener("ended", handleAudioEnd1);
          })
          .catch((error) => {
            togglePlay1();
            console.error("Error attempting to play audio:", error);
          });
      }
      setPlayer1(true);
    } else {
      console.log(currentAudio);
      console.log(isPlaying);
      if (currentAudio && isPlaying) {
        currentAudio.pause();
        setPausedAt(currentAudio.currentTime);
        setIsPlaying(false);
      }
      setPlayer1(false);
    }
  };

  const handleclickcheck = async (wordsToUpdate, isUndoOperation = false) => {
    if (!freetry) {
        console.log("Free trial, no API call.");
        return;
    }

    try {
        const apiUrl = `${API_BASE_URL}/user_dashboard/set_word_status`;
        const normalizedWords = Array.isArray(wordsToUpdate) ? wordsToUpdate : [wordsToUpdate];
        const requestData = normalizedWords.reduce((acc, item) => {
          if (Object.hasOwn(acc, item.key)) {
            const existingValue = acc[item.key];
            if (!Array.isArray(existingValue)) {
              // Convert to an array only if the new translation is different
              if (existingValue.translation !== item.translation) {
                acc[item.key] = [
                  { translation: existingValue.translation, status: existingValue.status },
                  { translation: item.translation, status: item.status }
                ];
              }
            } else {
              // Add to the array only if the translation is unique
              if (!existingValue.some(entry => entry.translation === item.translation)) {
                existingValue.push({ translation: item.translation, status: item.status });
              }
            }
          } else {
            acc[item.key] = { translation: item.translation, status: item.status };
          }
            return acc;
        }, {});

        const headers = {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("access_token"),
        };

        const response = await fetch(apiUrl, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(requestData),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        console.log("API Response:", data);

        // REMOVE THIS ENTIRE BLOCK FOR UNDO TO WORK PROPERLY
        // No additional processing of words is needed

        setShowBox(false);
        setRefreshHeader((prev) => prev + 1);
        // refreshStats()
        // Simply refresh the data after changes
        if (isSurah === "T") {
            getFullSurahAPI(surahcurrent[0], surahcurrent[1]);
        } else {
            getFullParahAPI(surahcurrent[0], surahcurrent[1]);
        }
    } catch (error) {
        console.error("Error in process:", error);
    }
};

  const Surah = {
    bg: {
      // background: "rgb(244, 245, 246)",
      padding: "33px 24px",
    },
    h3: {
      fontSize: "18px",
      fontWeight: "500",
      margin: "0",
    },
    h2: {
      fontSize: "80px",
      fontWeight: "500",
      fontFamily: "uth",
      textAlign: "center",
      margin: "0",
    },
    h5: {
      fontSize: "48px",
      fontWeight: "500",
      textAlign: "center",
      fontFamily: "uth",
      margin: "0",
      padding: "0 10px",
    },
    mainHeading: {
      margin: "0 auto",
      width: "70%",
      display: "flex",
      textAlign: "center",
    },
    heading: {
      width: "40%",
      color: "black",
      fontSize: "17px",
      fontWeight: "600",
    },
    P: {
      width: "40%",
      color: "#818181",
      fontSize: "17px",
      fontWeight: "500",
    },
    voiceSection: {
      // width: "85%",
      // height: "275px",
      minWidth: "340px",
      borderRadius: "30px",
      background: "#FFF",
      boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.40)",
      padding: "16px",
      // marginLeft: "auto",
      position: "fixed",
      // left:"20px",
      // top: "100px",
    },
    voiceDiv: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: "16px",
    },
    h4: {
      color: "#000",
      fontSize: "45px",
      fontWeight: "500",
      fontFamily: "uth",
      margin: "0",
    },
    iconsDiv: {
      padding: "3px",
      borderRadius: "29px",
      height: "30px",
      width: "29px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      border: "1px solid transparent",
    },

    mainIconDiv: {
      display: "flex",
      gap: "8px",
      borderRadius: "19px",
      background: "#FFF",
      boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.40)",
      padding: "18px 24px",
      width: "70%",
      justifyContent: "space-between",
      width: "100%",
      marginTop: "20px",
    },
    search: {
      display: "flex",
      justifyContent: "center",
      marginBottom: "18px",
      paddingTop: "25px",
    },
  };

  const [maxValue, setMaxValue] = useState("50");
  const [sliderVAlue, setSliderVAlue] = useState(0);

  const setSurahPageAPI = (sura_id, page) => {
    const apiUrl = `${API_BASE_URL}/user_dashboard/set_surah_page`;
    const requestData = {
      surah: sura_id,
      page: page,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("access_token"),
    };
    fetch(apiUrl, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        // setSliderVAlue(sura_id)
        getFullSurahAPI(sura_id, page);
        setRefreshHeader((prev) => prev + 1);
      })
      .catch((error) => {
        console.error("Error fetching API:", error);
      });
  };
  const setParahPageAPI = (parah_id, page) => {
    const apiUrl = `${API_BASE_URL}/user_dashboard/set_parah_page`;
    console.log(localStorage.getItem("Profile_toggle"));
    const requestData = {
      parah: parah_id,
      page: page,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("access_token"),
    };
    fetch(apiUrl, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        // setSliderVAlue(parah_id)
        getFullParahAPI(parah_id, page);
      })
      .catch((error) => {
        console.error("Error fetching API:", error);
      });
  };

  const KnownSurah = () => {
    if (freetry) {
      if (isSurah === "T") {
        console.log("Calling Surah Set API");
        setSurahPageAPI(surahDetail?.current_surah, surahDetail?.current_page);
      } else if (isSurah === "F") {
        console.log("Calling Parah Set API");
        setParahPageAPI(parahDetail?.current_parah, parahDetail?.current_page);
      }
    } else {
      //   if(statusChange.words[indexnumber][i]["status"] == "Unknown"){
      //     statusChange.words[indexnumber][i]["status"] = "Known";
      //     statusChange.words[indexnumber][i]["color"] = "transparent";
      //   }
      var statusChange = surahDetail;
      for (let i = 0; i < statusChange?.words?.length; i++) {
        for (let k = 0; k < statusChange?.words[i]?.length; k++) {
          if (statusChange.words[i][k]["status"] == "Unknown") {
            statusChange.words[i][k]["status"] = "Known";
            statusChange.words[i][k]["color"] = "transparent";
          }
        }
      }

      console.log(statusChange);
      localStorage.setItem("freeSurah", JSON.stringify(statusChange));
      setSurahDetail(statusChange);
    }
  };

  const handleMaxChange = (newValue) => {
    console.log(newValue, "slider");
    setSliderVAlue(newValue);
    setKnowWords(false); // Reset state when using slider
    if (isSurah === "T") {
      console.log("Calling Surah API");
      getFullSurahAPI(surahDetail?.current_surah, newValue);
    } else if (isSurah === "F") {
      console.log("Calling Parah API");
      getFullParahAPI(parahDetail?.current_parah, newValue);
    }
  };
  const moveSliderRight = () => {
    const max =
      isSurah === "T" ? surahDetail?.start_page : parahDetail?.start_page;
    setSliderVAlue((prevValue) => {
      const newValue = prevValue > max ? prevValue - 1 : prevValue;
      handleMaxChange(newValue); // Call handleMaxChange with the new value
      return newValue;
    });
    setKnowWords(false); // Reset state when using slider arrows
  };

  // Function to increment the slider value (move left)
  const moveSliderLeft = () => {
    const min = isSurah === "T" ? surahDetail?.end_page : parahDetail?.end_page;
    setSliderVAlue((prevValue) => {
      const newValue = prevValue < min ? prevValue + 1 : prevValue;
      handleMaxChange(newValue); // Call handleMaxChange with the new value
      return newValue;
    });
    setKnowWords(false); // Reset state when using slider arrows
  };

  const [showdefination, setShowdefination] = useState(true);

  useEffect(() => {
    if (surahDetail?.words) {
      const hasNewWords = surahDetail.words.flat().some(item => item.status === "New" || item.status === "Unknown");
      if (!hasNewWords) {
        setKnowWords(true);
      }
    }
  }, [surahDetail]);

  return (
    <>
      {loginFalse && (
        <div>
          {freetry ? (
            <Nav
              headervalue={"nav2"}
              sidemenufun={sidemenufun}
              refreshHeader={refreshHeader}
            />
          ) : (
            <Nav headervalue={"nav1"} />
          )}
          {showBox && !isMobile && (
            <div className="responsive-width" style={{ width: "25%", gap: "8px" }}>
              <WordCard
                word={selectedWord}
                onClose={() => setShowBox(false)}
                onStatusChange={handlesingleworkclick}
              />
            </div>
          )}

          <Dialog
            open={showBox && isMobile}
            onClose={() => setShowBox(false)}
            PaperProps={{
              style: {
                width: "35%",
                minWidth: "340px",
                minHeight: "250px",
                position: "relative",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                borderRadius: "30px",
              },
            }}
          >
            <DialogContent
              style={{
                padding: 0,
                position: "relative",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <WordCard
                word={selectedWord}
                onClose={() => setShowBox(false)}
                onStatusChange={handlesingleworkclick}
              />
            </DialogContent>
          </Dialog>
          <AdminSidebar sidebar={sidebar} sidebaracc={"Mobile-Sidebar"} />
          {isSurah === "T" ? (
            <div className="mobile-view-pages-main" style={Surah.bg}>
              <div className="mobile-view-pages">
                {freetry && (
                  <div
                    className="search progress-bar-pages"
                    style={Surah.search}
                  >
                    <ThemeProvider theme={theme}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <IconButton
                          onClick={moveSliderLeft}
                          aria-label="Move right"
                        >
                          <ArrowBackIosIcon />
                        </IconButton>
                        <Box sx={{ width: 300 }}>
                          <Slider
                            aria-label="Progress"
                            valueLabelDisplay="auto"
                            step={1}
                            marks
                            min={surahDetail?.start_page}
                            max={surahDetail?.end_page}
                            value={sliderVAlue}
                            onChange={(e) => handleMaxChange(e.target.value)}
                          />
                        </Box>
                        <IconButton
                          onClick={moveSliderRight}
                          aria-label="Move left"
                        >
                          <ArrowForwardIosIcon />
                        </IconButton>
                      </div>
                    </ThemeProvider>
                  </div>
                )}
                <div
                  className="pageview-responsive"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div className="responsive-width" style={{ width: "65%" }}>
                    <h3 style={Surah.h2}> سُوْرَۃُ {surahDetail?.["name"]}</h3>
                    {surahDetail?.["show_bism"] !== "" && (
                      <div className="bismillah">
                        {surahDetail?.["show_bism"]}
                      </div>
                    )}
                    <div>
                      {surahDetail?.words?.map((ayah, index) => (
                        <ArabicVerse
                          key={index}
                          words={ayah}
                          showTranslation={showdefination}
                          onWordClick={(word, subIndex) => handleSelectedWord(word, index, subIndex)}
                          verseKey={ayah?.[0]?.key}
                          endIcon={surahDetail?.ayat?.[ayah?.[0]?.key]?.[3]}
                          size="normal"
                        />
                      ))}
                    </div>
                  </div>
                  {/* Modal for mobile view */}
                  <Dialog
                    open={showBox && isMobile}
                    onClose={() => setShowBox(false)}
                    PaperProps={{
                      style: {
                        width: "35%",
                        minWidth: "340px",
                        minHeight: "250px",
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        borderRadius: "30px",
                      },
                    }}
                  >
                    <DialogContent
                      style={{
                        padding: 0,
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      {/* <div
                                            onClick={ () => setShowBox(false) }
                                            style={ {
                                                position: 'absolute',
                                                top: 10,
                                                right: 20,
                                                cursor: 'pointer',
                                            } }
                                        >
                                            <svg width="15" height="15" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.07016 0.501271C2.73337 0.175989 2.28229 -0.0040011 1.81408 6.75047e-05C1.34588 0.00413611 0.897996 0.191938 0.56691 0.523024C0.235824 0.85411 0.0480225 1.30199 0.0439539 1.7702C0.0398853 2.23841 0.219875 2.68948 0.545158 3.02627L9.99694 12.4781L0.545158 21.9298C0.374604 22.0946 0.238564 22.2916 0.144976 22.5095C0.0513889 22.7273 0.00212781 22.9617 6.74228e-05 23.1988C-0.00199296 23.4359 0.0431887 23.671 0.132976 23.8905C0.222763 24.1099 0.355358 24.3093 0.523024 24.477C0.690689 24.6446 0.890068 24.7772 1.10953 24.867C1.32898 24.9568 1.56413 25.002 1.80123 24.9999C2.03834 24.9979 2.27266 24.9486 2.49052 24.855C2.70839 24.7614 2.90543 24.6254 3.07016 24.4548L12.5219 15.0031L21.9737 24.4548C22.3105 24.7801 22.7616 24.9601 23.2298 24.956C23.698 24.952 24.1459 24.7642 24.477 24.4331C24.8081 24.102 24.9959 23.6541 24.9999 23.1859C25.004 22.7177 24.824 22.2666 24.4987 21.9298L15.0469 12.4781L24.4987 3.02627C24.824 2.68948 25.004 2.23841 24.9999 1.7702C24.9959 1.30199 24.8081 0.85411 24.477 0.523024C24.1459 0.191938 23.698 0.00413611 23.2298 6.75047e-05C22.7616 -0.0040011 22.3105 0.175989 21.9737 0.501271L12.5219 9.95306L3.07016 0.501271Z" fill="black" />
                                            </svg>
                                        </div> */}
                      <WordCard
                        word={selectedWord}
                        onClose={() => setShowBox(false)}
                        onStatusChange={handlesingleworkclick}
                      />
                    </DialogContent>
                  </Dialog>
                  {/* Div for desktop view */}

                </div>
                <ButtonGroup 
                player={player1}
                togglePlay={togglePlay2}
                knowWords={knowWords}
                handleMoveToKnown={() => {
                  const wordsToMove = surahDetail?.words.flat().filter(item => 
                    knowWords
                      ? item.status === "Known"
                      : item.status === "New" || item.status === "Unknown"
                  );
                  if (wordsToMove.length > 0) {
                    handleButtonClick(wordsToMove, knowWords);
                  }
                }}
                onNavigateNext={() => {
                  if (surahDetail?.next_surah != null && surahDetail.next_page != null) {
                    getFullSurahAPI(surahDetail.next_surah, surahDetail.next_page);
                  }
                }}
                onNavigatePrev={() => {
                  if (surahDetail?.prev_surah != null && surahDetail.prev_page != null) {
                    getFullSurahAPI(surahDetail.prev_surah, surahDetail.prev_page);
                  }
                }}
                onViewModeToggle={() => NavigateSurahPages("/page-view")}
                newWordsCount={surahDetail?.words
                  .flat()
                  .filter(item => item.status === "New" || item.status === "Unknown")
                  .length}
                canNavigateNext={surahDetail?.next_surah != null && surahDetail.next_page != null}
                canNavigatePrev={surahDetail?.prev_surah != null && surahDetail.prev_page != null}
                isSentenceView={false}
              />
              </div>
            </div>
          ) : (
            <div className="mobile-view-pages-main" style={Surah.bg}>
              <div className="mobile-view-pages">
                <div className="search progress-bar-pages" style={Surah.search}>
                  <ThemeProvider theme={theme}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <IconButton
                        onClick={moveSliderLeft}
                        aria-label="Move right"
                      >
                        <ArrowBackIosIcon />
                      </IconButton>
                      <Box sx={{ width: 300 }}>
                        <Slider
                          aria-label="Progress"
                          valueLabelDisplay="auto"
                          step={1}
                          marks
                          min={parahDetail?.start_page}
                          max={parahDetail?.end_page}
                          value={sliderVAlue}
                          onChange={(e) => handleMaxChange(e.target.value)}
                        />
                      </Box>
                      <IconButton
                        onClick={moveSliderRight}
                        aria-label="Move left"
                      >
                        <ArrowForwardIosIcon />
                      </IconButton>
                    </div>
                  </ThemeProvider>
                </div>
                <div
                  className="pageview-responsive"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div className="responsive-width" style={{ width: "50%" }}>
                    <h3 style={Surah.h2}>{parahDetail?.["name"]}</h3>
                    <AyatComponent
                      selectWord={handleSelectedWord}
                      surah={parahDetail}
                      showdefination={showdefination}
                    />
                    <div className="arrows-css">

                      {parahDetail.prev_surah != "" &&
                        parahDetail.prev_page != "" && (
                          <img
                            onClick={() => {
                              getFullParahAPI(
                                parahDetail.prev_parah,
                                parahDetail.prev_page
                              );
                              window.scrollTo({
                                top: 0,
                                behavior: "smooth",
                              });
                            }}
                            style={{
                              width: "auto",
                              height: "35px",
                              cursor: "pointer",
                            }}
                            src={ImagePath.RightArrow}
                            alt="logo"
                          />
                        )}
                    </div>
                  </div>
                  {showBox && (
                    <div
                      className="responsive-width"
                      style={{
                        width: "35%",
                        justifyContent: "end",
                        position: "relative",
                        marginLeft: "auto",
                      }}
                    >
                      <div
                        onClick={() => {
                          setShowBox(false);
                        }}
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: "20px",
                          cursor: "pointer",
                        }}
                      >
                        <svg
                          width="15"
                          height="15"
                          viewBox="0 0 25 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M3.07016 0.501271C2.73337 0.175989 2.28229 -0.0040011 1.81408 6.75047e-05C1.34588 0.00413611 0.897996 0.191938 0.56691 0.523024C0.235824 0.85411 0.0480225 1.30199 0.0439539 1.7702C0.0398853 2.23841 0.219875 2.68948 0.545158 3.02627L9.99694 12.4781L0.545158 21.9298C0.374604 22.0946 0.238564 22.2916 0.144976 22.5095C0.0513889 22.7273 0.00212781 22.9617 6.74228e-05 23.1988C-0.00199296 23.4359 0.0431887 23.671 0.132976 23.8905C0.222763 24.1099 0.355358 24.3093 0.523024 24.477C0.690689 24.6446 0.890068 24.7772 1.10953 24.867C1.32898 24.9568 1.56413 25.002 1.80123 24.9999C2.03834 24.9979 2.27266 24.9486 2.49052 24.855C2.70839 24.7614 2.90543 24.6254 3.07016 24.4548L12.5219 15.0031L21.9737 24.4548C22.3105 24.7801 22.7616 24.9601 23.2298 24.956C23.698 24.952 24.1459 24.7642 24.477 24.4331C24.8081 24.102 24.9959 23.6541 24.9999 23.1859C25.004 22.7177 24.824 22.2666 24.4987 21.9298L15.0469 12.4781L24.4987 3.02627C24.824 2.68948 25.004 2.23841 24.9999 1.7702C24.9959 1.30199 24.8081 0.85411 24.477 0.523024C24.1459 0.191938 23.698 0.00413611 23.2298 6.75047e-05C22.7616 -0.0040011 22.3105 0.175989 21.9737 0.501271L12.5219 9.95306L3.07016 0.501271Z"
                            fill="black"
                          />
                        </svg>
                      </div>
                      <div style={Surah.voiceSection}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div></div>
                          <span
                            onClick={() => setShowBox(false)}
                            style={{ cursor: "pointer" }}
                          >
                            <svg
                              width="15"
                              height="15"
                              viewBox="0 0 25 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M3.07016 0.501271C2.73337 0.175989 2.28229 -0.0040011 1.81408 6.75047e-05C1.34588 0.00413611 0.897996 0.191938 0.56691 0.523024C0.235824 0.85411 0.0480225 1.30199 0.0439539 1.7702C0.0398853 2.23841 0.219875 2.68948 0.545158 3.02627L9.99694 12.4781L0.545158 21.9298C0.374604 22.0946 0.238564 22.2916 0.144976 22.5095C0.0513889 22.7273 0.00212781 22.9617 6.74228e-05 23.1988C-0.00199296 23.4359 0.0431887 23.671 0.132976 23.8905C0.222763 24.1099 0.355358 24.3093 0.523024 24.477C0.690689 24.6446 0.890068 24.7772 1.10953 24.867C1.32898 24.9568 1.56413 25.002 1.80123 24.9999C2.03834 24.9979 2.27266 24.9486 2.49052 24.855C2.70839 24.7614 2.90543 24.6254 3.07016 24.4548L12.5219 15.0031L21.9737 24.4548C22.3105 24.7801 22.7616 24.9601 23.2298 24.956C23.698 24.952 24.1459 24.7642 24.477 24.4331C24.8081 24.102 24.9959 23.6541 24.9999 23.1859C25.004 22.7177 24.824 22.2666 24.4987 21.9298L15.0469 12.4781L24.4987 3.02627C24.824 2.68948 25.004 2.23841 24.9999 1.7702C24.9959 1.30199 24.8081 0.85411 24.477 0.523024C24.1459 0.191938 23.698 0.00413611 23.2298 6.75047e-05C22.7616 -0.0040011 22.3105 0.175989 21.9737 0.501271L12.5219 9.95306L3.07016 0.501271Z"
                                fill="black"
                              />
                            </svg>
                          </span>
                        </div>
                        <div style={Surah.voiceDiv}>
                          <h3 style={Surah.h3}>Word</h3>
                          <h4 style={Surah.h4}>{selectedWord?.["word"]} </h4>
                        </div>
                        <div style={Surah.voiceDiv}>
                          <h3 style={Surah.h3}>Meaning</h3>
                          <span style={{ fontSize: "14px", color: "#338FFF" }}>
                            {selectedWord?.["translation"]}
                          </span>
                        </div>
                        <div style={Surah.mainIconDiv}>
                          <div
                            onMouseEnter={handleHover}
                            onMouseLeave={handleLeave}
                            onClick={() => {
                              handlesingleworkclick(
                                selectedWord?.word_key,
                                selectedWord?.translation_key,
                                "Unknown"
                              );
                            }}
                            className="num-hover iconsDiv"
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </div>
                          <Tooltip title="New" placement="top">
                            <div
                              onMouseEnter={handleHover}
                              onClick={() => {
                                handlesingleworkclick(
                                  selectedWord?.word_key,
                                  selectedWord?.translation_key,
                                  "New"
                                );
                              }}
                              className="num-hover iconsDiv"
                              onMouseLeave={handleLeave}
                              style={
                                selectedWord.status === "New"
                                  ? { background: selectedWord.color }
                                  : {}
                              }
                            >
                              1
                            </div>
                          </Tooltip>
                          <Tooltip title="Recognized" placement="top">
                            <div
                              onMouseEnter={handleHover}
                              onClick={() => {
                                handlesingleworkclick(
                                  selectedWord?.word_key,
                                  selectedWord?.translation_key,
                                  "Recognized"
                                );
                              }}
                              className="num-hover iconsDiv"
                              onMouseLeave={handleLeave}
                              style={
                                selectedWord.status === "Recognized"
                                  ? { background: selectedWord.color }
                                  : {}
                              }
                            >
                              2
                            </div>
                          </Tooltip>
                          <Tooltip title="Familiar" placement="top">
                            <div
                              onMouseEnter={handleHover}
                              onClick={() => {
                                handlesingleworkclick(
                                  selectedWord?.word_key,
                                  selectedWord?.translation_key,
                                  "Familiar"
                                );
                              }}
                              className="num-hover iconsDiv"
                              onMouseLeave={handleLeave}
                              style={
                                selectedWord.status === "Familiar"
                                  ? { background: selectedWord.color }
                                  : {}
                              }
                            >
                              3
                            </div>
                          </Tooltip>
                          <Tooltip title="Learned" placement="top">
                            <div
                              onMouseEnter={handleHover}
                              onClick={() => {
                                handlesingleworkclick(
                                  selectedWord?.word_key,
                                  selectedWord?.translation_key,
                                  "Learned"
                                );
                              }}
                              className="num-hover iconsDiv"
                              onMouseLeave={handleLeave}
                              style={
                                selectedWord.status === "Learned"
                                  ? {
                                    background: selectedWord.color,
                                    border: "solid 1px #000",
                                  }
                                  : {}
                              }
                            >
                              4
                            </div>
                          </Tooltip>
                          <Tooltip title="Known" placement="top">
                            <div
                              onMouseEnter={handleHover}
                              onClick={() => {
                                handlesingleworkclick(
                                  selectedWord?.word_key,
                                  selectedWord?.translation_key,
                                  "Known"
                                );
                              }}
                              className="num-hover iconsDiv"
                              onMouseLeave={handleLeave}
                              style={
                                selectedWord.status === "Known"
                                  ? {
                                    background: selectedWord.color,
                                    border: "solid 1px #000",
                                  }
                                  : {}
                              }
                            >
                              <FontAwesomeIcon icon={faCheck} />
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <ButtonGroup 
                player={player1}
                togglePlay={togglePlay2}
                knowWords={knowWords}
                handleMoveToKnown={() => {
                  const wordsToMove = parahDetail?.words.flat().filter(item => 
                    knowWords
                      ? item.status === "Known"
                      : item.status === "New" || item.status === "Unknown"
                  );
                  if (wordsToMove.length > 0) {
                    handleButtonClick(wordsToMove, knowWords);
                  }
                }}
                onNavigateNext={() => {
                  if (parahDetail?.next_parah && parahDetail?.next_page) {
                    getFullParahAPI(parahDetail.next_parah, parahDetail.next_page);
                  }
                }}
                onNavigatePrev={() => {
                  if (parahDetail?.prev_parah && parahDetail?.prev_page) {
                    getFullParahAPI(parahDetail.prev_parah, parahDetail.prev_page);
                  }
                }}
                onViewModeToggle={() => NavigateParahPages("/page-view")}
                newWordsCount={parahDetail?.words
                  .flat()
                  .filter(item => item.status === "New" || item.status === "Unknown")
                  .length}
                canNavigateNext={parahDetail?.next_parah && parahDetail?.next_page}
                canNavigatePrev={parahDetail?.prev_parah && parahDetail?.prev_page}
                isSentenceView={false}
              />
              </div>
            </div>
          )}
          <Footer />
        </div>
      )}
    </>
  );
}

export default SurahPage;
