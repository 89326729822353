import React, { useEffect, useState } from 'react';
import Nav from '../Components/Nav';
import Footer from '../Components/Footer';
import SearchBar from '../Components/SearchBar';
import { ImagePath } from '../Constant/ImgPath';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeadphones, faCopy } from '@fortawesome/free-solid-svg-icons';
import { Form } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import AdminSidebar from '../Components/Sidebar';
import API_BASE_URL from '../apiConfig';

function OpenLessons() {
    const navigate = useNavigate();
    const [courseDetail, setCourseDetail] = useState([])
    const [courseDetailbackup, setCourseDetailbackup] = useState([])
    const [totalWords, setTotalWords] = useState(0)
    const [isSurah, setIsSurah] = useState(true)
    const location = useLocation();
    // sidebar start
    const [sidebar, setsidebar] = useState(false);
    const sidemenufun = () => {
        setsidebar(!sidebar);
    }
    const [loginFalse, setLoginFalse] = useState(false)

    useEffect(() => {
        localStorage.getItem("login");
        if(localStorage.getItem("login") == "true"){
            setLoginFalse(true);
        }else{
            navigate("/login");
        }
    }, [localStorage.getItem("login")])
    // sidebar end

    

    // var receivedData = location.state?.parahs ? location.state.parahs : location.state.course;
    // var receivedDatabacup = location.state?.parahs ? location.state.parahs : location.state.course;
    const [receivedData, setReceivedData] = useState(location.state?.parahs ? location.state.parahs : location.state.course)
    const [receivedDatabacup, setReceivedDatabacup] = useState(location.state?.parahs ? location.state.parahs : location.state.course)
    const user = JSON.parse(localStorage.getItem("userData"))
    console.log(location)
    useEffect(() => {
        if (Array.isArray(receivedData)) {
            setIsSurah(false)
            console.log("check check")
        }
        else {
            getSurahs();
        }
    }, [])
    const getSurahs = () => {
        console.log("Surah data retrieval requested for course:", receivedData?.["course_id"]);
        setTotalWords(receivedData?.["new"] + receivedData?.["recognized"] + receivedData?.["familiar"] + receivedData?.["learned"] + receivedData?.["known"] + receivedData?.["unknown"])
        
        /* COMMENTED OUT - Only Dashboard should make API calls to get_course_detail
        const apiUrl = `${API_BASE_URL}/user_dashboard/get_course_detail`;
        const requestData = {
            course_id: receivedData?.["course_id"]
        }
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem("access_token"),
        };
        fetch(apiUrl, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(requestData),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                setCourseDetail(data)
                setCourseDetailbackup(data)
                localStorage.setItem('localdata', "");
            })
            .catch(error => {
                console.error('Error fetching API:', error);
            });
        */
        
        // Try to retrieve cached course data from localStorage instead
        try {
            const cachedCourses = localStorage.getItem('cached_courses');
            if (cachedCourses) {
                const parsed = JSON.parse(cachedCourses);
                console.log("Using cached course data from Dashboard");
                setCourseDetail(parsed);
                setCourseDetailbackup(parsed);
            } else {
                console.log("No cached course data available");
            }
        } catch (e) {
            console.error("Error parsing cached courses:", e);
        }
    }
    const NavigatePages = (data, surah, p) => {
        navigate(data, { state: { id: surah, page: Number(p) } });
    }
    const NavigatePageParah = (data, parah, page) => {
        navigate(data, { state: { parah: parah, page: page } });
    }
    console.log("Course Details", courseDetail)
    console.log("Parah Details", receivedData)
    const OpenLessons = {

        bg: {
            // background: "rgb(244, 245, 246)",
            padding: "33px 24px"
        },
        ayatBanner: {
            height: "336px",
            width: "auto",
            padding: "80px 40px 0px"
        },
        h4: {
            fontSize: "90px",
            fontWeight: "400",
            fontFamily: "ayat-font",
            color: "#fff",
            margin: "0"
        },
        p: {
            fontSize: "18px",
            fontWeight: "600",
            display: "flex",
            gap: "8px",
            alignItems: "center",
            padding: "0px 13px",
            margin: "0"
        },
        UpgradePlan: {
            display: "flex",
            justifyContent: 'space-between',
            height: "48px",
            borderRadius: "23px",
            boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.40)",
            padding: "20px"
        },
        UpgradePlanIcon: {
            color: "#FFF",
            fontSize: "18px",
            fontWeight: "600",
            display: "flex",
            gap: "12px",
            alignItems: "center",
            cursor: "pointer"
        },
        UpgradePlanButton: {
            width: "161px",
            height: "auto",
            borderRadius: "17px",
            background: "#fff",
            padding: "16px 0px",
            fontSize: "18px",
            fontWeight: "600",
            border: "transparent",
            cursor: "pointer",
            color: "#000"
        },
        OpenLessonBtn: {
            width: "161px",
            height: "auto",
            borderRadius: "17px",
            background: "#fff",
            padding: "16px 0px",
            fontSize: "15px",
            fontWeight: "600",
            border: "transparent",
            cursor: "pointer",
            color: "#000"
        },

        buttonlesson: {
            borderRadius: "12px",
            border: "1px solid #676767",
            padding: "10px 18px",
            display: "flex",
            gap: "8px",
            cursor: "pointer",
            background: "transparent",
            margin: "0"
        },
        selectButton: {
            borderRadius: "12px",
            border: "1px solid #676767",
            padding: "8px 18px",
            display: "flex",
            gap: "8px",
            cursor: "pointer",
            background: "transparent"
        },
        rightArea: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
        },
        AreaLeftP: {
            color: "#010101",
            fontSize: "18px",
            fontWeight: "500"
        },
        AreaRightP: {
            color: "#010101",
            fontSize: "18px",
            fontWeight: "700"
        },
        headingList: {
            display: "flex", justifyContent: "space-between", width: "95%",
            margin: '30px auto', flexWrap: "wrap"
        },
        Totalwords: {
            color: "#888",
            fontSize: "18px",
            fontWeight: "400",
            padding: "0 8px",
            border: "none",
            borderRight: "1px solid rgb(136, 136, 136)",
            margin: "0"
        },
        Uniquewords: {
            color: "#888",
            fontSize: "18px",
            fontWeight: "400",
            padding: "0 8px",
            margin: "0"
        },
        headingSecond: {
            display: "flex", alignItems: "center", gap: "19px", marginBottom: "18px"
        },
        mainPage: {
            margin: "0 auto", width: "95%"
        },
        flexText: {
            display: "flex", flexWrap: "wrap", marginTop: "30px",
        },
        ayatbox: {
            margin: "7px 0px",
            fontSize: "35px",
            fontWeight: "300",
            fontFamily: "ayat-font",
            textAlign: "center"
        },
        span: {
            fontSize: "14px",
            fontWeight: "500"
        }
    };
    const handleChange = (event) => {
        const [number, page] = event.target.value.split(',');
        if (isSurah) {
            NavigatePages("/page-view", number, page)
        }
        else {
            NavigatePageParah("/page-view", number, page)
        }
    }

    const SearchResutfunc = (data) =>{
        console.log(data);
        console.log(courseDetail);
        if(data == ""){
            console.log("working");
            setCourseDetail(courseDetailbackup)
            // receivedData = receivedDatabacup
            setReceivedDatabacup(setReceivedData)
        }else{
            if(isSurah){
                const filteredData = courseDetail.filter(item =>
                    item.english_name.toLowerCase().includes(data.toLowerCase())
                );
                setCourseDetail(filteredData);
            }else{
                const filteredData = receivedData.filter(item =>
                    item.name.toLowerCase().includes(data.toLowerCase())
                );
                // receivedData = filteredData
                setReceivedData(filteredData)
            }
        } 
    }
    return (
        <>
            {loginFalse && <div >
                <Nav headervalue={"nav2"} sidemenufun={() => sidemenufun()} />
                <AdminSidebar sidebar={sidebar} sidebaracc={'Mobile-Sidebar'} />
                <div style={OpenLessons.bg}>
                    <div className='mobile-view-pages'>
                        <div className='ayatBanner' style={OpenLessons.ayatBanner}>
                            <h4 className="ayatBannerh4" style={OpenLessons.h4}> سُوْرَۃُ الفَاتِحَة</h4>
                            <button className="ayatBannerbutton btn-hover" onClick={() => {NavigatePages("/page-view", 1, 1)}} style={OpenLessons.OpenLessonBtn}>Open Lessons</button>
                        </div>
                        <div style={OpenLessons.headingList}>
                            <div>
                                <div className='flexText' style={OpenLessons.headingSecond}>
                                    <SearchBar SearchResut={SearchResutfunc} />
                                    {/* <button style={OpenLessons.buttonlesson}> <FontAwesomeIcon icon={faCopy} />Lesson</button> */}
                                    <select onChange={handleChange} defaultValue="none" style={OpenLessons.selectButton}>
                                        <option value="none" disabled>Select</option>
                                        {isSurah ? courseDetail?.map((surah) => (
                                            <option value={`${surah?.sura_number},${surah?.start_page}`}>
                                                {surah?.english_name}
                                            </option>
                                        )) :
                                            receivedData?.map((parah) => (
                                                <option value={`${parah?.parah_number},${parah?.start_page}`}>
                                                    {parah?.name}
                                                </option>
                                            ))}
                                    </select>

                                </div>
                            </div>
                            <div className='landing-Boxs' style={{ width: "30%" }}>
                            <div style={OpenLessons.rightArea}>
                                    <p style={OpenLessons.AreaLeftP}>New words ({((user?.user_info?.["new_words"] / 28164) * 100).toFixed(2)}%)</p>
                                    <p style={OpenLessons.AreaRightP}>{user?.user_info?.["new_words"]}</p>
                                </div>
                                {/* <div style={OpenLessons.rightArea}>
                                <p style={OpenLessons.AreaLeftP}>Recognized words ({((receivedData?.["recognized"]/totalWords)*100).toFixed(2)}%)</p>
                                <p style={OpenLessons.AreaRightP}>{receivedData?.["recognized"]}</p>
                            </div>
                            <div style={OpenLessons.rightArea}>
                                <p style={OpenLessons.AreaLeftP}>Familiar words ({((receivedData?.["familiar"]/totalWords)*100).toFixed(2)}%)</p>
                                <p style={OpenLessons.AreaRightP}>{receivedData?.["familiar"]}</p>
                            </div>
                            <div style={OpenLessons.rightArea}>
                                <p style={OpenLessons.AreaLeftP}>Learned words ({((receivedData?.["learned"]/totalWords)*100).toFixed(2)}%)</p>
                                <p style={OpenLessons.AreaRightP}>{receivedData?.["learned"]}</p>
                            </div> */}
                                <div style={OpenLessons.rightArea}>
                                    <p style={OpenLessons.AreaLeftP}>Known words ({((user?.user_info?.["known_words"] / 28164) * 100).toFixed(2)}%)</p>
                                    <p style={OpenLessons.AreaRightP}>{user?.user_info?.["known_words"]}</p>
                                </div>
                                <div style={OpenLessons.rightArea}>
                                    <p style={OpenLessons.AreaLeftP}>Unknown words ({((user?.user_info?.["unknown_words"] / 28164) * 100).toFixed(2)}%)</p>
                                    <p style={OpenLessons.AreaRightP}>{user?.user_info?.["unknown_words"]}</p>
                                </div>
                            </div>
                        </div>
                        <div style={OpenLessons.mainPage}>
                            <p className='connectp-responsive' style={OpenLessons.p}>Course: Link Quran 101 - Getting Started</p>
                            {isSurah ? courseDetail.map((lesson, index) => (
                                <div className='flexText' key={index} style={OpenLessons.flexText}>
                                    <div className='ayat-box-bg' onClick={() => { NavigatePages("/page-view", lesson?.["sura_number"], lesson?.["start_page"]) }} style={{ cursor: "pointer" }}>
                                        <p style={OpenLessons.ayatbox}>{lesson?.["name"]}</p>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', margin: "20px 0 30px 0" }}>
                                        <p className='connectp-responsive' style={OpenLessons.p}>
                                            {lesson?.["sura_number"]} - {lesson?.["english_name"]}
                                            <span style={OpenLessons.span}>({lesson?.["name_translation"]})</span>
                                            <span style={OpenLessons.span}>({lesson?.["number_of_aya"]})</span>
                                        </p>
                                        <div className='flexText' style={OpenLessons.flexText}>
                                            <div className='color-div'>
                                                <div style={{ background: '#83D6FF' }}></div>{/*lesson.progress.current*/}{lesson?.["unknown"]}{""} New
                                            </div>
                                            <div className='color-div'>
                                                <div style={{ background: '#EDAF59' }}></div>{/*lesson.progress.total*/}{lesson?.["known"]}{""} Learning
                                            </div>
                                            {/* <div style={{ display: 'flex', alignItems: 'center', gap: '11px' }}>
                                            <p style={{ color: '#858585', margin: "0" }}>LevelBeginner 1</p>
                                            <p className='connectp-responsive' style={OpenLessons.p}>
                                                <FontAwesomeIcon icon={faHeadphones} />
                                                {lesson.duration}00:44
                                            </p>
                                        </div> */}
                                        </div>
                                    </div>
                                </div>
                            )) :
                                receivedData?.map((parah, index) => (
                                    <div className='flexText' key={index} style={OpenLessons.flexText}>
                                        <div className='ayat-box-bg' onClick={() => { NavigatePageParah("/page-view", parah?.["parah_number"], parah?.["start_page"]) }} style={{ cursor: "pointer" }}>
                                            <p style={OpenLessons.ayatbox}>{parah?.["name"]}</p>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', margin: "20px 0 30px 0" }}>
                                            <p className='connectp-responsive' style={OpenLessons.p}>
                                                {parah?.["parah_number"]} - {parah?.["name"]}
                                                {/* <span style={OpenLessons.span}>({lesson?.["name_translation"]})</span>
                                        <span style={OpenLessons.span}>({lesson?.["number_of_aya"]})</span> */}
                                            </p>
                                            <div className='flexText' style={OpenLessons.flexText}>
                                                <div className='color-div'>
                                                    <div style={{ background: '#83D6FF' }}></div>{/*lesson.progress.current*/}{parah?.["unknown"]}{""} New
                                                </div>
                                                <div className='color-div'>
                                                    <div style={{ background: '#EDAF59' }}></div>{/*lesson.progress.total*/}{parah?.["known"]}{""} Learning
                                                </div>
                                                {/* <div style={{ display: 'flex', alignItems: 'center', gap: '11px' }}>
                                            <p style={{ color: '#858585', margin: "0" }}>LevelBeginner 1</p>
                                            <p className='connectp-responsive' style={OpenLessons.p}>
                                                <FontAwesomeIcon icon={faHeadphones} />
                                                {lesson.duration}00:44
                                            </p>
                                        </div> */}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
                <Footer />
            </div>}
        </>
    );
}

export default OpenLessons;
