import React, { useState, useEffect } from 'react';
import API_BASE_URL from '../apiConfig';
import { 
  Container, 
  Grid, 
  Typography, 
  Box,
  CircularProgress,
  TextField,
  InputAdornment,
  Card,
  CardContent,
  CardActionArea,
  IconButton,
  Divider,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse,
  Button,
  useMediaQuery
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faSearch, 
  faArrowLeft, 
  faBookmark, 
  faChevronDown, 
  faChevronUp, 
  faBook, 
  faTrash 
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import Nav from '../Components/Nav';
import Footer from '../Components/Footer';
import LibraryBookCard from '../Components/LibraryBookCard';
import AdminSidebar from '../Components/Sidebar';
import { useDebounce } from 'use-debounce';
import Pagination from '@mui/material/Pagination';

function CategoryCard({ category, onClick }) {
  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <CardActionArea onClick={onClick} sx={{ flexGrow: 1 }}>
          <CardContent>
            <Typography 
              gutterBottom 
              variant="h5" 
              component="div" 
              align="right"
              dir="rtl"
              lang="ar"
              sx={{
                fontFamily: '"Amiri", "Noto Naskh Arabic", serif',
                fontSize: '1.2rem',
                fontWeight: 600,
                mb: 2,
                lineHeight: 1.4
              }}
            >
              {category.name}
            </Typography>
            <Typography 
              variant="body2" 
              color="text.secondary" 
              align="right"
              dir="rtl"
              lang="ar"
              sx={{
                fontFamily: '"Amiri", "Noto Naskh Arabic", serif',
                fontSize: '0.9rem'
              }}
            >
              عدد الكتب: {category.book_count}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
}

function BookmarkItem({ bookmark, onDelete, onClick }) {
  return (
    <ListItem
      secondaryAction={
        <IconButton 
          edge="end" 
          aria-label="delete" 
          onClick={() => onDelete(bookmark.id)}
          sx={{ 
            color: 'error.light',
            '&:hover': { 
              color: 'error.main',
              backgroundColor: 'rgba(211, 47, 47, 0.04)'
            } 
          }}
        >
          <FontAwesomeIcon icon={faTrash} size="sm" />
        </IconButton>
      }
      sx={{ 
        borderBottom: '1px solid rgba(0,0,0,0.08)',
        '&:last-child': {
          borderBottom: 'none'
        },
        py: 1.5,
        px: 2
      }}
    >
      <ListItemIcon sx={{ 
        minWidth: '40px', 
        color: 'primary.main',
        alignSelf: 'flex-start',
        mt: 0.5
      }}>
        <FontAwesomeIcon icon={faBook} />
      </ListItemIcon>
      <ListItemText
        primary={
          <Box sx={{ display: 'flex', flexDirection: 'column', mb: 0.5 }}>
            <Typography 
              variant="body1" 
              component="div"
              dir="rtl"
              lang="ar"
              sx={{
                fontFamily: '"Amiri", "Noto Naskh Arabic", serif !important',
                textAlign: 'right',
                fontSize: '1.1rem',
                fontWeight: 600,
                color: '#333',
                mb: 0.5,
                lineHeight: 1.3,
                letterSpacing: '0.01em'
              }}
            >
              {bookmark.book_name}
            </Typography>
            {bookmark.book_author && (
              <Typography
                variant="body2"
                dir="rtl"
                lang="ar"
                sx={{
                  fontFamily: '"Amiri", "Noto Naskh Arabic", serif',
                  textAlign: 'right',
                  fontSize: '0.9rem',
                  color: 'text.secondary',
                  fontStyle: 'italic'
                }}
              >
                {bookmark.book_author}
              </Typography>
            )}
            {bookmark.book_category && (
              <Typography
                variant="caption"
                dir="rtl"
                lang="ar"
                sx={{
                  fontFamily: '"Amiri", "Noto Naskh Arabic", serif',
                  textAlign: 'right',
                  fontSize: '0.8rem',
                  color: 'text.secondary',
                  mt: 0.5,
                  display: 'inline-block',
                  backgroundColor: 'rgba(25, 118, 210, 0.08)',
                  p: '2px 8px',
                  borderRadius: '4px'
                }}
              >
                {bookmark.book_category}
              </Typography>
            )}
          </Box>
        }
        secondary={
          <Button
            variant="outlined"
            size="small"
            onClick={() => onClick(bookmark)}
            startIcon={<FontAwesomeIcon icon={faBookmark} />}
            sx={{ 
              borderRadius: '20px',
              fontFamily: '"Amiri", "Noto Naskh Arabic", serif',
              mt: 1,
              boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
              '&:hover': {
                boxShadow: '0 2px 6px rgba(0,0,0,0.15)'
              }
            }}
          >
            صفحة {bookmark.page_number}
          </Button>
        }
      />
    </ListItem>
  );
}

function LibraryView() {
  const [sidebar, setSidebar] = useState(false);
  const [categories, setCategories] = useState([]);
  const [books, setBooks] = useState([]);
  const [allBooks, setAllBooks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearchQuery] = useDebounce(searchQuery, 500); // 500ms debounce
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [bookmarks, setBookmarks] = useState([]);
  const [loadingBookmarks, setLoadingBookmarks] = useState(false);
  const [bookmarksError, setBookmarksError] = useState(null);
  const [showBookmarks, setShowBookmarks] = useState(false);
  const [viewMode, setViewMode] = useState('allBooks');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalBooks, setTotalBooks] = useState(0);
  const booksPerPage = 20; // Number of books to show per page
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)'); // Check if screen is mobile

  // Add global style for Arabic text in bookmarks
  useEffect(() => {
    // Add a style element to ensure proper Arabic font rendering
    const styleElement = document.createElement('style');
    styleElement.innerHTML = `
      .library-arabic-text [dir="rtl"],
      .library-arabic-text [lang="ar"],
      .library-arabic-text [dir="rtl"] *,
      .library-arabic-text [lang="ar"] * {
        font-family: 'Amiri', 'Noto Naskh Arabic', serif !important;
      }
    `;
    document.head.appendChild(styleElement);
    
    return () => {
      // Clean up when component unmounts
      document.head.removeChild(styleElement);
    };
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${API_BASE_URL}/api/categories`);
        if (!response.ok) throw new Error('Failed to fetch categories');
        const data = await response.json();
        setCategories(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchAllBooks = async () => {
      if (viewMode !== 'allBooks') return;
      
      try {
        setLoading(true);
        
        // Build query parameters for pagination and search
        const queryParams = new URLSearchParams({
          page: page,
          limit: booksPerPage
        });
        
        if (debouncedSearchQuery) {
          queryParams.append('search', debouncedSearchQuery);
        }
        
        const response = await fetch(`${API_BASE_URL}/api/books?${queryParams.toString()}`);
        
        if (!response.ok) throw new Error('Failed to fetch books');
        
        const data = await response.json();
        
        // Check if response has pagination information
        if (data.books) {
          // Backend returns paginated data
          setAllBooks(data.books);
          setTotalPages(data.totalPages || Math.ceil(data.total / booksPerPage));
          setTotalBooks(data.total || 0);
        } else {
          // Fallback if backend doesn't support pagination yet
          setAllBooks(data);
          
          // Simulate pagination on the client side
          const filteredBooks = data.filter(book => 
            !debouncedSearchQuery || 
            book.name.toLowerCase().includes(debouncedSearchQuery.toLowerCase()) ||
            (book.info && book.info.toLowerCase().includes(debouncedSearchQuery.toLowerCase()))
          );
          
          setTotalBooks(filteredBooks.length);
          setTotalPages(Math.ceil(filteredBooks.length / booksPerPage));
          
          // Get only the books for the current page
          const start = (page - 1) * booksPerPage;
          const end = start + booksPerPage;
          setAllBooks(filteredBooks.slice(start, end));
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAllBooks();
  }, [viewMode, page, debouncedSearchQuery]);

  useEffect(() => {
    const fetchBooks = async () => {
      if (!selectedCategory) return;
      
      try {
        setLoading(true);
        const response = await fetch(`${API_BASE_URL}/api/books?category=${selectedCategory.cat_id}`);
        if (!response.ok) throw new Error('Failed to fetch books');
        const data = await response.json();
        setBooks(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBooks();
  }, [selectedCategory]);

  // Fetch bookmarks
  useEffect(() => {
    const fetchBookmarks = async () => {
      try {
        const token = localStorage.getItem('access_token');
        if (!token) {
          console.log('No access token found, skipping bookmark fetch');
          return;
        }

        setLoadingBookmarks(true);
        console.log('Fetching bookmarks from:', `${API_BASE_URL}/user-stats/bookmarks`);
        
        // Check if we have a JWT token format
        const tokenToUse = token.startsWith('Bearer ') ? token : `Bearer ${token}`;
        
        const response = await fetch(`${API_BASE_URL}/user-stats/bookmarks`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': tokenToUse
          }
        });

        console.log('Bookmark API response status:', response.status);
        
        if (!response.ok) {
          const errorText = await response.text();
          console.error('Failed to fetch bookmarks:', errorText);
          throw new Error(`Failed to fetch bookmarks: ${response.status}`);
        }
        
        const data = await response.json();
        console.log('Bookmarks fetched successfully:', data);
        
        // Add detailed debug info for each bookmark
        if (Array.isArray(data)) {
          data.forEach((bookmark, index) => {
            console.log(`Bookmark ${index + 1}:`, {
              id: bookmark.id,
              book_id: bookmark.book_id,
              book_name: bookmark.book_name,
              has_name: !!bookmark.book_name,
              page_number: bookmark.page_number,
              book_author: bookmark.book_author,
              book_category: bookmark.book_category
            });
          });
        }
        
        setBookmarks(data);
        // If data is empty array but status was 200, show empty state correctly
        if (Array.isArray(data) && data.length === 0) {
          console.log('No bookmarks found for user');
        }
      } catch (err) {
        console.error('Error fetching bookmarks:', err);
        setBookmarksError(err.message);
      } finally {
        setLoadingBookmarks(false);
      }
    };

    fetchBookmarks();
  }, []);

  const sidemenufun = () => {
    setSidebar(!sidebar);
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setSearchQuery(''); // Reset search when changing category
  };

  const handleBackClick = () => {
    if (selectedCategory) {
      setSelectedCategory(null);
      setBooks([]);
    } else {
      // If we're in All Books view, go back to Categories
      if (viewMode === 'allBooks') {
        setViewMode('categories');
      }
    }
  };

  const filteredItems = selectedCategory
    ? books
        .filter(book => 
          book.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          (book.info && book.info.toLowerCase().includes(searchQuery.toLowerCase()))
        )
        .sort((a, b) => a.id - b.id)  // Sort books by id
    : viewMode === 'categories'
        ? categories
            .filter(category =>
              category.name.toLowerCase().includes(searchQuery.toLowerCase())
            )
            .sort((a, b) => a.cat_id - b.cat_id)  // Sort categories by cat_id
        : allBooks; // For "All Books" view, data is already filtered by the API

  // Handle bookmark click
  const handleBookmarkClick = (bookmark) => {
    navigate(`/library/book/${bookmark.book_id}`, { 
      state: { initialPage: bookmark.page_number } 
    });
  };

  // Delete bookmark
  const handleDeleteBookmark = async (bookmarkId) => {
    try {
      const token = localStorage.getItem('access_token');
      if (!token) {
        alert('Please log in to manage bookmarks');
        return;
      }

      const response = await fetch(`${API_BASE_URL}/user-stats/bookmarks/${bookmarkId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': token
        }
      });

      if (!response.ok) throw new Error('Failed to delete bookmark');

      // Update bookmarks list
      setBookmarks(currentBookmarks => 
        currentBookmarks.filter(bookmark => bookmark.id !== bookmarkId)
      );
    } catch (error) {
      console.error('Error deleting bookmark:', error);
      alert('Failed to delete bookmark. Please try again.');
    }
  };

  // Toggle between "All Books" and "Categories" view
  const handleViewModeChange = (mode) => {
    setViewMode(mode);
    setSelectedCategory(null);
    setBooks([]);
    setSearchQuery('');
    setPage(1); // Reset to first page when changing view mode
  };

  // Handle page change for pagination
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    // Scroll to top when changing pages
    window.scrollTo(0, 0);
  };

  // Use search input to trigger API calls after debounce
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(1); // Reset to first page when searching
  };

  if (loading && categories.length === 0 && allBooks.length === 0) {
    return (
      <>
        <Nav headervalue="nav2" sidemenufun={sidemenufun} />
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 64px)' }}>
          <CircularProgress />
        </Box>
      </>
    );
  }

  if (error) {
    return (
      <>
        <Nav headervalue="nav2" sidemenufun={sidemenufun} />
        <Box 
          sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            justifyContent: 'center', 
            alignItems: 'center', 
            height: 'calc(100vh - 64px)', 
            color: 'error.main',
            gap: 2
          }}
        >
          <Typography variant="h6">{error}</Typography>
          <button 
            onClick={() => window.location.reload()}
            style={{
              padding: '8px 16px',
              backgroundColor: '#1976d2',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer'
            }}
          >
            Retry
          </button>
        </Box>
      </>
    );
  }

  return (
    <>
      <Nav headervalue="nav2" sidemenufun={sidemenufun} />
      <AdminSidebar sidebar={sidebar} sidebaracc="Mobile-Sidebar" />
      
      <Container maxWidth="lg" sx={{ py: 4 }} className="library-arabic-text">
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 4, gap: 2 }}>
          {selectedCategory ? (
            <IconButton 
              onClick={handleBackClick} 
              sx={{ 
                color: 'primary.main',
                '&:hover': {
                  backgroundColor: 'rgba(25, 118, 210, 0.04)'
                }
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </IconButton>
          ) : (
            <IconButton 
              onClick={() => navigate('/dashboard')} 
              sx={{ 
                color: 'primary.main',
                '&:hover': {
                  backgroundColor: 'rgba(25, 118, 210, 0.04)'
                }
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </IconButton>
          )}
          <Typography 
            variant="h5" 
            dir="rtl"
            lang="ar"
            className="amiri-regular"
            sx={{ 
              flexGrow: 1, 
              textAlign: 'right', 
              direction: 'rtl',
              fontFamily: '"Amiri", "Noto Naskh Arabic", serif',
              fontSize: '1.5rem',
              fontWeight: 600
            }}
          >
            {selectedCategory ? selectedCategory.name : 'المكتبة الإسلامية'}
          </Typography>
        </Box>

        {/* Bookmark Section */}
        {!selectedCategory && (
          <>
            <Paper 
              elevation={1}
              sx={{ 
                mb: 4, 
                borderRadius: '12px', 
                overflow: 'hidden',
                border: '1px solid rgba(0,0,0,0.08)',
                boxShadow: '0 4px 12px rgba(0,0,0,0.05)'
              }}
            >
              <Box
                sx={{
                  p: 2,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  cursor: 'pointer',
                  backgroundColor: 'rgba(25, 118, 210, 0.08)',
                  borderBottom: '1px solid rgba(25, 118, 210, 0.2)',
                  transition: 'all 0.2s ease',
                  '&:hover': {
                    backgroundColor: 'rgba(25, 118, 210, 0.12)'
                  }
                }}
                onClick={() => setShowBookmarks(!showBookmarks)}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                  <Box sx={{
                    bgcolor: 'primary.main',
                    color: 'white',
                    width: 36,
                    height: 36,
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                    <FontAwesomeIcon icon={faBookmark} />
                  </Box>
                  <Typography 
                    variant="h6"
                    dir="rtl"
                    lang="ar"
                    sx={{ 
                      fontFamily: '"Amiri", "Noto Naskh Arabic", serif',
                      fontSize: '1.2rem',
                      fontWeight: 600
                    }}
                  >
                    Bookmarks
                  </Typography>
                  {bookmarks.length > 0 && (
                    <Box sx={{
                      bgcolor: 'primary.main',
                      color: 'white',
                      borderRadius: '10px',
                      px: 1,
                      py: 0.2,
                      fontSize: '0.75rem',
                      fontWeight: 'bold',
                      minWidth: '24px',
                      textAlign: 'center'
                    }}>
                      {bookmarks.length}
                    </Box>
                  )}
                  {loadingBookmarks && <CircularProgress size={16} sx={{ ml: 2 }} />}
                </Box>
                <Box sx={{
                  transition: 'transform 0.3s ease',
                  transform: showBookmarks ? 'rotate(180deg)' : 'rotate(0deg)'
                }}>
                  <FontAwesomeIcon icon={faChevronDown} />
                </Box>
              </Box>
              
              <Collapse in={showBookmarks}>
                <Box sx={{ 
                  p: 0,
                  maxHeight: bookmarks.length > 5 ? '400px' : 'auto',
                  overflowY: bookmarks.length > 5 ? 'auto' : 'visible'
                }}>
                  {bookmarks.length === 0 ? (
                    <Box 
                      sx={{ 
                        textAlign: 'center', 
                        p: 4, 
                        color: 'text.secondary',
                        direction: 'rtl',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 2
                      }}
                    >
                      <FontAwesomeIcon icon={faBookmark} size="2x" opacity={0.3} />
                      <Typography 
                        dir="rtl"
                        lang="ar"
                        sx={{ 
                          fontFamily: '"Amiri", "Noto Naskh Arabic", serif',
                          fontSize: '1rem',
                        }}
                      >
                        {bookmarksError 
                          ? 'Error loading bookmarks'
                          : 'No bookmarks yet'}
                      </Typography>
                      <Typography
                        dir="rtl"
                        lang="ar"
                        sx={{ 
                          fontFamily: '"Amiri", "Noto Naskh Arabic", serif',
                          fontSize: '0.9rem',
                          color: 'text.disabled',
                          maxWidth: '300px'
                        }}
                      >
                        You can add bookmarks while reading by clicking the bookmark icon
                      </Typography>
                    </Box>
                  ) : (
                    <List sx={{ p: 0 }}>
                      {bookmarks.map(bookmark => (
                        <BookmarkItem 
                          key={bookmark.id} 
                          bookmark={bookmark} 
                          onDelete={handleDeleteBookmark}
                          onClick={handleBookmarkClick}
                        />
                      ))}
                    </List>
                  )}
                </Box>
              </Collapse>
            </Paper>

            {/* View Mode Toggle */}
            <Box 
              sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                mb: 3,
                gap: 2
              }}
            >
              <Button 
                variant={viewMode === 'allBooks' ? 'contained' : 'outlined'}
                onClick={() => handleViewModeChange('allBooks')}
                sx={{ 
                  borderRadius: '20px',
                  px: 3,
                  fontWeight: viewMode === 'allBooks' ? 600 : 400
                }}
              >
                All Books
              </Button>
              <Button 
                variant={viewMode === 'categories' ? 'contained' : 'outlined'}
                onClick={() => handleViewModeChange('categories')}
                sx={{ 
                  borderRadius: '20px',
                  px: 3,
                  fontWeight: viewMode === 'categories' ? 600 : 400
                }}
              >
                Categories
              </Button>
            </Box>
          </>
        )}

        <TextField
          fullWidth
          variant="outlined"
          placeholder={selectedCategory || viewMode === 'allBooks' ? "البحث عن الكتب..." : "البحث عن التصنيفات..."}
          value={searchQuery}
          onChange={handleSearchChange}
          sx={{ mb: 3 }}
          inputProps={{
            dir: "rtl",
            lang: "ar",
            className: "amiri-regular",
            style: { fontFamily: '"Amiri", "Noto Naskh Arabic", serif' }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon icon={faSearch} />
              </InputAdornment>
            ),
            sx: { 
              direction: 'rtl',
              fontFamily: '"Amiri", "Noto Naskh Arabic", serif'
            }
          }}
        />

        {/* Display total books count when in All Books mode and searching */}
        {viewMode === 'allBooks' && !selectedCategory && (
          <Box sx={{ mb: 2, textAlign: 'center' }}>
            {loading ? (
              <CircularProgress size={20} sx={{ mr: 1 }} />
            ) : (
              <Typography variant="body2" color="text.secondary">
                {debouncedSearchQuery ? 
                  `${totalBooks} books found matching "${debouncedSearchQuery}"` : 
                  `Showing page ${page} of ${totalPages} (${totalBooks} total books)`}
              </Typography>
            )}
          </Box>
        )}

        <Grid container spacing={2}>
          {loading && !filteredItems.length ? (
            <Box sx={{ width: '100%', textAlign: 'center', py: 8 }}>
              <CircularProgress />
            </Box>
          ) : filteredItems.length === 0 ? (
            <Box sx={{ width: '100%', textAlign: 'center', py: 8 }}>
              <Typography 
                variant="h6" 
                sx={{ color: 'text.secondary', direction: 'rtl' }}
                dir="rtl"
                lang="ar"
                className="library-arabic-text"
              >
                {selectedCategory || viewMode === 'allBooks' 
                  ? 'لا توجد كتب متطابقة مع بحثك' 
                  : 'لا توجد تصنيفات متطابقة مع بحثك'}
              </Typography>
            </Box>
          ) : (
            selectedCategory || viewMode === 'allBooks' ? (
              // Show books (either from a category or all books)
              filteredItems.map(book => (
                <LibraryBookCard 
                  key={book.id} 
                  book={book}
                  onClick={() => {
                    navigate(`/library/book/${book.id}`);
                  }}
                />
              ))
            ) : (
              // Show categories
              filteredItems.map(category => (
                <CategoryCard
                  key={category.cat_id}
                  category={category}
                  onClick={() => handleCategoryClick(category)}
                />
              ))
            )
          )}
        </Grid>

        {/* Pagination for All Books mode */}
        {viewMode === 'allBooks' && !selectedCategory && totalPages > 1 && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <Pagination 
              count={totalPages} 
              page={page} 
              onChange={handlePageChange}
              color="primary"
              size={isMobile ? "small" : "medium"}
              showFirstButton
              showLastButton
            />
          </Box>
        )}
      </Container>
      
      <Footer />
    </>
  );
}

export default LibraryView;
