import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUserProfileData } from '../redux/actions/userProfileAction';

const AuthInitializer = () => {
  const dispatch = useDispatch();
  
  useEffect(() => {
    // Check if user is already logged in
    const isLoggedIn = localStorage.getItem("login") === "true";
    const userDataString = localStorage.getItem("userData");
    
    if (isLoggedIn && userDataString) {
      try {
        const userData = JSON.parse(userDataString);
        // Dispatch to Redux store to restore the user session
        dispatch(setUserProfileData(userData));
        console.log("User session restored from localStorage");
      } catch (e) {
        console.error("Failed to parse user data from localStorage", e);
      }
    }
  }, [dispatch]);
  
  // This component doesn't render anything
  return null;
};

export default AuthInitializer; 