import { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faBell, faGear } from '@fortawesome/free-solid-svg-icons';
import { ImagePath } from '../Constant/ImgPath';

function Notifications({ setIsVisible, userStats }) {
    // const [userStats, setUserStats] = useState([]);

    const handleClose = () => {
        setIsVisible(false);
    };
    const Notification = {
        notification: {
            position: "absolute",
            right: "34px",
            top: "66px",
            width: "23%",
            height: "455px",
            backgroundColor: "#fff",
            border: "1px solid #767676",
            borderRadius: "22px",
            zIndex: "999",
            color: "#000"
        },
        header: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "7px 14px",
        },
        AvatarArea: {
            backgroundColor: "#EAEAEA",
            width: "90%",
            margin: "0 auto",
            borderRadius: "13px",
            height: "126px"
        },
        headerText: {
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "27px",
            display: "flex",
            alignItems: "center",
            gap: "11px"
        },
        AvatarText: {
            margin: "2px 0",
            fontSize: "13px"
        },
        AvatarP: {
            margin: "0",
            fontSize: "12px,",
        },
        secondHeader: {
            display: "flex",
            justifyContent: "space-between",
            padding: "17px 13px",
        },
        notificationImg: {
            width: "34px",
            height: "34px"
        },
        countryImg: {
            width: "25px",
            height: "25px"
        },
        P: {
            margin: "0 auto",
            padding: "0 11px",
            fontWeight: "500"
        },
        Box: {
            padding: "11px", borderRadius: "12px", width: "100%", display: "flex", gap: "11px"
        }
    }


    return (
        <>
            <div className='blur-background'>
                <div className='notification' style={Notification.notification}>
                    <div style={Notification.header}>
                        <header style={Notification.headerText}> <FontAwesomeIcon icon={faBell} />Notifications</header>
                        <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faTimes} onClick={handleClose} />
                    </div>
                    <div className='example'>
                        <div style={Notification.secondHeader} >
                            <p style={Notification.AvatarP}></p>
                            <FontAwesomeIcon icon={faGear} style={{ cursor: "pointer" }} />
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", gap: "11px" }}>
                            {/* serStats */}
                            {userStats?.["user_badges"].map((badge, index) => (
                                <div style={Notification.AvatarArea} key={index}>
                                    <div style={Notification.Box}>
                                        <div>
                                            <div style={{ display: "flex", gap: "7px" }}>
                                                <img style={Notification.countryImg} src={badge.badge_image} alt={badge.badge_name} />
                                                <h3 style={Notification.AvatarText}>{badge.badge_name}</h3>
                                            </div>
                                            {/* Format the date here */}
                                            <p style={{ fontSize: "13px",paddingLeft: "31px" }}>
                                                {new Date(badge.date).toLocaleDateString()}
                                            </p>
                                        </div>
                                    </div>
                                    <p style={{margin: "0px auto",padding: "0px 11px",fontWeight: "500",fontSize: "13px"}}>Congratulations You have unlocked new badge.</p>
                                </div>
                            ))}
                            s
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Notifications;