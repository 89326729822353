import React, { useState } from 'react';

// --- Reusable Styles (Consider moving to a CSS file or shared JS object later) ---
const styles = {
  quizContainer: {
    marginTop: '40px',
    padding: '30px',
    border: '1px solid #e0e0e0',
    borderRadius: '12px',
    backgroundColor: '#ffffff',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)'
  },
  quizTitle: {
    fontSize: '1.4rem',
    fontWeight: '600',
    marginBottom: '25px',
    color: '#334155',
    textAlign: 'center'
  },
  questionRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '15px',
    padding: '15px 0',
    borderBottom: '1px solid #f1f5f9'
  },
  arabicWord: { // Style adjusted for potentially longer phrases
    fontFamily: '"Noto Naskh Arabic", serif',
    fontSize: '1.6rem',
    fontWeight: 'normal',
    color: '#1e293b',
    flex: 1, // Allow phrase to take space
    marginRight: '20px',
    textAlign: 'right'
  },
  optionsContainer: {
    display: 'flex',
    gap: '12px'
  },
  optionButton: {
    padding: '8px 16px',
    border: '1px solid #cbd5e1',
    borderRadius: '6px',
    cursor: 'pointer',
    backgroundColor: 'white',
    color: '#475569',
    transition: 'all 0.2s ease-in-out',
    fontSize: '1.3rem',
    fontFamily: '"Noto Naskh Arabic", serif',
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)'
  },
  optionButtonHover: {
    backgroundColor: '#f8fafc',
    borderColor: '#94a3b8',
    color: '#1e293b'
  },
  optionButtonSelected: {
    borderWidth: '2px',
    boxShadow: 'none'
  },
  optionButtonCorrect: {
    backgroundColor: '#f0fdf4',
    borderColor: '#4ade80',
    color: '#166534'
  },
  optionButtonIncorrect: {
    backgroundColor: '#fef2f2',
    borderColor: '#f87171',
    color: '#991b1b'
  },
  resetButton: {
    display: 'block',
    margin: '25px auto 0',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '6px',
    backgroundColor: '#64748b',
    color: 'white',
    cursor: 'pointer',
    fontSize: '0.95rem',
    fontWeight: '500',
    transition: 'background-color 0.2s'
  },
  resetButtonHover: {
    backgroundColor: '#475569'
  }
};
// --- End Styles ---


const quiz2Data = [
  // Examples from Lesson 2 text where possible
  { id: 1, word: 'مُحَمَّدٌ', correctAnswer: 'مُفْرَدٌ' }, // Single name
  { id: 2, word: 'ذَهَبَ الرَّجُلُ', correctAnswer: 'مُرَكَّبٌ' }, // The man went (verb + noun)
  { id: 3, word: 'فِي المَسْجِدِ', correctAnswer: 'مُرَكَّبٌ' }, // In the musjid (particle + noun)
  { id: 4, word: 'كِتَابٌ', correctAnswer: 'مُفْرَدٌ' }, // Book (example single word)
];

const quiz2Options = ['مُفْرَدٌ', 'مُرَكَّبٌ'];

const LessonQuiz2 = () => {
  const [answers, setAnswers] = useState({});
  const [hoveredOption, setHoveredOption] = useState({ questionId: null, option: null });
  const [hoveredReset, setHoveredReset] = useState(false);

  const handleSelection = (questionId, selectedOption) => {
    const question = quiz2Data.find(q => q.id === questionId);
    if (!question) return;

    const isCorrect = question.correctAnswer === selectedOption;
    setAnswers(prev => ({ ...prev, [questionId]: { selected: selectedOption, status: isCorrect ? 'correct' : 'incorrect' } }));
  };

  const handleReset = () => {
    setAnswers({});
  };

  return (
    <div style={styles.quizContainer}>
      <h4 style={styles.quizTitle}>Quiz 2: <span style={{ fontFamily: '"Noto Naskh Arabic", serif' }}>مُفْرَدٌ أَوْ مُرَكَّبٌ؟</span></h4>
      {quiz2Data.map((question) => {
        const answer = answers[question.id];
        return (
          <div key={question.id} style={styles.questionRow}>
            <span style={styles.arabicWord}>{question.word}</span>
            <div style={styles.optionsContainer}>
              {quiz2Options.map((option) => {
                let buttonStyle = { ...styles.optionButton };
                const isHovered = hoveredOption.questionId === question.id && hoveredOption.option === option;

                if (answer && answer.selected === option) {
                  buttonStyle = { 
                    ...buttonStyle, 
                    ...styles.optionButtonSelected,
                    ...(answer.status === 'correct' ? styles.optionButtonCorrect : styles.optionButtonIncorrect)
                  };
                } else if (isHovered) {
                  buttonStyle = { ...buttonStyle, ...styles.optionButtonHover };
                }

                return (
                  <button 
                    key={option}
                    style={buttonStyle}
                    onClick={() => handleSelection(question.id, option)}
                    onMouseEnter={() => setHoveredOption({ questionId: question.id, option: option })}
                    onMouseLeave={() => setHoveredOption({ questionId: null, option: null })}
                  >
                    {option}
                  </button>
                );
              })}
            </div>
          </div>
        );
      })}
      <button 
        style={{ ...styles.resetButton, ...(hoveredReset ? styles.resetButtonHover : {}) }} 
        onClick={handleReset}
        onMouseEnter={() => setHoveredReset(true)}
        onMouseLeave={() => setHoveredReset(false)}
      >
        Reset Quiz 2
      </button>
    </div>
  );
};

export default LessonQuiz2; 