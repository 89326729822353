import React, { useEffect, useState } from "react";
import { ImagePath } from "../../Constant/ImgPath";
import PasswordInput from "../../Components/PasswordInput";
import { useLocation, useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_BASE_URL from "../../apiConfig/index";
import { Typography, Box, Button } from "@mui/material";
import {Grid, TextField } from "@mui/material";
import singupimage from '../../assets/signupimage.png'
import { useDispatch } from 'react-redux';
import { setUserProfileData } from '../../redux/actions/userProfileAction';

function Login() {
  const location = useLocation();
  const [isChecked, setChecked] = useState(false);
  const [isAdminRole, setIsAdminRole] = useState(false);
  const navigate = useNavigate();
  const plan = location.state && location.state.plan;
  const dispatch = useDispatch();

  const notify = (message) =>
    toast.error(message, {
      position: "top-right",
    });

  const showToastMessage = (message) => {
    toast.success(message, {
      position: "top-right",
    });
  };

  const NavigatePages = (data) => {
    navigate(data);
  };

  useEffect(() => {
    localStorage.setItem("login", "false");
    localStorage.setItem("access_token", "");
    localStorage.setItem("localdata", "");
    localStorage.setItem("userData", "");
    localStorage.clear();
    console.log(location.pathname.includes("admin"));
    if (location.pathname.includes("admin")) {
      setIsAdminRole(true);
    } else {
      setIsAdminRole(false);
    }
  }, [location.pathname]);

  const handleCheckboxChange = () => {
    setChecked(!isChecked);
  };

  const initialFormData = {
    email: "",
    password: "",
  };
  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlePassword = (password) => {
    setFormData({ ...formData, password });
  };

  const handleData = () => {
    let apiUrl;
    if (isAdminRole) {
      apiUrl = `${API_BASE_URL}/admin/admin_login`;
    } else {
      apiUrl = `${API_BASE_URL}/auth/login`;
    }
    
    // Log the actual request data being sent for debugging
    const requestData = {
      email: formData.email,
      password: formData.password,
    };
    //console.log("Login attempt with:", { email: requestData.email, passwordLength: requestData.password.length });
    
    const headers = {
      "Content-Type": "application/json",
    };
    
    // Add timestamp to track request timing
    const requestStartTime = new Date().getTime();
    //console.log(`Login request started at: ${new Date().toISOString()}`);
    
    fetch(apiUrl, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        // Log response timing and status
        const responseTime = new Date().getTime() - requestStartTime;
        //console.log(`Login response received in ${responseTime}ms with status: ${response.status}`);
        
        if (!response.ok) {
          // Capture and log the actual error response
          return response.text().then(text => {
            try {
              // Try to parse as JSON for structured error info
              const errorData = JSON.parse(text);
              console.error("Login error details:", errorData);
              throw new Error(`Login failed: ${errorData.message || 'Unknown error'}`);
            } catch (e) {
              // If not JSON, log as text
              console.error("Raw error response:", text);
              throw new Error(`HTTP error! Status: ${response.status}, Body: ${text.substring(0, 100)}`);
            }
          });
        }
        return response.json();
      })
      .then((data) => {
       // console.log(data);
        // First set all localStorage items
        localStorage.setItem("login", "true");
        localStorage.setItem(
          "access_token",
          "Bearer " + data?.["access_token"]
        );
        localStorage.setItem("localdata", "");
        localStorage.setItem("hideDefinition", "false"); // Set translations to show by default
        let access_token = localStorage.getItem("access_token");
        localStorage.setItem("selectedVoice", "yasserAlDosari");
        localStorage.setItem("selectedTranslator", "saheehinternational");
       // console.log("at====>>", access_token);
        localStorage.setItem("userData", JSON.stringify(data.user_info));
        localStorage.setItem("hasSeenFirstTimeModal", "false");
        
        // Update Redux store with user data
        dispatch(setUserProfileData(data.user_info));
        
        // Add a longer delay to ensure localStorage is set before navigation
        // This helps prevent race conditions in the Dashboard component
        setTimeout(() => {
         // console.log("PLAN======>>", plan);
          console.log("Login successful, navigating to destination");
          if (plan === "monthly" || plan === "yearly") {
            navigate("/payment1", { state: { plan: plan } });
          } else {
            if (isAdminRole) {
              NavigatePages("/admin-dashboard");
            } else {
              // Set a special flag to indicate this is a fresh login
              // This helps the Dashboard component know to show the loading spinner
              localStorage.setItem("freshLogin", "true");
              NavigatePages("/dashboard");
            }
          }
        }, 1500);
      })
      .catch((error) => {
        console.error("Error fetching API:", error);
        
        // Diagnostics to help identify common backend issues
        const errorMessage = error.message || 'Unknown error';
        let suggestedFix = '';
        let userMessage = '';
        
        // Special case: Handle the backend's incorrect use of 500 status for auth failures
        if (errorMessage.includes('Invalid credentials')) {
          // This is actually an authentication error, not a server error
          userMessage = "Invalid email or password. Please try again.";
          suggestedFix = 'Backend is returning 500 status code for auth failures instead of 401. Fix the backend error handling.';
        } else if (errorMessage.includes('JWT') || errorMessage.includes('token')) {
          userMessage = "Authentication error. Please try again.";
          suggestedFix = 'There might be an issue with JWT token generation. Check the backend secret key and token configuration.';
        } else if (errorMessage.includes('database') || errorMessage.includes('SQL') || errorMessage.includes('query')) {
          userMessage = "Database connection error. Please try again later.";
          suggestedFix = 'Database error detected. Check database connection and query execution in the login process.';
        } else if (errorMessage.includes('server error') || errorMessage.includes('500')) {
          userMessage = "Server error. Please try again later or contact support.";
          suggestedFix = 'Server-side error. Check backend logs for exceptions, missing environment variables, or configuration issues.';
        } else {
          userMessage = "Login failed. Please try again later.";
        }
        
        // Log diagnostic info
        console.error('Authentication diagnostic:', {
          error: errorMessage,
          possibleIssue: suggestedFix || 'Unknown',
          endpoint: apiUrl,
          timestamp: new Date().toISOString()
        });
        
        // Show a more user-friendly message
        notify(userMessage);
      });
  };

  const googleLoginAPI = (credentialResponse) => {
    const apiUrl = `${API_BASE_URL}/auth/login_google`;
    const requestData = { credentialResponse: credentialResponse };
    const headers = {
      "Content-Type": "application/json",
    };
    fetch(apiUrl, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        // First set all localStorage items
        localStorage.setItem("login", "true");
        localStorage.setItem("access_token", "Bearer " + data?.["access_token"]);
        localStorage.setItem("localdata", "");
        localStorage.setItem("hideDefinition", "false"); // Set translations to show by default
        localStorage.setItem("selectedVoice", "yasserAlDosari");
        localStorage.setItem("selectedTranslator", "saheehinternational");
        localStorage.setItem("userData", JSON.stringify(data.user_info));
        
        // Update Redux store with user data
        dispatch(setUserProfileData(data.user_info));
        
        showToastMessage("Successfully signed in with Google");
        
        // Longer delay to ensure localStorage is properly set before redirecting
        // This helps prevent race conditions in the Dashboard component
        setTimeout(() => {
          console.log("Google login successful, navigating to dashboard");
          // Set a special flag to indicate this is a fresh login
          localStorage.setItem("freshLogin", "true");
          NavigatePages("/dashboard");
        }, 500);
      })
      .catch((error) => {
        console.error("Error fetching API:", error);
        notify("Error signing in with Google");
      });
  };

  const loginGoogle = useGoogleLogin({
    onSuccess: (credentialResponse) => {
      console.log({ credentialResponse });
      googleLoginAPI(credentialResponse);
    },
  });

  // Add a diagnostic login function for testing
  const runDiagnosticLogin = async () => {
    try {
      // Create a simplified test request
      const testData = {
        email: formData.email,
        password: formData.password
      };
      
      console.log("Running diagnostic login with:", { 
        email: testData.email,
        url: `${API_BASE_URL}/auth/login`
      });
      
      // Make the request with simple parameters
      const response = await fetch(`${API_BASE_URL}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(testData)
      });
      
      // Log the raw response before any processing
      console.log("Diagnostic raw response status:", response.status);
      
      // Get the response text regardless of status code
      const responseText = await response.text();
      console.log("Diagnostic raw response:", responseText);
      
      // Try to parse the response as JSON
      try {
        const jsonData = JSON.parse(responseText);
        console.log("Diagnostic parsed response:", jsonData);
      } catch (e) {
        console.log("Diagnostic response is not valid JSON");
      }
      
      if (response.ok) {
        console.log("Diagnostic login succeeded!");
        showToastMessage("Diagnostic test successful!");
      } else {
        console.error("Diagnostic login failed with status:", response.status);
        notify(`Diagnostic test failed: ${response.status}. Check console for details.`);
      }
    } catch (error) {
      console.error("Diagnostic test exception:", error);
      notify(`Diagnostic error: ${error.message}`);
    }
  };

  return (
    <Box 
      sx={{ 
        minHeight: "100vh", 
        backgroundColor: "black", 
        display: "flex", 
        alignItems: "center",
        justifyContent: "center",
        padding: { xs: "1rem", md: "2rem" }
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "450px",
          backgroundColor: "white",
          borderRadius: "16px",
          padding: "2rem",
          boxShadow: `
            0 0 10px rgba(255, 255, 255, 0.1),
            0 0 20px rgba(255, 255, 255, 0.1),
            0 0 30px rgba(255, 255, 255, 0.1),
            0 0 40px rgba(255, 255, 255, 0.1)
          `,
          transition: "all 0.3s ease",
          '&:hover': {
            boxShadow: `
              0 0 15px rgba(255, 255, 255, 0.2),
              0 0 30px rgba(255, 255, 255, 0.2),
              0 0 45px rgba(255, 255, 255, 0.2),
              0 0 60px rgba(255, 255, 255, 0.2)
            `,
            transform: "translateY(-5px)"
          }
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontWeight: '700',
            color: '#0D0E10',
            fontFamily: 'Roboto, sans-serif',
            fontSize: '32px',
            marginBottom: "1.5rem",
            textAlign: "center"
          }}
        >
          Login
        </Typography>

        <button
          onClick={loginGoogle}
          style={{
            width: "100%",
            border: '1px solid #1E2022',
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '0.625rem',
            background: 'white',
            cursor: 'pointer',
            fontSize: "15px",
            height: '45px',
            fontWeight: "700",
            marginBottom: "1.5rem"
          }}
        >
          <img src={ImagePath.googlebtn} alt="google login" style={{ width: '20px' }} />
          Sign in with Google
        </button>

        <div style={{ position: 'relative', marginBottom: "1.5rem" }}>
          <hr style={{ 
            margin: '0', 
            border: 'none', 
            borderTop: '1px solid #eee'
          }} />
          <span style={{ 
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            padding: '0 0.625rem',
            color: '#666'
          }}>
            or
          </span>
        </div>

        <div style={{ marginBottom: '1rem' }}>
          <label style={{ 
            display: 'block', 
            marginBottom: '4px',
            fontSize: '14px',
            fontWeight: '500',
            color: '#000'
          }}>
            Email
          </label>
          <input
            name="email"
            value={formData.email}
            onChange={handleChange}
            type="email"
            placeholder="example@gmail.com"
            style={{
              width: '100%',
              padding: '12px',
              borderRadius: '8px',
              border: '1px solid #ddd',
              fontSize: '16px',
              boxSizing: 'border-box',
              height: '45px'
            }}
          />
        </div>

        <div style={{ marginBottom: '1.5rem' }}>
          <label style={{ 
            display: 'block', 
            marginBottom: '4px',
            fontSize: '14px',
            fontWeight: '500',
            color: '#000'
          }}>
            Password
          </label>
          <PasswordInput setpassword={handlePassword} placeHolder="Enter your password" />
        </div>

        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '1.5rem'
        }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
              style={{ cursor: 'pointer' }}
            />
            <span style={{ color: '#666', fontSize: '14px' }}>Remember me</span>
          </div>
          <button
            onClick={() => NavigatePages("/forgotpassword")}
            style={{ 
              background: 'none',
              border: 'none',
              color: '#000',
              textDecoration: 'none',
              fontSize: '14px',
              cursor: 'pointer',
              padding: 0
            }}
          >
            Forgot Password?
          </button>
        </div>

        <button 
          onClick={handleData}
          style={{
            width: '100%',
            padding: '12px',
            backgroundColor: '#000',
            color: 'white',
            border: 'none',
            borderRadius: '8px',
            fontSize: '16px',
            fontWeight: '500',
            cursor: 'pointer',
            height: '45px',
            marginBottom: '1rem',
            transition: 'all 0.3s ease',
            '&:hover': {
              backgroundColor: '#333'
            }
          }}
        >
          Login
        </button>

        <p style={{ 
          textAlign: 'center',
          margin: '0',
          color: '#666',
          fontSize: '14px'
        }}>
          Don't have an account?{' '}
          <button
            onClick={() => NavigatePages("/signup")}
            style={{ 
              background: 'none',
              border: 'none',
              color: '#000',
              textDecoration: 'none',
              fontWeight: '500',
              cursor: 'pointer',
              padding: 0
            }}
          >
            Sign Up
          </button>
        </p>

        {/* <Button
          variant="text"
          onClick={runDiagnosticLogin}
          sx={{
            mt: 2,
            fontSize: '0.75rem',
            color: '#999',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: 'rgba(0,0,0,0.05)'
            }
          }}
        >
          Run Diagnostic Test
        </Button> */}

        <ToastContainer />
      </Box>
    </Box>
  );
}

export default Login;
