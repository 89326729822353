import React from "react";
import { ImagePath } from "../Constant/ImgPath";
import { Link } from "react-router-dom";

const AdminSidebar = ({ sidebar }) => {
  const AdminSidebar = {
    sidebar: {
      height: "100vh",
      width: "20%",
      padding: "23px",
      boxShadow: "rgba(45, 80, 43, 0.2) 0px 4px 10px",
      minWidth: "227px",
      position: "fixed",
      top: "12%",
      zIndex: "999",
      backgroundColor: "#fff",
      // border: "1px solid rgba(134, 134, 134, 0.6)"
    },
    TextArea: {
      background: "rgba(252, 192, 108, 0.26)",
      borderRadius: "9px",
      color: "#E89B2F",
      padding: "12px",
      fontWeight: "bold",
      marginTop: "11px",
      borderRadius: "9px",
      cursor: "pointer",
      display: "flex",
      gap: "11px",
      alignItems: "center",
    },
    sidebarIcon: {
      width: "20px",
    },
  };
  return (
    <div
      className={
        sidebar
          ? "AdminSidebar-responsive showsidebar"
          : "AdminSidebar-responsive"
      }
      style={AdminSidebar.sidebar}
    >
      <Link to="/admin-dashboard" style={{ textDecoration: "none" }}>
        <div style={AdminSidebar.TextArea} className="sidebar-TextArea">
          <img style={AdminSidebar.sidebarIcon} src={ImagePath.sidebarIcon} />{" "}
          Dashboard
        </div>
      </Link>
      <Link to="/user-management" style={{ textDecoration: "none" }}>
        <div style={AdminSidebar.TextArea} className="sidebar-TextArea">
          <img style={AdminSidebar.sidebarIcon} src={ImagePath.sidebarIcon} />{" "}
          User Management
        </div>
      </Link>
      <Link to="/transactions" style={{ textDecoration: "none" }}>
        <div style={AdminSidebar.TextArea} className="sidebar-TextArea">
          <img style={AdminSidebar.sidebarIcon} src={ImagePath.sidebarIcon} />{" "}
          Transactions
        </div>
      </Link>
      <Link to="/subscription" style={{ textDecoration: "none" }}>
        <div style={AdminSidebar.TextArea} className="sidebar-TextArea">
          <img style={AdminSidebar.sidebarIcon} src={ImagePath.sidebarIcon} />{" "}
          Subscription Plans
        </div>
      </Link>
    </div>
  );
};

export default AdminSidebar;
