import React from "react";
import infoIcon from "../assets/info-icon.svg";

const MembershipInfo = ({ membership }) => {
  return (
    <>
      <div className="user-info">
        <p className="membership">Membership</p>
        <p className="membership-detail">{membership}</p>
      </div>

      {membership === "Starter" && (
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            gap: "10px",
            margin: "0.5rem 0 1rem 200px"
          }}
        >
          {/* <img src={infoIcon} alt="info" style={{ marginTop: "4px", flexShrink: 0 }} />
          <p className="info-text" style={{ margin: 0 }}>
            We hope you are enjoying our Starter Access. Upgrade to full access
            by clicking below.
          </p> */}
        </div>
      )}

      {membership === "Free" && (
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            gap: "10px",
            margin: "0.5rem 0 1rem 200px"
          }}
        >
          <img src={infoIcon} alt="info" style={{ marginTop: "4px", flexShrink: 0 }} />
          <p className="info-text" style={{ margin: 0 }}>
            You are using our free version with full access
          </p>
        </div>
      )}
    </>
  );
};

export default MembershipInfo;
