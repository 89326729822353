import React, { useState, useEffect } from 'react';
import { Box, Modal, Typography, Button, Grid } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: '85%', sm: '360px' },
  bgcolor: 'background.paper',
  borderRadius: '16px',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
  p: 3.5,
  textAlign: 'center'
};

const listStyle = {
  listStyle: 'none',
  padding: 0,
  margin: '24px 0',
  '& li': {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    margin: '16px 0',
    fontSize: '15px',
    color: '#4B4B4B',
    textAlign: 'left',
    '&::before': {
      content: 'attr(data-number)',
      minWidth: '24px',
      height: '24px',
      backgroundColor: '#000',
      color: '#fff',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '13px',
      fontWeight: '500'
    }
  }
};

const buttonStyle = {
  backgroundColor: '#000',
  color: '#fff',
  padding: '12px 32px',
  minHeight: '44px',  // Minimum touch target size for mobile
  width: '100%',      // Full width on mobile
  maxWidth: '200px',  // But constrained on larger screens
  borderRadius: '8px',
  textTransform: 'none',
  fontSize: '15px',
  fontWeight: '500',
  letterSpacing: '0.3px',
  transition: 'all 0.2s ease',
  border: '2px solid #000',  // Added border
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',  // Added subtle shadow
  display: 'inline-flex',    // For better center alignment
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  margin: '8px 0',          // Added some vertical spacing
  WebkitTapHighlightColor: 'transparent', // Remove tap highlight on mobile
  '&:hover': {
    backgroundColor: '#333',
    borderColor: '#333',
    color: '#fff',
    transform: 'translateY(-1px)',
    boxShadow: '0 4px 8px rgba(0,0,0,0.15)'
  },
  '&:active': {
    transform: 'translateY(0)',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
  }
};

const FirstTimeLoginModal = ({ handleClose, open }) => {
  // Default tab is 'Creating Links'
  const [selectedTab, setSelectedTab] = useState('Creating Links');
  
  // Force re-render to ensure initial selection is visible
  useEffect(() => {
    // Force a re-render to ensure the selection is visible
    const timer = setTimeout(() => {
      setSelectedTab('Creating Links');
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  const renderContent = () => {
    switch (selectedTab) {
      case 'Creating Links':
        return (
          <>
            <Box component="ul" sx={listStyle}>
              {/* <Typography variant="subtitle1" component="h4" sx={{ fontWeight: 'bold', mt: 2 }}>
                Word Tracking
              </Typography> */}
              <li data-number="1">Click any word to mark it as Learning or Known.</li>
              <li data-number="2">Updates throughout the Quran.</li>
              <li data-number="3">'All to Known' button, moves all words (won't change words in Learning).</li>
            </Box>
            {/* <Box component="ul" sx={listStyle}>
              <Typography variant="subtitle1" component="h4" sx={{ fontWeight: 'bold', mt: 2 }}>
                Page Tracking
              </Typography>
              <li data-number="1">Click "Mark Complete" after reviewing a page to track your progress.</li>
            </Box> */}
          </>
        );
      // case 'Taraweeh Mode':
      //   return (
      //     <Box component="ul" sx={listStyle}>
      //       <Typography variant="subtitle1" component="h4" sx={{ fontWeight: 'bold', mt: 2 }}>
      //         Taraweeh Mode
      //       </Typography>
      //       <li data-number="1">Practice listening with your flashcards integrated with the recitation.</li>
      //     </Box>
      //   );
      // case 'Dictionary':
      //   return (
      //     <Box component="ul" sx={listStyle}>
      //       <Typography variant="subtitle1" component="h4" sx={{ fontWeight: 'bold', mt: 2 }}>
      //         Dictionary Use
      //       </Typography>
      //       <li data-number="1">Automatically opens the root in Ejtaal (Hans Wehr, Lanes Lexicon, and more).</li>
      //     </Box>
      //   );
      default:
        return null;
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="welcome-modal"
      aria-describedby="welcome-description"
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(0, 0, 0, 0.6)'
        }
      }}
    >
      <Box sx={style}>
        <Typography variant="h5" component="h2" sx={{ 
          fontWeight: 600, 
          mb: 2,
          fontSize: '22px',
          background: 'linear-gradient(45deg, #000, #333)',
          backgroundClip: 'text',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent'
        }}>
          Welcome to LinkQuran!
        </Typography>
        <Typography variant="h6" component="h3" sx={{ 
          mb: 1, 
          color: '#666',
          fontSize: '16px',
          fontWeight: '500'
        }}>
          How to use:
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
          <Box sx={{ 
            display: 'flex',
            backgroundColor: '#f8f9fa',
            borderRadius: '10px',
            padding: '8px',
            width: '100%',
            maxWidth: '500px',
            gap: '10px',
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)'
          }}>
            {/* Creating Links Button */}
            <Button
              disableElevation
              onClick={() => setSelectedTab('Creating Links')}
              sx={{
                flex: 1,
                minHeight: '44px',
                fontWeight: selectedTab === 'Creating Links' ? 600 : 400,
                textTransform: 'none',
                fontSize: '14px',
                borderRadius: '8px',
                backgroundColor: selectedTab === 'Creating Links' ? '#1976D2 !important' : 'white !important',
                color: selectedTab === 'Creating Links' ? 'white !important' : '#333 !important',
                border: selectedTab === 'Creating Links' ? 'none !important' : '1px solid #e0e0e0 !important',
                boxShadow: selectedTab === 'Creating Links' ? '0 4px 8px rgba(25, 118, 210, 0.25) !important' : 'none !important',
                transition: 'all 0.2s ease-in-out !important',
                '&:hover': {
                  backgroundColor: selectedTab === 'Creating Links' ? '#1565C0 !important' : '#f8f9fa !important',
                  transform: 'translateY(-1px)',
                  boxShadow: selectedTab === 'Creating Links' ? '0 6px 12px rgba(25, 118, 210, 0.3) !important' : '0 2px 5px rgba(0, 0, 0, 0.08) !important'
                }
              }}
            >
              Word Status
            </Button>
            {/* Dictionary Button */}
            {/* <Button
              variant={selectedTab === 'Dictionary' ? 'contained' : 'outlined'}
              color="primary"
              onClick={() => setSelectedTab('Dictionary')}
              sx={{
                flex: 1,
                minHeight: '44px',
                fontWeight: selectedTab === 'Dictionary' ? 600 : 400,
                textTransform: 'none',
                fontSize: '14px',
                borderRadius: '8px',
                backgroundColor: selectedTab === 'Dictionary' ? '#1976D2 !important' : 'white !important',
                color: selectedTab === 'Dictionary' ? 'white !important' : '#333 !important',
                border: selectedTab === 'Dictionary' ? 'none !important' : '1px solid #e0e0e0 !important',
                boxShadow: selectedTab === 'Dictionary' ? '0 4px 8px rgba(25, 118, 210, 0.25) !important' : 'none !important',
                transition: 'all 0.2s ease-in-out !important',
                '&:hover': {
                  backgroundColor: selectedTab === 'Dictionary' ? '#1565C0 !important' : '#f8f9fa !important',
                  transform: 'translateY(-1px)',
                  boxShadow: selectedTab === 'Dictionary' ? '0 6px 12px rgba(25, 118, 210, 0.3) !important' : '0 2px 5px rgba(0, 0, 0, 0.08) !important'
                }
              }}
            >
              Dictionary
            </Button> */}
            {/* Taraweeh Mode Button */}
            {/* <Button
              variant={selectedTab === 'Taraweeh Mode' ? 'contained' : 'outlined'}
              color="primary"
              onClick={() => setSelectedTab('Taraweeh Mode')}
              sx={{
                flex: 1,
                minHeight: '44px',
                fontWeight: selectedTab === 'Taraweeh Mode' ? 600 : 400,
                textTransform: 'none',
                fontSize: '14px',
                borderRadius: '8px',
                backgroundColor: selectedTab === 'Taraweeh Mode' ? '#1976D2 !important' : 'white !important',
                color: selectedTab === 'Taraweeh Mode' ? 'white !important' : '#333 !important',
                border: selectedTab === 'Taraweeh Mode' ? 'none !important' : '1px solid #e0e0e0 !important',
                boxShadow: selectedTab === 'Taraweeh Mode' ? '0 4px 8px rgba(25, 118, 210, 0.25) !important' : 'none !important',
                transition: 'all 0.2s ease-in-out !important',
                '&:hover': {
                  backgroundColor: selectedTab === 'Taraweeh Mode' ? '#1565C0 !important' : '#f8f9fa !important',
                  transform: 'translateY(-1px)',
                  boxShadow: selectedTab === 'Taraweeh Mode' ? '0 6px 12px rgba(25, 118, 210, 0.3) !important' : '0 2px 5px rgba(0, 0, 0, 0.08) !important'
                }
              }}
            >
              Taraweeh Mode
            </Button> */}
          </Box>
        </Box>
        {renderContent()}

        <Button 
          onClick={handleClose} 
          sx={buttonStyle}
          disableElevation
          variant="contained"
        >
          Get Started
        </Button>
      </Box>
    </Modal>
  );
};

export default FirstTimeLoginModal;
