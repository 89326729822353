import { USER_PROFILE_DATA } from "../types/type";

const initial_state = {
  user_profile_data: null,
  isAuthenticated: false,
  token: null
};

export const userProfileReducer = (state = initial_state, action) => {
  const { payload } = action;
  switch (action.type) {
    case USER_PROFILE_DATA:
      // When user profile data is updated, also set authentication state
      return {
        ...state,
        user_profile_data: payload,
        isAuthenticated: payload !== null,
        // Try to get token from localStorage if not already in state
        token: state.token || localStorage.getItem("access_token") || null
      };
    default:
      return state;
  }
};