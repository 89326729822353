import React, { useState, useEffect, useCallback } from "react";
import Nav from "../Components/Nav";
import Footer from "../Components/Footer";
import Sidebar from "../Components/Sidebar";
import DictionaryMode from "../Components/Analysis/DictionaryMode";
import NahwMode from "../Components/Analysis/NahwMode";
import WritingMode from "../Components/Analysis/WritingMode";
import { 
  Box, 
  Typography, 
  Container, 
  CircularProgress, 
  Paper,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faBook,
  faChalkboardTeacher,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";

function ArabicDictionary() {
  const navigate = useNavigate();
  const [sidebar, setSidebar] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loginFalse, setLoginFalse] = useState(false);
  const [recentSearches, setRecentSearches] = useState([
    "مرحبا", "كتاب", "قلم", "مدرسة", "بيت"
  ]);
  const [analysisMode, setAnalysisMode] = useState('dictionary');

  // Sidebar toggle function
  const sidemenufun = () => {
    setSidebar(!sidebar);
  };

  // Handle analysis mode change
  const handleAnalysisModeChange = (event, newMode) => {
    if (newMode !== null) {
      setAnalysisMode(newMode);
    }
  };

  // Handle recent search
  const handleRecentSearch = (term) => {
    if (!recentSearches.includes(term)) {
      setRecentSearches(prev => [term, ...prev.filter(item => item !== term)].slice(0, 5));
    }
  };

  // Authentication check function
  const checkAuth = useCallback(async () => {
    try {
      const token = localStorage.getItem("access_token");
      const isLoggedIn = localStorage.getItem("login") === "true";
      
      if (!isLoggedIn || !token) {
        navigate("/login");
        return false;
      }
      
      return true;
    } catch (error) {
      console.error('Error during authentication check:', error);
      navigate("/login");
      return false;
    }
  }, [navigate]);

  // Authentication effect
  useEffect(() => {
    setIsLoading(true);
    
    const initializeAuth = async () => {
      const isAuthenticated = await checkAuth();
      if (isAuthenticated) {
        setLoginFalse(true);
        setIsAuthenticated(true);
      }
      setIsLoading(false);
    };
    
    initializeAuth();
  }, [checkAuth]);

  // Render login screen if not authenticated
  if (!loginFalse) {
    return (
      <div>
        <Nav headervalue={"nav2"} sidemenufun={() => sidemenufun()} />
        <Sidebar sidebar={sidebar} sidebaracc={"Mobile-Sidebar"} />
        <div style={{ 
          padding: '40px 20px',
          backgroundColor: '#f5f5f5',
          minHeight: 'calc(100vh - 64px)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <Paper
            elevation={2}
            sx={{
              borderRadius: '16px',
              padding: '40px',
              maxWidth: '450px',
              width: '100%',
              textAlign: 'center'
            }}
          >
            <Typography variant="h4" sx={{ fontWeight: 700, mb: 3 }}>
              Please Login
            </Typography>
            <Typography variant="body1" sx={{ color: '#555', mb: 3 }}>
              You need to be logged in to access the Arabic Dictionary features.
            </Typography>
          </Paper>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Nav headervalue={"nav2"} sidemenufun={() => sidemenufun()} />
      <Sidebar sidebar={sidebar} sidebaracc={"Mobile-Sidebar"} />
      <div style={{ 
        backgroundColor: '#f5f5f5', 
        minHeight: 'calc(100vh - 64px)',
        backgroundImage: 'linear-gradient(to bottom, #f8f8f8, #f2f2f2)'
      }}>
        {isLoading ? (
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '60vh',
            color: '#222'
          }}>
            <CircularProgress color="inherit" size={54} />
            <Typography variant="h6" sx={{ mt: 3, fontWeight: 500 }}>
              Loading...
            </Typography>
          </Box>
        ) : (
          <Container maxWidth="lg">
            <Box sx={{ 
              minHeight: 'calc(100vh - 200px)',
              padding: '24px 28px',
              backgroundColor: '#fff',
              borderRadius: '16px',
              marginTop: '24px',
              marginBottom: '24px',
              boxShadow: '0 4px 20px rgba(0,0,0,0.06)'
            }}>
              <Typography 
                variant="h4" 
                component="h1" 
                sx={{ 
                  textAlign: 'center',
                  color: '#111',
                  marginBottom: '28px',
                  fontWeight: 700,
                  letterSpacing: '-0.02em',
                  fontSize: {
                    xs: '1.5rem',
                    sm: '1.75rem',
                    md: '2.25rem'
                  }
                }}
              >
                Arabic AI Dictionary/Nahw Analyzer
              </Typography>
              
              {/* Mode Selection */}
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
                <ToggleButtonGroup
                  value={analysisMode}
                  exclusive
                  onChange={handleAnalysisModeChange}
                  aria-label="analysis mode"
                  sx={{
                    flexWrap: { xs: 'wrap', sm: 'nowrap' },
                    justifyContent: 'center',
                    '.MuiToggleButtonGroup-grouped': {
                      borderRadius: 12,
                      borderColor: '#d8d8d8',
                      m: 0.5,
                      padding: { xs: '6px 12px', sm: '8px 16px' },
                      '&.Mui-selected': {
                        backgroundColor: '#111',
                        color: 'white',
                        '&:hover': {
                          backgroundColor: '#333',
                        },
                      },
                    },
                  }}
                >
                  <ToggleButton value="dictionary" aria-label="dictionary mode">
                    <FontAwesomeIcon icon={faBook} style={{ marginRight: '8px' }} />
                    Dictionary
                  </ToggleButton>
                  <ToggleButton value="nahw" aria-label="grammar mode">
                    <FontAwesomeIcon icon={faChalkboardTeacher} style={{ marginRight: '8px' }} />
                    Nahw Analysis
                  </ToggleButton>
                  <ToggleButton value="writing" aria-label="writing mode">
                    <FontAwesomeIcon icon={faEdit} style={{ marginRight: '8px' }} />
                    Writing Analysis
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
              
              {/* Analysis Components */}
              {analysisMode === 'dictionary' && (
                <DictionaryMode onRecentSearch={handleRecentSearch} />
              )}
              
              {analysisMode === 'nahw' && (
                <NahwMode onRecentSearch={handleRecentSearch} />
              )}
              
              {analysisMode === 'writing' && (
                <WritingMode />
              )}
            </Box>
          </Container>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default ArabicDictionary;