import React from "react";
import infoIcon from "../assets/info-icon.svg";

const AutoRenewalInfo = ({ paymentStatus, handleCancelSubscription, userDetails }) => {
  const formatExpiryDate = () => {
    const billingInfo = userDetails?.billing_info;
    if (!billingInfo || !billingInfo.last_payment?.time) return '';
    
    const lastPaymentDate = new Date(billingInfo.last_payment.time);
    const nextPaymentDate = new Date(lastPaymentDate);
    
    // Add one month based on the actual day of last payment
    nextPaymentDate.setMonth(nextPaymentDate.getMonth() + 1);
    
    return `Next payment due on: ${nextPaymentDate.toLocaleString('en-US', { 
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    })}`;
  };
  
  // Format the access expiration date for canceled subscriptions
  const formatAccessEndDate = () => {
    // If the subscription is canceled, look for the end_date from the subscription details
    if (userDetails?.end_date) {
      const endDate = new Date(userDetails.end_date);
      return endDate.toLocaleString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
      });
    }
    
    // If end_date isn't available, estimate 30 days from current date as fallback
    const estimatedDate = new Date();
    estimatedDate.setDate(estimatedDate.getDate() + 30);
    return estimatedDate.toLocaleString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    });
  };

  return (
    <>
      <div className="user-info">
        <p className="membership">Subscription</p>
        <div className="membership-detail" style={{ gap: '0.5rem' }}>
          <span style={{ display: 'flex', alignItems: 'center', height: '28px' }}>Status: {paymentStatus}</span>
          
          {paymentStatus === "Active" && (
            <button
              onClick={handleCancelSubscription}
              style={{
                background: '#f44336',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                padding: '6px 12px',
                cursor: 'pointer'
              }}
            >
              Cancel Subscription
            </button>
          )}
        </div>
      </div>

      {paymentStatus === "Canceled" && (
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            gap: "10px",
            margin: "0.5rem 0 1rem 200px"
          }}
        >
          <img src={infoIcon} alt="info" style={{ marginTop: "4px", flexShrink: 0 }} />
          <p className="info-text" style={{ margin: 0 }}>
            Your subscription is canceled. Thank you for your support.
          </p>
        </div>
      )}

      <div className="user-info">
        <p className="membership">{paymentStatus === "Active" ? "Auto-Renewal Date" : "Access Until"}</p>
        <p className="membership-detail">
          {paymentStatus === "Active" ? formatExpiryDate() : formatAccessEndDate()}
        </p>
      </div>
    </>
  );
};

export default AutoRenewalInfo;
