import React, { useState, useEffect } from 'react';
import { CircularProgress, Typography, Box } from '@mui/material';
import API_BASE_URL from '../apiConfig';

const WordProgressIndicator = () => {
  const [percentage, setPercentage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchWordPercentage = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${API_BASE_URL}/user-stats/words-percentage`, {
          method: 'GET',
          headers: {
            'Authorization': localStorage.getItem('access_token')
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch word percentage');
        }

        const data = await response.json();
        setPercentage(data.percentage || 0);
        setError(null);
      } catch (err) {
        console.error('Error fetching word percentage:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchWordPercentage();

    // Set up a refresh interval (every 5 minutes)
    const intervalId = setInterval(fetchWordPercentage, 5 * 60 * 1000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  // Determine color based on percentage - adjusted for total words count 
  // Lower thresholds since the percentage will be smaller when using all word occurrences
  const getColor = () => {
    if (percentage < 1) return '#FF5252'; // Red
    if (percentage < 5) return '#FF9800'; // Orange
    if (percentage < 10) return '#FFEB3B'; // Yellow
    return '#4CAF50'; // Green
  };

  // If there was an error, show a muted version
  if (error) {
    return (
      <Box
        sx={{
          position: 'relative',
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#f0f0f0',
          borderRadius: '50%',
          padding: '1px',
          opacity: 0.7
        }}
      >
        <CircularProgress
          variant="determinate"
          value={0}
          size={34}
          thickness={3}
          sx={{ color: '#bdbdbd' }}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
            fontSize="0.65rem"
            fontWeight="bold"
          >
            ?%
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f5f5f5',
        borderRadius: '50%',
        padding: '1px',
        boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
      }}
    >
      <CircularProgress
        variant="determinate"
        value={loading ? 10 : (percentage > 100 ? 100 : percentage)}
        size={34}
        thickness={3}
        sx={{ 
          color: loading ? '#bdbdbd' : getColor(),
          animation: loading ? 'pulse 1.5s infinite ease-in-out' : 'none',
          '@keyframes pulse': {
            '0%': { opacity: 0.6 },
            '50%': { opacity: 1 },
            '100%': { opacity: 0.6 }
          }
        }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          fontSize="0.65rem"
          fontWeight="bold"
        >
          {loading ? '...' : `${Math.round(percentage)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

export default WordProgressIndicator; 