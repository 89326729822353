import React, { useState } from 'react';

const quizData = [
  { id: 1, word: 'كَتَبَ', correctAnswer: 'فِعْلٌ' }, // Wrote
  { id: 2, word: 'رَجُلٌ', correctAnswer: 'اِسْمٌ' },  // Man
  { id: 3, word: 'فِي', correctAnswer: 'حَرْفٌ' }, // In
  { id: 4, word: 'يَدْرُسُ', correctAnswer: 'فِعْلٌ' }, // He studies
];

// Use Arabic terms for options and ensure consistency with correctAnswer
const options = ['اِسْمٌ', 'فِعْلٌ', 'حَرْفٌ'];

// Improved Styles
const styles = {
  quizContainer: {
    marginTop: '40px',
    padding: '30px',
    border: '1px solid #e0e0e0',
    borderRadius: '12px', // More rounded
    backgroundColor: '#ffffff', // Clean white background
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)' // Softer shadow
  },
  quizTitle: {
    fontSize: '1.4rem', // Slightly larger
    fontWeight: '600', // Bolder
    marginBottom: '25px',
    color: '#334155', // Darker grey-blue
    textAlign: 'center'
  },
  questionRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '15px',
    padding: '15px 0',
    borderBottom: '1px solid #f1f5f9' // Lighter border
  },
  arabicWord: {
    fontFamily: '"Noto Naskh Arabic", serif', // Specific Arabic font
    fontSize: '1.6rem',
    fontWeight: 'normal',
    color: '#1e293b', // Darker text color
    minWidth: '100px', 
    textAlign: 'right'
  },
  optionsContainer: {
    display: 'flex',
    flexWrap: 'wrap', // Allow buttons to wrap
    justifyContent: 'flex-end', // Align buttons to the right on wrap
    gap: '8px' // Slightly reduce gap to help with wrapping
  },
  optionButton: {
    padding: '8px 16px', // More padding
    border: '1px solid #cbd5e1', // Grey border
    borderRadius: '6px',
    cursor: 'pointer',
    backgroundColor: 'white',
    color: '#475569', // Grey text
    transition: 'all 0.2s ease-in-out',
    fontSize: '1.3rem', // Increase font size
    fontFamily: '"Noto Naskh Arabic", serif',
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)' // Subtle shadow
  },
  optionButtonHover: { // Added hover style
    backgroundColor: '#f8fafc',
    borderColor: '#94a3b8',
    color: '#1e293b'
  },
  optionButtonSelected: {
    borderWidth: '2px',
    // fontWeight: 'bold', // Removed, relying on color/border
    boxShadow: 'none'
  },
  optionButtonCorrect: {
    backgroundColor: '#f0fdf4', // Lighter green
    borderColor: '#4ade80',
    color: '#166534' // Darker green text
  },
  optionButtonIncorrect: {
    backgroundColor: '#fef2f2', // Lighter red
    borderColor: '#f87171',
    color: '#991b1b' // Darker red text
  },
  resetButton: {
    display: 'block',
    margin: '25px auto 0',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '6px',
    backgroundColor: '#64748b', // Slate grey
    color: 'white',
    cursor: 'pointer',
    fontSize: '0.95rem',
    fontWeight: '500',
    transition: 'background-color 0.2s'
  },
  resetButtonHover: { // Added hover style
    backgroundColor: '#475569' // Darker slate
  }
};

const LessonQuiz = () => {
  const [answers, setAnswers] = useState({});
  const [hoveredOption, setHoveredOption] = useState({ questionId: null, option: null });
  const [hoveredReset, setHoveredReset] = useState(false);

  const handleSelection = (questionId, selectedOption) => {
    const question = quizData.find(q => q.id === questionId);
    if (!question) return;

    const isCorrect = question.correctAnswer === selectedOption;
    setAnswers(prev => ({ ...prev, [questionId]: { selected: selectedOption, status: isCorrect ? 'correct' : 'incorrect' } }));
  };

  const handleReset = () => {
    setAnswers({});
  };

  return (
    <div style={styles.quizContainer}>
      <h4 style={styles.quizTitle}>Quiz 1: <span style={{ fontFamily: '"Noto Naskh Arabic", serif' }}>اِسْمٌ، فِعْلٌ، أَوْ حَرْفٌ؟</span></h4>
      {quizData.map((question) => {
        const answer = answers[question.id];
        return (
          <div key={question.id} style={styles.questionRow}>
            <span style={styles.arabicWord}>{question.word}</span>
            <div style={styles.optionsContainer}>
              {options.map((option) => {
                let buttonStyle = { ...styles.optionButton };
                const isHovered = hoveredOption.questionId === question.id && hoveredOption.option === option;

                if (answer && answer.selected === option) {
                  buttonStyle = { 
                    ...buttonStyle, 
                    ...styles.optionButtonSelected,
                    ...(answer.status === 'correct' ? styles.optionButtonCorrect : styles.optionButtonIncorrect)
                  };
                } else if (isHovered) {
                  buttonStyle = { ...buttonStyle, ...styles.optionButtonHover }; // Apply hover style
                }

                return (
                  <button 
                    key={option}
                    style={buttonStyle}
                    onClick={() => handleSelection(question.id, option)}
                    onMouseEnter={() => setHoveredOption({ questionId: question.id, option: option })}
                    onMouseLeave={() => setHoveredOption({ questionId: null, option: null })}
                  >
                    {option}
                  </button>
                );
              })}
            </div>
          </div>
        );
      })}
      <button 
        style={{ ...styles.resetButton, ...(hoveredReset ? styles.resetButtonHover : {}) }} 
        onClick={handleReset}
        onMouseEnter={() => setHoveredReset(true)}
        onMouseLeave={() => setHoveredReset(false)}
      >
        Reset Quiz 1
      </button>
    </div>
  );
};

export default LessonQuiz; 