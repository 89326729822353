import React, { useEffect, useState, useMemo } from "react";
import Nav from "../Components/Nav";
import { ImagePath } from "../Constant/ImgPath";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faChevronLeft, faChevronRight, faFileLines } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Joyride from "react-joyride";
import AdminSidebar from "../Components/Sidebar";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import "../App.css";
import CircularProgress from "@mui/material/CircularProgress";
import io from "socket.io-client";
import { Dialog, DialogContent } from "@mui/material";
import WordCard from "../Components/PageviewComponents/WordCard";
import API_BASE_URL from "../apiConfig";
import ButtonGroup from '../Components/PageviewComponents/ButtonGroup';
import ArabicVerse from '../Components/PageviewComponents/ArabicVerse';
import AdvancedAudio from '../Components/PageviewComponents/AdvancedAudio';
import SimpleFlashCard from '../Components/PageviewComponents/SimpleFlashCard';
import VerseContainer from '../Components/PageviewComponents/VerseContainer';
import { FormControl, Select, MenuItem } from '@mui/material';
import { faBookmark as fasBookmark } from '@fortawesome/free-solid-svg-icons';
import { faBookmark as farBookmark } from '@fortawesome/free-regular-svg-icons';

// Helper function for logout/redirect (can be shared or defined per file)
const handleUnauthorized = () => {
  console.warn('Unauthorized (401). Logging out and redirecting.');
  localStorage.removeItem("access_token");
  localStorage.removeItem("user_details");
  window.location.href = '/login?sessionExpired=true';
};

// Helper function for page dropdown/bubbles - MOVED OUTSIDE COMPONENT
const RenderPageBubbles = ({ surahDetail, currentPage, handlePageChange }) => {
  const startPage = surahDetail?.start_page;
  const endPage = surahDetail?.end_page;

  // Validate inputs
  if (startPage === undefined || endPage === undefined) return null;

  // Determine a safe value for the Select component
  // Check if currentPage is within the valid range for the *current* surahDetail
  const isValidPage = currentPage >= startPage && currentPage <= endPage;
  const selectValue = isValidPage ? currentPage : ''; // Use empty string if out of range temporarily

  return (
    <FormControl
      size="small"
      sx={{
        minWidth: 150,
        '& .MuiOutlinedInput-root': {
          borderRadius: '8px',
          backgroundColor: 'white',
          boxShadow: '0 2px 4px rgba(0,0,0,0.08)',
          border: '1px solid rgba(0,0,0,0.12)',
          '&:hover': { '& > fieldset': { borderColor: '#338FFF' } },
          '&.Mui-focused': { '& > fieldset': { borderColor: '#338FFF' }, boxShadow: '0 2px 8px rgba(0,0,0,0.12)' }
        }
      }}
    >
      <Select
        value={selectValue} // Use the validated selectValue
        onChange={(e) => handlePageChange(e.target.value)} // Call handlePageChange
        displayEmpty
        sx={{ '& .MuiSelect-select': { py: 1.5, textAlign: 'center', fontWeight: 500 } }}
      >
        {Array.from(
          { length: endPage - startPage + 1 }, // Use calculated endPage and startPage
          (_, i) => {
            const pageNum = startPage + i;
            const isCurrentPage = pageNum === currentPage;
            return (
              <MenuItem
                key={pageNum}
                value={pageNum}
                sx={{
                  justifyContent: 'center', py: 1.5,
                  fontWeight: isCurrentPage ? 600 : 400,
                  color: isCurrentPage ? '#338FFF' : 'rgba(0,0,0,0.87)',
                  '&.Mui-selected': { backgroundColor: '#f0f7ff', color: '#338FFF' },
                  '&:hover': { backgroundColor: 'rgba(0,0,0,0.04)' }
                }}
              >
                Page {pageNum}
              </MenuItem>
            );
          }
        )}
      </Select>
    </FormControl>
  );
};

function PageView({ refesh, setRefresh }) {
  const [showBox, setShowBox] = useState(false);
  const [refreshHeader, setRefreshHeader] = useState(1);
  const [showdefination, setShowdefination] = useState(true);
  const [hideDefinition, setHideDefinition] = useState(() => {
    const savedState = localStorage.getItem('hideDefinition');
    return savedState ? savedState === 'true' : false; // Default to false if no value exists
  });

  useEffect(() => {
    // Save hideDefinition to localStorage whenever it changes
    localStorage.setItem('hideDefinition', hideDefinition.toString());
  }, [hideDefinition]);

  const [includeLearned, setIncludeLearned] = useState(() => {
    const savedState = localStorage.getItem('includeLearned');
    return savedState === 'true';
  });
  const [surahDetail, setSurahDetail] = useState({});
  const [surahline, setSurahline] = useState([]);
  const [surahpop, setSurahpop] = useState();
  const [indexnumber, setIndexnumber] = useState(0);
  const [freetry, setFreetry] = useState(true);
  const [nextAyaatNav, setNextAyaatNav] = useState(false);
  const [wordSelectedindex, setWordSelectedindex] = useState(0);
  const [hasFetchedData, setHasFetchedData] = useState(false);
  const [words, setWords] = useState({});
  const [loginFalse, setLoginFalse] = useState(false);
  // Page review status removed
  const location = useLocation();

  // Keep these two states for passing to VerseContainer
  const [currentlyPlayingVerse, setCurrentlyPlayingVerse] = useState(null);
  const [currentWordIndex, setCurrentWordIndex] = useState(-1);

  // Add state for AdvancedAudio component
  const [showAdvancedAudio, setShowAdvancedAudio] = useState(false);
  const [isPlayingFullSurah, setIsPlayingFullSurah] = useState(false);
  const [currentAudioId] = useState(parseInt(localStorage.getItem("currentAudioId") || "1"));

  const [showModal, setShowModal] = React.useState(false);
  const [knowWords, setKnowWords] = React.useState(false);
  const [wordCount, setWordCount] = React.useState(3);
  const [modalData, setModalData] = React.useState({
    icon: ImagePath.Move,
    message: "Are you sure you want to move these words?",
  });

  const [isMarkAllLoading, setIsMarkAllLoading] = useState(false);
  const [loadingVerseIndex, setLoadingVerseIndex] = useState(null);

  const [allSurahs, setAllSurahs] = useState([]); // State to hold the full list of Surahs

  const [currentPage, setCurrentPage] = useState(1); // Add state for current page
  const [isPageLoading, setIsPageLoading] = useState(false); // Add state for page loading (if needed)

  const [stickyBarInfo, setStickyBarInfo] = useState({
    count_remaining_next_level: 0,
    next_level_name: "Loading..."
  });

  const [bookmarks, setBookmarks] = useState({});

  // Extract fetchStickyBarInfo as a component-level function
  const fetchStickyBarInfo = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/user_dashboard/get_sticky_bar_info`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem("access_token")
        }
      });
      
      // *** Add 401 check ***
      if (response.status === 401) {
        handleUnauthorized();
        return;
      }
      
      if (!response.ok) {
        throw new Error('Failed to fetch sticky bar info');
      }
      
      const data = await response.json();
      console.log('Sticky bar info updated:', data);
      setStickyBarInfo(data);
    } catch (error) {
      if (error.message?.includes('401')) return;
      console.error('Error fetching sticky bar info:', error);
      setStickyBarInfo({
        count_remaining_next_level: 0,
        next_level_name: "Error"
      });
    }
  };

  const handleMoveBtn = () => {
    // Batch all words into a single API call
    const wordsToUpdate = {};
    surahline.forEach(item => {
      if (["New", "Unknown", "Known"].includes(item.status)) {
        wordsToUpdate[item.word_key] = {
          translation: item.translation_key,
          status: knowWords ? "Unknown" : "Known"
        };
      }
    });

    // Single API call with all words
    handleclickcheck1(wordsToUpdate);
    setKnowWords(!knowWords);
  };

  const { course7Detail } = location.state || {};
  const recievedData = location.state;

  // Updated useEffect for initialization
  useEffect(() => {
    if (location.state) {
      if (location.state.id) {
        setSurahId_id(location.state.id);
        setCurrentsurahId_id(location.state.id);
        // Initialize currentPage from location state
        const initialPage = parseInt(location.state.page) || 1;
        setCurrentPage(initialPage);
        setPagessurah(initialPage);
      }
    }

    if (localStorage.getItem("login") == "true" || localStorage.getItem("demologin") == "true") {
      setLoginFalse(true);
    } else {
      navigate("/login");
    }
  }, [location.state]); // Depend on location.state

  const callFreeDemo = () => {
    if (recievedData?.page == "Free") {
      setFreetry(false);

      var locadata = localStorage.getItem("freeSurah");
      locadata = JSON.parse(locadata);
      setSurahline(locadata["words"][indexnumber]);
      setSurahDetail(locadata);
      setLineKey(locadata?.words[indexnumber]?.[0]?.["key"]);
      // setSurahline(recievedData.id["words"][indexnumber])
    }
  };
  const navigate = useNavigate();

  const NavigatePages = (data) => {
    setKnowWords(false); // Reset state when navigating between views
    if (location?.state?.id) {
      navigate(data, {
        state: { id: location?.state?.id, page: location?.state?.page },
      });
    } else {
      navigate(data, {
        state: { parah: location?.state?.parah, page: location?.state?.page },
      });
    }
  };

  const [showtr, setShowtr] = useState(false);
  const [showTrr, setShowTrr] = useState(false);
  const [rafefreshapi, setRefreshapi] = useState(false);

  const [pagessurah, setPagessurah] = useState(location?.state?.page);
  const [surahId_id, setSurahId_id] = useState(location?.state?.id);
  const [currentsurahId_id, setCurrentsurahId_id] = useState(
    location?.state?.id
  );

  const [tour, setTour] = useState(false);
  const [sidebar, setsidebar] = useState(false);

  const [recivedparah, setRecivedparah] = useState(recievedData?.parah);
  const [recivedpage, setRecivedpage] = useState(recievedData?.page);
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 991);
  const [isMushafView, setIsMushafView] = useState(false);

  // Function to mark all default words as Known without affecting Learned words
  const markAllWordsAsKnown = async (verseWords, verseIndex) => {
    try {
      // Show loading state for all buttons, but track which one was clicked
      setIsMarkAllLoading(true);
      setLoadingVerseIndex(verseIndex);
      console.log("markAllWordsAsKnown called");

      // Use provided verse words if available, otherwise use current verse
      let wordsToProcess = verseWords;
      let currentVerseIndex = -1;

      // If no verse words were provided, try to get them from the current verse
      if (!wordsToProcess && surahDetail && surahDetail.words && indexnumber >= 0 && indexnumber < surahDetail.words.length) {
        wordsToProcess = surahDetail.words[indexnumber];
        currentVerseIndex = indexnumber;
        console.log("Using current verse based on indexnumber:", indexnumber);
      } else if (wordsToProcess && wordsToProcess.length > 0) {
        // Try to determine the verse index from the first word's key
        const firstWordKey = wordsToProcess[0]?.key;
        if (firstWordKey) {
          const verseNumber = parseInt(firstWordKey.split(':')[1]);
          if (!isNaN(verseNumber) && surahDetail && surahDetail.words) {
            // Find the verse index in surahDetail.words
            for (let i = 0; i < surahDetail.words.length; i++) {
              if (surahDetail.words[i][0]?.key === firstWordKey) {
                currentVerseIndex = i;
                break;
              }
            }
          }
        }
      }

      // Validate that we have words to process
      if (!Array.isArray(wordsToProcess) || wordsToProcess.length === 0) {
        console.log("No valid verse words to process");
        setIsMarkAllLoading(false);
        return;
      }

      console.log("Words in verse to process:", wordsToProcess.length);

      // Create a single request object for words in the verse
      const requestData = {};
      let wordsAdded = 0;

      // Add ALL words from the verse that are not already Known or Learned
      for (let i = 0; i < wordsToProcess.length; i++) {
        const word = wordsToProcess[i];
        if (!word) continue;

        // Skip words that are already Known or Learned
        if (word.status === "Learned" || word.status === "Known") {
          continue;
        }

        // Get the dictionary lookup value
        const dictionary_lookup = word.dictionary_lookup || word.word_lemma || word.word_uthmani_no_diacritics;

        // Skip if no dictionary lookup value is available
        if (!dictionary_lookup) {
          console.log("Word missing dictionary lookup value:", word);
          continue;
        }

        // Add the word to the request data - let backend handle time fields
        requestData[dictionary_lookup] = {
          status: "Known"
        };
        wordsAdded++;

        // Update word status locally - let backend handle time fields
        wordsToProcess[i] = {
          ...word,
          status: "Known"
        };
      }

      console.log("Words added to request:", wordsAdded);

      if (wordsAdded === 0) {
        console.log("No words to mark as Known in this verse");
        setIsMarkAllLoading(false);
        return;
      }

      // Update the UI immediately to make it feel faster
      if (surahDetail && surahDetail.words) {
        // Create a deep copy of surahDetail
        const updatedSurahDetail = { ...surahDetail };
        updatedSurahDetail.words = [...surahDetail.words];

        // Update the specific verse first
        if (currentVerseIndex >= 0) {
          updatedSurahDetail.words[currentVerseIndex] = wordsToProcess;
        }

        // Create a map of dictionary_lookup to new status for quick lookup
        const wordStatusMap = {};
        Object.keys(requestData).forEach(lookup => {
          wordStatusMap[lookup] = "Known";
        });

        // Now update all other verses to reflect the same word status changes
        for (let verseIndex = 0; verseIndex < updatedSurahDetail.words.length; verseIndex++) {
          // Skip the current verse as we've already updated it
          if (verseIndex === currentVerseIndex) continue;

          const verseWords = updatedSurahDetail.words[verseIndex];
          if (!Array.isArray(verseWords)) continue;

          // Create a new array for this verse to avoid mutating the original
          const updatedVerseWords = [...verseWords];
          let verseUpdated = false;

          // Check each word in this verse
          for (let wordIndex = 0; wordIndex < updatedVerseWords.length; wordIndex++) {
            const word = updatedVerseWords[wordIndex];
            if (!word) continue;

            // Get the dictionary lookup value for this word
            const dictionary_lookup = word.dictionary_lookup || word.word_lemma || word.word_uthmani_no_diacritics;

            // If this word is in our update map and not already Known/Learned, update it
            if (dictionary_lookup && wordStatusMap[dictionary_lookup] &&
                word.status !== "Known" &&
                word.status !== "Learned") {
              updatedVerseWords[wordIndex] = { ...word, status: "Known" };
              verseUpdated = true;
            }
          }

          // Only update the verse if changes were made
          if (verseUpdated) {
            updatedSurahDetail.words[verseIndex] = updatedVerseWords;
          }
        }

        // Update the state with all changes
        setSurahDetail(updatedSurahDetail);
      }

      // Make API call to update the words
      await new Promise(resolve => setTimeout(resolve, 500));
      await handleclickcheck1(requestData);

      // Add this line to update sticky bar after "Mark All Known"
      await fetchStickyBarInfo();

      // Add a small delay after the API call to ensure the loading state is visible
      await new Promise(resolve => setTimeout(resolve, 500));

    } catch (error) {
      console.error("Error marking words as known:", error);
      // If there was an error, refresh the data using non-scrolling version
      await getFullSurahAPIWithoutScroll(currentsurahId_id, currentPage);
    } finally {
      // Hide loading state
      setIsMarkAllLoading(false);
    }
  };

  useEffect(() => {
    // Update isMobile state on window resize
    const handleResize = () => setIsMobile(window.innerWidth <= 991);
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const sidemenufun = () => {
    setsidebar(!sidebar);
  };

  const [lineKey, setLineKey] = useState("");

  // Effect to fetch initial data on mount or when relevant state changes
  useEffect(() => {
    if (!hasFetchedData && loginFalse) { // Only fetch if logged in and not already fetched
      const initialSurahId = location.state?.id;
      const initialPageNum = parseInt(location.state?.page) || 1;

      if (initialSurahId) {
        getFullSurahAPI(initialSurahId, initialPageNum);
        setHasFetchedData(true); // Mark as fetched
      } else {
        // Handle case where initial Surah ID is missing (e.g., direct navigation)
        console.warn("Initial Surah ID not found in location state. Cannot fetch data.");
        setLoading(false); // Stop loading if we can't fetch
      }
    }
  }, [hasFetchedData, loginFalse, location.state]); // Add dependencies

  const getFullParahAPI = (dataa) => {
    // setLoading(true)
    const apiUrl = `${API_BASE_URL}/user_dashboard/get_parah_detail`;

    var requestData;

    if (dataa == "new") {
      requestData = {
        parah: surahDetail.next_parah,
        page: surahDetail.next_page,
      };
    } else if (dataa == "prev") {
      requestData = {
        parah: surahDetail.prev_parah,
        page: surahDetail.prev_page,
      };
    } else {
      requestData = {
        parah: recivedparah,
        page: recivedpage,
      };
    }

    const headers = {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("access_token"),
    };
    fetch(apiUrl, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        // *** Add 401 check ***
        if (response.status === 401) {
          handleUnauthorized();
          return Promise.reject(new Error('401 Unauthorized')); // Stop further processing
        }
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setSurahDetail(data);

        if (dataa == "new") {
          setSurahline(data?.words[0]);
          setLineKey(data?.words[0]?.[0]?.["key"]);
        } else if (dataa == "prev") {
          setSurahline(data?.words[data?.words.length - 1]);
          setLineKey(data?.words[data?.words.length - 1]?.[0]?.["key"]);
          setIndexnumber(data?.words.length - 1);
          localStorage.setItem("surahData", JSON.stringify(data));
        } else {
          setSurahline(data?.words[indexnumber]);
          setLineKey(data?.words[indexnumber]?.[0]?.["key"]);
        }

        localStorage.setItem("parahData", JSON.stringify(data));
      })
      .catch((error) => {
        if (error.message?.includes('401')) return;
        console.error("Error fetching Parah API:", error);
      })
      .finally(() => {
        // setLoading(false)
      });
  };
  const getFullSurahAPI = (suraId, page) => {
    // Reset audio state
    setCurrentlyPlayingVerse(null);
    setCurrentWordIndex(-1);

    // Show loading indicator
    setLoading(true);

    // *** ADD LOG: Log the requested page ***
    console.log(`getFullSurahAPI: Requesting Surah ${suraId}, Page ${page}`);

    const apiUrl = `${API_BASE_URL}/user_dashboard/get_sura_detail`;
    let requestData = {
      sura_id: suraId,
      page: page,
      qari: localStorage.getItem("selectedVoice"),
      include_review_status: true
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("access_token"),
    };

    // Get surah details
    fetch(apiUrl, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        // *** Add 401 check ***
        if (response.status === 401) {
          handleUnauthorized();
          return Promise.reject(new Error('401 Unauthorized')); 
        }
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        // *** ADD LOG: Log the page returned by API ***
        console.log(`getFullSurahAPI: Received data for page: ${data?.current_page}`);

        // Update state with the data
        setSurahDetail(data);
        // **Important:** Ensure state reflects the requested surah/page as numbers
        const numericPage = parseInt(page); // Parse page arg
        const numericSuraId = parseInt(suraId); // Parse suraId arg
        setCurrentsurahId_id(numericSuraId);
        setCurrentPage(numericPage);
        setPagessurah(numericPage); // Keep pagessurah in sync if needed elsewhere

        // Process review status (keep existing logic)
        if (data.review_status) {
          // Handle review status data here
        }
        else if (data.current_surah) {
          fetch(`${API_BASE_URL}/user_dashboard/get_surah_review_status`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
              surah_id: data.current_surah
            })
          })
          .then(res => {
            // *** Add 401 check ***
            if (res.status === 401) {
              handleUnauthorized();
              return Promise.reject(new Error('401 Unauthorized'));
            }
            if (!res.ok) {
               throw new Error(`Review status fetch failed: ${res.status}`);
            }
            return res.json();
          })
          .then(reviewStatus => {
            // Process review status
          })
          .catch(error => {
            if (error.message?.includes('401')) return;
            console.error("Error fetching review status:", error);
          });
        }

        // Extract verse number (keep existing logic)
        if (data.words && data.words.length > 0 && data.words[0].length > 0) {
          const firstVerseKey = data.words[0][0].key;
          const verseNumber = parseInt(firstVerseKey.split(':')[1]);
          setVerseStartNumber(verseNumber);
        }

        // Handle the words array for the current page
        if (data.words && data.words.length > 0) {
          // Reset index to 0 when loading a new surah/page generally
          setIndexnumber(0);
          setSurahline(data.words[0]);
        } else {
          // Handle case with no words
          setIndexnumber(0);
          setSurahline([]);
        }

        // Save current settings (keep existing logic)
        localStorage.setItem('hideDefinition', hideDefinition.toString());
        localStorage.setItem('includeLearned', includeLearned.toString());

        // *** ADD LOG: Log state right after setting ***
        console.log(`getFullSurahAPI: Set currentPage state to: ${numericPage}`);
      })
      .catch((error) => {
        if (error.message?.includes('401')) return;
        console.error("Error fetching Surah API:", error);
      })
      .finally(() => {
        // Hide loading indicator
        setLoading(false);
      });
  };
  const handleclickcheck = async (word, translation, status) => {
    // Get the current word from surahDetail since that has all the data
    const currentWord = surahDetail?.words?.[indexnumber]?.[wordSelectedindex];
    setSurahId_id(surahDetail.current_surah);

    // If word is provided directly (from WordCard), use it instead
    const wordToUpdate = word ? { dictionary_lookup: word } : currentWord;

    if (!freetry) {
      let color = status === "New" ? "#93C47D" :
                 status === "Learned" ? "#FBD866" :
                 status === "Familiar" ? "#8CF485" :
                 status === "known" ? "#93C47D" : "#93C47D";

      var statusChange = surahDetail;
      statusChange.words[indexnumber][wordSelectedindex]["status"] = status;
      statusChange.words[indexnumber][wordSelectedindex]["color"] = color;

      localStorage.setItem("freeSurah", JSON.stringify(statusChange));
      setSurahline(statusChange["words"][indexnumber]);
      setSurahDetail(statusChange);
      setLineKey(statusChange?.words[indexnumber]?.[0]?.["key"]);

      return Promise.resolve(); // Return resolved promise for non-API case
    } else {
      // Log the full word object and its structure
      // Log the word data we're about to send
      console.log('Word data:', {
        word: wordToUpdate?.word,
        dictionary_lookup: wordToUpdate?.dictionary_lookup,
        word_lemma: wordToUpdate?.word_lemma,
        word_uthmani_no_diacritics: wordToUpdate?.word_uthmani_no_diacritics,
        status
      });

      const apiUrl = `${API_BASE_URL}/user_dashboard/set_word_status`;

      // Use dictionary_lookup for matching in the backend
      const dictionary_lookup = word || wordToUpdate?.dictionary_lookup || wordToUpdate?.word_lemma || wordToUpdate?.word_uthmani_no_diacritics;
      if (!dictionary_lookup) {
        console.error('No dictionary lookup value available:', wordToUpdate);
        return;
      }

      const requestData = {
        [dictionary_lookup]: {
          status: status,
        },
      };

      console.log('Request data being sent:', requestData);
      const headers = {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      };

      try {
        const response = await fetch(apiUrl, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(requestData),
        });
        
        // *** Add 401 check ***
        if (response.status === 401) {
          handleUnauthorized();
          return; // Stop processing
        }

        if (!response.ok) {
           throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
          console.log("API_Data", data);

        // Fetch sticky bar info immediately after successful word status update
        // Only fetch for New and Known status changes (not for Learn)
        if (status === "New" || status === "Known" || status === "Learn" || status === "Learned") {
          await fetchStickyBarInfo(); // Already has 401 check
        }

        // Continue with original functionality
        await getFullSurahAPIWithoutScroll(currentsurahId_id, currentPage); // Needs 401 check
          setRefreshHeader((prev) => prev + 1);
          setRefreshapi(true);

        return data; // Return the API response
      } catch (error) {
        if (error.message?.includes('401')) return;
        console.error("Error updating word status:", error);
        throw error; // Re-throw to let WordCard handle the error
      }
    }
  };

  const handleHover = (event) => {
    event.target.style.border = "1px solid black";
  };

  const countdounfunadd = () => {
    setWords({});
    setShowtr(false);
    const nextWords = surahDetail?.words[indexnumber + 1];
    // Check if there are any new/unknown words in the next sentence
    const hasNewWords = nextWords?.some(item => item.status === "New" || item.status === "Unknown");
    setKnowWords(false); // Always reset to "Move x words to known" state
    setIndexnumber(indexnumber + 1);
    setSurahline(nextWords);
    setLineKey(nextWords?.[1]?.["key"]);
  };

  const countdounfunmin = () => {
    setWords({});
    setShowtr(false);
    const prevWords = surahDetail?.words[indexnumber - 1];
    // Check if there are any new/unknown words in the previous sentence
    const hasNewWords = prevWords?.some(item => item.status === "New" || item.status === "Unknown");
    setKnowWords(false); // Always reset to "Move x words to known" state
    setIndexnumber(indexnumber - 1);
    setSurahline(prevWords);
    setLineKey(prevWords?.[0]?.["key"]);
  };

  useEffect(() => {
    if (surahline && surahline.length > 0) {
      // Check if there are any new/unknown words whenever surahline changes
      const hasNewWords = surahline.some(item => item.status === "New" || item.status === "Unknown");
      if (!hasNewWords) {
        setKnowWords(true); // If no new words, show "Undo"
      } else {
        setKnowWords(false); // If there are new words, show "Move x words to known"
      }
    }
  }, [surahline]);

  const handleLeave = (event) => {
    event.target.style.border = "1px solid transparent";
  };

  const Surah = {
    bg: {
      // background: "rgb(244, 245, 246)",
      padding: "10px 24px",
    },
    h3: {
      fontSize: "18px",
      fontWeight: "500",
      margin: "0",
    },
    h2: {
      fontSize: "80px",
      fontWeight: "500",
      fontFamily: "ayat-font",
      textAlign: "center",
      margin: "0",
    },
    h5: {
      fontSize: "48px",
      fontWeight: "500",
      textAlign: "center",
      fontFamily: "ayat-font",
      margin: "0",
      padding: "0 10px",
    },
    mainHeading: {
      margin: "0 auto",
      width: "70%",
      display: "flex",
      textAlign: "center",
    },
    heading: {
      width: "40%",
      color: "black",
      fontSize: "17px",
      fontWeight: "600",
    },
    p: {
      fontSize: "20px",
      fontWeight: "600",
      display: "flex",
      gap: "8px",
      alignItems: "center",
      padding: "0px 13px",
    },
    voiceSection: {
      width: "85%",
      minWidth: "340px",
      borderRadius: "30px",
      background: "#FFF",
      boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.40)",
      padding: "16px",
      marginLeft: "auto",
      position: "sticky",
      top: "100px",
    },
    voiceDiv: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: "15px",
      marginTop: "16px",
    },
    h4: {
      color: "#000",
      fontSize: "45px",
      fontWeight: "500",
      fontFamily: "uth",
      margin: "0",
    },

    mainIconDiv: {
      display: "flex",
      gap: "8px",
      borderRadius: "19px",
      background: "#FFF",
      boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.40)",
      padding: "18px 24px",
      width: "70%",
      justifyContent: "space-between",
      width: "100%",
      marginTop: "20px",
    },
    span: {
      fontSize: "11px",
    },
    mainSurah: {
      textAlign: "center",
    },
    h5: {
      fontSize: "38px", // this controls the arabic font size
      fontWeight: "500",
      textAlign: "center",
      fontFamily: "ayat-font",
      margin: "0",
      padding: "6px 10px",
    },
    mainAyatDiv: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      // marginTop: "34px",
      // marginBottom: "30px",
      justifyContent: "center",
      width: "100%",
      // position: "relative",
    },
    flexText: {
      display: "flex",
      flexWrap: "wrap",
    },
    ayatbox: {
      margin: "11px 0px",
      fontSize: "46px",
      fontWeight: "300",
      fontFamily: "ayat-font",
      textAlign: "center",
    },
    headingSecond: {
      display: "flex",
      alignItems: "center",
      gap: "19px",
    },
    buttonlesson: {
      borderRadius: "12px",
      border: "1px solid #676767",
      padding: "8px 18px",
      display: "flex",
      gap: "8px",
      cursor: "pointer",
    },
    button: {
      color: "#fff",
      borderRadius: "24px",
      border: "1px solid transparent",
      background: "#D9D9D9",
      fontSize: "16px",
      fontWeight: "400",
      padding: "11px",
      width: "17%",
      cursor: "pointer",
      height: "43px",
      minWidth: "150px",
    },
  };



  var localStorageVal = localStorage.getItem("selectedTranslator");

  const MarkAllKnown = () => {
    if (freetry) {
      for (let i = 0; i < surahline.length; i++) {
        if (surahline[i].status == "Unknown") {
          words[surahline[i].word_key] = {
            translation: surahline[i].translation_key,
            status: "Known",
          };
        }
      }
      handleclickcheck1(words);

    } else {

      const newStatusChange = JSON.parse(JSON.stringify(surahDetail));

      for (let i = 0; i < newStatusChange.words[indexnumber].length; i++) {
        if (newStatusChange.words[indexnumber][i]["status"] === "Unknown") {
          newStatusChange.words[indexnumber][i]["status"] = "Known";
          newStatusChange.words[indexnumber][i]["color"] = "transparent";
        }
      }

      localStorage.setItem("freeSurah", JSON.stringify(newStatusChange));
      setSurahline(newStatusChange["words"][indexnumber]);
      setLineKey(newStatusChange?.words[indexnumber]?.[0]?.["key"]);
      setSurahDetail(newStatusChange);
    }
  };

  const handleclickcheck1 = (arraylist) => {
    const apiUrl = `${API_BASE_URL}/user_dashboard/set_word_status`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("access_token"),
    };

    fetch(apiUrl, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(arraylist),
    })
      .then((response) => {
        // *** Add 401 check ***
        if (response.status === 401) {
          handleUnauthorized();
          return Promise.reject(new Error('401 Unauthorized'));
        }
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setShowBox(false);
        setRefreshHeader((prev) => prev + 1);
        setRefreshapi(true);
        
        // Update sticky bar info after marking words as known/unknown
        fetchStickyBarInfo();
        
        if (recievedData?.parah) {
          getFullParahAPI("");
        } else {
          // Always use the non-scrolling version to prevent scrolling to top
          getFullSurahAPIWithoutScroll(currentsurahId_id, currentPage);
        }
      })
      .catch((error) => {
        if (error.message?.includes('401')) return;
        console.error("Error in handleclickcheck1:", error);
      });
  };

  const checkprevious_surah = () => {
    // setPagessurah(0);
    setCurrentsurahId_id(() => surahDetail.prev_surah);
    // For previous surah navigation, we do want to scroll to top, but avoid double scrolling
    window.scrollTo(0, 0);
    getFullSurahAPIWithoutScroll("prev");
    setIndexnumber(surahDetail?.words.length - 1);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  // Populate allSurahs from location state
  useEffect(() => {
    if (location.state?.surahList && Array.isArray(location.state.surahList)) {
      setAllSurahs(location.state.surahList);
    } else if (location.state?.surahs && Array.isArray(location.state.surahs)) {
      // Try alternative property name
      setAllSurahs(location.state.surahs);
    } else {
      console.warn("Surah list not found in location state. Using dummy data for development.");
      // Add some dummy data for testing/development
      setAllSurahs([
        { id: 1, name_en: "Al-Fatihah", start_page: 1, end_page: 1 },
        { id: 2, name_en: "Al-Baqarah", start_page: 2, end_page: 49 },
        // Add a few more surahs for development purposes
        { id: 3, name_en: "Aal-E-Imran", start_page: 50, end_page: 76 },
        { id: 4, name_en: "An-Nisa", start_page: 77, end_page: 106 }
      ]);
    }
  }, [location.state]);

  // Add debug logging without circular dependencies
  useEffect(() => {
    // Debug: Log surah data for debugging
    console.log("Debug - Current Surah ID:", currentsurahId_id);
    console.log("Debug - Available Surahs:", allSurahs);
    console.log("Debug - Current Surah Detail:", surahDetail);
    console.log("Debug - Previous Surah ID:", surahDetail?.prev_surah);
    console.log("Debug - Next Surah ID:", surahDetail?.next_surah);
  }, [allSurahs, surahDetail, currentsurahId_id]);

  // Debug allSurahs and surah IDs
  useEffect(() => {
    // Debug: Log detailed surah lookup information
    if (surahDetail?.prev_surah) {
      console.log("Previous Surah ID:", surahDetail.prev_surah, typeof surahDetail.prev_surah);
      const foundPrev = allSurahs.find(s => parseInt(s.id) === parseInt(surahDetail.prev_surah));
      console.log("Found previous surah:", foundPrev);
    }
    if (surahDetail?.next_surah) {
      console.log("Next Surah ID:", surahDetail.next_surah, typeof surahDetail.next_surah);
      const foundNext = allSurahs.find(s => parseInt(s.id) === parseInt(surahDetail.next_surah));
      console.log("Found next surah:", foundNext);
    }
  }, [allSurahs, surahDetail]);

  // Find previous and next surah names when needed - ensure IDs match by parsing as integers
  const prevSurah = surahDetail?.prev_surah ? 
    allSurahs.find(s => parseInt(s.id) === parseInt(surahDetail.prev_surah)) : null;
  const nextSurah = surahDetail?.next_surah ? 
    allSurahs.find(s => parseInt(s.id) === parseInt(surahDetail.next_surah)) : null;

  // Check if we're at the first/last page of current surah
  const isAtFirstPage = currentPage <= (surahDetail?.start_page || 1);
  const isAtLastPage = currentPage >= (surahDetail?.end_page || 1);

  // Compute button text - use the name property or fallback to meaningful text
  const prevButtonText = isAtFirstPage && surahDetail?.prev_surah ? 
    `Surah ${prevSurah?.name_en || `${surahDetail.prev_surah}`}` : "Previous";
  const nextButtonText = isAtLastPage && surahDetail?.next_surah ? 
    `Surah ${nextSurah?.name_en || `${surahDetail.next_surah}`}` : "Next";

  // Buttons are disabled only if no page or surah to navigate to
  const isPreviousDisabled = isAtFirstPage && !surahDetail?.prev_surah;
  const isNextDisabled = isAtLastPage && !surahDetail?.next_surah;

  // Function to scroll to top once - simpler version
  const scrollToTopOnce = () => {
    window.scrollTo(0, 0);
  };

  const handlePreviousClick = () => {
    setCurrentlyPlayingVerse(null);
    setCurrentWordIndex(-1);

    console.log(`handlePreviousClick: Entered. Current page state is: ${currentPage}`);

    const targetPage = parseInt(currentPage) - 1; // Ensure number calculation
    console.log(`handlePreviousClick: Calculated targetPage as: ${targetPage}`);
    const currentStartPage = surahDetail?.start_page;
    console.log(`handlePreviousClick: Checking against start page: ${currentStartPage}`);

    if (currentStartPage !== undefined && targetPage >= currentStartPage) {
      // Stay within the same surah, just go to previous page
      console.log(`handlePreviousClick: Condition met. Calling handlePageChange(${targetPage})`);
      handlePageChange(targetPage);
    } else if (surahDetail?.prev_surah) {
      // Go to the previous surah's first page
      try {
        const prevSurahId = parseInt(surahDetail.prev_surah);
        console.log(`handlePreviousClick: Going to previous surah ${prevSurahId}`);
        
        // Scroll to top once
        scrollToTopOnce();
        
        // Find the surah object to get its start page
        const prevSurahObj = allSurahs.find(s => parseInt(s.id) === prevSurahId);
        
        if (prevSurahObj) {
          // Use the start_page from the surah object
          const startPage = parseInt(prevSurahObj.start_page || 1);
          console.log(`handlePreviousClick: Found start page ${startPage} for surah ${prevSurahId}`);
          
          // Force a clean navigation to prevent blank page
          setLoading(true); // Show loading state
          
          // Update all state to match what dropdown does
          setCurrentsurahId_id(prevSurahId);
          setCurrentPage(startPage);
          setIndexnumber(0);
          setKnowWords(false);
          
          // Fetch data without causing another scroll
          getFullSurahAPIWithoutScroll(prevSurahId, startPage);
        } else {
          console.error(`Previous surah object not found for ID: ${prevSurahId}`);
          // Fallback to page 1 if we can't find the surah object
          setCurrentsurahId_id(prevSurahId);
          setCurrentPage(1);
          getFullSurahAPIWithoutScroll(prevSurahId, 1);
        }
      } catch (error) {
        console.error("Error navigating to previous surah:", error);
        setLoading(false);
      }
    } else {
      console.log(`handlePreviousClick: Condition NOT met and no prev_surah. Doing nothing.`);
    }
  };

  const handleNextClick = () => {
    setCurrentlyPlayingVerse(null);
    setCurrentWordIndex(-1);

    console.log(`handleNextClick: Entered. Current page state is: ${currentPage}`);

    // Recalculate target page using the most direct state access, ensuring number
    const targetPage = parseInt(currentPage) + 1;
    console.log(`handleNextClick: Calculated targetPage as: ${targetPage}`);

    // Get end page from current detail, ensuring detail exists
    const currentEndPage = surahDetail?.end_page;
    console.log(`handleNextClick: Checking against end page: ${currentEndPage}`);

    if (currentEndPage !== undefined && targetPage <= currentEndPage) {
      // Stay within the same surah, just go to next page
      console.log(`handleNextClick: Condition met. Calling handlePageChange(${targetPage})`);
      handlePageChange(targetPage);
    } else if (surahDetail?.next_surah) {
      // Go to the next surah's first page
      try {
        const nextSurahId = parseInt(surahDetail.next_surah);
        console.log(`handleNextClick: Going to next surah ${nextSurahId}`);
        
        // Scroll to top once
        scrollToTopOnce();
        
        // Find the surah object to get its start page
        const nextSurahObj = allSurahs.find(s => parseInt(s.id) === nextSurahId);
        
        if (nextSurahObj) {
          // Use the start_page from the surah object
          const startPage = parseInt(nextSurahObj.start_page || 1);
          console.log(`handleNextClick: Found start page ${startPage} for surah ${nextSurahId}`);
          
          // Force a clean navigation to prevent blank page
          setLoading(true); // Show loading state
          
          // Update all state to match what dropdown does
          setCurrentsurahId_id(nextSurahId);
          setCurrentPage(startPage);
          setIndexnumber(0);
          setKnowWords(false);
          
          // Fetch data without causing another scroll
          getFullSurahAPIWithoutScroll(nextSurahId, startPage);
        } else {
          console.error(`Next surah object not found for ID: ${nextSurahId}`);
          // Fallback to page 1 if we can't find the surah object
          setCurrentsurahId_id(nextSurahId);
          setCurrentPage(1);
          getFullSurahAPIWithoutScroll(nextSurahId, 1);
        }
      } catch (error) {
        console.error("Error navigating to next surah:", error);
        setLoading(false);
      }
    } else {
      console.log(`handleNextClick: Condition NOT met and no next_surah. Doing nothing.`);
    }
  };

  const [activeTab, setActiveTab] = useState("surah")
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredSurahs, setFilteredSurahs] = useState(course7Detail);
  const [showList, setShowList] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [selectedSurah, setSelectedSurah] = useState(null);
  const [verses, setVerses] = useState([]);

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (value.trim() === "") {
      setFilteredSurahs(course7Detail);
      setShowList(false);
    } else {
      const filtered = course7Detail.filter((surah) =>
        surah.english_name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredSurahs(filtered);
      setShowList(true);
    }
  };

  const handleSelectSurah = async (surah) => {
    setSearchTerm(surah.english_name);
    setSelectedSurah(surah);
    setShowList(false);
    setCurrentPage(1); // Reset to first page when changing surahs

    // Fetch new surah data
    const requestData = {
      sura_id: surah.number,
      page: 1, // Start from first page
      qari: localStorage.getItem("selectedVoice"),
    };

    try {
      const response = await fetch(`${API_BASE_URL}/user_dashboard/get_sura_detail`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("access_token"),
        },
        body: JSON.stringify(requestData),
      });

      // *** Add 401 check ***
      if (response.status === 401) {
        handleUnauthorized();
        return;
      }

      if (!response.ok) throw new Error("Failed to fetch surah data");
      const data = await response.json();

      // Update surahDetail and reset states
      setSurahDetail(data);
      setCurrentsurahId_id(data.current_surah);
      setIndexnumber(0);
      setSurahline(data.words[0]);
      setLineKey(data.words[0][0]?.["key"]);
      setKnowWords(false); // Reset known words state
      localStorage.setItem("surahData", JSON.stringify(data));

    } catch (error) {
      if (error.message?.includes('401')) return;
      console.error("Error fetching surah data:", error);
    }
  };

  // Add useEffect to handle initial page loading
  useEffect(() => {
    if (recievedData?.page) {
      setCurrentPage(parseInt(recievedData.page));
    }
  }, [recievedData]);

  const scrollToPage = (pageNumber) => {
    const pageElement = document.getElementById(`page-${pageNumber}`);
    if (pageElement) {
      pageElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

const getPageBubbleColor = (pageVerses) => {
  let hasLearnedOrFamiliar = false;
  let hasNewWords = false;

  for (const verse of pageVerses) {
    for (const word of verse) {
      if (word.status === "New" || word.status === "Unknown") {
        hasNewWords = true;
      }
      if (word.status === "Learned" || word.status === "Familiar") {
        hasLearnedOrFamiliar = true;
      }
    }
  }

  // If there are any new/unknown words, keep the bubble clear
  if (hasNewWords) {
    return '#fff';
  }

  // If no new words and has learned/familiar words, make it yellow
  if (hasLearnedOrFamiliar) {
    return '#FBD866';
  }

  // If no new words and all words are known
  return '#34C759'; // Same green as the undo button
};

  // Updated handlePageChange function
  const handlePageChange = (newPage) => {
    const pageNum = parseInt(newPage);
    // *** ADD LOG: Log inputs to handlePageChange ***
    console.log(`handlePageChange: Called with newPage=${newPage}, pageNum=${pageNum}`);
    console.log(`handlePageChange: Current state - currentSurahId=${currentsurahId_id}, startPage=${surahDetail?.start_page}, endPage=${surahDetail?.end_page}`);

    if (isNaN(pageNum) || pageNum < surahDetail?.start_page || pageNum > surahDetail?.end_page) {
        // *** ADD LOG: Log why it's returning early ***
        console.log(`handlePageChange: Returning early. Condition failed: isNaN=${isNaN(pageNum)}, pageNum<start=${pageNum < surahDetail?.start_page}, pageNum>end=${pageNum > surahDetail?.end_page}`);
        return;
    }
    
    // Only scroll for page navigation, not for word status changes
    if (pageNum !== currentPage) {
      window.scrollTo(0, 0);
    }
    
    // *** ADD LOG: Log before calling API ***
    console.log(`handlePageChange: Calling getFullSurahAPI(${currentsurahId_id}, ${pageNum})`);
    
    // Use the non-scrolling version to prevent double scrolling
    getFullSurahAPIWithoutScroll(currentsurahId_id, pageNum);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Add state for tracking verse numbers
  const [verseStartNumber, setVerseStartNumber] = useState(1);

  // *** ADD LOGGING HERE before the return statement ***
  console.log('Rendering PageView - Button State Check:', {
    currentSurahId: currentsurahId_id,
    currentPage: currentPage,
    surahDetailStart: surahDetail?.start_page,
    surahDetailEnd: surahDetail?.end_page,
    isPreviousDisabled: isPreviousDisabled,
    isNextDisabled: isNextDisabled,
    prevSurahNav: surahDetail?.prev_surah,
    nextSurahNav: surahDetail?.next_surah,
    prevButtonText,
    nextButtonText
  });

  // Removed Page Complete functionality

  // This is a copy of getFullSurahAPI but without the scroll to top behavior
  const getFullSurahAPIWithoutScroll = (suraId, page) => {
    // Reset audio state
    setCurrentlyPlayingVerse(null);
    setCurrentWordIndex(-1);

    // Show loading indicator
    setLoading(true);

    // *** ADD LOG: Log the requested page ***
    console.log(`getFullSurahAPIWithoutScroll: Requesting Surah ${suraId}, Page ${page}`);

    const apiUrl = `${API_BASE_URL}/user_dashboard/get_sura_detail`;
    let requestData = {
      sura_id: suraId,
      page: page,
      qari: localStorage.getItem("selectedVoice"),
      include_review_status: true
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("access_token"),
    };

    // Get surah details
    fetch(apiUrl, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        // *** ADD LOG: Log the page returned by API ***
        console.log(`getFullSurahAPIWithoutScroll: Received data for page: ${data?.current_page}`);

        // Update state with the data
        setSurahDetail(data);
        // **Important:** Ensure state reflects the requested surah/page as numbers
        const numericPage = parseInt(page); // Parse page arg
        const numericSuraId = parseInt(suraId); // Parse suraId arg
        setCurrentsurahId_id(numericSuraId);
        setCurrentPage(numericPage);
        setPagessurah(numericPage); // Keep pagessurah in sync if needed elsewhere

        // Process review status (keep existing logic)
        if (data.review_status) {
          // Handle review status data here
        }
        else if (data.current_surah) {
          fetch(`${API_BASE_URL}/user_dashboard/get_surah_review_status`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
              surah_id: data.current_surah
            })
          })
          .then(res => res.json())
          .then(reviewStatus => {
            // Process review status
          })
          .catch(error => {
            console.error("Error fetching review status:", error);
          });
        }

        // Extract verse number (keep existing logic)
        if (data.words && data.words.length > 0 && data.words[0].length > 0) {
          const firstVerseKey = data.words[0][0].key;
          const verseNumber = parseInt(firstVerseKey.split(':')[1]);
          setVerseStartNumber(verseNumber);
        }

        // Handle the words array for the current page
        if (data.words && data.words.length > 0) {
          // Reset index to 0 when loading a new surah/page generally
          setIndexnumber(0);
          setSurahline(data.words[0]);
        } else {
          // Handle case with no words
          setIndexnumber(0);
          setSurahline([]);
        }

        // Save current settings (keep existing logic)
        localStorage.setItem('hideDefinition', hideDefinition.toString());
        localStorage.setItem('includeLearned', includeLearned.toString());

        // *** ADD LOG: Log state right after setting ***
        console.log(`getFullSurahAPIWithoutScroll: Set currentPage state to: ${numericPage}`);
        
        // No window.scrollTo(0, 0) here! That's the key difference.
      })
      .catch((error) => {
        console.error("Error fetching API:", error);
        // Optionally handle the error state
      })
      .finally(() => {
        // Hide loading indicator
        setLoading(false);
      });
  };

  // Update the useEffect to use the component-level fetchStickyBarInfo function
  useEffect(() => {
    if (loginFalse) {
      fetchStickyBarInfo();
    }
  }, [loginFalse]);

  // Add function to toggle bookmark
  const toggleBookmark = async (verseKey, surahId, pageNumber) => {
    try {
      const existingBookmark = bookmarks[verseKey];
      
      if (existingBookmark) {
        // Delete bookmark
        const response = await fetch(`${API_BASE_URL}/user_dashboard/verse_bookmarks/${existingBookmark.id}`, {
          method: 'DELETE',
          headers: {
            'Authorization': localStorage.getItem("access_token"),
          }
        });

        if (response.ok) {
          const newBookmarks = { ...bookmarks };
          delete newBookmarks[verseKey];
          setBookmarks(newBookmarks);
        }
      } else {
        // Add bookmark
        const response = await fetch(`${API_BASE_URL}/user_dashboard/verse_bookmarks`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem("access_token"),
          },
          body: JSON.stringify({
            surah_id: surahId,
            verse_key: verseKey,
            page_number: pageNumber
          })
        });

        if (response.ok) {
          const data = await response.json();
          setBookmarks({
            ...bookmarks,
            [verseKey]: {
              id: data.id,
              surah_id: surahId,
              verse_key: verseKey,
              page_number: pageNumber
            }
          });
        }
      }
    } catch (error) {
      console.error('Error toggling bookmark:', error);
    }
  };

  // Add function to fetch bookmarks
  const fetchBookmarks = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/user_dashboard/verse_bookmarks`, {
        headers: {
          'Authorization': localStorage.getItem("access_token"),
        }
      });

      if (response.ok) {
        const data = await response.json();
        const bookmarksMap = {};
        data.forEach(bookmark => {
          bookmarksMap[bookmark.verse_key] = bookmark;
        });
        setBookmarks(bookmarksMap);
      }
    } catch (error) {
      console.error('Error fetching bookmarks:', error);
    }
  };

  // Add useEffect to fetch bookmarks on mount
  useEffect(() => {
    if (loginFalse) {
      fetchBookmarks();
    }
  }, [loginFalse]);

  return (
    <div>
      {/* Sticky Bar */}
      {loginFalse && (
        <div style={{
          position: 'fixed',
          top: 0, // Position at the very top
          left: 0,
          right: 0,
          backgroundColor: '#1a1a1a', // Darker black
          color: 'white',
          padding: '6px', // Slightly increased padding
          textAlign: 'center',
          zIndex: 1100, // Increased z-index to be higher than Nav's z-index
          fontSize: '15px', // Increased from 13px
          fontWeight: '500',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontFamily: '"Poppins", "Roboto", sans-serif',
          boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
          letterSpacing: '0.2px', // Added for better readability
          height: '36px' // Fixed height to ensure consistency
        }}>
          <span style={{
            backgroundColor: '#FF8C00', // Orange color
            color: 'white',
            borderRadius: '15px',
            padding: '3px 10px',
            marginRight: '10px',
            fontWeight: '600',
            display: 'inline-block',
            fontSize: '14px', // Increased from 12px
            boxShadow: '0 1px 2px rgba(0,0,0,0.2)'
          }}>
            {stickyBarInfo.count_remaining_next_level}
          </span>
          known words till {stickyBarInfo.next_level_name}
        </div>
      )}

      {/* Rest of your existing JSX */}
      {loginFalse && (
        <div style={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          paddingTop: '36px' // Add padding to accommodate the sticky bar height
        }}>
          <header>
            {freetry ? (
              <Nav
                headervalue={"nav2"}
                sidemenufun={sidemenufun}
                refreshHeader={refreshHeader}
              />
            ) : (
              <Nav
                headervalue={"nav1"}
                refesh={rafefreshapi}
                sidemenufun={sidemenufun}
                refreshHeader={refreshHeader}
              />
            )}
          </header>

          <AdminSidebar sidebar={sidebar} sidebaracc={"Mobile-Sidebar"} />

          <main style={{ flex: 1 }}>
            <div style={{ ...Surah.bg }}>
              <div className="pageview-content">
                <div className="flexText" style={{
                  ...Surah.flexText,
                  flexDirection: "column",
                  alignItems: "center",
                  background: '#fff',
                  padding: '1rem', // Reduced from 2rem
                  borderRadius: '12px',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
                }}>
                  <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "0.5rem", // Reduced from 1rem
                    width: "100%"
                  }}>
                    <p style={{
                      ...Surah.ayatbox,
                      margin: "0",
                      fontSize: "52px",
                      lineHeight: 1.2
                    }}>{surahDetail?.["name"]}</p>

                    <div style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "0.25rem"
                    }}>
                      <p style={{
                        fontSize: "24px",
                        margin: "0",
                        color: "#333",
                        fontWeight: "500"
                      }}>
                        {surahDetail?.["english_name"]}
                      </p>
                      {surahDetail?.["name_translation"] && (
                        <p style={{
                          fontSize: "18px",
                          margin: "0",
                          color: "#666",
                          fontStyle: "italic"
                        }}>
                          ({surahDetail?.["name_translation"]})
                        </p>
                      )}
                    </div>
                  </div>

                  {/* Container for Surah and Page Dropdowns and Checkbox */}
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem', marginTop: '1rem' }}>

                    {/* Surah Dropdown (Top) */}
                    <FormControl
                      size="small"
                      sx={{
                        minWidth: 180, // Adjusted width
                        width: 'fit-content', // Ensure it doesn't stretch unnecessarily
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '8px',
                          backgroundColor: 'white',
                          boxShadow: '0 2px 4px rgba(0,0,0,0.08)',
                          border: '1px solid rgba(0,0,0,0.12)',
                          '&:hover': { '& > fieldset': { borderColor: '#338FFF' } },
                          '&.Mui-focused': { '& > fieldset': { borderColor: '#338FFF' }, boxShadow: '0 2px 8px rgba(0,0,0,0.12)' }
                        }
                      }}
                    >
                      <Select
                        value={currentsurahId_id || ''}
                        onChange={async (e) => {
                          const newSurahId = e.target.value;
                          const selectedSurahObj = allSurahs.find(s => s.id === newSurahId);
                          if (selectedSurahObj) {
                            // For surah changes, we do want to scroll to top
                            window.scrollTo(0, 0);
                            const startPage = parseInt(selectedSurahObj.start_page || 1); // Ensure number
                            // Set states immediately
                            setCurrentsurahId_id(newSurahId);
                            setCurrentPage(startPage); // Set with number
                            setIndexnumber(0);
                            setKnowWords(false);
                            // Fetch data using the new ID and start page
                            await getFullSurahAPIWithoutScroll(newSurahId, startPage);
                          }
                        }}
                        displayEmpty
                        disabled={allSurahs.length === 0}
                        sx={{ '& .MuiSelect-select': { py: 1.5, textAlign: 'center', fontWeight: 500 } }}
                      >
                        {allSurahs.map((s) => (
                          <MenuItem
                            key={s.id}
                            value={s.id}
                            sx={{
                              justifyContent: 'center', py: 1.5,
                              fontWeight: s.id === currentsurahId_id ? 600 : 400,
                              color: s.id === currentsurahId_id ? '#338FFF' : 'rgba(0,0,0,0.87)',
                              '&.Mui-selected': { backgroundColor: '#f0f7ff', color: '#338FFF' },
                              '&:hover': { backgroundColor: 'rgba(0,0,0,0.04)' }
                            }}
                          >
                            {`${s.id}. ${s.name_en}`}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {/* Page Dropdown (Middle) */}
                    <RenderPageBubbles
                      surahDetail={surahDetail}
                      currentPage={currentPage}
                      handlePageChange={handlePageChange}
                    />

                    {/* Hide Dictionary Words Checkbox (Bottom) */}
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                      marginTop: '0.5rem' // Add some margin if needed
                    }}>
                      <input
                        type="checkbox"
                        id="includeLearned"
                        checked={includeLearned}
                        onChange={(e) => {
                          setIncludeLearned(e.target.checked);
                          localStorage.setItem('includeLearned', e.target.checked);
                        }}
                        style={{ width: '16px', height: '16px', cursor: 'pointer' }}
                      />
                      <label
                        htmlFor="includeLearned"
                        style={{ fontSize: '14px', color: '#666', cursor: 'pointer' }}
                      >
                        Hide Dictionary Words
                      </label>
                    </div>
                  </div>

                  {/* Advanced Audio Component (Position remains unchanged relative to the main header structure) */}
                  <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '0.5rem'
                  }}>
                    <AdvancedAudio
                      showAdvancedAudio={showAdvancedAudio}
                      setShowAdvancedAudio={setShowAdvancedAudio}
                      isPlayingFullSurah={isPlayingFullSurah}
                      setIsPlayingFullSurah={setIsPlayingFullSurah}
                      currentAudioId={currentAudioId}
                      surahDetail={surahDetail}
                    />
                  </div>

                </div>

                {surahDetail?.["show_bism"] && (
                  <div className="bismillah" style={{
                    fontSize: "32px",
                    fontFamily: "ayat-font",
                    textAlign: "center",
                    margin: "1rem 0", // Reduced from 2rem
                    color: "#333"
                  }}>
                    {surahDetail["show_bism"]}
                  </div>
                )}

                <div style={{ width: "100%", padding: "0 20px" }}>
                  <div style={Surah.mainAyatDiv}>
                    <div style={{ width: "100%" }}>
                      {surahDetail?.words && surahDetail.words.length > 0 && (
                        <>
                          <VerseContainer
                            key={`${surahDetail.current_surah}-${surahDetail.current_page}-${verseStartNumber}`}
                            verses={surahDetail.words}
                            pageNumber={surahDetail.current_page}
                            startingVerseNumber={verseStartNumber - 1}
                            currentlyPlayingVerse={currentlyPlayingVerse}
                            currentWordIndex={currentWordIndex}
                            hideDefinition={hideDefinition}
                            includeLearned={includeLearned}
                            localStorageVal={localStorageVal}
                            surahDetail={surahDetail}
                            showtr={showtr}
                            setShowtr={setShowtr}
                            handleclickcheck={handleclickcheck}
                            getFullSurahAPI={getFullSurahAPIWithoutScroll}
                            markAllWordsAsKnown={markAllWordsAsKnown}
                            setIndexnumber={setIndexnumber}
                            setShowBox={setShowBox}
                            setSurahpop={setSurahpop}
                            setWordSelectedindex={setWordSelectedindex}
                            currentAudioId={currentAudioId}
                            isMushafView={isMushafView}
                            isMarkAllLoading={isMarkAllLoading}
                            loadingVerseIndex={loadingVerseIndex}
                          />

                  <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '2rem 0',
                            marginTop: '2rem',
                            borderTop: '1px solid #eee',
                    width: '100%',
                            maxWidth: '500px',
                            margin: '2rem auto 0'
                          }}>
                            <button
                              onClick={handleNextClick}
                              disabled={isNextDisabled}
                              style={{
                                padding: '0.5rem 1rem',
                                borderRadius: '8px',
                                border: '1px solid #ddd',
                                background: isNextDisabled ? '#f5f5f5' : (isAtLastPage && surahDetail?.next_surah ? '#f0f7ff' : '#fff'),
                                cursor: isNextDisabled ? 'not-allowed' : 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '0.5rem',
                                width: 'auto',
                                minWidth: '130px',
                                maxWidth: '200px',
                                justifyContent: 'center',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                color: isNextDisabled ? '#999' : (isAtLastPage && surahDetail?.next_surah ? '#338FFF' : 'inherit')
                              }}
                              title={nextButtonText}
                            >
                              <FontAwesomeIcon icon={faChevronLeft} />
                              {nextButtonText}
                            </button>

                            <button
                              onClick={scrollToTop}
                      style={{
                                padding: '0.5rem',
                                borderRadius: '50%',
                        border: 'none',
                                background: '#444',
                                color: 'white',
                                cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                                justifyContent: 'center',
                                boxShadow: '0 2px 4px rgba(0,0,0,0.15)',
                                transition: 'all 0.2s ease-in-out',
                                width: '40px',
                                height: '40px'
                              }}
                              aria-label="Scroll to top"
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z"/>
                              </svg>
                            </button>

                            <button
                              onClick={handlePreviousClick}
                              disabled={isPreviousDisabled}
                              style={{
                                padding: '0.5rem 1rem',
                                borderRadius: '8px',
                                border: '1px solid #ddd',
                                background: isPreviousDisabled ? '#f5f5f5' : (isAtFirstPage && surahDetail?.prev_surah ? '#f0f7ff' : '#fff'),
                                cursor: isPreviousDisabled ? 'not-allowed' : 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '0.5rem',
                                width: 'auto',
                                minWidth: '130px',
                                maxWidth: '200px',
                                justifyContent: 'center',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                color: isPreviousDisabled ? '#999' : (isAtFirstPage && surahDetail?.prev_surah ? '#338FFF' : 'inherit')
                              }}
                              title={prevButtonText}
                            >
                              {prevButtonText}
                              <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                  </div>

                          {/* Page Complete button removed */}
                        </>
                )}
                    </div>
                  </div>
                </div>
              </div>

              {!isMobile && showBox && (
                <WordCard
                  word={surahpop}
                  onClose={() => setShowBox(false)}
                  onStatusChange={handleclickcheck}
                />
              )}

              {isMobile && showBox && (
                <Dialog
                  open={showBox}
                  onClose={() => setShowBox(false)}
                  fullWidth
                  maxWidth="sm"
                  PaperProps={{
                    style: {
                      width: "35%",
                      minWidth: "340px",
                      minHeight: "250px",
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      borderRadius: "30px",
                    },
                  }}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <WordCard
                      word={surahpop}
                      onClose={() => setShowBox(false)}
                      onStatusChange={handleclickcheck}
                    />
                  </DialogContent>
                </Dialog>
              )}
            </div>
          </main>
        </div>
      )}
    </div>
  );
}

export default PageView;
