// reducers.js

import { SET_CUSTOM_AMOUNT, SET_SELECTED_AMOUNT, SET_PAYMENT_FREQUENCY } from "../types/type";

const initialState = {
  selectedAmount: null,
  customAmount: "",
  isMonthly: true, // Default to monthly
};

export const amountReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_AMOUNT:
      return {
        ...state,
        selectedAmount: action.payload,
        customAmount: "",
      };
    case SET_CUSTOM_AMOUNT:
      return {
        ...state,
        customAmount: action.payload,
        selectedAmount: null,
      };
    case SET_PAYMENT_FREQUENCY:
      return {
        ...state,
        isMonthly: action.payload,
      };
    default:
      return state;
  }
};
