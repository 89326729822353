import React, { useEffect, useState } from 'react';
import API_BASE_URL from '../apiConfig/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy, faSyncAlt, faChartLine, faBook, faQuran, faCheckCircle, faHourglassHalf, faLock, faGraduationCap, faArrowUp, faStar } from '@fortawesome/free-solid-svg-icons';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import '../Styles/Statistics.css';
import Nav from '../Components/Nav';
import Footer from '../Components/Footer';
import Sidebar from '../Components/Sidebar';

// Define level structure and order (Ensure these EXACTLY match backend names)
const levels = [
  "Beginner 1", "Beginner 2", "Beginner 3",
  "Intermediate 1", "Intermediate 2", "Intermediate 3",
  "Advanced 1", "Advanced 2", "Advanced 3"
];

// Words per level target
const wordsPerLevel = {
  "Beginner 1": 1548,
  "Beginner 2": 3871,
  "Beginner 3": 7743,
  "Intermediate 1": 19357,
  "Intermediate 2": 38715,
  "Intermediate 3": 50329,
  "Advanced 1": 61943,
  "Advanced 2": 69686,
  "Advanced 3": 77429
};

// Helper function for logout/redirect
const handleUnauthorized = () => {
  console.warn('Unauthorized (401). Logging out and redirecting.');
  localStorage.removeItem("access_token");
  localStorage.removeItem("user_details"); // Add other relevant keys if needed
  window.location.href = '/login?sessionExpired=true'; // Adjust login route if needed
};

const Statistics = () => {
  const [timeSeriesData, setTimeSeriesData] = useState({});
  const [timeFrame, setTimeFrame] = useState('all-time');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sidebar, setSidebar] = useState(false);
  const [userStatsData, setUserStatsData] = useState(null);
  const [currentLevel, setCurrentLevel] = useState(null);
  
  const toggleSidebar = () => {
    setSidebar(!sidebar);
  };
  
  const sidemenufun = () => {
    toggleSidebar();
  };

  const fetchUserLevel = async () => {
    try {
      const apiUrl = `${API_BASE_URL}/user_dashboard/get_sticky_bar_info`;
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("access_token"),
      };
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: headers
      });

      if (response.status === 401) {
        handleUnauthorized();
        return;
      }

      if (!response.ok) {
        throw new Error(`Failed to fetch current level (Status: ${response.status})`);
      }
      const data = await response.json();
      console.log('Sticky bar data:', data);
      
      if (data && data.next_level_name) {
        setCurrentLevel(data.next_level_name);
      }
    } catch (err) {
      if (err.message?.includes('401')) return; 
      console.error('Error fetching current level:', err);
    }
  };

  const fetchUserStats = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const apiUrl = `${API_BASE_URL}/user_dashboard/get_user_stats`;
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("access_token"),
      };
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({}),
      });

      if (response.status === 401) {
        handleUnauthorized();
        return;
      }

      if (!response.ok) {
        throw new Error(`Failed to fetch progress data (Status: ${response.status})`);
      }
      const data = await response.json();
      console.log('Progress API Response Data:', data);
      
      setUserStatsData(data);
      
      if (data.time_series && Array.isArray(data.time_series.known)) {
        setTimeSeriesData({
          ...data.time_series,
          known: data.time_series.known.map(point => ({
            date: point.date,
            count: parseInt(point.count, 10)
          }))
        });
      } else {
        setTimeSeriesData({ known: [] });
      }
    } catch (err) {
      if (err.message?.includes('401')) return;
      console.error('Error fetching user stats:', err);
      setError(err.message);
      setTimeSeriesData({ known: [] });
      setUserStatsData(null);
    } finally {
      if (window.location.pathname !== '/login') { 
          setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchUserStats();
    fetchUserLevel();
  }, []);

  const getLevelStatus = (levelName, currentLevelName) => {
    const currentIndex = levels.indexOf(currentLevelName);
    const levelIndex = levels.indexOf(levelName);

    if (levelIndex === -1) return 'locked';
    if (currentIndex === -1) return 'locked';

    if (levelIndex < currentIndex) return 'completed';
    if (levelIndex === currentIndex) return 'current';
    return 'locked';
  };

  const getLevelInfo = (level) => {
    // Information about what each level contains
    const levelInfo = {
      "Beginner 1": { words: "100 most common words", percentage: "~40% of Quran text" },
      "Beginner 2": { words: "200 next most common", percentage: "~25% of Quran text" },
      "Beginner 3": { words: "300 fundamental words", percentage: "~15% of text coverage" },
      "Intermediate 1": { words: "500 important words", percentage: "~10% of text" },
      "Intermediate 2": { words: "750 expanded vocabulary", percentage: "~5% of text" },
      "Intermediate 3": { words: "1000 intermediate words", percentage: "~3% more text" },
      "Advanced 1": { words: "1500 advanced words", percentage: "~1% of remaining text" },
      "Advanced 2": { words: "2000 specialized terms", percentage: "~0.5% of text" },
      "Advanced 3": { words: "All remaining words", percentage: "100% comprehension" }
    };
    
    return levelInfo[level] || { words: "Level information", percentage: "Text coverage" };
  };

  const renderLevelProgressBars = () => {
    if (!currentLevel) return (
      <div className="loading-spinner level-loading">
        <div className="spinner"></div>
        <p>Loading level information...</p>
      </div>
    );

    // Group levels by category
    const levelGroups = {
      Beginner: levels.slice(0, 3),
      Intermediate: levels.slice(3, 6),
      Advanced: levels.slice(6, 9)
    };

    // Icons for each level group
    const groupIcons = {
      Beginner: faBook,
      Intermediate: faGraduationCap,
      Advanced: faStar
    };

    return (
      <div className="level-progress-bars-container">
        {Object.entries(levelGroups).map(([groupName, groupLevels]) => (
          <div key={groupName} className="level-group">
            <h3 className="level-group-title">
              <FontAwesomeIcon icon={groupIcons[groupName]} />
              <span>{groupName}</span>
            </h3>
            <div className="level-bars">
              {groupLevels.map((level, index) => {
                const status = getLevelStatus(level, currentLevel);
                
                return (
                  <div 
                    key={level} 
                    className={`level-bar-wrapper ${status === 'current' ? 'level-bar-wrapper--current' : ''}`}
                  >
                    <div className={`level-bar level-bar--${status}`}>
                      <span className="level-number">Level {index + 1}</span>
                    </div>
                    <div className="level-word-count">
                      {wordsPerLevel[level].toLocaleString()} words
                    </div>
                    {status === 'current' && (
                      <span className="current-level-tag">Current</span>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <Nav headervalue="nav2" toggleSidebar={toggleSidebar} sidemenufun={sidemenufun} />
      <Sidebar sidebar={sidebar} sidebaracc="Mobile-Sidebar" sidemenufun={sidemenufun} />
      <div className="statistics-container">
        <h1 className="statistics-title">Your Learning Progress</h1>
        
        {isLoading ? (
          <div className="loading-spinner">
             <div className="spinner"></div>
             <p>Loading your progress...</p>
           </div>
        ) : error ? (
          <div className="error-message">
            <p>{error}</p>
            <button onClick={fetchUserStats} className="retry-button">
              <FontAwesomeIcon icon={faSyncAlt} /> Try Again
            </button>
          </div>
        ) : userStatsData ? (
          <>
            {/* Current Level Highlight Section */}
            <div className="stats-section current-level-highlight">
              <div className="current-level-info">
                <FontAwesomeIcon icon={faTrophy} className="current-level-icon" />
                <div className="current-level-details">
                  <h3>Current Level: <span className="current-level-name">{currentLevel}</span></h3>
                  {currentLevel && (
                    <div className="level-target">
                      Target: <strong>{wordsPerLevel[currentLevel].toLocaleString()}</strong> words
                    </div>
                  )}
                </div>
              </div>
            </div>
            
            {/* --- Quran Word Statistics --- */}
            <div className="stats-section">
              <h2 className="section-title">Quran Word Statistics</h2>
              <div className="quran-word-stats">
                <div className="stat-card no-icon">
                  <div className="stat-info">
                    <h3>Total Words in the Quran</h3>
                    <div className="stat-value">77,429</div>
                  </div>
                </div>
                
                <div className="stat-card">
                  <div className="stat-icon known-icon">
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </div>
                  <div className="stat-info">
                    <h3>Your Known Words</h3>
                    <div className="stat-value">{userStatsData?.known_words_count?.toLocaleString() || "0"}</div>
                    <div className="stat-percentage">
                      {userStatsData?.known_words_count ? 
                        ((userStatsData.known_words_count / 77429) * 100).toFixed(1) + "%" : "0%"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            {/* --- Level Progress Section --- */}
            <div className="stats-section level-section">
              <h2 className="section-title">Level Progress</h2>
              {renderLevelProgressBars()}
            </div>
            
            {/* --- Known Words Graph Section --- */}
            <div className="stats-section chart-section">
              <h2 className="section-title">
                <span>Known Words Growth</span>
                <FontAwesomeIcon icon={faChartLine} className="chart-icon" />
              </h2>
              <div className="chart-controls">
                <div className="control-group">
                  <label>Time Frame:</label>
                  <select 
                    value={timeFrame} 
                    onChange={(e) => setTimeFrame(e.target.value)}
                    className="chart-select"
                  >
                    <option value="all-time">All Time</option>
                    <option value="monthly">Last 30 Days</option>
                    <option value="weekly">Current Week</option>
                  </select>
                </div>
              </div>
              <div className="chart-container">
                {timeSeriesData?.known?.length > 0 ? (
                  <ResponsiveContainer width="100%" height={300}>
                    <LineChart
                      data={timeSeriesData.known.filter(point => {
                        if (!point || !point.date) return false;
                        const date = new Date(point.date);
                        const now = new Date();
                        if (timeFrame === 'weekly') {
                          const weekAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
                          return date >= weekAgo;
                        } else if (timeFrame === 'monthly') {
                          const monthAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
                          return date >= monthAgo;
                        }
                        return true; // all-time
                      })}
                      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis 
                        dataKey="date" 
                        tickFormatter={(date) => date ? new Date(date).toLocaleDateString() : ''}
                      />
                      <YAxis allowDecimals={false} />
                      <Tooltip 
                        labelFormatter={(date) => date ? new Date(date).toLocaleDateString() : ''}
                        formatter={(value) => [value, 'Words']}
                      />
                      <Legend />
                      <Line
                        type="monotone"
                        dataKey="count"
                        name="Known Words"
                        stroke="#3498db"
                        strokeWidth={2}
                        dot={{ r: 4 }}
                        activeDot={{ r: 6 }}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                ) : (
                  <p className="no-words-message">No known words data available for the graph.</p>
                )}
              </div>
            </div>
          </>
        ) : (
           <p>Could not load statistics data.</p>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Statistics;
