import React, { useEffect, useState } from "react";
import Nav from "../Components/Nav";
import UserInfo from "../Components/UserInfo";
import AdminSidebar from "../Components/Sidebar";
import MembershipInfo from "../Components/MembershipInfo";
import AutoRenewalInfo from "../Components/AutoRenewalInfo";
import VoiceAndTranslatorSettings from "../Components/VoiceAndTranslatorSettings";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
import API_BASE_URL from "../apiConfig";
import { Switch, styled } from "@mui/material"
import { Info } from "lucide-react";
import infoIcon from "../assets/info-icon.svg";


const SubscriptionStarter = () => {
  const [userDetails, setUserDetails] = useState({});
  const [paymentStatus, setPaymentStatus] = useState("");
  const [hideDefinition, setHideDefinition] = useState(true);
  const [showTranslation, setShowTranslation] = useState(true);
  const [sidebar, setsidebar] = useState(false);
  const [selectedVoice, setSelectedVoice] = useState(() => {
    const savedVoice = localStorage.getItem("selectedVoice");
    if (!savedVoice) {
      localStorage.setItem("selectedVoice", "yasserAlDosari");
    }
    return savedVoice || "yasserAlDosari";
  });
  const [selectedTranslator, setSelectedTranslator] = useState(
    () => localStorage.getItem("selectedTranslator") || "saheehinternational"
  );

  const CustomSwitch = styled(Switch)(({ theme }) => ({
    width: 48,
    height: 28,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(20px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: "#34C759",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      width: 24,
      height: 24,
      backgroundColor: "#fff",
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: "#E5E7EB",
      borderRadius: 14,
    },
  }))

  useEffect(() => {
    localStorage.setItem("selectedVoice", selectedVoice);
  }, [selectedVoice]);

  useEffect(() => {
    localStorage.setItem("selectedTranslator", selectedTranslator);
  }, [selectedTranslator]);


  useEffect(() => {
    let check = localStorage.getItem('hideDefinition') == 'true' ? true : false;
    setHideDefinition(check);
    check = localStorage.getItem('showTranslation') == 'true' ? true : false;
    // alert(localStorage.getItem('showTranslation')) 
    setShowTranslation(check);
  }, []);

  const handleChangeVoice = (value) => {
    setSelectedVoice(value);
  };

  const handleChangeTranslator = (value) => {
    setSelectedTranslator(value);
  };

  const sidemenufun = () => {
    setsidebar(!sidebar);
  };

  const getUserInfo = () => {
    const apiUrl = `${API_BASE_URL}/user_dashboard/get_plan_info`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("access_token"),
    };
    fetch(apiUrl, {
      method: "GET",
      headers: headers,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setUserDetails(data);
        setPaymentStatus(data?.status);
        localStorage.setItem("localdata", "");
      })
      .catch((error) => {
        console.error("Error fetching API:", error);
      });
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const handleCancelSubscription = () => {
    const apiUrl = `${API_BASE_URL}/payment/paypal/cancel-subscription`;

    const headers = {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("access_token"),
    };

    const body = JSON.stringify({
      reason: "User canceled subscription",
    });

    fetch(apiUrl, {
      method: "POST",
      headers: headers,
      body: body,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setPaymentStatus("Canceled");
        // Show a success message to the user
        alert("Your subscription has been canceled. You'll maintain access until the end of your billing period.");
      })
      .catch((error) => {
        console.error("Error canceling subscription:", error);
      });
  };

  const user = JSON.parse(localStorage.getItem("userData"));
  const userName = user?.user_info?.username;
  const userEmail = user?.user_info?.email;
  const membershipStatus = userDetails?.name;

  return (
    <>
      <Nav headervalue={"nav2"} sidemenufun={sidemenufun} />
      <AdminSidebar sidebar={sidebar} sidebaracc={"Mobile-Sidebar"} />

      <div className="starter-main">
        <p className="account-heading">ACCOUNT</p>

        <UserInfo userName={userName} userEmail={userEmail} />

        <hr />

        <MembershipInfo membership={membershipStatus} />

       {membershipStatus !== "Free Plan" && membershipStatus !== "Starter" && paymentStatus ? (
        <AutoRenewalInfo
          paymentStatus={paymentStatus}
          handleCancelSubscription={handleCancelSubscription}
          userDetails={userDetails}
        />
      ) : ""}
        <div
          style={{
            width: "45%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "1rem",
          }}
        >
          {/* <Link to="/subscription-plan">
            <button className="update-btn">
              {membershipStatus === "Free"
                ? "Contribute again!"
                : "Update Plan"}
            </button>
          </Link> */}
        </div>

        {/* <hr />
        <div
          className="d-flex align-items-center justify-content-between mt-4 global-definition"
        > */}
          {/* <p className="responsive-text mb-0">Hide Definition</p>
          <CustomSwitch
            checked={hideDefinition}
            onChange={(e) => {
              const newValue = e.target.checked
              setHideDefinition(newValue)
              localStorage.setItem("hideDefinition", String(newValue))
            }}
          />
        </div>    
        <div style={{ display: "flex", alignItems: "center", marginTop: "5px", marginBottom: "10px" }}>
            <img src={infoIcon} alt="Info" style={{ marginRight: "5px" }} />
            <span style={{ fontSize: "14px", color: "#666" }}>This will hide the definition of new words</span>
        </div>
        <div style={{ marginTop: '15px', marginLeft: '0' }}>
            <div style={{ 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start'
            }}>
                <div style={{ 
                    backgroundColor: '#E6F3FF', 
                    padding: '15px 20px',
                    borderRadius: '8px',
                    width: 'fit-content'
                }}>
                    <div style={{ 
                        fontSize: '32px',
                        fontWeight: '500',
                        fontFamily: 'uth',
                        textAlign: 'left'
                    }}>
                        الرحيم
                    </div>
                </div>
                <div style={{ 
                    fontSize: '11px',
                    color: '#666',
                    fontFamily: 'inter',
                    marginTop: '5px',
                    textAlign: 'left',
                    height: '15px'
                }}>
                    {!hideDefinition && "the Most Merciful"}
                </div>
            </div>
        </div> */}

        {/* <div
          className="d-flex align-items-center justify-content-between global-definition"
        >
          <p className="responsive-text mb-0">Show Translation</p>
          <CustomSwitch
            checked={showTranslation}
            onChange={() => {
              localStorage.setItem("showTranslation", !showTranslation);
              setShowTranslation(!showTranslation);
            }}
          />
        </div> */}
        <VoiceAndTranslatorSettings
          handleChangeVoice={handleChangeVoice}
          handleChangeTranslator={handleChangeTranslator}
          selectedVoice={selectedVoice}
          setSelectedVoice={setSelectedVoice}
          selectedTranslator={selectedTranslator}
          setSelectedTranslator={setSelectedTranslator}
        />
      </div>

      <Footer />
    </>
  );
};

export default SubscriptionStarter;
