import React from 'react';
import { ImagePath } from "../../Constant/ImgPath";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

// Define the CSS for the animations
const animations = `
  @keyframes pulse {
    0% { opacity: 0.7; }
    50% { opacity: 1; }
    100% { opacity: 0.7; }
  }
  
  @keyframes bgPulse {
    0% { background-color: #333; }
    50% { background-color: #555; }
    100% { background-color: #333; }
  }
  
  @keyframes borderPulse {
    0% { border-color: rgba(255,255,255,0.3); }
    50% { border-color: rgba(255,255,255,0.7); }
    100% { border-color: rgba(255,255,255,0.3); }
  }
  
  .loading-button {
    animation: bgPulse 2s infinite ease-in-out, borderPulse 2s infinite ease-in-out;
    border: 2px solid rgba(255,255,255,0.3);
  }
  
  .spinner-icon {
    animation: spin 1s infinite linear;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

// Add the style to the document
if (typeof document !== 'undefined') {
  const style = document.createElement('style');
  style.type = 'text/css';
  style.appendChild(document.createTextNode(animations));
  document.head.appendChild(style);
}

const ButtonGroup = ({
  player,
  togglePlay,
  knowWords,
  handleMoveToKnown,
  onViewModeToggle,
  newWordsCount,
  isSentenceView,
  isMarkAllLoading, // Global loading state for all buttons
  isActiveLoading, // Whether this specific button is actively loading
  verseIndex // Add verse index to know which verse is being processed
}) => {
  return (
    <div className="button-group" style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "20px",
      gap: "20px",
      width: "100%",
      position: "relative"
    }}>
      {/* Container for all buttons to keep them centered together */}
      <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "12px",  // Reduced gap for closer spacing, especially on mobile
        flexWrap: "nowrap"  // Prevent wrapping on small screens
      }}>
        {/* Play/Pause Button */}
        <div 
          onClick={togglePlay}
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <img
            style={{
              width: "40px",
              height: "40px",
              objectFit: "contain"
            }}
            src={player ? ImagePath.PlayBtn : ImagePath.PauseIcon}
            alt={player ? "Play" : "Pause"}
          />
        </div>

        {/* Mark All Known Button - Only show if there are new words */}
        {newWordsCount > 0 && (
          <div
            onClick={isMarkAllLoading ? null : handleMoveToKnown}
            className={isActiveLoading ? "loading-button" : ""}
            style={{
              cursor: isMarkAllLoading ? "not-allowed" : "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: isActiveLoading ? "#4a4a4a" : "black",
              color: "white",
              padding: "8px 16px",
              borderRadius: "4px",
              fontSize: "14px",
              fontWeight: "500",
              transition: "all 0.2s ease",
              opacity: isActiveLoading ? 1 : (isMarkAllLoading ? 0.5 : 1),
              pointerEvents: isMarkAllLoading ? "none" : "auto", // Prevent clicks during loading
              boxShadow: isActiveLoading ? "0 0 10px rgba(255,255,255,0.4)" : "none", // Add glow effect when loading
              border: isActiveLoading ? "2px solid rgba(255,255,255,0.2)" : "none" // Border for animation
            }}
          >
            {isActiveLoading ? (
              <>
                <span style={{ marginRight: "8px", fontWeight: "bold" }}>Processing</span>
                <FontAwesomeIcon 
                  className="spinner-icon"
                  icon={faSpinner} 
                  style={{ 
                    fontSize: '18px', 
                    color: '#fff',
                    display: 'inline-block'
                  }} 
                />
              </>
            ) : (
              "Mark All Known"
            )}
          </div>
        )}

        {/* Translation Toggle Button */}
        <div
          onClick={onViewModeToggle}
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "8px",
            borderRadius: "50%",
            backgroundColor: isSentenceView ? "#000" : "#fff",
            border: `1px solid ${isSentenceView ? "transparent" : "#000"}`,
            width: "40px",
            height: "40px",
            transition: "all 0.3s ease",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)"
          }}
        >
          <i className="fa-solid fa-t" style={{
            fontSize: "16px",
            color: isSentenceView ? "#fff" : "#000",
            transition: "color 0.3s ease"
          }}></i>
        </div>
      </div>
    </div>
  );
};

export default ButtonGroup;