import React, { useState, useRef } from 'react';
import {
  Box,
  TextField,
  IconButton,
  InputAdornment,
  Paper,
  Typography,
  CircularProgress,
  Alert,
  Card,
  CardContent,
  Grid,
  Chip,
  Fade,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider
} from '@mui/material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faTimes,
  faPuzzlePiece,
  faGavel,
  faFileAlt
} from "@fortawesome/free-solid-svg-icons";
import API_BASE_URL from "../../apiConfig";

const NahwResult = ({ result, searchTerm }) => {
  if (!result) return null;

  return (
    <Fade in={!!result}>
      <Card elevation={3} sx={{ mt: 2, borderRadius: '16px' }}>
        {/* Header */}
        <Box sx={{ p: 3, bgcolor: '#f8f8f8', borderBottom: '1px solid #e8e8e8' }}>
          <Typography variant="h5" sx={{ fontWeight: 700, mb: 1, fontFamily: '"Amiri", serif', direction: 'rtl' }}>
            {result.fully_diacritized || searchTerm}
          </Typography>
          {result.syntactic_role && (
            <Chip 
              label={result.syntactic_role} 
              sx={{ fontFamily: '"Amiri", serif', fontSize: '1rem' }}
            />
          )}
        </Box>

        <CardContent>
          {/* Grammatical Analysis */}
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" sx={{ 
              fontWeight: 700, 
              mb: 2.5,
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid #e8e8e8',
              pb: 1.5
            }}>
              <FontAwesomeIcon icon={faGavel} style={{ marginRight: '8px' }} />
              Grammatical Analysis
            </Typography>

            {/* Word Components Visualization */}
            {result.components && result.components.length > 0 && (
              <Box sx={{ mb: 4 }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 2 }}>
                  Sentence Structure
                </Typography>
                <Paper 
                  elevation={0} 
                  sx={{ 
                    p: 3, 
                    bgcolor: '#f8f8f8',
                    borderRadius: '8px',
                    position: 'relative',
                    overflow: 'visible'
                  }}
                >
                  <Box 
                    sx={{ 
                      display: 'flex', 
                      flexDirection: 'row-reverse', 
                      justifyContent: 'center',
                      alignItems: 'flex-start',
                      gap: 3,
                      mb: 5,
                      position: 'relative'
                    }}
                  >
                    {result.components.map((component, index) => (
                      <Box 
                        key={index}
                        sx={{ 
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          position: 'relative'
                        }}
                      >
                        {/* Word */}
                        <Paper
                          elevation={2}
                          sx={{
                            p: 2,
                            borderRadius: '8px',
                            bgcolor: 'white',
                            border: '1px solid #e0e0e0',
                            mb: 1,
                            minWidth: '80px',
                            textAlign: 'center'
                          }}
                        >
                          <Typography 
                            sx={{ 
                              fontFamily: '"Amiri", serif', 
                              fontSize: '1.25rem',
                              fontWeight: 500,
                              mb: 0.5
                            }}
                          >
                            {component.diacritized || component.part}
                          </Typography>
                          {component.translation && (
                            <Typography 
                              variant="caption" 
                              sx={{ 
                                display: 'block',
                                color: 'text.secondary'
                              }}
                            >
                              {component.translation}
                            </Typography>
                          )}
                        </Paper>
                        
                        {/* Arrow */}
                        <Box sx={{ 
                          height: '40px', 
                          width: '2px', 
                          bgcolor: '#ccc',
                          position: 'relative'
                        }}>
                          <Box sx={{ 
                            position: 'absolute',
                            bottom: 0,
                            left: '50%',
                            transform: 'translateX(-50%)',
                            width: 0,
                            height: 0,
                            borderLeft: '6px solid transparent',
                            borderRight: '6px solid transparent',
                            borderTop: '8px solid #ccc'
                          }} />
                        </Box>
                        
                        {/* Function */}
                        <Box 
                          sx={{ 
                            mt: 1,
                            bgcolor: '#f0f7ff',
                            border: '1px solid #d0e4ff',
                            borderRadius: '6px',
                            px: 2,
                            py: 1,
                            minWidth: '100px',
                            textAlign: 'center'
                          }}
                        >
                          <Typography 
                            sx={{ 
                              fontFamily: '"Amiri", serif',
                              fontSize: '1rem',
                              color: '#0057b8'
                            }}
                          >
                            {component.function}
                          </Typography>
                          {component.case && (
                            <Chip
                              label={component.case}
                              size="small"
                              sx={{ 
                                mt: 0.5,
                                fontFamily: '"Amiri", serif',
                                fontSize: '0.75rem',
                                height: '20px'
                              }}
                            />
                          )}
                        </Box>
                      </Box>
                    ))}
                  </Box>
                  
                  {/* Syntactic Structure */}
                  {result.syntactic_structure && (
                    <Box 
                      sx={{ 
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 2
                      }}
                    >
                      <Chip
                        label={result.syntactic_structure}
                        color="primary"
                        variant="outlined"
                        sx={{ 
                          fontFamily: '"Amiri", serif',
                          fontSize: '1rem',
                          px: 1
                        }}
                      />
                    </Box>
                  )}
                </Paper>
              </Box>
            )}

            <Grid container spacing={3}>
              {/* Left Column */}
              <Grid item xs={12} md={6}>
                {/* Base Form */}
                {result.base_form && (
                  <Box sx={{ mb: 3 }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 1 }}>
                      Base Form
                    </Typography>
                    <Paper elevation={0} sx={{ p: 2, bgcolor: '#f5f5f5' }}>
                      <Typography sx={{ 
                        fontFamily: '"Amiri", serif',
                        fontSize: '1.25rem',
                        direction: 'rtl',
                        textAlign: 'right'
                      }}>
                        {result.base_form}
                      </Typography>
                    </Paper>
                  </Box>
                )}

                {/* Type */}
                {result.type && (
                  <Box sx={{ mb: 3 }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 1 }}>
                      Word Type
                    </Typography>
                    <Paper elevation={0} sx={{ p: 2, bgcolor: '#f5f5f5' }}>
                      <Typography sx={{ fontFamily: '"Amiri", serif' }}>
                        {result.type}
                      </Typography>
                    </Paper>
                  </Box>
                )}
              </Grid>

              {/* Right Column */}
              <Grid item xs={12} md={6}>
                {/* Properties */}
                {result.properties && (
                  <Box sx={{ mb: 3 }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 1 }}>
                      Properties
                    </Typography>
                    <Paper elevation={0} sx={{ p: 2, bgcolor: '#f5f5f5' }}>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {Object.entries(result.properties).map(([key, value]) => (
                          <Chip
                            key={key}
                            label={`${key}: ${value}`}
                            sx={{ fontFamily: '"Amiri", serif' }}
                          />
                        ))}
                      </Box>
                    </Paper>
                  </Box>
                )}

                {/* Case */}
                {result.case && (
                  <Box sx={{ mb: 3 }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 1 }}>
                      Case
                    </Typography>
                    <Paper elevation={0} sx={{ p: 2, bgcolor: '#f5f5f5' }}>
                      <Typography sx={{ fontFamily: '"Amiri", serif' }}>
                        {result.case}
                      </Typography>
                    </Paper>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>

          {/* Rules and Explanation */}
          {(result.grammatical_rules?.length > 0 || result.detailed_analysis) && (
            <Box>
              <Typography variant="h6" sx={{ 
                fontWeight: 700, 
                mb: 2.5,
                display: 'flex',
                alignItems: 'center',
                borderBottom: '1px solid #e8e8e8',
                pb: 1.5
              }}>
                <FontAwesomeIcon icon={faFileAlt} style={{ marginRight: '8px' }} />
                Rules & Explanation
              </Typography>

              {/* Rules */}
              {result.grammatical_rules && result.grammatical_rules.length > 0 && (
                <Box sx={{ mb: 3 }}>
                  <List>
                    {result.grammatical_rules.map((rule, index) => (
                      <ListItem key={index}>
                        <ListItemIcon sx={{ minWidth: '32px' }}>
                          <Box sx={{ 
                            width: 24, 
                            height: 24, 
                            borderRadius: '50%', 
                            bgcolor: '#f0f0f0',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '0.8rem'
                          }}>
                            {index + 1}
                          </Box>
                        </ListItemIcon>
                        <ListItemText 
                          primary={
                            <Typography sx={{ 
                              fontFamily: '"Amiri", serif',
                              fontSize: '1.1rem',
                              direction: 'rtl'
                            }}>
                              {rule}
                            </Typography>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              )}

              {/* Detailed Analysis */}
              {result.detailed_analysis && (
                <Box>
                  <Paper elevation={0} sx={{ p: 2, bgcolor: '#f5f5f5' }}>
                    <Typography sx={{ lineHeight: 1.8 }}>
                      {result.detailed_analysis}
                    </Typography>
                  </Paper>
                </Box>
              )}
            </Box>
          )}
        </CardContent>
      </Card>
    </Fade>
  );
};

const NahwMode = ({ onRecentSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [analysisError, setAnalysisError] = useState(null);
  const [analysisResult, setAnalysisResult] = useState(null);
  const [isFocused, setIsFocused] = useState(false);
  const abortControllerRef = useRef(null);

  const clearSearch = () => {
    setSearchTerm("");
    setAnalysisResult(null);
    setAnalysisError(null);
  };

  const cancelSearch = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null;
      setIsAnalyzing(false);
      setAnalysisError("Search cancelled");
    }
  };

  const analyzeNahw = async (word) => {
    if (!word.trim()) return;
    
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    
    abortControllerRef.current = new AbortController();
    const { signal } = abortControllerRef.current;
    
    try {
      setIsAnalyzing(true);
      setAnalysisError(null);
      setAnalysisResult(null);
      
      const response = await fetch(`${API_BASE_URL}/word/nahwanalyze`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('access_token')
        },
        body: JSON.stringify({ text: word }),
        signal
      });
      
      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }
      
      const data = await response.json();
      console.log('Nahw analysis response:', data);
      
      if (!signal.aborted) {
        if (data.result) {
          setAnalysisResult(data.result);
          if (onRecentSearch) {
            onRecentSearch(word.trim());
          }
        } else {
          setAnalysisError('No grammatical analysis found');
        }
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Search aborted');
      } else {
        console.error('Error analyzing grammar:', error);
        setAnalysisError(`Failed to analyze grammar: ${error.message}`);
      }
    } finally {
      if (!signal.aborted) {
        setIsAnalyzing(false);
        abortControllerRef.current = null;
      }
    }
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    if (!searchTerm.trim()) return;
    analyzeNahw(searchTerm);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <Box>
      <Paper
        elevation={isFocused ? 3 : 0}
        sx={{
          p: 0.75,
          borderRadius: '14px',
          maxWidth: '700px',
          mx: 'auto',
          mb: 3,
          backgroundColor: '#f9f9f9',
          border: isFocused ? '2px solid #111' : '1px solid #e0e0e0',
        }}
      >
        <form onSubmit={handleSearchSubmit}>
          <Box sx={{ display: 'flex', alignItems: 'center', p: '2px 4px' }}>
            <IconButton
              sx={{ p: '10px', color: '#222' }}
              type={isAnalyzing ? 'button' : 'submit'}
              onClick={isAnalyzing ? cancelSearch : undefined}
              disabled={!searchTerm.trim() && !isAnalyzing}
            >
              <FontAwesomeIcon icon={isAnalyzing ? faTimes : faSearch} />
            </IconButton>

            <TextField
              fullWidth
              placeholder=""
              value={searchTerm}
              onChange={handleSearchChange}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              variant="standard"
              disabled={isAnalyzing}
              InputProps={{
                disableUnderline: true,
                style: { 
                  fontSize: '24px',
                  fontFamily: '"Amiri", serif',
                  direction: 'rtl',
                  padding: '12px 8px'
                },
                endAdornment: searchTerm && !isAnalyzing && (
                  <InputAdornment position="end">
                    <IconButton onClick={clearSearch}>
                      <FontAwesomeIcon icon={faTimes} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Box>
        </form>
      </Paper>

      {isAnalyzing ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3, mb: 2 }}>
          <CircularProgress size={40} sx={{ color: '#111' }} />
        </Box>
      ) : (
        <>
          {analysisError && (
            <Alert severity="error" sx={{ mt: 3, borderRadius: '8px' }}>
              {analysisError}
            </Alert>
          )}
          
          {analysisResult && (
            <NahwResult 
              result={analysisResult}
              searchTerm={searchTerm}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default NahwMode;