import React from 'react';
import './ArabicVerse.css';

const ArabicVerse = ({
  words,
  showTranslation,
  onWordClick,
  verseKey,
  endIcon,
  currentWordIndex = -1,
  size = 'normal', // 'normal' or 'large' for different text sizes
  includeLearned = false // New prop to control whether to hide learned word translations
}) => {
  return (
    <div className="arabic-verse">
      <div className="arabic-verse__content">
        {words?.map((word, index) => (
          <div 
            key={`${word.key}-${index}`}
            className="arabic-verse__word-container"
          >
            <div
              className={`
                arabic-verse__word
                ${word?.status === "Familiar" ? "arabic-verse__word--familiar" : ""}
                ${size === 'large' ? 'arabic-verse__word--large' : ''}
                ${currentWordIndex === index ? 'arabic-verse__word--playing' : ''}
              `}
              onClick={() => onWordClick?.(word, index)}
              style={{
                background:
                  word?.status === "Known"
                    ? "transparent"
                    : word?.status === "Learned"
                    ? "#fcd966"
                    : "#def3fc",
              }}
            >
              <h5 className="arabic-verse__text">
                {word?.word}
              </h5>
            </div>
            {/* Handle translations based on word status */}
            {word?.status === "Learned" ? (
              // For Learned words, only show if Hide Learning is off
              !includeLearned && (
                <span className="arabic-verse__translation">
                  {word?.translation}
                </span>
              )
            ) : word?.status !== "Known" && (
              // Show translation for all words except Known
              <span className="arabic-verse__translation">
                {word?.translation}
              </span>
            )}
          </div>
        ))}
      </div>
      {endIcon && (
        <div className="arabic-verse__end-icon">
          {endIcon}
        </div>
      )}
    </div>
  );
};

export default ArabicVerse;