import React, { useState } from "react";
import { ImagePath } from "../../Constant/ImgPath";
import PasswordInput from "../../Components/PasswordInput";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_BASE_URL from "../../apiConfig/index";
import { Box, Grid, Typography, Button, TextField } from "@mui/material";
import singupimage from "../../assets/signupimage.png";

function SignUp() {
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  localStorage.setItem("login", "false");
  localStorage.setItem("access_token", "");
  localStorage.setItem("localdata", "");
  localStorage.setItem("userData", "");
  localStorage.clear();
  const navigate = useNavigate();
  const NavigatePages = (data) => {
    navigate(data);
  };
  const notify = (message) =>
    toast.error(message, {
      position: "top-right",
    });
  const showToastMessage = (message) => {
    toast.success(message, {
      position: "top-right",
    });
  };
  const initialFormData = {
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    password: "",
  };
  const [formData, setFormData] = useState(initialFormData);
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleConfirmPasswordChange = (cp) => {
    setConfirmPassword(cp);
  };
  const handlePassword = (password) => {
    setFormData({ ...formData, ["password"]: password });
  };
  const handleData = () => {
    if (formData.password !== confirmPassword) {
      setError("Passwords don't match");
    } else {
      if (formData.password.length > 8) {
        const apiUrl = `${API_BASE_URL}/auth/register`;
        const requestData = {
          first_name: formData.first_name,
          last_name: formData.last_name,
          username: formData.username,
          email: formData.email,
          password: formData.password,
        };
        const headers = {
          "Content-Type": "application/json",
        };
        fetch(apiUrl, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(requestData),
        })
          .then((response) => response.json())
          .then((data) => {
            //console.log(data);
            if (data.message == "User registered successfully") {
              

              // Login After Registration

              let apiUrlLogin = `${API_BASE_URL}/auth/login`;
              
              // Create a separate request object for login that only includes email and password
              const loginRequestData = {
                email: formData.email,
                password: formData.password
              };

              fetch(apiUrlLogin, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(loginRequestData),  // Use the login-specific request data
              })
                .then((response) => {
                  if (!response.ok) {
                    // Capture and log the actual error response for debugging
                    return response.text().then(text => {
                      console.error(`Login failed with status: ${response.status}, Response:`, text);
                      throw new Error(`HTTP error! Status: ${response.status}`);
                    });
                  }
                  return response.json();
                })
                .then((data) => {
                  //console.log(data);
                  localStorage.setItem("login", "true");
                  localStorage.setItem(
                    "access_token",
                    "Bearer " + data?.["access_token"]
                  );
                  localStorage.setItem("localdata", "");
                  localStorage.setItem("hideDefinition", "false"); // Set translations to show by default
                  let access_token = localStorage.getItem("access_token");
                  localStorage.setItem(
                    "selectedVoice",
                    "yasserAlDosari"
                  );
                  localStorage.setItem(
                    "selectedTranslator",
                    "saheehinternational"
                  );
                  //console.log("at====>>", access_token);
                  localStorage.setItem(
                    "userData",
                    JSON.stringify(data.user_info)
                  );
                  showToastMessage("User registered successfully");
                  setTimeout(() => {
                    NavigatePages("/dashboard");
                  }, 2000);
                })
                .catch((error) => {
                  console.error("Error fetching API:", error);
                  
                  // Check if this is the specific "Invalid credentials" error with 500 status
                  const errorMessage = error.toString();
                  if (errorMessage.includes("Invalid credentials")) {
                    // This is the backend sending 500 for auth failure - give user helpful message
                    notify("Your account was created successfully! Please go to the login page to sign in.");
                  } else {
                    // Show a generic error message for other errors
                    notify("Registration was successful, but automatic login failed. Please try logging in manually.");
                  }
                  
                  // Add a delay and redirect to login page as a fallback
                  setTimeout(() => {
                    NavigatePages("/login");
                  }, 3000);
                });
            } else {
              notify(data.message);
            }
          })
          .catch((error) => {
            console.error("Error fetching API:", error);
          });

        setError("");
      } else {
        console.log("working");
        notify("you have to enter at least 9 characters!");
      }
    }
  };
  const googleLoginAPI = (credentialResponse) => {
    const apiUrl = `${API_BASE_URL}/auth/login_google`;
    const requestData = { credentialResponse: credentialResponse };
    const headers = {
      "Content-Type": "application/json",
    };
    fetch(apiUrl, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        localStorage.setItem("login", "true");
        localStorage.setItem("access_token", "Bearer " + data?.["access_token"]);
        localStorage.setItem("localdata", "");
        localStorage.setItem("selectedVoice", "yasserAlDosari");
        localStorage.setItem("selectedTranslator", "saheehinternational");
        localStorage.setItem("userData", JSON.stringify(data.user_info));
        showToastMessage("Successfully signed in with Google");
        NavigatePages("/dashboard");
      })
      .catch((error) => {
        console.error("Error fetching API:", error);
        notify("Error signing in with Google");
      });
  };
  const loginGoogle = useGoogleLogin({
    onSuccess: (credentialResponse) => {
      console.log({ credentialResponse });
      googleLoginAPI(credentialResponse);
      // save the information on successful login
    },
  });

  // Add a diagnostic login function for testing
  const runDiagnosticLogin = async () => {
    try {
      // Create a simplified test request
      const testData = {
        email: formData.email,
        password: formData.password
      };
      
      console.log("Running diagnostic login with:", { 
        email: testData.email,
        url: `${API_BASE_URL}/auth/login`
      });
      
      // Make the request with simple parameters
      const response = await fetch(`${API_BASE_URL}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(testData)
      });
      
      // Log the raw response before any processing
      console.log("Diagnostic raw response status:", response.status);
      
      // Get the response text regardless of status code
      const responseText = await response.text();
      console.log("Diagnostic raw response:", responseText);
      
      // Try to parse the response as JSON
      try {
        const jsonData = JSON.parse(responseText);
        console.log("Diagnostic parsed response:", jsonData);
      } catch (e) {
        console.log("Diagnostic response is not valid JSON");
      }
      
      if (response.ok) {
        console.log("Diagnostic login succeeded!");
        showToastMessage("Diagnostic test successful!");
      } else {
        console.error("Diagnostic login failed with status:", response.status);
        notify(`Diagnostic test failed: ${response.status}. Check console for details.`);
      }
    } catch (error) {
      console.error("Diagnostic test exception:", error);
      notify(`Diagnostic error: ${error.message}`);
    }
  };

  return (
    <Box 
      sx={{ 
        minHeight: "100vh", 
        backgroundColor: "black", 
        display: "flex", 
        alignItems: "center",
        justifyContent: "center",
        padding: { xs: "1rem", md: "2rem" }
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "450px",
          backgroundColor: "white",
          borderRadius: "16px",
          padding: "2rem",
          boxShadow: "0 0 10px rgba(255, 255, 255, 0.1), 0 0 20px rgba(255, 255, 255, 0.1), 0 0 30px rgba(255, 255, 255, 0.1), 0 0 40px rgba(255, 255, 255, 0.1)",
          transition: "all 0.3s ease",
          "&:hover": {
            boxShadow: "0 0 15px rgba(255, 255, 255, 0.2), 0 0 30px rgba(255, 255, 255, 0.2), 0 0 45px rgba(255, 255, 255, 0.2), 0 0 60px rgba(255, 255, 255, 0.2)",
            transform: "translateY(-5px)"
          }
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontWeight: "700",
            color: "#0D0E10",
            fontFamily: "Roboto, sans-serif",
            fontSize: "32px",
            marginBottom: "1.5rem",
            textAlign: "center"
          }}
        >
          Sign Up
        </Typography>

        <button
          onClick={loginGoogle}
          style={{
            width: "100%",
            border: "1px solid #1E2022",
            borderRadius: "8px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "0.625rem",
            background: "white",
            cursor: "pointer",
            fontSize: "15px",
            height: "45px",
            fontWeight: "700",
            marginBottom: "1.5rem"
          }}
        >
          <img src={ImagePath.googlebtn} alt="google login" style={{ width: "20px" }} />
          Sign up with Google
        </button>

        <div style={{ position: "relative", marginBottom: "1.5rem" }}>
          <hr style={{ 
            margin: "0", 
            border: "none", 
            borderTop: "1px solid #eee"
          }} />
          <span style={{ 
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "0 0.625rem",
            color: "#666"
          }}>
            or
          </span>
        </div>

        
        <div style={{ marginBottom: "1rem" }}>
          <label style={{ 
            display: "block", 
            marginBottom: "4px",
            fontSize: "14px",
            fontWeight: "500",
            color: "#000"
          }}>
            Email
          </label>
          <input
            name="email"
            value={formData.email}
            onChange={handleChange}
            type="email"
            placeholder="example@gmail.com"
            style={{
              width: "100%",
              padding: "12px",
              borderRadius: "8px",
              border: "1px solid #ddd",
              fontSize: "16px",
              boxSizing: "border-box",
              height: "45px"
            }}
          />
        </div>

        <div style={{ marginBottom: "1rem" }}>
          <label style={{ 
            display: "block", 
            marginBottom: "4px",
            fontSize: "14px",
            fontWeight: "500",
            color: "#000"
          }}>
            Username
          </label>
          <input
            name="username"
            value={formData.username}
            onChange={handleChange}
            type="text"
            placeholder="Enter Username"
            style={{
              width: "100%",
              padding: "12px",
              borderRadius: "8px",
              border: "1px solid #ddd",
              fontSize: "16px",
              boxSizing: "border-box",
              height: "45px"
            }}
          />
        </div>

        
        
        <div style={{ marginBottom: "1rem" }}>
          <label style={{ 
            display: "block", 
            marginBottom: "4px",
            fontSize: "14px",
            fontWeight: "500",
            color: "#000"
          }}>
            Password
          </label>
          <PasswordInput setpassword={handlePassword} placeHolder="Enter your password" />
        </div>

        <div style={{ marginBottom: "1.5rem" }}>
          <label style={{ 
            display: "block", 
            marginBottom: "4px",
            fontSize: "14px",
            fontWeight: "500",
            color: "#000"
          }}>
            Confirm Password
          </label>
          <PasswordInput setpassword={handleConfirmPasswordChange} placeHolder="Re-enter your password" />
          {error && (
            <p style={{
              color: "#dc3545",
              fontSize: "12px",
              margin: "4px 0 0",
              textAlign: "left"
            }}>
              {error}
            </p>
          )}
        </div>

        <button 
          onClick={handleData}
          style={{
            width: "100%",
            padding: "12px",
            backgroundColor: "#000",
            color: "white",
            border: "none",
            borderRadius: "8px",
            fontSize: "16px",
            fontWeight: "500",
            cursor: "pointer",
            height: "45px",
            marginBottom: "1rem",
            transition: "all 0.3s ease"
          }}
        >
          Sign Up
        </button>

        <p style={{ 
          textAlign: "center",
          margin: "0",
          color: "#666",
          fontSize: "14px"
        }}>
          Already have an account?{" "}
          <button
            onClick={() => NavigatePages("/login")}
            style={{ 
              background: "none",
              border: "none",
              color: "#000",
              textDecoration: "none",
              fontWeight: "500",
              cursor: "pointer",
              padding: 0
            }}
          >
            Login
          </button>
        </p>

        {/* <button
          onClick={runDiagnosticLogin}
          style={{
            background: "none",
            border: "none",
            color: "#999",
            fontSize: "12px",
            marginTop: "16px",
            cursor: "pointer",
            textAlign: "center",
            width: "100%"
          }}
        >
          Run Diagnostic Test
        </button> */}
        
        <ToastContainer />
      </Box>
    </Box>
  );
}

export default SignUp;
