import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const DictionaryModal = ({ 
  showDictionary, 
  iframeRef, 
  dictionaryLoading, 
  closeDictionary, 
  handleIframeLoad 
}) => {
  if (!showDictionary) return null;

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      zIndex: 10000,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <div style={{
        background: 'white',
        borderRadius: '8px',
        width: '95%',
        maxWidth: '1000px',
        height: '90vh',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)'
      }}>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '12px 16px',
          borderBottom: '1px solid #eee'
        }}>
          <h3 style={{ margin: 0 }}>
            {iframeRef.current && iframeRef.current.src.includes('ksaa.gov.sa') ? 'KSAA Dictionary' : 'Ejtaal Dictionary'}
          </h3>
          <button 
            onClick={closeDictionary} 
            style={{
              background: 'none',
              border: 'none',
              cursor: 'pointer',
              fontSize: '20px',
              color: '#666'
            }}
          >
            ×
          </button>
        </div>
        <div style={{ flex: 1, position: 'relative' }}>
          {dictionaryLoading && (
            <div style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              padding: '20px',
              borderRadius: '8px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              zIndex: 10,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <FontAwesomeIcon icon={faSpinner} spin style={{ fontSize: '24px', marginBottom: '10px', color: '#4A86E8' }} />
              <div>Loading {iframeRef.current && iframeRef.current.src.includes('ksaa.gov.sa') ? 'KSAA' : 'Ejtaal'} dictionary...</div>
            </div>
          )}
          <iframe 
            ref={iframeRef}
            src="about:blank"
            style={{ width: '100%', height: '100%', border: 'none' }}
            onLoad={handleIframeLoad}
            title="Dictionary Viewer"
            sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-modals allow-top-navigation allow-popups-to-escape-sandbox"
          />
        </div>
      </div>
    </div>
  );
};

export default DictionaryModal;
