import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Nav from '../Components/Nav';
import Footer from '../Components/Footer';
import Sidebar from '../Components/Sidebar';
import LessonQuiz from '../Components/LessonQuiz';
import LessonQuiz2 from '../Components/LessonQuiz2';

// Basic styling for the lesson content
const styles = {
  contentWrapper: {
    maxWidth: '800px',
    margin: '0 auto',
    backgroundColor: '#fff',
    padding: '30px',
    borderRadius: '8px',
    boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
    lineHeight: '1.6',
    color: '#333'
  },
  chapterHeading: {
    textAlign: 'center',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    margin: '20px 0',
    color: '#1a237e' // Dark blue for chapter heading
  },
  sectionHeading: {
    fontSize: '1.3rem',
    fontWeight: 'bold',
    marginTop: '25px',
    marginBottom: '15px',
    color: '#0d47a1' // Slightly lighter blue for section
  },
  subHeading: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
    marginTop: '20px',
    marginBottom: '10px',
    color: '#333'
  },
  paragraph: {
    fontSize: '1rem',
    marginBottom: '15px',
    textAlign: 'justify'
  },
  arabic: {
    fontFamily: '"Traditional Arabic", serif', // Example Arabic font
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: '#2E7D32', // Green for Arabic
    margin: '0 5px' // Spacing around Arabic terms
  },
  list: {
    paddingLeft: '30px',
    marginBottom: '15px'
  },
  listItem: {
    marginBottom: '8px'
  }
};

const LessonDetail = () => {
  const { lessonId } = useParams();
  const [sidebar, setSidebar] = useState(false);
  const navigate = useNavigate();

  const sidemenufun = () => {
    setSidebar(!sidebar);
  };

  const handleBack = () => {
    navigate('/lessons');
  };

  // Helper to get lesson title (can be moved to a shared utility later)
  const getLessonTitle = (id) => {
    if (id === '1') return 'Introduction to Nahw';
    if (id === '2') return 'اﻟﻜﻠﻤﺔ and اﻟﻜﻼم';
    // Add more titles as needed
    return `Lesson ${id}`; // Fallback
  };
  
  const pageTitle = getLessonTitle(lessonId);

  // Content specific to Lesson 1
  const lesson1Content = (
    <>
      <h3 style={styles.sectionHeading}>
        Section 1.1 <span style={{...styles.arabic, fontSize: '1.5rem'}}> النَّحْو</span> – Arabic Grammar
      </h3>
      
      <h4 style={styles.subHeading}>Definition:</h4>
      <p style={styles.paragraph}>
        Nahw is that science, which teaches us:
      </p>
      <ul style={styles.list}>
        <li style={styles.listItem}>
          a) how to join nouns, verbs and particles to form a complete sentence
        </li>
        <li style={styles.listItem}>
          b) what the i'raab (condition - harkat of the last letter) of every word should be.
        </li>
      </ul>

      <h4 style={styles.subHeading}>Subject Matter:</h4>
      <p style={styles.paragraph}>
        Its subject matter is <span style={styles.arabic}>كَلِمَات</span> (words) and <span style={styles.arabic}>كَلَام</span> (sentences).
      </p>

      <h4 style={styles.subHeading}>Objective:</h4>
      <ul style={styles.list}>
        <li style={styles.listItem}>
          a) The <strong>immediate objective</strong> is to learn how to read, write and speak correct Arabic, and to avoid making mistakes in this.
        </li>
        <li style={styles.listItem}>
          b) The <strong>secondary objective</strong> is to use our Arabic skills to understand the Qur'an, Hadeeth, Fiqh and other Islamic sciences, so that we can practice upon them.
        </li>
        <li style={styles.listItem}>
          c) The <strong>ultimate objective</strong>, through the above, is to gain the pleasure of Allah, the Exalted.
        </li>
      </ul>
      
      <h4 style={{...styles.subHeading, marginTop: '30px'}}>Remember:</h4>
      <p style={styles.paragraph}>
        The fundamental flaw regarding most Arabic methods of teaching is that it ignores the system that Arabic uses to convey meanings – the system that Ibn Khaldun described centuries ago as the most sophisticated system for conveyance of meaning on the planet:
      </p>
      <p style={{...styles.paragraph, fontStyle: 'italic', marginLeft: '20px', borderLeft: '3px solid #eee', paddingLeft: '15px'}}>
        In Arabic the majority of meanings do not come from the words.
      </p>
      <p style={styles.paragraph}>
        If you have a sentence that has 5 words the total number of meanings you will be getting is much more than 5. The 5 words will individually be giving you 5 meanings and on top of that you will be getting an additional 10, 12, 13 meanings.
      </p>
      <p style={styles.paragraph}>
        The vast majority of meanings in Arabic come from vowels, patterns and grammatical structure. Vowels, patterns and grammatical structure is this system that we discuss in Nahw and Sarf.
      </p>
    </>
  );

  // Content specific to Lesson 2
  const lesson2Content = (
    <>
      <h3 style={styles.sectionHeading}>
        <span style={{...styles.arabic, fontSize: '1.5rem'}}>الكَلِمَةُ</span> and <span style={{...styles.arabic, fontSize: '1.5rem'}}>الكَلَامُ</span>
      </h3>

      <p style={styles.paragraph}>
        <span style={{...styles.arabic, fontWeight: 'bold'}}>مُفْرَدٌ</span> or <span style={{...styles.arabic, fontWeight: 'bold'}}>كَلِمَةٌ</span> - Singular: It is that single word, which conveys one meaning.
      </p>
      <p style={styles.paragraph}>
        <span style={{...styles.arabic, fontWeight: 'bold'}}>مُرَكَّبٌ</span> or <span style={{...styles.arabic, fontWeight: 'bold'}}>كَلَامٌ</span> - It is a group of words. They may form a complete sentence or an incomplete one.
      </p>

      <h4 style={{...styles.subHeading, marginTop: '30px'}}>There are three types of <span style={styles.arabic}>كَلِمَة</span>:</h4>
      <p style={styles.paragraph}>
        <span style={styles.arabic}>اِسْمٌ</span> (noun), <span style={styles.arabic}>فِعْلٌ</span> (verb) and <span style={styles.arabic}>حَرْفٌ</span> (particle).
      </p>

      <h4 style={styles.subHeading}>1) <span style={styles.arabic}>اِسْمٌ</span> (noun or naming word)</h4>
      <ul style={styles.list}>
        <li style={styles.listItem}>a) It is that word which refers to the name of a person, place or thing.</li>
        <li style={styles.listItem}>b) Its meaning can be understood without joining it with another word.</li>
        <li style={styles.listItem}>c) It does not have any tense i.e. past present or future.</li>
        <li style={styles.listItem}>eg: <span style={styles.arabic}>مُحَمَّدٌ</span> (Muhammad), <span style={styles.arabic}>رَجُلٌ</span> (man), <span style={styles.arabic}>ضَرْبٌ</span> (to hit), <span style={styles.arabic}>طَيِّبٌ</span> (good), <span style={styles.arabic}>هُوَ</span> (he), <span style={styles.arabic}>أَنَا</span> (I).</li>
        <li style={styles.listItem}>d) An <span style={styles.arabic}>اِسْمٌ</span> can never have a tanween and an <span style={styles.arabic}>ال</span> at the same time.</li>
      </ul>

      <h4 style={styles.subHeading}>2) <span style={styles.arabic}>فِعْلٌ</span> (verb or doing word)</h4>
      <ul style={styles.list}>
        <li style={styles.listItem}>a) It is that word which refers to an action or occurrence, viz. it is a doing word.</li>
        <li style={styles.listItem}>b) Its meaning can be understood without joining it with another word.</li>
        <li style={styles.listItem}>c) It has one of the three tenses: past, present, or future.</li>
        <li style={styles.listItem}>Eg: <span style={styles.arabic}>كَتَبَ</span> (wrote), <span style={styles.arabic}>يَكْتُبُ</span> (write/will write), and <span style={styles.arabic}>اُكْتُبْ</span> (Write!).</li>
        <li style={styles.listItem}>d) A <span style={styles.arabic}>فِعْلٌ</span> can never have a tanween and it can never have an <span style={styles.arabic}>ال</span>.</li>
      </ul>

      <h4 style={styles.subHeading}>3) <span style={styles.arabic}>حَرْفٌ</span> (particle or joining word)</h4>
      <ul style={styles.list}>
        <li style={styles.listItem}>a) It is that word which indicates to a meaning in an ism or fi'l.</li>
        <li style={styles.listItem}>b) Its meaning cannot be understood without joining an ism or fi'l or both to it.</li>
        <li style={styles.listItem}>c) It does not have any tense i.e. past present or future.</li>
        <li style={styles.listItem}>eg: <span style={styles.arabic}>هَلْ</span> (question article), <span style={styles.arabic}>لَمْ</span> (did not), <span style={styles.arabic}>مِنْ</span> (from), <span style={styles.arabic}>عَلَى</span> (on), <span style={styles.arabic}>فِي</span> (in), <span style={styles.arabic}>إِلَى</span> (till)</li>
        <li style={styles.listItem}>eg: <span style={styles.arabic}>ذَهَبَ الرَّجُلُ إِلَى المَسْجِدِ</span> (The man went to the musjid).</li> 
      </ul>

      {/* Render Quiz 1 */}
      <LessonQuiz />
      
      {/* Render Quiz 2 */}
      <LessonQuiz2 />
    </>
  );

  // Placeholder for other lessons
  const placeholderContent = (
    <p style={{ fontSize: '1.1rem', color: '#555', textAlign: 'center' }}>
      Content for Lesson {lessonId} will be added here soon.
    </p>
  );

  // Style for the back button
  const backButtonStyle = {
    display: 'inline-block',
    marginBottom: '20px',
    padding: '8px 15px',
    backgroundColor: '#6c757d', // A neutral grey color
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '0.9rem',
    textDecoration: 'none',
    transition: 'background-color 0.2s'
  };

  const backButtonHoverStyle = {
    backgroundColor: '#5a6268' // Darker grey on hover
  };
  
  const [isBackButtonHovered, setIsBackButtonHovered] = useState(false);
  const [isComplete, setIsComplete] = useState(false); // State for completion status
  const [isCompleteButtonHovered, setIsCompleteButtonHovered] = useState(false);

  // Key for localStorage
  const completionStorageKey = 'lessonCompletionStatus';

  // Check completion status on mount
  useEffect(() => {
    try {
      const completedLessons = JSON.parse(localStorage.getItem(completionStorageKey) || '{}');
      if (completedLessons[lessonId]) {
        setIsComplete(true);
      }
    } catch (error) {
      console.error("Error reading completion status:", error);
    }
  }, [lessonId]);

  // Function to mark lesson as complete
  const handleMarkComplete = () => {
    try {
      const completedLessons = JSON.parse(localStorage.getItem(completionStorageKey) || '{}');
      completedLessons[lessonId] = true;
      localStorage.setItem(completionStorageKey, JSON.stringify(completedLessons));
      setIsComplete(true);
      // Optional: Show feedback to the user
      // alert(`Lesson ${lessonId} marked as complete!`);
    } catch (error) {
      console.error("Error saving completion status:", error);
    }
  };

  // Style for the completion button
  const completeButtonStyle = {
    display: 'inline-block',
    marginLeft: '15px', // Space it from the back button
    padding: '8px 15px',
    backgroundColor: isComplete ? '#28a745' : '#007bff', // Green if complete, blue otherwise
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: isComplete ? 'default' : 'pointer',
    fontSize: '0.9rem',
    textDecoration: 'none',
    transition: 'background-color 0.2s',
    opacity: isComplete ? 0.7 : 1
  };

  const completeButtonHoverStyle = {
    backgroundColor: isComplete ? '#28a745' : '#0056b3' // Darker blue on hover if not complete
  };

  return (
    <>
      <Nav headervalue={"nav2"} sidemenufun={sidemenufun} />
      <Sidebar sidebar={sidebar} sidebaracc={"Mobile-Sidebar"} sidemenufun={sidemenufun} />
      <div className="lesson-detail-container" style={{ padding: '20px', paddingTop: '20px', minHeight: 'calc(100vh - 120px)', background: '#f9f9f9' }}> 
        {/* --- Top Buttons Section (Only Back Button) --- */}
        <div style={{ marginBottom: '20px' }}>
          <button 
            style={{ 
              ...backButtonStyle, 
              ...(isBackButtonHovered ? backButtonHoverStyle : {}),
              marginTop: '15px'
            }}
            onClick={handleBack}
            onMouseEnter={() => setIsBackButtonHovered(true)}
            onMouseLeave={() => setIsBackButtonHovered(false)}
          >
            &larr; Back to Lessons
          </button>

          {/* --- Mark Complete Button Removed from here --- */}
        </div>
        
        <h1 style={{ textAlign: 'center', marginBottom: '30px', color: '#2c3e50', marginTop: '0' }}>
          Lesson {lessonId}: {pageTitle}
        </h1>
        
        {/* --- Main Content Wrapper --- */}
        <div style={styles.contentWrapper}>
          {lessonId === '1' ? lesson1Content : 
           lessonId === '2' ? lesson2Content : 
           placeholderContent}
        </div>

        {/* --- Mark Complete Button Moved Here --- */}
        <div style={{ marginTop: '30px', textAlign: 'center' }}> {/* Center the button */}
          <button
            style={{ 
              ...completeButtonStyle,
              marginLeft: '0', // Remove left margin as it's centered
              ...(isCompleteButtonHovered && !isComplete ? completeButtonHoverStyle : {})
            }}
            onClick={!isComplete ? handleMarkComplete : null}
            disabled={isComplete}
            onMouseEnter={() => setIsCompleteButtonHovered(true)}
            onMouseLeave={() => setIsCompleteButtonHovered(false)}
          >
            {isComplete ? '✓ Completed' : 'Mark Complete'}
          </button>
        </div>

      </div>
      <Footer />
    </>
  );
};

export default LessonDetail; 