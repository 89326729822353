import React, { useState } from 'react';
import AdminSidebar from '../../Components/AdminSidebar';
import TransactionsComponent from '../../Components/TransactionsComponent';
import Nav from '../../Components/Nav'

function Transactions() {
    // sidebar start
    const [sidebar, setsidebar] = useState(false);
    const sidemenufun = () => {
        setsidebar(!sidebar);
    }
    // sidebar end
    const UserManagement = {
        h4: {
            fontSize: "24px",
            fontWeight: "600",
            margin: "0px"
        },
        padding: {
            padding: "40px",
            width: "80%"
        }
    }
    return (
        <>
            <Nav headervalue={"nav3"} sidemenufun={sidemenufun} />
            <div className='main-section'>
                <AdminSidebar sidebar={sidebar} />
                <div className='user-reponsive' style={UserManagement.padding}>
                    <h4 style={UserManagement.h4}>Transactions</h4>
                    <TransactionsComponent />
                </div>
            </div>
        </>
    );
}

export default Transactions;
