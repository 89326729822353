import React, { useState, useEffect } from 'react';
import Nav from '../Components/Nav';
import { Line, Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import styled from 'styled-components';
import API_BASE_URL from '../apiConfig';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const UserStatsContainer = styled.div`
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const Header = styled.h1`
  color: #333;
  margin-bottom: 2rem;
  text-align: center;
  font-size: 2.2rem;
  font-weight: 700;
  position: relative;
  padding-bottom: 0.5rem;
  
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 3px;
    background: #4CAF50;
    border-radius: 2px;
  }
`;

const StatsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
`;

const StatCard = styled.div`
  background: white;
  padding: 1.8rem;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.08);
  text-align: center;
  border: 1px solid #f0f0f0;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 16px rgba(0,0,0,0.1);
  }

  h3 {
    margin: 0;
    color: #666;
    font-size: 1rem;
    font-weight: 500;
  }

  p {
    margin: 0.8rem 0 0;
    font-size: 2rem;
    font-weight: bold;
    color: #333;
  }
`;

const ChartContainer = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.08);
  margin-top: 2rem;
  border: 1px solid #f0f0f0;
`;

const TimeRangeSelector = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
  gap: 0.8rem;
`;

const TimeButton = styled.button`
  padding: 0.6rem 1.2rem;
  border: 1px solid #ddd;
  background: ${props => props.active ? '#4CAF50' : 'white'};
  color: ${props => props.active ? 'white' : '#333'};
  border-radius: 8px;
  cursor: pointer;
  font-weight: ${props => props.active ? 'bold' : 'normal'};
  transition: all 0.2s ease;
  box-shadow: ${props => props.active ? '0 2px 5px rgba(0,0,0,0.2)' : '0 1px 3px rgba(0,0,0,0.1)'};
  font-size: 0.95rem;
  
  &:hover {
    background: ${props => props.active ? '#45a049' : '#f8f8f8'};
    transform: translateY(-1px);
    box-shadow: 0 3px 7px rgba(0,0,0,0.15);
  }
  
  &:active {
    transform: translateY(0);
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  }
`;

const ChartTypeSelector = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
  gap: 0.8rem;
`;

const NoDataMessage = styled.div`
  text-align: center;
  padding: 2rem;
  color: #666;
  font-style: italic;
`;

const SubscriptionStatsContainer = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.08);
  margin-top: 2rem;
  border: 1px solid #f0f0f0;
`;

const SubsTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
  
  th, td {
    padding: 0.8rem;
    text-align: left;
    border-bottom: 1px solid #eee;
  }
  
  th {
    background-color: #f8f8f8;
    font-weight: 600;
    color: #444;
  }
  
  tr:hover {
    background-color: #f9f9f9;
  }
`;

const UserStats = () => {
  const [userData, setUserData] = useState({ totalUsers: 0, newUsersToday: 0, dailyStats: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [timeRange, setTimeRange] = useState('all'); // 'all', 'week', 'month'
  const [chartType, setChartType] = useState('bar'); // 'bar', 'line'

  useEffect(() => {
    // No login check needed since we're using a public endpoint

    // Create a flag to track component mount state
    let isMounted = true;
    
    const fetchUserStats = async () => {
      try {
        // Add a small delay to prevent race conditions with authentication
        await new Promise(resolve => setTimeout(resolve, 100));
        
        const response = await fetch(`${API_BASE_URL}/user-stats`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });
        
        if (!response.ok) {
          throw new Error('Failed to fetch user stats');
        }

        const data = await response.json();
        
        // Only update state if component is still mounted
        if (isMounted) {
          setUserData(data);
          setError(null);
        }
      } catch (err) {
        if (isMounted) {
          setError(err.message);
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    fetchUserStats();
    
    // Cleanup function to prevent state updates after unmount
    return () => {
      isMounted = false;
    };
  }, []);

  // Filter data based on selected time range
  const getFilteredData = () => {
    if (!userData.dailyStats || userData.dailyStats.length === 0) {
      return [];
    }

    const today = new Date();
    const stats = [...userData.dailyStats];
    
    // Sort by date (oldest to newest)
    stats.sort((a, b) => new Date(a.date) - new Date(b.date));
    
    if (timeRange === 'week') {
      const oneWeekAgo = new Date();
      oneWeekAgo.setDate(today.getDate() - 7);
      return stats.filter(stat => new Date(stat.date) >= oneWeekAgo);
    } else if (timeRange === 'month') {
      const oneMonthAgo = new Date();
      oneMonthAgo.setMonth(today.getMonth() - 1);
      return stats.filter(stat => new Date(stat.date) >= oneMonthAgo);
    }
    
    return stats;
  };

  // Format date labels based on time range
  const formatDateLabel = (dateStr) => {
    const date = new Date(dateStr);
    if (timeRange === 'week') {
      return date.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric' });
    } else if (timeRange === 'month') {
      return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
    }
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
  };

  const filteredData = getFilteredData();
  
  // Prepare chart data
  const chartData = {
    labels: filteredData.map(stat => formatDateLabel(stat.date)),
    datasets: [
      {
        label: 'New Users',
        data: filteredData.map(stat => stat.newUsers),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgb(75, 192, 192)',
        borderWidth: 1,
      },
      {
        label: 'Cumulative Users',
        data: filteredData.map(stat => stat.cumulativeUsers),
        backgroundColor: 'rgba(153, 102, 255, 0.6)',
        borderColor: 'rgb(153, 102, 255)',
        borderWidth: 1,
        // Only show cumulative users in line chart
        hidden: chartType === 'bar',
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          usePointStyle: true,
          padding: 20,
          font: {
            size: 12
          }
        }
      },
      title: {
        display: true,
        text: `User Growth ${timeRange === 'week' ? '(Last 7 Days)' : timeRange === 'month' ? '(Last 30 Days)' : '(All Time)'}`,
        font: {
          size: 18,
          weight: 'bold'
        },
        padding: {
          top: 10,
          bottom: 20
        }
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            const label = context.dataset.label || '';
            const value = context.parsed.y;
            const date = filteredData[context.dataIndex]?.date;
            return `${label}: ${value} ${label === 'New Users' ? `on ${date}` : ''}`;
          }
        },
        backgroundColor: 'rgba(0,0,0,0.8)',
        padding: 12,
        titleFont: {
          size: 14
        },
        bodyFont: {
          size: 13
        }
      },
      datalabels: {
        display: chartType === 'bar',
        color: '#333',
        anchor: 'end',
        align: 'top',
        offset: 0,
        font: {
          weight: 'bold',
          size: 12
        },
        formatter: (value) => value > 0 ? value : ''
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Number of Users',
          font: {
            size: 14,
            weight: 'bold'
          },
          padding: {top: 0, bottom: 10}
        },
        ticks: {
          precision: 0,
          font: {
            size: 12
          },
          padding: 8
        },
        grid: {
          color: 'rgba(0,0,0,0.05)'
        }
      },
      x: {
        title: {
          display: true,
          text: 'Date',
          font: {
            size: 14,
            weight: 'bold'
          },
          padding: {top: 10, bottom: 0}
        },
        ticks: {
          font: {
            size: 11
          },
          maxRotation: 45,
          minRotation: 45
        },
        grid: {
          color: 'rgba(0,0,0,0.05)'
        }
      }
    },
  };

  if (isLoading) {
    return (
      <UserStatsContainer>
        <Header>Loading...</Header>
      </UserStatsContainer>
    );
  }

  return (
    <>
      <Nav />
      <UserStatsContainer>
        <Header>User Statistics</Header>
        {error && <div style={{ color: 'red', textAlign: 'center', marginBottom: '1rem' }}>{error}</div>}
        <StatsContainer>
          <StatCard>
            <h3>Total Users</h3>
            <p>{userData.totalUsers}</p>
          </StatCard>
          <StatCard>
            <h3>New Users Today</h3>
            <p>{userData.newUsersToday}</p>
          </StatCard>
          {userData.subscriptionStats && (
            <StatCard>
              <h3>Active Subscriptions</h3>
              <p>{userData.subscriptionStats.activeSubscriptions || 0}</p>
            </StatCard>
          )}
        </StatsContainer>

        {userData.newUsersToday > 0 && (
          <div style={{ 
            textAlign: 'center', 
            margin: '1.5rem 0', 
            color: '#4CAF50',
            padding: '0.8rem',
            background: 'rgba(76, 175, 80, 0.1)',
            borderRadius: '8px',
            fontWeight: 'bold',
            fontSize: '1.1rem',
            boxShadow: '0 2px 8px rgba(76, 175, 80, 0.15)'
          }}>
            🎉 {userData.newUsersToday} new user{userData.newUsersToday > 1 ? 's' : ''} joined today!
          </div>
        )}

        <ChartContainer>
          <TimeRangeSelector>
            <TimeButton 
              active={timeRange === 'all'} 
              onClick={() => setTimeRange('all')}
            >
              All Time
            </TimeButton>
            <TimeButton 
              active={timeRange === 'month'} 
              onClick={() => setTimeRange('month')}
            >
              Last 30 Days
            </TimeButton>
            <TimeButton 
              active={timeRange === 'week'} 
              onClick={() => setTimeRange('week')}
            >
              Last 7 Days
            </TimeButton>
          </TimeRangeSelector>
          
          <ChartTypeSelector>
            <TimeButton 
              active={chartType === 'bar'} 
              onClick={() => setChartType('bar')}
            >
              Bar Chart
            </TimeButton>
            <TimeButton 
              active={chartType === 'line'} 
              onClick={() => setChartType('line')}
            >
              Line Chart
            </TimeButton>
          </ChartTypeSelector>

          {filteredData.length === 0 ? (
            <NoDataMessage>No data available for the selected time period</NoDataMessage>
          ) : (
            <div style={{ height: '400px' }}>
              {chartType === 'bar' ? (
                <Bar data={chartData} options={chartOptions} />
              ) : (
                <Line data={chartData} options={chartOptions} />
              )}
            </div>
          )}
        </ChartContainer>
        
        {userData.subscriptionStats && userData.subscriptionStats.subscriptionsByDate && (
          <SubscriptionStatsContainer>
            <h3 style={{ marginBottom: '1rem', fontSize: '1.5rem' }}>Subscription Statistics</h3>
            
            <SubsTable>
              <thead>
                <tr>
                  <th>Start Date</th>
                  <th>Active Subscriptions</th>
                </tr>
              </thead>
              <tbody>
                {userData.subscriptionStats.subscriptionsByDate.length > 0 ? (
                  userData.subscriptionStats.subscriptionsByDate.map((sub, index) => (
                    <tr key={index}>
                      <td>{new Date(sub.date).toLocaleDateString('en-US', { 
                        year: 'numeric', 
                        month: 'long', 
                        day: 'numeric' 
                      })}</td>
                      <td>{sub.count}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={2} style={{ textAlign: 'center' }}>No subscription data available</td>
                  </tr>
                )}
              </tbody>
              <tfoot>
                <tr style={{ fontWeight: 'bold', backgroundColor: '#f0f8f0' }}>
                  <td>Total Active Subscriptions</td>
                  <td>{userData.subscriptionStats.activeSubscriptions || 0}</td>
                </tr>
              </tfoot>
            </SubsTable>
          </SubscriptionStatsContainer>
        )}
      </UserStatsContainer>
    </>
  );
};

export default UserStats;
