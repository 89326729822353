// actions.js

import { SET_CUSTOM_AMOUNT, SET_SELECTED_AMOUNT, SET_PAYMENT_FREQUENCY } from "../types/type";

export const setSelectedAmount = (amount) => ({
  type: SET_SELECTED_AMOUNT,
  payload: amount,
});

export const setCustomAmount = (amount) => ({
  type: SET_CUSTOM_AMOUNT,
  payload: amount,
});

export const setPaymentFrequency = (isMonthly) => ({
  type: SET_PAYMENT_FREQUENCY,
  payload: isMonthly,
});

export const saveSelectedAmount = (amount) => {
  return (dispatch) => {
    dispatch(setCustomAmount(""));
    dispatch(setSelectedAmount(amount));
    dispatch(setPaymentFrequency(true)); // Monthly payment when selecting predefined amount
  };
};

export const saveCustomAmount = (amount) => {
  return (dispatch) => {
    dispatch(setSelectedAmount(null));
    dispatch(setCustomAmount(amount));
    dispatch(setPaymentFrequency(false)); // One-time payment when entering custom amount
  };
};
