import React, { useEffect, useState, useRef } from 'react';
import Notifications from '../Components/Notifications';
import { ImagePath } from '../Constant/ImgPath';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faChartLine } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import API_BASE_URL from '../apiConfig';
import { useNavigate } from 'react-router-dom';
import FirstTimeLoginModal from '../Pages/FirstTimeLoginModal';

const Sidebar = ({ sidebar, sidebaracc, sidemenufun }) => {
    const [popupUpload, setPopupUpload] = useState(false);
    const [isVisib, setIsVisib] = useState(false);
    const [isVisib1, setIsVisib1] = useState(false);
    const [isVisib2, setIsVisib2] = useState(false);
    const [userStats, setUserStats] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const navigate = useNavigate();

    //Get user profile data
    const userProfileData = useSelector((state) => state.userProfileReducer.user_profile_data);

    useEffect(() => {
        getStatsAPI();
        
        // Add event listener for word status changes
        const handleWordStatusChange = () => {
            getStatsAPI();
        };
        
        window.addEventListener('wordStatusChanged', handleWordStatusChange);
        
        // Cleanup
        return () => {
            window.removeEventListener('wordStatusChanged', handleWordStatusChange);
        };
    }, []);

    const handleLogout = () => {
        try {
            // First clear all storage
            localStorage.clear();
            // Finally navigate - with a slight delay to ensure cleanup is complete
            setTimeout(() => {
                navigate('/');
            }, 100);
        } catch (error) {
            console.error('Logout error:', error);
            // Fallback navigation if there's an error
            navigate('/');
        }
    };

    const handleClick = () => {
        setIsVisib(!isVisib)
    };
    const setIsVisible = () => {
        setIsVisib(!isVisib)
    }
    const handleClick1 = () => {
        setIsVisib1(!isVisib1)
    };
    const setIsVisible1 = () => {
        setIsVisib1(!isVisib1)
    }
    const handleClick2 = () => {
        setIsVisib2(!isVisib2)
    };
    const setIsVisible2 = () => {
        setIsVisib2(!isVisib2)
    }
    const closeProfilePopup = () => {
        setPopupUpload(!popupUpload);
    }
    const toggleModal = () => setModalOpen(prev => !prev);

    const AdminSidebar = {
        sidebar: {
            height: "90vh",
            width: "20%",
            padding: "23px",
            boxShadow: "rgba(45, 80, 43, 0.2) 0px 4px 10px",
            minWidth: "270px",
            position: 'fixed',
            top: "12%",
            right: 0,
            zIndex: "999",
            backgroundColor: "white",
            overflow: "auto",
            transform: 'translateX(100%)',
            transition: 'transform 0.3s ease-in-out',
            borderRadius: '10px',
        },
        TextArea: {
            background: "black",
            borderRadius: "9px",
            color: "white",
            padding: "12px",
            fontWeight: "bold",
            marginTop: "11px",
            borderRadius: "9px",
            cursor: "pointer",
            display: "flex",
            gap: "11px",
            alignItems: "center",
            transition: "background-color 0.3s, color 0.3s"
        },
        TextAreaHover: {
            background: "lightgrey",
            color: "white"
        },
        sidebarIcon: {
            width: "20px",
        },
        NavFireImg5: {
            width: "40px",
            height: "40px"
        },
        dashboardboxes2: {
            // width: "19%",
            height: "58px",
            borderRadius: "37.5px",
            background: "#CCC",
            display: "flex",
            gap: "5px",
            padding: "9px 11px",
            marginTop: "20px"
        },
        dashboardboxes3: {
            // width: "28%",
            height: "58px",
            borderRadius: "37.5px",
            background: "#CCC",
            display: "flex",
            gap: "18px",
            padding: "9px 11px",
            marginTop: "20px"
        },
        dashboardboxeFirst: {
            display: "flex",
            color: "black",
            fontWeight: '600',
            gap: "5px",
            alignItems: "center",
            cursor: "pointer"
        },
        dashboardboxeFirstp: {
            margin: "0"
        },
        NavP: {
            margin: "0"
        },
        NavFireImg: {
            width: "40px",
            height: "40px"
        },
    }
    const getStatsAPI = () => {
        const apiUrl = `${API_BASE_URL}/user_dashboard/get_learning_count`;
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem("access_token"),
        };
        fetch(apiUrl, {
            method: 'GET',
            headers: headers,
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                setUserStats({ learning_count: data.learning_count });
            })
            .catch(error => {
                console.error('Error fetching API:', error);
            });
    }

    return (
        <div>
            {sidebaracc == "AdminSidebar" &&
                <div 
                    className={sidebar ? 'AdminSidebar-responsive showsidebar' : 'AdminSidebar-responsive'} 
                    style={{
                        ...AdminSidebar.sidebar,
                        transform: sidebar ? 'translateX(0)' : 'translateX(100%)',
                    }}
                >
                    <Link to='/admin-dashboard' style={{ textDecoration: "none" }}>
                        <div style={AdminSidebar.TextArea} className='sidebar-TextArea'>
                            <img style={AdminSidebar.sidebarIcon} src={ImagePath.sidebarIcon} /> Dashboard
                        </div>
                    </Link>
                    <Link to='/lessons' style={{ textDecoration: "none" }}>
                        <div style={AdminSidebar.TextArea} className='sidebar-TextArea'>
                            <span style={{ width: "20px", display: "inline-block", textAlign: "center" }}>📚</span>
                            Nahw Fundamentals
                        </div>
                    </Link>
                    <Link to='/user-management' style={{ textDecoration: "none" }}>
                        <div style={AdminSidebar.TextArea} className='sidebar-TextArea'>
                            <img style={AdminSidebar.sidebarIcon} src={ImagePath.sidebarIcon} /> User Management
                        </div>
                    </Link>
                    <Link to='/transactions' style={{ textDecoration: "none" }}>
                        <div style={AdminSidebar.TextArea} className='sidebar-TextArea'>
                            <img style={AdminSidebar.sidebarIcon} src={ImagePath.sidebarIcon} /> Transactions
                        </div>
                    </Link>
                    <Link to='/subscription' style={{ textDecoration: "none" }}>
                        <div style={AdminSidebar.TextArea} className='sidebar-TextArea'>
                            <img style={AdminSidebar.sidebarIcon} src={ImagePath.sidebarIcon} />  Subscription Plans
                        </div>
                    </Link>
                </div>
            }
            {sidebaracc == "Mobile-Sidebar" &&
                <div className={sidebar ? 'AdminSidebar-responsive showsidebar' : 'AdminSidebar-responsive displaynone'} 
                    style={{
                        ...AdminSidebar.sidebar,
                        transform: sidebar ? 'translateX(0)' : 'translateX(100%)',
                    }}
                >
                    <Link to='/dashboard' style={{ textDecoration: "none" }}>
                        <div style={AdminSidebar.TextArea} className='sidebar-TextArea'>
                            <img style={AdminSidebar.sidebarIcon} src={ImagePath.sidebarIcon} /> Dashboard
                        </div>
                    </Link>
                    
                    <Link to='/lessons' style={{ textDecoration: "none" }}>
                        <div style={AdminSidebar.TextArea} className='sidebar-TextArea'>
                            <span style={{ width: "20px", display: "inline-block", textAlign: "center" }}>📚</span>
                            Nahw Fundamentals
                        </div>
                    </Link>

                    <Link to='/library' style={{ textDecoration: "none" }}>
                        <div style={AdminSidebar.TextArea} className='sidebar-TextArea'>
                            <img style={AdminSidebar.sidebarIcon} src={ImagePath.sidebarIcon} /> Islamic Library
                        </div>
                    </Link>

                    {/* <Link to='/arabic-dictionary' style={{ textDecoration: "none" }}>
                        <div style={AdminSidebar.TextArea} className='sidebar-TextArea'>
                            <img style={AdminSidebar.sidebarIcon} src={ImagePath.sidebarIcon} /> AI Dictionary
                        </div>
                    </Link> */}

                    <Link to='/progress' style={{ textDecoration: "none" }}>
                        <div style={AdminSidebar.TextArea} className='sidebar-TextArea'>
                            <img style={AdminSidebar.sidebarIcon} src={ImagePath.sidebarIcon} /> Progress
                        </div>
                    </Link>

                    <Link to='/account' style={{ textDecoration: "none" }}>
                        <div style={AdminSidebar.TextArea} className='sidebar-TextArea'>
                            <img style={AdminSidebar.sidebarIcon} src={ImagePath.sidebarIcon} />  Settings
                        </div>
                    </Link>
                    <Link to='' style={{ textDecoration: "none" }}>
                        <div>
                            <div onClick={handleLogout} style={AdminSidebar.TextArea} className='sidebar-TextArea'>
                                <img style={AdminSidebar.sidebarIcon} src={ImagePath.sidebarIcon} />  Logout
                            </div>
                        </div>
                    </Link>
                    <div style={{ textAlign: 'center', marginTop: '10px' }}>
    Contact: linkquran@protonmail.com
</div>

                </div>
            }
            {isModalOpen && <FirstTimeLoginModal open={isModalOpen} handleClose={toggleModal} />}
        </div>
    );
};

export default Sidebar;
