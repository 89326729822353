import React, { useEffect, useState, useCallback, useRef } from "react";
import Nav from "../Components/Nav";
import Footer from "../Components/Footer";
import SearchBar from "../Components/SearchBar";
import BookCard from "../Components/BookCard";
import CircularProgress from "@mui/material/CircularProgress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faCopy, faFileLines, faBookmark, faTimes } from "@fortawesome/free-solid-svg-icons";
import quranLogo from "../assets/dashboard/quran1.png";
import "../Styles/BookCard.css";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import AdminSidebar from "../Components/Sidebar";
import Tooltip from "@mui/material/Tooltip";
import API_BASE_URL from "../apiConfig";
import FirstTimeLoginModal from "./FirstTimeLoginModal";
import HeroSection from "../Components/HeroSection";
import PaymentInfoModal from "./PaymentInfoModal";
import { getSurahHistory, updateSurahHistory } from "../helpers/storageHelper";

function Dashboard() {
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({});
  const [surahs, setSurahs] = useState([]);
  const [course, setCourse] = useState([]);
  const [parahList, setParahList] = useState([]);
  const [parahListbackup, setParahListbackup] = useState([]);
  const [displayParah, setDisplayParah] = useState(false);
  const [selectedWord, setSelectedWord] = useState({});
  const [paymentModal, setPaymentModal] = useState(false);
  const [surahHistory, setSurahHistory] = useState(getSurahHistory());
  const [sidebar, setsidebar] = useState(false);
  const [isFirstTimeLogin, setIsFirstTimeLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [showSurahList, setShowSurahList] = useState(true);
  const [errorLoading, setErrorLoading] = useState(null);
  const [loginFalse, setLoginFalse] = useState(false);

  // Track all pending API requests
  const [pendingRequests, setPendingRequests] = useState({
    userInfo: false,
    surahs: false,
    courses: false
  });

  // Refs to prevent concurrent fetches and manage abort controller
  const isLoadingData = useRef(false);
  const abortControllerRef = useRef(null);

  const sidemenufun = () => {
    setsidebar(!sidebar);
  };

  // Memoized authentication check function
  const checkAuth = useCallback(async () => {
    try {
      const token = localStorage.getItem("access_token");
      const isLoggedIn = localStorage.getItem("login") === "true";
      
      console.log('Dashboard auth check - isLoggedIn:', isLoggedIn, 'token exists:', !!token);
      
      if (!isLoggedIn || !token) {
        console.log('Authentication failed, redirecting to login');
        navigate("/login");
        return false;
      }
      
      // Clear any fresh login flags
      localStorage.removeItem("freshLogin");
      return true;
      
    } catch (error) {
      console.error('Error during authentication check:', error);
      navigate("/login");
      return false;
    }
  }, [navigate]);

  // --- Consolidated Data Loading Effect ---
  useEffect(() => {
    let isMounted = true; // Flag to prevent state updates if unmounted
    abortControllerRef.current = new AbortController(); // Create new controller for this load attempt
    const signal = abortControllerRef.current.signal;

    const loadDashboardData = async () => {
      // Prevent starting a new load if one is already in progress
      if (isLoadingData.current) {
        console.log("Data load already in progress, skipping.");
        return;
      }
      
      console.log("Starting dashboard data load sequence...");
      setIsLoading(true);
      isLoadingData.current = true; // Mark as loading
      setErrorLoading(null); // Reset error state
      setDataLoaded(false);

      try {
        // Step 1: Authenticate
        const authenticated = await checkAuth();
        if (!authenticated || !isMounted) {
          if (isMounted) setIsLoading(false);
          isLoadingData.current = false;
          return; // Stop if not authenticated or component unmounted
        }
        if (isMounted) setLoginFalse(true);

        // Step 2: Get User Info (Plan Info)
        console.log("Fetching user info...");
        const userInfoUrl = `${API_BASE_URL}/user_dashboard/get_plan_info`;
        const userInfo = await fetchWithRetry(userInfoUrl, {
          method: "GET",
          headers: { "Content-Type": "application/json", Authorization: localStorage.getItem("access_token") },
          signal // Pass the signal here
        });
        if (!isMounted) { isLoadingData.current = false; return; }
        console.log("User info received:", userInfo);
        setUserDetails(userInfo);
        // Cache user data
        try { localStorage.setItem("cached_user_data", JSON.stringify(userInfo)); } catch (e) { console.warn("Could not cache user data:", e); }

        // Step 3: Get Course/Surah Details
        console.log("Fetching course/surah details...");
        const courseDetailUrl = `${API_BASE_URL}/user_dashboard/get_course_detail`;
        const courseData = await fetchWithRetry(courseDetailUrl, {
          method: "POST",
          headers: { "Content-Type": "application/json", Authorization: localStorage.getItem("access_token") },
          body: JSON.stringify({ course_id: "" }),
          signal // Pass the signal here
        });
        if (!isMounted) { isLoadingData.current = false; return; }
        console.log(`Course/Surah details received: ${courseData.length} items`);
        setSurahs(courseData);
        setCourse(courseData); // Update course state if still used elsewhere
        // Remove localStorage caching for courses
        // try { localStorage.setItem('cached_courses', JSON.stringify(courseData)); localStorage.setItem('courses_cache_timestamp', Date.now()); } catch (e) { console.warn("Failed to cache course data:", e); }

        // All data loaded successfully
        if (isMounted) {
             setDataLoaded(true);
             console.log("Dashboard data loaded successfully.");
        }

      } catch (error) {
        if (!isMounted) { isLoadingData.current = false; return; } // Check mount status again before setting error state
        console.error("Error loading dashboard data:", error);
        if (error.name !== 'AbortError') { // Don't set error state if it was just an abort
             setErrorLoading(error.message || "Failed to load data.");
             // Remove loading from cache on error for courses
             // loadDataFromCache();
        }
        if (error.message && error.message.includes("Authentication failed")) {
          navigate("/login");
        }
      } finally {
        if (isMounted) {
            setIsLoading(false); // Ensure loading is set to false in finally block
        }
        isLoadingData.current = false; // Mark loading as finished
      }
    };

    loadDashboardData();

    // Cleanup function
    return () => {
      isMounted = false; // Set flag on unmount
      console.log("Dashboard component unmounting or effect re-running, aborting fetch...");
      if (abortControllerRef.current) {
        abortControllerRef.current.abort(); // Abort ongoing fetch
      }
      isLoadingData.current = false; // Reset loading flag on cleanup
    };

  }, []); // Run only once on mount

  // Helper function to try loading data from cache
  const loadDataFromCache = () => {
      console.log("Attempting to load data from cache...");
      const cachedUserData = localStorage.getItem("cached_user_data");
      // const cachedCourseData = localStorage.getItem("cached_courses");
      let loadedFromCache = false;

      if (cachedUserData) {
          try {
              setUserDetails(JSON.parse(cachedUserData));
              console.log("Loaded user details from cache.");
              loadedFromCache = true;
          } catch (e) { console.error("Failed to parse cached user data", e); }
      }
      // if (cachedCourseData) {
      //     try {
      //         const courses = JSON.parse(cachedCourseData);
      //         setSurahs(courses);
      //         setCourse(courses);
      //         console.log("Loaded course/surah details from cache.");
      //         loadedFromCache = true;
      //     } catch (e) { console.error("Failed to parse cached course data", e); }
      // }
      if (loadedFromCache) setDataLoaded(true); // Mark as loaded if cache was used
  };

  // Mount Effect for payment/first time modal (Keep this as it's unrelated to data loading)
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has("ba_token")) return;
    const hasPaymentParams = params.has("paymentId") || params.has("PAYID") || params.has("token");
    if (hasPaymentParams) {
      setPaymentModal(true);
    } else {
      const hasSeenModal = localStorage.getItem("hasSeenFirstTimeModal");
      if (!hasSeenModal) {
        setIsFirstTimeLogin(true);
        localStorage.setItem("hasSeenFirstTimeModal", "true");
      }
    }
  }, []);

  // Fetch with retry function (keep existing)
  const fetchWithRetry = async (url, options, maxRetries = 3, delay = 1000) => {
    let retries = 0;
    while (retries < maxRetries) {
      try {
        const response = await fetch(url, options);
        
        // Check if we got HTTP error status
        if (!response.ok) {
          const responseText = await response.text();
          
          // Check if response is HTML (Heroku error page) instead of JSON
          if (responseText.includes('<!DOCTYPE html>') || responseText.includes('<html')) {
            console.error(`Server returned HTML error page for ${url}. Status: ${response.status}`);
            throw new Error('Server returned HTML error page');
          }
          
          // Try to parse as JSON error message
          try {
            const errorData = JSON.parse(responseText);
            throw new Error(errorData.error || `HTTP error! Status: ${response.status}`);
          } catch (jsonError) {
            // If not valid JSON, throw with status code
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
        }
        
        // Try to parse the response as JSON
        const data = await response.json();
        return data;
      } catch (error) {
        // Don't retry if the request was aborted
        if (error.name === 'AbortError') {
             console.log("Fetch aborted.");
             throw error; // Re-throw abort error
        }

        retries++;
        console.warn(`Attempt ${retries}/${maxRetries} failed for ${url}:`, error.message);
        
        // If we've used all retries, throw the error
        if (retries >= maxRetries) {
          throw error;
        }
        
        // Wait with exponential backoff before retrying
        const backoffDelay = delay * Math.pow(2, retries - 1);
        console.log(`Retrying in ${backoffDelay}ms...`);
        await new Promise(resolve => setTimeout(resolve, backoffDelay));
      }
    }
  };

  const NavigatePageSurah = (data, id, page, surahList) => {
    navigate(data, { state: { id: id, page: page, surahList } });
  };
  const Dashboard = {
    bg: {
      // background: "rgb(244, 245, 246)",
      padding: "10px 24px", // Further adjust padding to reduce white space
    },
    h4: {
      fontSize: "24px",
      fontWeight: "600",
      margin: "0",
    },
    p: {
      color: "#4B4B4B",
      fontSize: "24px",
      fontWeight: "400",
      margin: "0",
    },
    UpgradePlan: {
      display: "flex",
      justifyContent: "space-between",
      height: "82px",
      borderRadius: "23px",
      boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.40)",
      padding: "20px",
    },
    UpgradePlanIcon: {
      color: "#FFF",
      fontSize: "18px",
      fontWeight: "600",
      display: "flex",
      gap: "12px",
      alignItems: "center",
      cursor: "pointer",
    },
    UpgradePlanButton: {
      width: "190px",
      height: "50px",
      borderRadius: "14px",
      background: "black",
      padding: "11px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "9px",
      color: "#FFF",
      fontSize: "14px",
      fontWeight: "600",
      border: "transparent",
      cursor: "pointer",
      marginTop: "0",
    },
    ayatboxsarea: {
      display: "flex",
      gap: "35px",
      marginTop: "6px",
      flexWrap: "wrap",
    },
    headingList: {
      display: "flex",
      alignItems: "center",
      gap: "13px",
      justifyContent: "space-between",
      margin: "10px 0", // Adjust margin to reduce white space
    },
    search: {
      display: "flex",
      width: "45%",
      gap: "11px",
      margin: "5px 0", // Further adjust margin to reduce white space
    },
    Headingh4: {
      margin: "20px 0px 11px 0px", // Adjust margin to reduce white space
      fontSize: "18px",
    },
    margin: {
      margin: "0",
    },
    recentlyStudiedHeader: {
      fontSize: '20px',
      fontWeight: '600',
      color: '#2C3E50',
      marginBottom: '12px',
      position: 'relative',
      display: 'inline-block'
    },
    recentlyStudiedUnderline: {
      content: '""',
      position: 'absolute',
      bottom: '-4px',
      left: '0',
      width: '100%',
      height: '2px',
      background: '#4CAF50',
      borderRadius: '2px'
    },
    surahHistoryContainer: {
      display: 'flex',
      gap: '12px',
      flexWrap: 'wrap',
      marginTop: '8px',
      marginBottom: '16px'
    },
    surahHistoryItem: {
      padding: '6px 14px',
      backgroundColor: '#f8f9fa',
      borderRadius: '20px',
      color: '#2C3E50',
      fontSize: '14px',
      fontWeight: '500',
      cursor: 'pointer',
      transition: 'all 0.2s ease',
      border: '1px solid #e9ecef',
      textDecoration: 'none',
      display: 'inline-block'
    },
    bookmarksHeader: {
      fontSize: '20px',
      fontWeight: '600',
      color: '#2C3E50',
      marginBottom: '12px',
      position: 'relative',
      display: 'inline-block'
    },
    bookmarksUnderline: {
      content: '""',
      position: 'absolute',
      bottom: '-4px',
      left: '0',
      width: '100%',
      height: '2px',
      background: '#4CAF50',
      borderRadius: '2px'
    },
    bookmarksContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
      marginTop: '8px',
      marginBottom: '16px'
    },
    bookmarksGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
      gap: '12px',
      width: '100%'
    },
    bookmarkItem: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '12px 16px',
      backgroundColor: '#f8f9fa',
      borderRadius: '8px',
      border: '1px solid #e9ecef',
      transition: 'all 0.2s ease',
      minWidth: '250px'
    },
    bookmarkContent: {
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
      cursor: 'pointer',
      flex: 1,
      fontSize: '0.9rem'
    },
    removeBookmarkButton: {
      background: 'none',
      border: 'none',
      color: '#666',
      cursor: 'pointer',
      padding: '4px',
      borderRadius: '4px',
      transition: 'all 0.2s ease',
      '&:hover': {
        color: '#dc3545',
        background: '#ffe5e5'
      }
    },
    showMoreButton: {
      background: 'none',
      border: '1px solid #ddd',
      padding: '8px 16px',
      borderRadius: '8px',
      color: '#338FFF',
      cursor: 'pointer',
      width: 'fit-content',
      alignSelf: 'center',
      marginTop: '12px',
      transition: 'all 0.2s ease',
      '&:hover': {
        backgroundColor: '#f0f7ff',
        borderColor: '#338FFF'
      }
    },
    noBookmarksMessage: {
      color: '#666',
      fontStyle: 'italic',
      textAlign: 'center',
      padding: '20px',
      backgroundColor: '#f8f9fa',
      borderRadius: '8px',
      border: '1px dashed #ccc'
    }
  };

  // Add state for bookmarks
  const [bookmarks, setBookmarks] = useState([]);

  // Add state for expanded bookmarks
  const [expandedBookmarks, setExpandedBookmarks] = useState(false);

  // Add function to fetch bookmarks
  const fetchBookmarks = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/user_dashboard/verse_bookmarks`, {
        headers: {
          'Authorization': localStorage.getItem("access_token"),
        }
      });

      if (response.ok) {
        const data = await response.json();
        setBookmarks(data);
      }
    } catch (error) {
      console.error('Error fetching bookmarks:', error);
    }
  };

  // Add function to remove bookmark
  const removeBookmark = async (bookmarkId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/user_dashboard/verse_bookmarks/${bookmarkId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': localStorage.getItem("access_token"),
        }
      });

      if (response.ok) {
        setBookmarks(bookmarks.filter(b => b.id !== bookmarkId));
      }
    } catch (error) {
      console.error('Error removing bookmark:', error);
    }
  };

  // Add useEffect to fetch bookmarks
  useEffect(() => {
    if (loginFalse) {
      fetchBookmarks();
    }
  }, [loginFalse]);

  const SearchResutfunc = (data) => {
    if (data === "") {
      // Re-fetch data if search is cleared (or use cached data if preferred)
      setIsLoading(true);
      setErrorLoading(null);
      // Trigger the main loading effect again, perhaps by resetting isAuthenticated momentarily
      // Or, simply call a re-fetch function if you create one.
      // For now, let's assume refetching:
       if (isAuthenticated) { // Need to re-check auth
           // Re-initiate the load sequence
            abortControllerRef.current?.abort(); // Abort previous if any
            const loadDashboardData = async () => { /* ... copy loadDashboardData logic ... */ };
            loadDashboardData();
       }
       setParahList(parahListbackup);
    } else {
      const filteredData = surahs?.filter((item) =>
        item.name_en.toLowerCase()?.includes(data?.toLowerCase())
      );
      const filteredData1 = parahList?.filter((item) =>
        item.name.toLowerCase()?.includes(data.toLowerCase())
      );
      setSurahs(filteredData);
      setParahList(filteredData1);
    }
  };

  const handleModalClose = () => {
    setIsFirstTimeLogin(false);
  };

  const handlePaymentModalClose = () => {
    setPaymentModal(false);
    navigate("/dashboard");
  };

  // Render logic based on loading and error states
  const renderContent = () => {
      if (isLoading) {
          return (
              <Box 
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minHeight: '50vh',
                  padding: '2rem 0',
                  color: '#333'
                }}
              >
                <CircularProgress color="warning" size={50} />
                <Typography variant="h6" sx={{ mt: 2, color: '#555' }}>
                  Loading your content...
                </Typography>
              </Box>
          );
      }

      if (errorLoading) {
          return (
              <div style={{
                backgroundColor: '#fff3cd', 
                color: '#856404', 
                padding: '10px 20px', 
                borderRadius: '4px',
                marginBottom: '15px',
                textAlign: 'center',
                position: 'relative',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
              }}>
                <span>Error: {errorLoading}. Please try refreshing.</span>
                <button 
                  onClick={() => window.location.reload()} 
                  style={{
                    marginLeft: '15px',
                    background: '#856404',
                    color: 'white',
                    border: 'none',
                    padding: '3px 10px',
                    borderRadius: '4px',
                    cursor: 'pointer'
                  }}
                >
                  Refresh
                </button>
                 <button 
                        onClick={() => setErrorLoading(null)} // Allow dismissing error
                        style={{
                          position: 'absolute',
                          right: '10px',
                          top: '10px',
                          background: 'none',
                          border: 'none',
                          fontSize: '18px',
                          cursor: 'pointer',
                          color: '#856404'
                        }}
                      >
                        ×
                      </button>
              </div>
          );
      }

      if (!dataLoaded) {
          // Should ideally not happen if loading is false and no error, but as a fallback
          return <Typography>Could not load data.</Typography>;
      }

      // --- Render actual dashboard content --- 
      return (
          <>
              <HeroSection userDetails={userDetails} />
              {/* Bookmarks Section */}
              <div style={Dashboard.headingList}>
                <h4 style={Dashboard.bookmarksHeader}>
                  Bookmarks
                  <div style={Dashboard.bookmarksUnderline}></div>
                </h4>
              </div>
              <div className="bookmarks-container" style={Dashboard.bookmarksContainer}>
                {bookmarks.length === 0 ? (
                  <div style={Dashboard.noBookmarksMessage}>
                    No bookmarks yet. Click the bookmark icon next to any verse to add it here.
                  </div>
                ) : (
                  <>
                    <div style={Dashboard.bookmarksGrid}>
                      {(expandedBookmarks ? bookmarks : bookmarks.slice(0, 8)).map((bookmark, index) => (
                        <div
                          key={index}
                          style={Dashboard.bookmarkItem}
                          onMouseEnter={(e) => {
                            e.currentTarget.style.backgroundColor = '#edf2ff';
                            e.currentTarget.style.borderColor = '#4CAF50';
                            e.currentTarget.style.transform = 'translateY(-2px)';
                            e.currentTarget.style.boxShadow = '0 2px 8px rgba(0,0,0,0.1)';
                          }}
                          onMouseLeave={(e) => {
                            e.currentTarget.style.backgroundColor = '#f8f9fa';
                            e.currentTarget.style.borderColor = '#e9ecef';
                            e.currentTarget.style.transform = 'none';
                            e.currentTarget.style.boxShadow = 'none';
                          }}
                        >
                          <div 
                            className="bookmark-content" 
                            style={Dashboard.bookmarkContent}
                            onClick={() => {
                              NavigatePageSurah(
                                "/page-view",
                                bookmark.surah_id,
                                bookmark.page_number,
                                surahs
                              );
                            }}
                          >
                            <FontAwesomeIcon icon={faBookmark} style={{ color: '#338FFF', fontSize: '0.9em' }} />
                            <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                              Surah {bookmark.surah_id} - {bookmark.surah_name}, Verse {bookmark.verse_key.split(':')[1]}
                            </span>
                          </div>
                          <button
                            onClick={() => removeBookmark(bookmark.id)}
                            style={Dashboard.removeBookmarkButton}
                            title="Remove bookmark"
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </button>
                        </div>
                      ))}
                    </div>
                    {bookmarks.length > 8 && (
                      <button 
                        onClick={() => setExpandedBookmarks(!expandedBookmarks)}
                        style={Dashboard.showMoreButton}
                      >
                        {expandedBookmarks ? 'Show Less' : `Show More (${bookmarks.length - 8} more)`}
                      </button>
                    )}
                  </>
                )}
              </div>
              {/* Books Section */}
              <div className="mobile-view-pages mt-3">
                  <div className="search-progressbar d-flex">
                  <div className="search" style={Dashboard.search}>
                      <SearchBar SearchResut={SearchResutfunc} />
                  </div>
                  </div>
                  {!showSurahList ? (
                      <div className="books-section">
                        <div style={Dashboard.headingList}>
                          <h4 style={Dashboard.Headingh4}>
                            Books <FontAwesomeIcon icon={faCaretRight} />
                          </h4>
                        </div>
                        <BookCard
                          title="The Noble Quran"
                          description="Explore and learn from the divine words"
                          icon={quranLogo}
                          stats={[
                            `${surahs.length} Surahs`,
                            '6236 Verses'
                          ]}
                          onClick={() => setShowSurahList(true)}
                        />
                        <BookCard
                          title="Arabic AI Dictionary/Nahw Analyzer"
                          description="Analyze and understand Arabic words with AI assistance"
                          icon={quranLogo}
                          stats={[
                            'Word Analysis',
                            'AI Translation'
                          ]}
                          onClick={() => navigate('/arabic-dictionary')}
                        />
                        <BookCard
                          title="Islamic Library"
                          description="Access to hundreds of Islamic books and resources"
                          icon={quranLogo}
                          stats={[
                            '2500+ Books',
                          ]}
                          onClick={() => navigate('/library')}
                        />
                        <BookCard
                          title="Fundamentals of Arabic"
                          description="Lessons in basics, nahw and sarf inshAllah"
                          icon={quranLogo}
                          stats={[
                            'Coming Soon'
                          ]}
                          disabled={true}
                          comingSoon={true}
                        />
                      </div>
                    ) : (
                      <div className="surah-section">
                        <div style={Dashboard.headingList}>
                          <div className="section-header">
                            {/* <button className="back-button" onClick={() => setShowSurahList(false)}>
                              <FontAwesomeIcon icon={faCaretRight} className="back-arrow" />
                              Back to Books
                            </button> */}
                            <h4 style={Dashboard.Headingh4}>
                            </h4>
                          </div>
                        </div>
                        <div className="ayat-boxs-area" style={Dashboard.ayatboxsarea}>
                          {surahs &&
                            surahs?.map((surah, index) => (
                              <div
                                onClick={() => {
                                  updateSurahHistory(surah);
                                  NavigatePageSurah(
                                    "/page-view",
                                    surah?.id,
                                    surah?.start_page,
                                    surahs
                                  );
                                }}
                                className="ayat"
                                key={index}
                              >
                                <div>
                                  <p className="text-ayat" style={{ 
                                    marginTop: '10px',
                                    marginBottom: '0',
                                    textAlign: 'center',
                                    lineHeight: '1.4',
                                    padding: '2px 0',
                                  }}>{`سُوْرَۃُ ${surah?.name}`}</p>

                                  <p style={{ 
                                    margin: '0',
                                    fontSize: '16px',
                                    fontWeight: '500',
                                    color: '#333',
                                    textAlign: 'center',
                                    lineHeight: '1.2'
                                  }}>{`${surah?.id} ${surah?.name_en}`}</p>
                                  
                                  <p style={{ 
                                    margin: '0 0 4px 0',
                                    fontSize: '14px',
                                    color: '#666',
                                    fontStyle: 'italic',
                                    textAlign: 'center',
                                    lineHeight: '1.2'
                                  }}>{surah?.translation}</p>

                                  {/* Percentage Complete (existing) */}
                                  <div style={{ 
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginBottom: '2px'
                                  }}>
                                    <p style={{ margin: 0, fontSize: '14px', color: '#666', lineHeight: '1.0' }}>
                                      <Tooltip title="Learning Progress" placement="top">
                                        <div style={{
                                          background: "#4CAF50", // Use a progress-related color
                                          width: '10px',
                                          height: '10px',
                                          borderRadius: '50%',
                                          marginRight: '6px',
                                          display: 'inline-block',
                                          verticalAlign: 'middle'
                                        }}></div>
                                      </Tooltip>
                                      {`${surah?.percentage_complete}% Complete`}
                                    </p>
                                  </div>

                                  {/* Progress Bar - Kept */}
                                  <div style={{ padding: '0 15px', marginTop: '10px' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                                      <Box sx={{ 
                                        width: '100%', 
                                        mr: 1, 
                                        height: 12, 
                                        bgcolor: '#e0e0e0',
                                        borderRadius: '6px',
                                        overflow: 'hidden' 
                                      }}>
                                        <Box sx={{ 
                                          height: '100%',
                                          width: `${surah?.percentage_complete || 0}%`, 
                                          bgcolor: '#82c91e',
                                          borderRadius: '6px 0 0 6px',
                                          transition: 'width .3s ease-in-out'
                                        }} />
                                      </Box>
                                      {/* <Typography 
                                        variant="caption" 
                                        sx={{ 
                                          position: 'absolute',
                                          right: '5px',
                                          top: '-2px',
                                          color: '#555',
                                          fontWeight: 'bold',
                                          fontSize: '11px'
                                        }}>{`${Math.round(surah?.percentage_complete || 0)}%`}</Typography> */}
                                    </Box>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
              </div>
          </>
      );
  };

  return (
    <>
      {/* Conditional rendering based on login status */}
      {loginFalse ? (
        <div>
          <Nav headervalue={"nav2"} sidemenufun={() => sidemenufun()} />
          <AdminSidebar sidebar={sidebar} sidebaracc={"Mobile-Sidebar"} />
          <div style={Dashboard.bg}>
            {renderContent()} 
          </div>
          <Footer />
        </div>
      ) : (
         // Show loading spinner *while* initial authentication check is happening
         <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
           <CircularProgress />
         </Box>
      )}
      {/* Modals */} 
      {isFirstTimeLogin && (
        <FirstTimeLoginModal
          open={isFirstTimeLogin}
          handleClose={handleModalClose}
        />
      )}
      {paymentModal && (
        <PaymentInfoModal
          handleClose={handlePaymentModalClose}
          open={paymentModal}
        />
      )}
    </>
  );
}

export default Dashboard;
