import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import API_BASE_URL from '../apiConfig';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Typography,
  Paper,
  Drawer,
  IconButton,
  CircularProgress,
  useTheme,
  useMediaQuery,
  Button
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faBars,
  faArrowLeft
} from '@fortawesome/free-solid-svg-icons';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Nav from '../Components/Nav';
import Footer from '../Components/Footer';
import BookReaderCard from '../Components/LibraryPageComponents/BookReaderCard';
import TableOfContents from '../Components/LibraryPageComponents/TableOfContents';
import { transliterateArabicToEnglish, cleanArabicWord } from '../utils/wordUtils';
import DictionaryModal from '../Components/LibraryPageComponents/DictionaryModal';
import BookPageContent, { wordMatches } from '../Components/LibraryPageComponents/BookPageContent';
import BookNavigationControls from '../Components/LibraryPageComponents/BookNavigationControls';
import Sidebar from '../Components/Sidebar';

const drawerWidth = 280;

// Add CSS for learned word tooltips
const tooltipStyles = `
  .learned-word {
    position: relative;
    display: inline-block;
  }
  .learned-word .word-translation {
    display: block;
    font-size: 0.8rem;
    color: #666;
    margin-top: 2px;
    text-align: center;
    font-family: system-ui, -apple-system, sans-serif;
    white-space: nowrap;
  }
`;

// Add custom styles for subscription limit toast
const subscriptionToastStyles = `
  .subscription-toast {
    border-radius: 8px !important;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15) !important;
    padding: 12px !important;
    min-width: 280px !important;
  }
  .subscription-toast .Toastify__toast-body {
    margin: 0 !important;
    padding: 0 !important;
  }
  .subscription-toast .Toastify__toast-icon {
    margin-right: 12px !important;
  }
  .subscription-toast button:hover {
    background: #1b5e20 !important;
    transform: translateY(-1px) !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25) !important;
  }
`;

// Add a style at the top level to make sure the sidebar shows correctly
const globalStyles = `
.AdminSidebar-responsive.showsidebar {
  display: block !important;
  transform: translateX(0) !important;
}
`;

// Helper function for logout/redirect
const handleUnauthorized = () => {
  console.warn('Unauthorized (401). Logging out and redirecting.');
  localStorage.removeItem("access_token");
  localStorage.removeItem("user_details");
  window.location.href = '/login?sessionExpired=true';
};

function BookReader() {
  // Add tooltip styles when component mounts
  useEffect(() => {
    if (!document.getElementById('learned-word-styles')) {
      const styleElement = document.createElement('style');
      styleElement.id = 'learned-word-styles';
      styleElement.innerHTML = tooltipStyles;
      document.head.appendChild(styleElement);
      
      return () => {
        const styleEl = document.getElementById('learned-word-styles');
        if (styleEl) styleEl.remove();
      };
    }
  }, []);

  // Add subscription toast styles when component mounts
  useEffect(() => {
    if (!document.getElementById('subscription-toast-styles')) {
      const styleElement = document.createElement('style');
      styleElement.id = 'subscription-toast-styles';
      styleElement.innerHTML = subscriptionToastStyles;
      document.head.appendChild(styleElement);
      
      return () => {
        const styleEl = document.getElementById('subscription-toast-styles');
        if (styleEl) styleEl.remove();
      };
    }
  }, []);

  // Add global styles when component mounts
  useEffect(() => {
    if (!document.getElementById('global-layout-styles')) {
      const styleElement = document.createElement('style');
      styleElement.id = 'global-layout-styles';
      styleElement.innerHTML = globalStyles;
      document.head.appendChild(styleElement);
      
      return () => {
        const styleEl = document.getElementById('global-layout-styles');
        if (styleEl) styleEl.remove();
      };
    }
  }, []);

  const { bookId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  
  const [book, setBook] = useState(null);
  const [pageContents, setPageContents] = useState({}); // New state for page contents
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [drawerOpen, setDrawerOpen] = useState(!isMobile);
  const [sidebar, setSidebar] = useState(false);
  const [expandedSections, setExpandedSections] = useState({});
  const [showPdf, setShowPdf] = useState(false);
  const [pageInputValue, setPageInputValue] = useState('1');
  
  // Dictionary lookup state
  const [selectedWord, setSelectedWord] = useState(null);
  const [showDictionary, setShowDictionary] = useState(false);
  const [dictionaryLoading, setDictionaryLoading] = useState(false);
  const [userTranslation, setUserTranslation] = useState('');
  const [userTranslations, setUserTranslations] = useState([]);  // Array to store multiple translations
  const [wordLearningStatus, setWordLearningStatus] = useState(null); // 'learning', 'known', or null
  const [isLearningLoading, setIsLearningLoading] = useState(false);
  const [isKnownLoading, setIsKnownLoading] = useState(false);
  const [userId, setUserId] = useState(null);
  const [wordExistsAsLearned, setWordExistsAsLearned] = useState(false); // Track if word already exists with 'Learned' status
  // State for learning words
  const [learningWords, setLearningWords] = useState([]); // Store user's learning words
  // Get user profile data from Redux store
  const userProfileData = useSelector(state => state.userProfile?.user_profile_data);
  const iframeRef = useRef(null);
  
  // Extract user ID from token (simplified)
  const getUserIdFromToken = useCallback(() => {
      const token = localStorage.getItem('access_token');
      if (token) {
          try {
              const payloadBase64 = token.split('.')[1];
              const payload = JSON.parse(atob(payloadBase64));
              return payload.sub; // 'sub' usually holds the user ID
          } catch (e) {
              console.error('Error decoding token:', e);
              return null;
          }
      }
      return null;
  }, []);
  
  const [extractedUserId, setExtractedUserId] = useState(getUserIdFromToken());
  
  // Get user ID from localStorage or Redux on component mount
  useEffect(() => {
    //console.log('BookReader component mounted, checking for user ID');
    
    // DEBUG: Dump all localStorage keys and values
    //console.log('All localStorage contents:');
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      const value = localStorage.getItem(key);
      //console.log(`${key}: ${value}`);
    }
    
    try {
      // First, check if we have the user ID in Redux state
      if (userProfileData) {
        //console.log('Found user profile data in Redux:', userProfileData);
        
        // Looking at the backend code, specifically logic/learning_logic.py's get_header_data function,
        // the data structure returned from /user_dashboard/header endpoint has user_info.user_id
        if (userProfileData.user_info) {
          //console.log('Found user_info in Redux userProfileData:', userProfileData.user_info);
          
          // The backend returns the user ID in the response which Nav.js puts in Redux
          if (userProfileData.user_info.user_id) {
            setUserId(userProfileData.user_info.user_id);
            //console.log('User ID set successfully from Redux user_info.user_id:', userProfileData.user_info.user_id);
            return;
          }
        }
        
        // Fallback logic - try other common patterns
        const reduxUserId = userProfileData.user_id || 
                           userProfileData.id || 
                           (userProfileData.ID) || 
                           (userProfileData.user && userProfileData.user.id);
        
        if (reduxUserId) {
          setUserId(reduxUserId);
          //console.log('User ID set successfully from Redux fallback:', reduxUserId);
          return;
        } else {
         // console.log('Redux user data does not contain a user ID:', userProfileData);
        }
      } else {
        //console.log('No user profile data in Redux');
      }
      
      // Check if user is logged in
      const isLoggedIn = localStorage.getItem('login') === 'true';
     // console.log('User login status:', isLoggedIn);
      
      if (!isLoggedIn) {
        console.log('User is not logged in');
        return;
      }
      
      // Get user data from localStorage - there are multiple possible ways it could be stored
      const userDataStr = localStorage.getItem('userData');
      
     // console.log('localStorage userData:', userDataStr);
      
      // Try to parse userData
      if (userDataStr) {
        try {
          const userData = JSON.parse(userDataStr);
          //console.log('Parsed userData:', userData);
          
          // Based on login.js and the backend structure, the userData contains user_info
          if (userData.user_info) {
            const userInfoId = userData.user_info.user_id;
            if (userInfoId) {
              setUserId(userInfoId);
            //  console.log('User ID set from userData.user_info.user_id:', userInfoId);
              return;
            }
          }
          
          // Handle nested structure if present (fallback logic)
          let userId = null;
          
          if (typeof userData === 'object') {
            userId = userData.user_id || 
                     userData.id || 
                     (userData.ID) || 
                     (userData.user && userData.user.id);
            
            // console.log('User ID candidates:', {
            //   'userData.user_id': userData.user_id,
            //   'userData.id': userData.id,
            //   'userData.ID': userData.ID,
            //   'userData.user?.id': userData.user?.id
            // });
          }
          
          if (userId) {
            setUserId(userId);
            //console.log('User ID set successfully from userData:', userId);
            return;
          }
        } catch (e) {
         // console.error('Error parsing userData JSON:', e);
        }
      }
      
      // Note: We've consolidated the user ID retrieval to focus on userData structure
      // from the backend API response, which contains user_info.user_id
      
      //console.error('Could not find user ID in any localStorage item or Redux');
    } catch (error) {
      //console.error('Error retrieving user ID:', error);
    }
  }, [userProfileData]); // Added userProfileData to dependency array
  
  // Group headings by their parent sections
  const groupedHeadings = useMemo(() => {
    if (!book || !book.indexes || !book.indexes.headings) return [];
    
    const topLevel = [];
    
    // First pass: identify top-level headings and create structure
    book.indexes.headings?.forEach((heading, index) => {
      heading.id = index; // Add an id for reference
      
      // Use the mapped page number from the backend
      const page = heading.page; // This is now the mapped page number
      
      if (heading.level === 1) {
        topLevel.push({...heading, children: [], page});
      }
    });
    
    // Second pass: assign children to their parents
    let currentParent = null;
    book.indexes.headings?.forEach((heading) => {
      if (heading.level === 1) {
        currentParent = topLevel.find(h => h.id === heading.id);
      } else if (currentParent) {
        // Use the mapped page number for children too
        currentParent.children.push({...heading, page: heading.page});
      }
    });
    
    console.log('Processed TOC with mapped pages:', topLevel);
    return topLevel;
  }, [book]);

  // Effect to log selectedWord changes
  useEffect(() => {
    if (selectedWord) {
      console.log('selectedWord changed:', selectedWord);
    }
  }, [selectedWord]);
  
  // Function to fetch user's learning words
  const fetchLearningWords = useCallback(async () => {
    if (!userId) {
      console.log('fetchLearningWords: No userId available yet, skipping fetch');
      return;
    }
    
    console.log('Fetching learning words with userId:', userId);
    
    try {
      const response = await fetch(`${API_BASE_URL}/word/learning/all?user_id=${userId}`);
      
      // console.log('Learning words API response status:', response.status);
      
      if (!response.ok) {
        console.error(`Failed to fetch learning words: ${response.status} ${response.statusText}`);
        throw new Error(`Failed to fetch learning words: ${response.status}`);
      }
      
      const responseText = await response.text();
      // console.log('Learning words API raw response:', responseText);
      
      let data;
      try {
        data = JSON.parse(responseText);
        console.log('Parsed learning words data:', data);
      } catch (parseError) {
        console.error('Error parsing learning words response:', parseError);
        console.log('Raw response that failed to parse:', responseText);
        return;
      }
      
      // Log each learning word's original words for debugging
      if (data.learning_words && data.learning_words.length > 0) {
        //console.log(`Successfully fetched ${data.learning_words.length} learning words:`);
        data.learning_words.forEach((word, index) => {
          //console.log(`Word ${index + 1}: ${word.word_lemma}, Original:`, word.word_original);
        });
        
        // Set the learning words in state
        setLearningWords(data.learning_words);
        console.log('Learning words state updated with', data.learning_words.length, 'words');
      } else {
        console.log('No learning words found in response or empty array returned');
        setLearningWords([]);
      }
    } catch (error) {
      console.error('Error fetching learning words:', error);
    }
  }, [userId]);

  // Effect to fetch learning words when userId changes
  useEffect(() => {
    if (userId) {
      console.log('userId is available, calling fetchLearningWords');
      fetchLearningWords();
    } else {
      //console.log('userId not available yet, will try to get it');
      
      // Try to extract user ID from various sources if not already set
      let extractedId = null;
      
      // Try from userProfileData in Redux
      if (userProfileData?.user_info?.user_id) {
        extractedId = userProfileData.user_info.user_id;
        //console.log('Extracted user ID from Redux userProfileData.user_info:', extractedId);
      } 
      // Try from localStorage
      else {
        try {
          const userDataStr = localStorage.getItem('userData');
          if (userDataStr) {
            const userData = JSON.parse(userDataStr);
            if (userData?.user_info?.user_id) {
              extractedId = userData.user_info.user_id;
              //console.log('Extracted user ID from localStorage userData:', extractedId);
            }
          }
          
          // Try from JWT token as last resort
          if (!extractedId) {
            const token = localStorage.getItem('access_token');
            if (token) {
              try {
                const payloadBase64 = token.split('.')[1];
                const payload = JSON.parse(atob(payloadBase64));
                if (payload.sub) {
                  extractedId = payload.sub;
                  //console.log('Extracted user ID from JWT token:', extractedId);
                }
              } catch (e) {
                //console.error('Error extracting user ID from JWT:', e);
              }
            }
          }
        } catch (e) {
         // console.error('Error extracting user ID:', e);
        }
      }
      
      // If we found an ID, set it and trigger the fetch
      if (extractedId) {
        console.log('Setting userId state with extracted ID:', extractedId);
        setUserId(extractedId);
        // The fetch will happen in the next render due to the useEffect dependency
      }
    }
  }, [userId, userProfileData, fetchLearningWords]);

  // Add an effect to retry fetching learning words if userId changes
  useEffect(() => {
    // This effect will run when the component mounts and each time currentPage changes
    // It ensures learning words are properly loaded for each page
    if (userId) {
      console.log('Page changed or component mounted with userId available, refreshing learning words');
      fetchLearningWords();
    }
  }, [currentPage, fetchLearningWords, userId]);

  // Effect to fetch learning words when the current page changes
  useEffect(() => {
    // Update page input value when current page changes
    setPageInputValue(currentPage.toString());
    
    // Fetch learning words when page changes
    if (userId) {
      fetchLearningWords();
    }
    
    console.log('Page changed to:', currentPage);
  }, [currentPage, userId, fetchLearningWords]);
  
  // Update the fetchBookData function in BookReader.js
  useEffect(() => {
    const fetchBookData = async () => {
      try {
        setIsLoading(true);
        setError(null);

        // Add a small delay to ensure loading state is properly shown
        await new Promise(resolve => setTimeout(resolve, 300));

        // Fetch book metadata
        const metaResponse = await fetch(`${API_BASE_URL}/api/books/${bookId}/meta`);
        if (!metaResponse.ok) throw new Error(`Failed to fetch book metadata: ${metaResponse.status}`);
        const bookData = await metaResponse.json();
        
        // Fetch navigation data with error handling
        let navigationData = { available_pages: [], first_page: 1 };
        try {
          const navResponse = await fetch(`${API_BASE_URL}/api/books/${bookId}/navigation`);
          if (navResponse.ok) {
            const navData = await navResponse.json();
            
            // Validate navigation data
            if (navData && typeof navData === 'object') {
              navigationData = navData;
              
              // Ensure available_pages is an array
              if (!Array.isArray(navigationData.available_pages)) {
                console.warn('Navigation data has invalid available_pages format, setting to empty array');
                navigationData.available_pages = [];
              }
              
              // Validate first_page
              if (navigationData.first_page === null || navigationData.first_page === undefined) {
                console.warn('Navigation data has invalid first_page, defaulting to 1');
                navigationData.first_page = navigationData.available_pages.length > 0 ? 
                  navigationData.available_pages[0] : 1;
              }
            } else {
              console.warn('Invalid navigation data format received:', navData);
            }
          } else {
            console.warn(`Navigation data fetch failed: ${navResponse.status}. Using default navigation.`);
          }
        } catch (navError) {
          console.error('Error fetching navigation data:', navError);
          // Continue with default navigation data
        }

        // Ensure navigation data has valid values
        navigationData.available_pages = Array.isArray(navigationData.available_pages) ? 
                                         navigationData.available_pages : [];
        navigationData.first_page = navigationData.first_page || 
                                   (navigationData.available_pages.length > 0 ? 
                                    navigationData.available_pages[0] : 1);

        // Fetch TOC data with error handling
        let tocData = { headings: [] };
        try {
          const tocResponse = await fetch(`${API_BASE_URL}/api/books/${bookId}/toc`);
          if (tocResponse.ok) {
            const parsedTocData = await tocResponse.json();
            if (parsedTocData && typeof parsedTocData === 'object') {
              tocData = parsedTocData;
              
              // Ensure headings is an array
              if (!Array.isArray(tocData.headings)) {
                console.warn('TOC data has invalid headings format, setting to empty array');
                tocData.headings = [];
              }
            } else {
              console.warn('Invalid TOC data format received:', parsedTocData);
            }
          } else {
            console.warn(`TOC data fetch failed: ${tocResponse.status}. Using empty TOC.`);
          }
        } catch (tocError) {
          console.error('Error fetching TOC data:', tocError);
          // Continue with empty TOC data
        }

        // Ensure TOC data has valid values
        tocData.headings = Array.isArray(tocData.headings) ? tocData.headings : [];

        // Build safe indexes object with proper error handling
        const safeIndexes = {
          headings: tocData.headings,
          page_map: navigationData.available_pages,
          print_pg_to_pg: (bookData.indexes && typeof bookData.indexes.print_pg_to_pg === 'object') ? 
                          bookData.indexes.print_pg_to_pg : {}
        };

        // Combine all data with safe defaults
        const processedBook = {
          meta: bookData,
          indexes: safeIndexes
        };

        // Set initial page, checking location state for bookmark navigation
        let initialPage = 1;
        try {
          // First check if we have an initialPage from location state (for bookmark navigation)
          if (location.state && location.state.initialPage) {
            console.log(`Using bookmark initialPage: ${location.state.initialPage}`);
            initialPage = parseInt(location.state.initialPage);
          } 
          // Otherwise use the first page from navigation data
          else if (navigationData.first_page !== null && navigationData.first_page !== undefined) {
            initialPage = navigationData.first_page;
          } 
          // Fall back to the first page in available_pages
          else if (navigationData.available_pages && navigationData.available_pages.length > 0) {
            initialPage = navigationData.available_pages[0];
          }
          // Finally, default to page 1 if all else fails
        } catch (pageError) {
          console.error('Error determining first page:', pageError);
          // Default to page 1 if all attempts fail
        }
        
        console.log(`Setting initial page to: ${initialPage}`);
        setCurrentPage(initialPage);
        setPageInputValue(initialPage.toString());

        // Now fetch the first page content before finishing loading
        try {
          const pageResponse = await fetch(`${API_BASE_URL}/api/books/${bookId}/pages/${initialPage}`);
          if (pageResponse.ok) {
            const pageData = await pageResponse.json();
            setPageContents(prev => ({
              ...prev,
              [initialPage]: pageData
            }));
          }
        } catch (pageError) {
          console.error('Error pre-loading first page:', pageError);
          // Continue anyway, since we'll have another chance to load the page
        }

        // Finally, set the book data and finish loading
        setBook(processedBook);
        
      } catch (error) {
        console.error('Error loading book:', error);
        setError(`Failed to load book: ${error.message}`);
      } finally {
        // Add a small delay before finishing loading to ensure smooth UI transition
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      }
    };

    if (bookId) {
      fetchBookData();
    }
  }, [bookId, location.state]);

  // Update the page fetching effect
  useEffect(() => {
    const fetchPageContent = async () => {
      if (!currentPage || !bookId) return;
      
      try {
        setIsLoading(true);
        const response = await fetch(`${API_BASE_URL}/api/books/${bookId}/pages/${currentPage}`);
        if (!response.ok) throw new Error(`Failed to fetch page content: ${response.status}`);
        
        const pageData = await response.json();
        setPageContents(prev => ({
          ...prev,
          [currentPage]: pageData
        }));
      } catch (error) {
        console.error('Error loading page:', error);
        setError(`Failed to load page: ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPageContent();
  }, [bookId, currentPage]);

  // Reference to track slider interaction state
  const sliderInteractionRef = useRef(false);

  const handlePageChange = (event, value) => {
    console.log('⚠️ Page change requested to:', value, 'from current page:', currentPage);
    
    if (value === currentPage) {
      console.log('Already on page', value, '- no navigation needed');
      return;
    }
    
    try {
      // Update the current page
      setCurrentPage(value);
      setPageInputValue(value.toString());
      
      // Scroll to top when page changes
      window.scrollTo(0, 0);
      
      console.log('✅ Navigation complete to page:', value);
    } catch (error) {
      console.error('❌ Error during page navigation:', error);
    }
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const sidemenufun = () => {
    setSidebar(!sidebar);
  };

  // Show loading spinner until both book metadata AND initial page are loaded
  if (isLoading || (!book && !error)) {
    return (
      <>
        <Nav headervalue="nav2" sidemenufun={sidemenufun} />
        <Sidebar sidebar={sidebar} sidebaracc="Mobile-Sidebar" />
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          minHeight: 'calc(100vh - 64px)' 
        }}>
          <CircularProgress />
        </Box>
      </>
    );
  }

  // Only show error or "book not found" message when we're certain book doesn't exist
  if (!book) {
    return (
      <>
        <Nav headervalue="nav2" sidemenufun={sidemenufun} />
        <Sidebar sidebar={sidebar} sidebaracc="Mobile-Sidebar" />
        <Box 
          sx={{ 
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 'calc(100vh - 64px)',
            gap: 2,
            p: 3
          }}
        >
          <Typography variant="h6" sx={{ color: 'error.main', textAlign: 'center' }}>
            {error ? error : 'الكتاب غير موجود'}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
            onClick={() => navigate('/library')}
          >
            {error ? 'Return to Library' : 'العودة إلى المكتبة'}
          </Button>
        </Box>
      </>
    );
  }

  // Toggle section expansion
  const toggleSection = (sectionId) => {
    setExpandedSections(prev => ({
      ...prev,
      [sectionId]: !prev[sectionId]
    }));
  };

  // Handle word selection
  const handleWordSelect = (word) => {
    const cleanedWord = cleanArabicWord(word);
    if (cleanedWord) {
      // Check if this is a learned word
      const learningWordData = learningWords.find(learningWord => 
        wordMatches(learningWord.word_original, word)
      );

      // Reset states
      setUserTranslation(learningWordData ? learningWordData.word_english[0] : '');
      setUserTranslations(learningWordData ? learningWordData.word_english.slice(1) : []);
      setWordLearningStatus(null);
      setWordExistsAsLearned(false);
      
      if (learningWordData) {
        // Use existing data for learned words
        setSelectedWord({
          original: cleanedWord,
          root: learningWordData.word_root,
          lemma: learningWordData.word_lemma,
          translation: learningWordData.word_english[0]
        });
      } else {
        // Set initial state and fetch analysis for new words
        setSelectedWord({
          original: cleanedWord,
          root: null,
          translation: null
        });
        fetchWordAnalysis(cleanedWord);
      }
    }
  };

  // Function to fetch word analysis using OpenAI API
  const fetchWordAnalysis = async (word) => {
    if (!word) return;
    setDictionaryLoading(true);
    try {
      // Assuming /word/analysis might not need auth, but check just in case
      const response = await fetch(`${API_BASE_URL}/word/analyze`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }, // No Auth needed? Adjust if required
        body: JSON.stringify({ text: word })
      });

      // *** Add 401 check (if endpoint becomes authenticated) ***
      // if (response.status === 401) {
      //   handleUnauthorized();
      //   return;
      // }
      
      if (!response.ok) {
        throw new Error('Failed to analyze word');
      }
      const data = await response.json();
      console.log('OpenAI analysis response:', data);
      
      if (data.result) {
        try {
          // The API returns a JSON string, so we need to parse it
          let analysisResult;
          
          if (typeof data.result === 'string') {
            // Parse the JSON string from the API
            analysisResult = JSON.parse(data.result);
            console.log('Parsed JSON string result:', analysisResult);
          } else {
            // If it's already an object (shouldn't happen with current API), use it directly
            analysisResult = data.result;
            console.log('Using object result directly:', analysisResult);
          }
          
          // Log the extracted properties for debugging
          console.log('Extracted properties:');
          console.log('- word_root:', analysisResult.word_root);
          console.log('- word_lemma:', analysisResult.word_lemma);
          console.log('- translation:', analysisResult.translation);
          console.log('- pos:', analysisResult.pos);
          console.log('- diacritized_form:', analysisResult.diacritized_form);
          console.log('Full analysis result:', analysisResult);
          
          // Update the selected word with all the analysis data
          const newSelectedWord = {
            original: word,
            root: analysisResult.word_root,
            lemma: analysisResult.word_lemma,
            diacritized_form: analysisResult.diacritized_form,
            translation: analysisResult.translation,
            pos: analysisResult.pos,
            verb_form: analysisResult.verb_form,
            noun_number: analysisResult.noun_number
          };
          console.log('Setting selectedWord to:', newSelectedWord);
          setSelectedWord(newSelectedWord);
          
          // Pre-populate the user translation field with the OpenAI translation if available
          console.log('Auto-filling translation field with:', analysisResult.translation);
          setUserTranslation(analysisResult.translation || '');
          
          // Log the updated selectedWord state
          console.log('Updated selectedWord state:', {
            original: word,
            root: Array.isArray(analysisResult.word_root) ? analysisResult.word_root.join('') : analysisResult.word_root,
            lemma: analysisResult.word_lemma,
            translation: analysisResult.translation,
            pos: analysisResult.pos
          });
        } catch (parseError) {
          console.error('Error parsing analysis result:', parseError);
          console.log('Raw result data:', data.result);
          
          // If parsing fails, just use the original word
          setSelectedWord({
            original: word,
            root: null
          });
        }
      } else {
        console.log('No analysis result found in API response, using original word');
        setSelectedWord({
          original: word,
          root: null
        });
      }
    } catch (error) {
      if (error.message?.includes('401')) return;
      console.error('Error analyzing word:', error);
      // If there's an error, just use the original word
      setSelectedWord({
        original: word,
        root: null
      });
      setDictionaryLoading(false);
    } finally {
      setDictionaryLoading(false);
    }
  };

  // Function to open Hans Wehr dictionary with a specific word
  const openDictionary = () => {
    try {
      // First show the dictionary and set loading
      setShowDictionary(true);
      setDictionaryLoading(true);

      // Small delay to ensure state is updated
      setTimeout(() => {
        let searchTerm;

        // Use the root word if available, otherwise use the original word
        if (selectedWord) {
          // Use the word_root from OpenAI analysis if available
          const wordToTransliterate = selectedWord.root || selectedWord.original || (typeof selectedWord === 'string' ? selectedWord : '');
          
          // Clean the root by removing dashes and spaces
          const cleanRoot = typeof wordToTransliterate === 'string' 
            ? wordToTransliterate.replace(/-/g, '').replace(/\s+/g, '') 
            : wordToTransliterate;
          
          // Transliterate the root to English
          const transliteratedWord = transliterateArabicToEnglish(cleanRoot);
          
          if (transliteratedWord) {
            searchTerm = transliteratedWord;
            console.log('Using transliterated word for Ejtaal:', { 
              original: selectedWord.original || (typeof selectedWord === 'string' ? selectedWord : ''), 
              root: cleanRoot,
              transliterated: transliteratedWord 
            });
          }
        }

        // Fallback
        if (!searchTerm) {
          searchTerm = 'a';
          console.log('No valid word found, using default search "a"');
        }

        // Set the URL directly on the iframe
        if (iframeRef.current) {
          const url = `https://ejtaal.net/aa/#q=${searchTerm}`;
          console.log('Opening dictionary URL:', url);
          iframeRef.current.src = url;
        }
      }, 100);
      
    } catch (error) {
      console.error('Error opening dictionary:', error);
      setDictionaryLoading(false);
    }
  };
  
  // This function was previously used for the 'Show Original Word' button
  // It's now removed as per user request, but kept commented for reference
  /*
  const openDictionaryWithWord = (word) => {
    try {
      setShowDictionary(true);
      setDictionaryLoading(true);
      
      setTimeout(() => {
        const transliteratedWord = transliterateArabicToEnglish(word);
        if (transliteratedWord && iframeRef.current) {
          const url = `https://ejtaal.net/aa/#q=${transliteratedWord}`;
          console.log('Opening dictionary URL directly:', url);
          iframeRef.current.src = url;
        }
      }, 100);
    } catch (error) {
      console.error('Error opening dictionary directly:', error);
      setDictionaryLoading(false);
    }
  };
  */

  // Function to open KSAA dictionary with the word or lemma
  const openKsaaDictionary = () => {
    try {
      // First show the dictionary and set loading
      setShowDictionary(true);
      setDictionaryLoading(true);

      // Small delay to ensure state is updated
      setTimeout(() => {
        let searchTerm;

        // Use the original word for the search
        if (selectedWord) {
          const wordToSearch = selectedWord.original || (typeof selectedWord === 'string' ? selectedWord : '');
          
          if (wordToSearch) {
            searchTerm = encodeURIComponent(wordToSearch);
            console.log('Using word for KSAA dictionary:', { 
              original: wordToSearch,
              encoded: searchTerm 
            });
          }
        }

        // Fallback
        if (!searchTerm) {
          console.log('No valid word found for KSAA dictionary');
          setDictionaryLoading(false);
          return;
        }

        // Set the URL directly on the iframe
        if (iframeRef.current) {
          const url = `https://dictionary.ksaa.gov.sa/result/${searchTerm}`;
          console.log('Opening KSAA dictionary URL:', url);
          iframeRef.current.src = url;
        }
      }, 100);
      
    } catch (error) {
      console.error('Error opening KSAA dictionary:', error);
      setDictionaryLoading(false);
    }
  };
  
  // Function to mark a word as learning
  const markWordAsLearning = async () => {
    console.log('markWordAsLearning called', { selectedWord, userId });
    if (!selectedWord) {
      console.log('No word selected');
      toast.error('Please select a word first');
      return;
    }
    
    // Check if user is logged in
    const isLoggedIn = localStorage.getItem('login') === 'true';
    if (!isLoggedIn) {
      console.log('User is not logged in');
      toast.error('Please log in to save words');
      return;
    }
    
    // Important: The JWT token already contains the user_id and the backend extracts it
    // We don't need to pass user_id explicitly in the request body
    // After examining PageView.js, we can see it doesn't include user_id in the request
    
    // Just verify we have a valid auth token
    const authToken = localStorage.getItem('access_token');
    if (!authToken) {
      console.error('No auth token available');
      toast.error('Authentication token not found. Please log in again.');
      return;
    }
    
    try {
      setIsLearningLoading(true);
      setWordLearningStatus('learning');
      const wordToSave = selectedWord.lemma || selectedWord.original || (typeof selectedWord === 'string' ? selectedWord : '');
      
      // Get the user ID from JWT token payload
      const token = localStorage.getItem('access_token');
      let extractedUserId = null;
      
      if (token) {
        try {
          // Decode the JWT token to get the user ID
          // JWT tokens have 3 parts separated by dots: header.payload.signature
          const payloadBase64 = token.split('.')[1];
          const payload = JSON.parse(atob(payloadBase64));
          extractedUserId = payload.sub; // 'sub' is where JWT stores the subject (user_id)
          //console.log('Extracted user_id from JWT token:', extractedUserId);
        } catch (e) {
          //console.error('Error extracting user_id from JWT:', e);
        }
      }
      
      // Check if the word already exists (to avoid counting updates toward the limit)
      let existingWordId = null;
      let isUpdatingExistingWord = false;
      
      try {
        const checkResponse = await fetch(`${API_BASE_URL}/word/check?word=${encodeURIComponent(selectedWord.original)}&user_id=${extractedUserId}`, {
          method: 'GET',
          headers: {
            'Authorization': localStorage.getItem('access_token')
          }
        });
        
        if (checkResponse.ok) {
          const checkData = await checkResponse.json();
          console.log('Word check response:', checkData);
          if (checkData.exists) {
            existingWordId = checkData.learning_ai_id;
            isUpdatingExistingWord = true;
            console.log('Word already exists, will update existing record:', existingWordId);
            
            // Populate translations from the database if available
            if (checkData.word_english && Array.isArray(checkData.word_english)) {
              // Skip AI-generated translation (first one) and populate the user translations
              const userAddedTranslations = checkData.word_english.slice(1);
              if (userAddedTranslations.length > 0) {
                console.log('Populating user translations from database:', userAddedTranslations);
                setUserTranslations(userAddedTranslations);
              }
            }
            
            // If it already exists and is in 'Learned' status, set flag for 'Known' button
            if (checkData.current_status === 'Learned') {
              setWordExistsAsLearned(true);
              console.log('Word exists with Learned status, enabling Known button');
            } else {
              setWordExistsAsLearned(false);
              console.log('Word exists but not with Learned status:', checkData.current_status);
            }
          } else {
            setWordExistsAsLearned(false);
            console.log('Word does not exist yet');
          }
        }
      } catch (checkError) {
        if (checkError.message?.includes('401')) return;
        console.error('Error checking if word exists:', checkError);
        setWordExistsAsLearned(false);
        // Continue with creating a new word even if check fails
      }
      
      // The /word/learning endpoint requires user_id in the request body
      // Ensure word_root is a string, not an array
      let rootValue = selectedWord.root?.root_form || selectedWord.root || '';
      console.log('Original root value type:', typeof rootValue, 'Value:', rootValue);
      
      try {
        // If root is an array, join it into a string
        if (Array.isArray(rootValue)) {
          console.log('Root is an array, joining:', rootValue);
          rootValue = rootValue.join('');
        } 
        // Handle object case
        else if (typeof rootValue === 'object' && rootValue !== null) {
          console.log('Root is an object, stringifying:', rootValue);
          rootValue = JSON.stringify(rootValue);
        }
        
        console.log('Final root value:', rootValue);
      } catch (e) {
        console.error('Error processing root value:', e);
        rootValue = ''; // Fallback to empty string if processing fails
      }
      
      // Prepare translations array, prioritizing user translation over OpenAI translation
      let translationsArray = [];
      
      // Use user translation if available, otherwise fall back to OpenAI translation
      if (userTranslation.trim()) {
        translationsArray.push(userTranslation.trim());
      } else if (selectedWord.translation) {
        translationsArray.push(selectedWord.translation);
      }
      
      // Add translations from the userTranslations array
      if (userTranslations.length > 0) {
        translationsArray = [...translationsArray, ...userTranslations];
      }
      
      // Remove duplicates
      translationsArray = [...new Set(translationsArray)];
      
      // Ensure we have at least one translation
      if (translationsArray.length === 0) {
        translationsArray = [''];
      }
      
      const requestBody = {
        user_id: extractedUserId,
        word_lemma: wordToSave,
        word_root: rootValue,
        word_original: [selectedWord.original || wordToSave],
        word_english: translationsArray,
        status: 'Learned',
        learning_ai_id: existingWordId // Include this if updating an existing entry
      };
      console.log('Sending request to mark as learning:', requestBody);
      
      const response = await fetch(`${API_BASE_URL}/word/learning`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('access_token') // Include auth token
        },
        body: JSON.stringify(requestBody)
      });
      
      console.log('Response status:', response.status);
      
      if (response.status === 401) {
        handleUnauthorized();
        return;
      }
      
      if (!response.ok) {
        const responseText = await response.text();
        throw new Error(`Failed to mark word as learning: ${responseText}`);
      }
      
      const data = await response.json();
      console.log('Word marked as learning:', data);
      
      // Update state to indicate the word is now in 'Learned' status
      setWordLearningStatus('learning');
      setWordExistsAsLearned(true);  // Now the word exists with 'Learned' status, enabling the 'Known' button
      
      // If the word already exists in the learningWords array, update it rather than adding a new entry
      const existingIndex = learningWords.findIndex(w => 
        (w.word_original && Array.isArray(w.word_original) && w.word_original.some(orig => 
          orig === selectedWord.original)) || w.word_lemma === wordToSave
      );
      
      if (existingIndex !== -1) {
        // Update existing entry
        setLearningWords(prevWords => {
          const updatedWords = [...prevWords];
          updatedWords[existingIndex] = {
            ...updatedWords[existingIndex],
            word_english: translationsArray,
            status: 'Learned'
          };
          return updatedWords;
        });
        console.log('Updated existing word in learningWords array');
      } else {
        // Add new entry
        const newLearningWord = {
          word_lemma: wordToSave,
          word_root: rootValue,
          word_original: [selectedWord.original || wordToSave],
          word_english: translationsArray,
          status: 'Learned',
          learning_ai_id: data.learning_ai_id || existingWordId
        };
        
        setLearningWords(prevWords => [...prevWords, newLearningWord]);
        console.log('Added new word to learningWords array:', newLearningWord);
      }
      
      // Show success message
      toast.success(`Word "${wordToSave}" added to learning list`);
      
      // Return success for the caller
      return { success: true };
    } catch (error) {
      if (error.message?.includes('401')) return;
      console.error('Error marking word as learning:', error);
      //toast.error('Failed to add word to learning list');
      setWordLearningStatus(null);
      setIsLearningLoading(false);
      
      // Return the error for the caller to handle
      return { 
        error: true, 
        message: error.message,
        status: error.status || 500 
      };
    } finally {
      setIsLearningLoading(false);
    }
  };

  // Function to mark a word as known
  const markWordAsKnown = async () => {
    console.log('markWordAsKnown called', { selectedWord, userId });
    if (!selectedWord) {
      console.log('No word selected');
      toast.error('Please select a word first');
      return;
    }
    
    // Check if user is logged in
    const isLoggedIn = localStorage.getItem('login') === 'true';
    if (!isLoggedIn) {
      console.log('User is not logged in');
      toast.error('Please log in to save words');
      return;
    }
    
    // Get user ID - First check Redux, then localStorage if not already set
    let currentUserId = userId;
    if (!currentUserId) {
      try {
        // First check Redux state (which should be most reliable)
        if (userProfileData) {
          console.log('Found user profile data in Redux in markWordAsKnown:', userProfileData);
          
          // Based on our investigation of the API response structure,
          // the backend expects user_id to match the user_id field from user_info
          
          // Check the correct path first based on the auth and user_dashboard endpoints
          if (userProfileData.user_info && userProfileData.user_info.user_id) {
            currentUserId = userProfileData.user_info.user_id;
            console.log('Found user_id in userProfileData.user_info.user_id:', currentUserId);
            setUserId(currentUserId);
            console.log('User ID set from Redux in known function:', currentUserId);
          } else {
            // Fallback for other possible structures
            currentUserId = userProfileData.user_id || 
                            userProfileData.id || 
                            (userProfileData.user && userProfileData.user.user_id) ||
                            (userProfileData.user && userProfileData.user.id);
            
            console.log('User ID candidates from Redux:', {
              'userProfileData.user_info?.user_id': userProfileData.user_info?.user_id,
              'userProfileData.user_id': userProfileData.user_id,
              'userProfileData.id': userProfileData.id,
              'userProfileData.user?.user_id': userProfileData.user?.user_id,
              'userProfileData.user?.id': userProfileData.user?.id,
            });
            
            if (currentUserId) {
              setUserId(currentUserId);
              console.log('User ID set from Redux fallback in known function:', currentUserId);
            }
          }
        }
        
        // If we couldn't get the ID from Redux, try localStorage
        if (!currentUserId) {
          // Try all possible sources of user data
          const userDataStr = localStorage.getItem('userData');
          console.log('Trying to get user ID from localStorage userData in markWordAsKnown');
          
          if (userDataStr) {
            console.log('Raw userData from localStorage:', userDataStr);
            const userData = JSON.parse(userDataStr);
            console.log('Parsed userData in known function:', userData);
            
            // Check the correct structure based on login.js storage
            if (userData.user_info && userData.user_info.user_id) {
              currentUserId = userData.user_info.user_id;
              console.log('Found user_id in userData.user_info.user_id:', currentUserId);
              setUserId(currentUserId);
              console.log('User ID set from localStorage in known function:', currentUserId);
            } else if (typeof userData === 'object') {
              // Fallback for other possible structures
              currentUserId = userData.user_id || 
                            userData.id || 
                            (userData.ID) || 
                            (userData.user && userData.user.user_id) || 
                            (userData.user && userData.user.id);
              
              console.log('User ID candidates from localStorage userData (fallback):', {
                'userData.user_info?.user_id': userData.user_info?.user_id,
                'userData.user_id': userData.user_id,
                'userData.id': userData.id,
                'userData.ID': userData.ID,
                'userData.user?.user_id': userData.user?.user_id,
                'userData.user?.id': userData.user?.id
              });
              
              if (currentUserId) {
                setUserId(currentUserId);
                console.log('User ID set from localStorage userData fallback:', currentUserId);
              }
            }
          }
        }
      } catch (e) {
        console.error('Error getting user ID in markWordAsKnown:', e);
      }
    }
    
    try {
      setIsKnownLoading(true);
      setWordLearningStatus('known');
      const wordToSave = selectedWord.lemma || selectedWord.original || (typeof selectedWord === 'string' ? selectedWord : '');
      
      // Get the user ID from JWT token payload
      const token = localStorage.getItem('access_token');
      let extractedUserId = null;
      
      if (token) {
        try {
          // Decode the JWT token to get the user ID
          // JWT tokens have 3 parts separated by dots: header.payload.signature
          const payloadBase64 = token.split('.')[1];
          const payload = JSON.parse(atob(payloadBase64));
          extractedUserId = payload.sub; // 'sub' is where JWT stores the subject (user_id)
          //console.log('Extracted user_id from JWT token for Known word:', extractedUserId);
        } catch (e) {
          //console.error('Error extracting user_id from JWT for Known word:', e);
        }
      }
      
      // First, check if this word already exists in the user's list
      let existingWordId = null;
      let isUpdatingExistingWord = false;
      try {
        const checkResponse = await fetch(`${API_BASE_URL}/word/check?word=${encodeURIComponent(selectedWord.original)}&user_id=${extractedUserId}`, {
          method: 'GET',
          headers: {
            'Authorization': localStorage.getItem('access_token')
          }
        });
        
        if (checkResponse.ok) {
          const checkData = await checkResponse.json();
          console.log('Word check response for Known word:', checkData);
          if (checkData.exists) {
            existingWordId = checkData.learning_ai_id;
            isUpdatingExistingWord = true;
            console.log('Word already exists, will update existing record for Known status:', existingWordId);
            
            // Check if it's already in Learned status
            if (checkData.current_status !== 'Learned') {
              console.error('Cannot mark as Known - word must be in Learned status first');
              toast.error('Please mark this word as Learning before marking it as Known');
              setIsKnownLoading(false);
              return; // Exit the function early
            }
          } else {
            console.error('Cannot mark as Known - word does not exist yet');
            toast.error('Please mark this word as Learning before marking it as Known');
            setIsKnownLoading(false);
            return; // Exit the function early
          }
        }
      } catch (checkError) {
        if (checkError.message?.includes('401')) return;
        console.error('Error checking if word exists for Known status:', checkError);
        toast.error('Error checking word status');
        setIsKnownLoading(false);
        return; 
      }
      
      // The /word/learning endpoint requires user_id in the request body
      // Ensure word_root is a string, not an array
      let rootValue = selectedWord.root?.root_form || selectedWord.root || '';
      console.log('Original root value type:', typeof rootValue, 'Value:', rootValue);
      
      try {
        // If root is an array, join it into a string
        if (Array.isArray(rootValue)) {
          console.log('Root is an array, joining:', rootValue);
          rootValue = rootValue.join('');
        } 
        // Handle object case
        else if (typeof rootValue === 'object' && rootValue !== null) {
          console.log('Root is an object, stringifying:', rootValue);
          rootValue = JSON.stringify(rootValue);
        }
        
        console.log('Final root value:', rootValue);
      } catch (e) {
        console.error('Error processing root value:', e);
        rootValue = ''; // Fallback to empty string if processing fails
      }
      
      // Prepare translations array, prioritizing user translation over OpenAI translation
      let translationsArray = [];
      
      // Use user translation if available, otherwise fall back to OpenAI translation
      if (userTranslation.trim()) {
        translationsArray.push(userTranslation.trim());
      } else if (selectedWord.translation) {
        translationsArray.push(selectedWord.translation);
      }
      
      // Add translations from the userTranslations array
      if (userTranslations.length > 0) {
        translationsArray = [...translationsArray, ...userTranslations];
      }
      
      // Remove duplicates
      translationsArray = [...new Set(translationsArray)];
      
      // Ensure we have at least one translation
      if (translationsArray.length === 0) {
        translationsArray = [''];
      }

      const requestBody = {
        user_id: extractedUserId,
        word_lemma: wordToSave,
        word_root: rootValue,
        word_original: [selectedWord.original || wordToSave],
        word_english: translationsArray,
        status: 'Known',
        learning_ai_id: existingWordId // Include this if updating an existing entry
      };
      console.log('Sending request to mark as known:', requestBody);
      
      const response = await fetch(`${API_BASE_URL}/word/learning`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('access_token') // Include auth token
        },
        body: JSON.stringify(requestBody)
      });
      
      console.log('Response status:', response.status);
      
      if (response.status === 401) {
        handleUnauthorized();
        return;
      }
      
      if (!response.ok) {
        const responseText = await response.text();
        throw new Error(`Failed to mark word as known: ${responseText}`);
      }
      
      const data = await response.json();
      console.log('Word marked as known:', data);
      
      // Update UI state
      setWordLearningStatus('known');
      
      // Update the learningWords array to reflect the status change
      setLearningWords(prevWords => {
        return prevWords.map(word => {
          // If this is the word we just updated (matching by lemma)
          if (word.word_lemma === wordToSave) {
            return {
              ...word,
              status: 'Known' // Update the status to Known
            };
          }
          return word;
        });
      });
      
      // Show success message
      toast.success(`Word "${wordToSave}" added to known words`);
    } catch (error) {
      if (error.message?.includes('401')) return;
      console.error('Error marking word as known:', error);
      //toast.error('Failed to add word to known words');
      setWordLearningStatus(null);
    } finally {
      setIsKnownLoading(false);
    }
  };
  
  // Function to reset word learning status (delete the word from the database)
  const resetWordLearningStatus = async () => {
    if (!selectedWord) {
      console.log('No word selected');
      return;
    }
    
    const wordToDelete = selectedWord.original || (typeof selectedWord === 'string' ? selectedWord : '');
    console.log('Attempting to delete word:', wordToDelete);
    
    // Get the user ID from JWT token payload
    const token = localStorage.getItem('access_token');
    let extractedUserId = null;
    
    if (token) {
      try {
        // Decode the JWT token to get the user ID
        const payloadBase64 = token.split('.')[1];
        const payload = JSON.parse(atob(payloadBase64));
        extractedUserId = payload.sub; // 'sub' is where JWT stores the subject (user_id)
        //console.log('Extracted user_id from JWT token for deleting word:', extractedUserId);
      } catch (e) {
        console.error('Error extracting user_id from JWT for deleting word:', e);
      }
    }
    
    if (!extractedUserId) {
      toast.error('Unable to delete word. Authentication issue detected.');
      return;
    }
    
    try {
      // First check if the word exists
      const checkResponse = await fetch(`${API_BASE_URL}/word/check?word=${encodeURIComponent(wordToDelete)}&user_id=${extractedUserId}`, {
        method: 'GET',
        headers: {
          'Authorization': localStorage.getItem('access_token')
        }
      });
      
      if (checkResponse.ok) {
        const checkData = await checkResponse.json();
        
        if (checkData.exists) {
          console.log('Word exists in database, will delete:', checkData);
          
          // Send delete request
          const deleteResponse = await fetch(`${API_BASE_URL}/word/delete`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': localStorage.getItem('access_token')
            },
            body: JSON.stringify({
              user_id: extractedUserId,
              word_original: wordToDelete
            })
          });
          
          if (deleteResponse.ok) {
            console.log('Word deleted successfully');
            toast.success(`Word "${wordToDelete}" removed from your lists`);
            
            // Remove the word from the learningWords array in the UI
            setLearningWords(prevWords => 
              prevWords.filter(word => !word.word_original.includes(wordToDelete))
            );
          } else {
            const errorText = await deleteResponse.text();
            console.error('Failed to delete word:', errorText);
            toast.error('Failed to delete word');
          }
        } else {
          console.log('Word not found in database, nothing to delete');
          toast.info('This word is not in your lists');
        }
      } else {
        console.error('Error checking if word exists for deletion');
        toast.error('Failed to check if word exists');
      }
    } catch (error) {
      console.error('Error in resetWordLearningStatus:', error);
      toast.error('Error deleting word');
    }
    
    // Reset UI state regardless of outcome
    setUserTranslation('');
    setUserTranslations([]); // Clear any added translations
    setWordLearningStatus(null);
    setWordExistsAsLearned(false); // Reset the flag since the word is no longer in the database
  };
  
  // Function to close the dictionary popup
  const closeDictionary = () => {
    setShowDictionary(false);
    if (iframeRef.current) {
      iframeRef.current.src = 'about:blank';
    }
  };
  
  // Handle iframe load event
  const handleIframeLoad = () => {
    setTimeout(() => {
      setDictionaryLoading(false);
    }, 1000);
  };

  return (
    <div className="book-reader-arabic library-arabic-text">
      {/* Add global styles to ensure sidebar works */}
      <style>
        {globalStyles}
      </style>
      {/* Add style to ensure Noto Naskh Arabic is used only for Arabic text in BookReader */}
      <style>
        {`
          .book-reader-arabic [dir="rtl"] * {
            font-family: 'Amiri', 'Noto Naskh Arabic', serif !important;
          }
          .book-reader-arabic .MuiPaper-root [dir="rtl"] * {
            font-family: 'Amiri', 'Noto Naskh Arabic', serif !important;
          }
          .book-reader-arabic * [dir="rtl"],
          .book-reader-arabic * [align="right"],
          .book-reader-arabic * [lang="ar"],
          .book-reader-arabic [dir="rtl"] * {
            font-family: 'Amiri', 'Noto Naskh Arabic', serif !important;
          }
        `}
      </style>
      <Nav headervalue="nav2" sidemenufun={sidemenufun} />
      <Sidebar sidebar={sidebar} sidebaracc="Mobile-Sidebar" />
      
      <Box sx={{ 
        display: 'flex',
        minHeight: 'calc(100vh - 64px)',
        bgcolor: '#f5f5f5'
      }}>
        {/* Table of Contents Drawer */}
        <Drawer
          variant={isMobile ? 'temporary' : 'persistent'}
          anchor="left"
          open={drawerOpen}
          onClose={toggleDrawer}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              mt: '64px',
              height: 'calc(100% - 64px)'
            },
          }}
        >
          <TableOfContents 
            drawerWidth={drawerWidth}
            groupedHeadings={groupedHeadings}
            currentPage={currentPage}
            expandedSections={expandedSections}
            toggleSection={toggleSection}
            setCurrentPage={(page) => handlePageChange(null, page)}
          />
        </Drawer>

        {/* Main Content */}
        <Box sx={{ 
          flexGrow: 1,
          p: { xs: 2, sm: 3 },
          width: { xs: '100%', sm: drawerOpen ? `calc(100% - ${drawerWidth}px)` : '100%' },
          ml: { xs: 0, sm: drawerOpen ? `${drawerWidth}px` : 0 },
          transition: 'all 0.3s ease',
          overflowX: 'hidden' // Prevent horizontal overflow
        }}>
          {/* Minimal Header */}
          <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <IconButton 
                onClick={toggleDrawer}
                aria-label="Toggle table of contents"
                sx={{ color: 'primary.main' }}
              >
                <FontAwesomeIcon icon={faBars} />
              </IconButton>
              
              <Button
                variant="outlined"
                size="small"
                startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
                onClick={() => navigate('/library')}
                sx={{ 
                  borderRadius: '20px',
                  fontFamily: '"Amiri", "Noto Naskh Arabic", serif'
                }}
              >
                المكتبة
              </Button>
            </Box>
            
            <Typography 
              variant="h6" 
              component="h1"
              dir="rtl"
              lang="ar"
              sx={{ 
                textAlign: 'right',
                direction: 'rtl',
                fontWeight: 600,
                fontFamily: '"Amiri", "Noto Naskh Arabic", serif',
                color: 'primary.main',
                wordBreak: 'break-word', // Handle long titles
                maxWidth: '100%',
                overflow: 'hidden'
              }}
            >
              {book.meta.name}
            </Typography>
          </Box>

          {/* Book Content */}
          <Paper 
            elevation={1}
            sx={{ 
              p: { xs: 2, sm: 3, md: 5 },
              mb: 4,
              minHeight: '70vh',
              backgroundColor: '#fff',
              borderRadius: 2,
              direction: 'rtl',
              boxShadow: '0 2px 12px rgba(0,0,0,0.05)',
              '& *': { fontFamily: '"Amiri", "Noto Naskh Arabic", serif !important' },
              overflowX: 'hidden', // Prevent horizontal overflow
              width: '100%',
              boxSizing: 'border-box'
            }}
          >
            <BookPageContent 
              isLoading={isLoading}
              error={error}
              book={book}
              pageContents={pageContents}
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              handleWordSelect={handleWordSelect}
              learningWords={learningWords}
            />
          </Paper>

          <BookNavigationControls 
            currentPage={currentPage}
            pageInputValue={pageInputValue}
            setPageInputValue={setPageInputValue}
            handlePageChange={handlePageChange}
            totalPages={book.meta.total_pages || 100}
            isLoading={isLoading}
            showPdf={showPdf}
            setShowPdf={setShowPdf}
            book={book}
            bookId={bookId}
          />
        </Box>
      </Box>
      
      <Footer />
      
      {/* Replace the Word Selection Dialog with BookReaderCard */}
      {selectedWord && (
        <BookReaderCard
          selectedWord={selectedWord}
          onClose={() => setSelectedWord(null)}
          dictionaryLoading={dictionaryLoading}
          userTranslation={userTranslation}
          setUserTranslation={setUserTranslation}
          userTranslations={userTranslations}
          setUserTranslations={setUserTranslations}
          wordLearningStatus={wordLearningStatus}
          isLearningLoading={isLearningLoading}
          isKnownLoading={isKnownLoading}
          wordExistsAsLearned={wordExistsAsLearned}
          markWordAsLearning={markWordAsLearning}
          markWordAsKnown={markWordAsKnown}
          resetWordLearningStatus={resetWordLearningStatus}
          openKsaaDictionary={openKsaaDictionary}
          openDictionary={openDictionary}
          isWordLearned={learningWords.some(word => wordMatches(word.word_original, selectedWord.original))}
        />
      )}

      {/* Dictionary Modal */}
      <DictionaryModal 
        showDictionary={showDictionary}
        iframeRef={iframeRef}
        dictionaryLoading={dictionaryLoading}
        closeDictionary={closeDictionary}
        handleIframeLoad={handleIframeLoad}
      />
      
      {/* Toast Container for notifications */}
      <ToastContainer />
    </div>
  );
}

export default BookReader;
