import React from "react";

const UserInfo = ({ userName, userEmail }) => {
  return (
    <div className="user-info">
      <p className="info-heading">Username</p>
      <p className="user-detail">{userName}</p>
      <p className="info-heading">Email</p>
      <p className="user-detail">{userEmail}</p>
    </div>
  );
};

export default UserInfo;
