import React, { useState, useRef } from 'react';
import {
  Box,
  TextField,
  IconButton,
  InputAdornment,
  Paper,
  Typography,
  CircularProgress,
  Alert,
  Card,
  CardContent,
  Grid,
  Chip,
  Fade,
  Divider
} from '@mui/material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faTimes,
  faFont,
  faBookOpen,
  faQuoteRight,
  faExchangeAlt,
} from "@fortawesome/free-solid-svg-icons";
import API_BASE_URL from "../../apiConfig";

// Move DictionaryResult outside of DictionaryMode
const DictionaryResult = ({ result, searchTerm, onRecentSearch }) => {
  if (!result) return null;
  
  return (
    <Fade in={!!result}>
      <Card elevation={3} sx={{ mt: 2, borderRadius: '16px' }}>
        {/* Header */}
        <Box sx={{ p: 3, bgcolor: '#f8f8f8', borderBottom: '1px solid #e8e8e8' }}>
          <Typography variant="h5" sx={{ fontWeight: 700, mb: 1, fontFamily: '"Amiri", serif' }}>
            {result.diacritized_form || searchTerm}
          </Typography>
          
          {/* Word Details - Modern Card Design */}
          <Box sx={{ 
            display: 'flex',
            flexWrap: 'wrap',
            gap: 1.5,
            mt: 2,
            mb: 3,
            bgcolor: '#f8f8f8',
            borderRadius: '8px',
            p: 2,
            border: '1px solid #eaeaea'
          }}>
            {result.translation && (
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center',
                mr: 3,
                pr: 3,
                borderRight: '1px solid #e0e0e0',
                height: '100%'
              }}>
                <Typography 
                  variant="h6" 
                  sx={{ 
                    fontWeight: 600, 
                    color: '#333',
                    fontSize: '1.25rem'
                  }}
                >
                  {result.translation}
                </Typography>
              </Box>
            )}
            
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 3 }}>
              {result.word_root && (
                <Box>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                    Root
                  </Typography>
                  <Typography sx={{ 
                    fontFamily: '"Amiri", serif', 
                    fontSize: '1.1rem',
                    fontWeight: 500,
                    color: '#222'
                  }}>
                    {result.word_root}
                  </Typography>
                </Box>
              )}
              
              {result.verb_form && (
                <Box>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                    Form
                  </Typography>
                  <Typography sx={{ fontSize: '1rem', color: '#222' }}>
                    {result.verb_form}
                  </Typography>
                </Box>
              )}
              
              {result.pos && (
                <Box>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                    Type
                  </Typography>
                  <Typography sx={{ 
                    textTransform: 'capitalize', 
                    fontSize: '1rem',
                    color: '#222'
                  }}>
                    {result.pos}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>

        <CardContent>
          {/* Definition */}
          {result.definition && (
            <Box sx={{ mb: 4 }}>
              <Typography variant="h6" sx={{ 
                fontWeight: 600,
                mb: 2,
                display: 'flex',
                alignItems: 'center'
              }}>
                <FontAwesomeIcon icon={faBookOpen} style={{ marginRight: '8px' }} />
                Definition
              </Typography>
              <Paper elevation={0} sx={{ p: 2, bgcolor: '#f5f5f5' }}>
                <Typography>{result.definition}</Typography>
              </Paper>
            </Box>
          )}

          {/* Example */}
          {result.example_sentence && (
            <Box sx={{ mb: 4 }}>
              <Typography variant="h6" sx={{ 
                fontWeight: 600,
                mb: 2,
                display: 'flex',
                alignItems: 'center'
              }}>
                <FontAwesomeIcon icon={faQuoteRight} style={{ marginRight: '8px' }} />
                Example
              </Typography>
              <Paper elevation={0} sx={{ bgcolor: '#f5f5f5', overflow: 'hidden' }}>
                <Box sx={{ p: 2, bgcolor: 'rgba(0,0,0,0.02)' }}>
                  <Typography sx={{ 
                    fontFamily: '"Amiri", serif',
                    fontSize: '1.25rem',
                    direction: 'rtl',
                    textAlign: 'right'
                  }}>
                    {result.example_sentence}
                  </Typography>
                </Box>
                {result.example_translation && (
                  <>
                    <Divider />
                    <Box sx={{ p: 2 }}>
                      <Typography sx={{ fontStyle: 'italic' }}>
                        {result.example_translation}
                      </Typography>
                    </Box>
                  </>
                )}
              </Paper>
            </Box>
          )}

          {/* Related Words */}
          {(result.synonyms?.length > 0 || result.antonyms?.length > 0) && (
            <Box>
              <Typography variant="h6" sx={{ 
                fontWeight: 600,
                mb: 2,
                display: 'flex',
                alignItems: 'center'
              }}>
                <FontAwesomeIcon icon={faExchangeAlt} style={{ marginRight: '8px' }} />
                Related Words
              </Typography>
              <Paper elevation={0} sx={{ p: 2, bgcolor: '#f5f5f5' }}>
                {/* Synonyms */}
                {result.synonyms && result.synonyms.length > 0 && (
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" sx={{ mb: 1 }}>
                      Synonyms
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                      {(Array.isArray(result.synonyms) ? result.synonyms : [result.synonyms])
                        .map((synonym, index) => (
                          <Chip
                            key={index}
                            label={synonym}
                            onClick={() => onRecentSearch && onRecentSearch(synonym)}
                            sx={{ fontFamily: '"Amiri", serif' }}
                          />
                        ))}
                    </Box>
                  </Box>
                )}

                {/* Antonyms */}
                {result.antonyms && result.antonyms.length > 0 && (
                  <Box>
                    <Typography variant="subtitle2" sx={{ mb: 1 }}>
                      Antonyms
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                      {(Array.isArray(result.antonyms) ? result.antonyms : [result.antonyms])
                        .map((antonym, index) => (
                          <Chip
                            key={index}
                            label={antonym}
                            onClick={() => onRecentSearch && onRecentSearch(antonym)}
                            sx={{ fontFamily: '"Amiri", serif' }}
                          />
                        ))}
                    </Box>
                  </Box>
                )}
              </Paper>
            </Box>
          )}
        </CardContent>
      </Card>
    </Fade>
  );
};

const DictionaryMode = ({ onRecentSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [analysisError, setAnalysisError] = useState(null);
  const [analysisResult, setAnalysisResult] = useState(null);
  const [isFocused, setIsFocused] = useState(false);
  const abortControllerRef = useRef(null);

  const clearSearch = () => {
    setSearchTerm("");
    setAnalysisResult(null);
    setAnalysisError(null);
  };

  const cancelSearch = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null;
      setIsAnalyzing(false);
      setAnalysisError("Search cancelled");
    }
  };

  const analyzeWord = async (word) => {
    if (!word.trim()) return;
    
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    
    abortControllerRef.current = new AbortController();
    const { signal } = abortControllerRef.current;
    
    try {
      setIsAnalyzing(true);
      setAnalysisError(null);
      setAnalysisResult(null);
      
      const response = await fetch(`${API_BASE_URL}/word/dictionaryanalyze`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('access_token')
        },
        body: JSON.stringify({ text: word }),
        signal
      });
      
      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }
      
      const data = await response.json();
      console.log('Word analysis response:', data);
      
      if (!signal.aborted) {
        if (data.result) {
          setAnalysisResult(data.result); // Make sure we're setting the result object
          if (onRecentSearch) {
            onRecentSearch(word.trim());
          }
        } else {
          setAnalysisError('No analysis result found');
        }
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Search aborted');
      } else {
        console.error('Error analyzing word:', error);
        setAnalysisError(`Failed to analyze the word: ${error.message}`);
      }
    } finally {
      if (!signal.aborted) {
        setIsAnalyzing(false);
        abortControllerRef.current = null;
      }
    }
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    if (!searchTerm.trim()) return;
    analyzeWord(searchTerm);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <Box>
      <Paper
        elevation={isFocused ? 3 : 0}
        sx={{
          p: 0.75,
          borderRadius: '14px',
          maxWidth: '700px',
          mx: 'auto',
          mb: 3,
          backgroundColor: '#f9f9f9',
          border: isFocused ? '2px solid #111' : '1px solid #e0e0e0',
        }}
      >
        <form onSubmit={handleSearchSubmit}>
          <Box sx={{ display: 'flex', alignItems: 'center', p: '2px 4px' }}>
            <IconButton
              sx={{ p: '10px', color: '#222' }}
              type={isAnalyzing ? 'button' : 'submit'}
              onClick={isAnalyzing ? cancelSearch : undefined}
              disabled={!searchTerm.trim() && !isAnalyzing}
            >
              <FontAwesomeIcon icon={isAnalyzing ? faTimes : faSearch} />
            </IconButton>

            <TextField
              fullWidth
              placeholder=""
              value={searchTerm}
              onChange={handleSearchChange}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              variant="standard"
              disabled={isAnalyzing}
              InputProps={{
                disableUnderline: true,
                style: { 
                  fontSize: '24px',
                  fontFamily: '"Amiri", serif',
                  direction: 'rtl',
                  padding: '12px 8px'
                },
                endAdornment: searchTerm && !isAnalyzing && (
                  <InputAdornment position="end">
                    <IconButton onClick={clearSearch}>
                      <FontAwesomeIcon icon={faTimes} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Box>
        </form>
      </Paper>

      {isAnalyzing ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3, mb: 2 }}>
          <CircularProgress size={40} sx={{ color: '#111' }} />
        </Box>
      ) : (
        <>
          {analysisError && (
            <Alert severity="error" sx={{ mt: 3, borderRadius: '8px' }}>
              {analysisError}
            </Alert>
          )}
          
          {analysisResult && (
            <DictionaryResult 
              result={analysisResult} 
              searchTerm={searchTerm}
              onRecentSearch={onRecentSearch}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default DictionaryMode;