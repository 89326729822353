import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Paper,
  Typography,
  CircularProgress,
  Alert,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Chip
} from '@mui/material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faLightbulb,
  faLanguage,
  faSpellCheck,
  faGavel,
  faExclamationTriangle,
  faCheck,
  faArrowCircleRight
} from "@fortawesome/free-solid-svg-icons";
import API_BASE_URL from "../../apiConfig";

const WritingMode = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [englishIntent, setEnglishIntent] = useState("");
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [analysisError, setAnalysisError] = useState(null);
  const [analysisResult, setAnalysisResult] = useState(null);
  const [isFocused, setIsFocused] = useState(false);

  const clearAnalysis = () => {
    setSearchTerm("");
    setEnglishIntent("");
    setAnalysisResult(null);
    setAnalysisError(null);
  };

  const handleWritingAnalyze = async () => {
    if (!searchTerm.trim()) return;

    setIsAnalyzing(true);
    setAnalysisError(null);

    try {
      const response = await fetch(`${API_BASE_URL}/word/writing-analyze`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('access_token')
        },
        body: JSON.stringify({ 
          text: searchTerm,
          intent: englishIntent.trim() || undefined
        })
      });

      const data = await response.json();
      console.log("Writing analysis response:", data);
      
      if (response.ok) {
        setAnalysisResult(data.result);
      } else {
        setAnalysisError(data.error || 'Analysis failed');
      }
    } catch (error) {
      console.error("Error analyzing writing:", error);
      setAnalysisError(error.message);
    } finally {
      setIsAnalyzing(false);
    }
  };

  return (
    <Box sx={{ maxWidth: '800px', mx: 'auto' }}>
      <Grid container spacing={2}>
        {/* Left column: Input fields */}
        <Grid item xs={12} md={6}>
          <Paper
            elevation={isFocused ? 3 : 0}
            sx={{
              p: { xs: 1.5, sm: 2 },
              borderRadius: '14px',
              height: '100%',
              backgroundColor: '#f9f9f9',
              border: isFocused ? '2px solid #111' : '1px solid #e0e0e0',
            }}
          >
            <Typography variant="subtitle1" sx={{ 
              color: '#333',
              fontWeight: 600,
              mb: 1,
              display: 'flex',
              alignItems: 'center'
            }}>
              <FontAwesomeIcon icon={faEdit} style={{ marginRight: '8px' }} />
              Arabic Text
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={6}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              variant="standard"
              disabled={isAnalyzing}
              InputProps={{
                disableUnderline: true,
                style: { 
                  fontSize: '24px',
                  fontFamily: '"Amiri", serif',
                  direction: 'rtl',
                  padding: '12px 8px'
                }
              }}
              sx={{
                '& .MuiInputBase-root': {
                  backgroundColor: 'white',
                  borderRadius: '8px',
                  padding: '4px'
                }
              }}
            />
          </Paper>
        </Grid>

        {/* Right column: English Intent */}
        <Grid item xs={12} md={6}>
          <Paper
            elevation={0}
            sx={{
              p: { xs: 1.5, sm: 2 },
              borderRadius: '14px',
              height: '100%',
              backgroundColor: 'rgba(249, 249, 249, 0.7)',
              border: '1px dashed #ccc'
            }}
          >
            <Typography variant="subtitle1" sx={{ 
              color: '#333',
              fontWeight: 600,
              mb: 1,
              display: 'flex',
              alignItems: 'center'
            }}>
              <FontAwesomeIcon icon={faLightbulb} style={{ marginRight: '8px' }} />
              What are you trying to say? (Optional)
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={3}
              value={englishIntent}
              onChange={(e) => setEnglishIntent(e.target.value)}
              variant="standard"
              disabled={isAnalyzing}
              InputProps={{
                disableUnderline: true,
                style: { 
                  fontSize: '16px',
                  padding: '8px 12px'
                }
              }}
              sx={{
                '& .MuiInputBase-root': {
                  backgroundColor: 'white',
                  borderRadius: '8px',
                  padding: '4px'
                }
              }}
            />

            {/* Translation Preview */}
            {analysisResult?.translation && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle1" sx={{ 
                  color: '#333',
                  fontWeight: 600,
                  mb: 1,
                  display: 'flex',
                  alignItems: 'center'
                }}>
                  <FontAwesomeIcon icon={faLanguage} style={{ marginRight: '8px' }} />
                  Translation
                </Typography>
                <Paper elevation={0} sx={{ 
                  p: 2,
                  borderRadius: '8px',
                  backgroundColor: '#f0f7ff',
                  border: '1px solid #cfe4ff'
                }}>
                  <Typography sx={{ 
                    color: '#0055aa',
                    fontSize: '1rem',
                    fontWeight: 500
                  }}>
                    "{analysisResult.translation}"
                  </Typography>
                </Paper>
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>

      {/* Analyze Button */}
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
        <Button
          variant="contained"
          onClick={handleWritingAnalyze}
          disabled={isAnalyzing || !searchTerm.trim()}
          sx={{ 
            px: { xs: 3, sm: 4 },
            py: { xs: 1, sm: 1.5 },
            borderRadius: '12px',
            fontWeight: 600,
            backgroundColor: '#111',
            minWidth: '200px'
          }}
        >
          {isAnalyzing ? (
            <>
              <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
              Analyzing...
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faEdit} style={{ marginRight: '8px' }} />
              Analyze my writing
            </>
          )}
        </Button>
      </Box>

      {/* Error message */}
      {analysisError && (
        <Alert severity="error" sx={{ mt: 3, borderRadius: '8px' }}>
          {analysisError}
        </Alert>
      )}

      {/* Analysis Results */}
      {analysisResult && (
        <Box sx={{ mt: 4 }}>
          <Grid container spacing={2}>
            {/* Spelling Analysis */}
            <Grid item xs={12} md={6}>
              <Paper
                elevation={1}
                sx={{
                  p: 2,
                  height: '100%',
                  borderRadius: '14px',
                  borderTop: !analysisResult.spelling.isCorrect ? 
                    '3px solid #f0ad4e' : '3px solid #28a745'
                }}
              >
                <Typography variant="subtitle1" sx={{ 
                  fontWeight: 600,
                  display: 'flex',
                  alignItems: 'center',
                  mb: 2
                }}>
                  <FontAwesomeIcon icon={faSpellCheck} style={{ marginRight: '8px' }} />
                  Spelling
                  {analysisResult.spelling.isCorrect && (
                    <Chip 
                      icon={<FontAwesomeIcon icon={faCheck} />} 
                      label="Correct" 
                      color="success" 
                      size="small" 
                      sx={{ ml: 2 }} 
                    />
                  )}
                </Typography>

                {!analysisResult.spelling.isCorrect ? (
                  <List dense>
                    {analysisResult.spelling.errors.map((error, index) => (
                      <ListItem key={index}>
                        <ListItemIcon sx={{ minWidth: '32px' }}>
                          <FontAwesomeIcon icon={faExclamationTriangle} color="#f0ad4e" />
                        </ListItemIcon>
                        <ListItemText 
                          primary={
                            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 1 }}>
                              <Typography component="span" sx={{ 
                                fontFamily: '"Amiri", serif',
                                fontSize: '1.1rem',
                                backgroundColor: '#fff3cd',
                                padding: '2px 8px',
                                borderRadius: '4px'
                              }}>
                                {error.word}
                              </Typography>
                              
                              {error.correct && (
                                <>
                                  <FontAwesomeIcon 
                                    icon={faArrowCircleRight} 
                                    style={{ margin: '0 8px', color: '#666' }} 
                                  />
                                  <Typography component="span" sx={{ 
                                    fontFamily: '"Amiri", serif',
                                    fontSize: '1.1rem',
                                    backgroundColor: '#d4edda',
                                    padding: '2px 8px',
                                    borderRadius: '4px',
                                    color: '#155724'
                                  }}>
                                    {error.correct}
                                  </Typography>
                                </>
                              )}
                            </Box>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Typography color="success.main" sx={{ fontWeight: 500 }}>
                    <FontAwesomeIcon icon={faCheck} style={{ marginRight: '8px' }} />
                    All words are spelled correctly!
                  </Typography>
                )}
              </Paper>
            </Grid>

            {/* Grammar Analysis */}
            <Grid item xs={12} md={6}>
              <Paper
                elevation={1}
                sx={{
                  p: 2,
                  height: '100%',
                  borderRadius: '14px',
                  borderTop: !analysisResult.grammar.isCorrect ? 
                    '3px solid #f0ad4e' : '3px solid #28a745'
                }}
              >
                <Typography variant="subtitle1" sx={{ 
                  fontWeight: 600,
                  display: 'flex',
                  alignItems: 'center',
                  mb: 2
                }}>
                  <FontAwesomeIcon icon={faGavel} style={{ marginRight: '8px' }} />
                  Grammar (Nahw/Irab)
                  {analysisResult.grammar.isCorrect && (
                    <Chip 
                      icon={<FontAwesomeIcon icon={faCheck} />} 
                      label="Correct" 
                      color="success" 
                      size="small" 
                      sx={{ ml: 2 }} 
                    />
                  )}
                </Typography>

                {!analysisResult.grammar.isCorrect ? (
                  <List dense>
                    {analysisResult.grammar.errors.map((error, index) => (
                      <ListItem key={index} alignItems="flex-start">
                        <ListItemIcon sx={{ minWidth: '32px' }}>
                          <FontAwesomeIcon icon={faExclamationTriangle} color="#f0ad4e" />
                        </ListItemIcon>
                        <ListItemText 
                          primary={
                            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 1 }}>
                              <Typography component="span" sx={{ 
                                fontFamily: '"Amiri", serif',
                                fontSize: '1.1rem',
                                backgroundColor: '#fff3cd',
                                padding: '2px 8px',
                                borderRadius: '4px'
                              }}>
                                {error.word}
                              </Typography>
                              
                              {error.correct && (
                                <>
                                  <FontAwesomeIcon 
                                    icon={faArrowCircleRight} 
                                    style={{ margin: '0 8px', color: '#666' }} 
                                  />
                                  <Typography component="span" sx={{ 
                                    fontFamily: '"Amiri", serif',
                                    fontSize: '1.1rem',
                                    backgroundColor: '#d4edda',
                                    padding: '2px 8px',
                                    borderRadius: '4px',
                                    color: '#155724'
                                  }}>
                                    {error.correct}
                                  </Typography>
                                </>
                              )}
                            </Box>
                          }
                          secondary={
                            <Box sx={{ mt: 1 }}>
                              {error.rule && (
                                <Typography sx={{ 
                                  fontFamily: '"Amiri", serif',
                                  fontSize: '1rem',
                                  fontWeight: 500,
                                  mb: 0.5
                                }}>
                                  {error.rule}
                                </Typography>
                              )}
                              {error.explanation && (
                                <Typography variant="body2" sx={{ 
                                  color: '#444',
                                  fontSize: '0.9rem'
                                }}>
                                  {error.explanation}
                                </Typography>
                              )}
                            </Box>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Typography color="success.main" sx={{ fontWeight: 500 }}>
                    <FontAwesomeIcon icon={faCheck} style={{ marginRight: '8px' }} />
                    Grammar is correct!
                  </Typography>
                )}
              </Paper>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default WritingMode;