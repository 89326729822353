import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const AuthHeader = ({ currentPage }) => {
  const [activeSection, setActiveSection] = useState(currentPage);

  const scrollToSection = (sectionId, section) => {
    const element = document.getElementById(sectionId);
    if (element) {
      // Special handling for features section
      if (sectionId === 'features-section') {
        const offsetPosition = element.getBoundingClientRect().top + window.pageYOffset - 80;
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      } 
      // Special handling for pricing section to ensure header is at the top
      else if (sectionId === 'pricing-section') {
        const headerOffset = 100; // Adjust this value as needed to position the header perfectly
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
      else if (sectionId === 'roadmap-section' && window.innerWidth <= 600) {
        const headerOffset = 100;
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      } else {
        // Default centered scrolling for other sections
        const windowHeight = window.innerHeight;
        const elementHeight = element.offsetHeight;
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - (windowHeight - elementHeight) / 2;
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
      setActiveSection(section);
    }
  };

  const handleHomeClick = () => {
    if (currentPage === 'about') {
      window.location.href = '/';
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      setActiveSection('landing');
    }
  };

  const linkStyle = {
    color: 'white',
    fontFamily: 'inter',
    fontSize: '1rem',
    fontWeight: 500,
    textDecoration: 'none',
    position: 'relative',
    padding: '4px 0', // Add padding to create space for underline
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: '2px',
      backgroundColor: '#fff',
      transformOrigin: 'right',
      transition: 'transform 0.3s ease',
      transform: 'scaleX(0)',
    },
    '&:hover::after': {
      transformOrigin: 'left',
      transform: 'scaleX(1)',
    }
  };

  const navItems = [
    { id: 'features', label: 'Features', sectionId: 'features-section' },
    // { id: 'pricing', label: 'Pricing', sectionId: 'pricing-section' },
    { id: 'support', label: 'Support', sectionId: 'support-section' }
  ];

  return (
    <Box
      sx={{
        width: '100%',
        height: '80px',
        backgroundColor: 'rgba(0, 0, 0, 0.95)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
        backdropFilter: 'blur(8px)',
      }}
    >
      {/* Centered Container with max-width */}
      <Box
        sx={{
          maxWidth: '1200px',
          width: '100%',
          margin: '0 auto',
          display: 'flex',
          justifyContent: 'center',
          px: { xs: 3, sm: 4 },
        }}
      >
        {/* Navigation Items */}
        <Box 
          sx={{ 
            display: 'flex', 
            alignItems: 'center',
            justifyContent: 'center',
            gap: { xs: 4, sm: 5 },
            overflow: 'auto',
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
              display: 'none'
            },
            WebkitOverflowScrolling: 'touch',
            paddingX: { xs: 2, sm: 0 },
            minWidth: 'fit-content',
          }}
        >
          <Box 
            onClick={handleHomeClick}
            sx={{ 
              textDecoration: 'none',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              whiteSpace: 'nowrap',
            }}
          >
            <Typography sx={{
              ...linkStyle,
              fontSize: { xs: '0.9rem', sm: '1rem' },
              '&::after': {
                ...linkStyle['&::after'],
                transform: activeSection === 'landing' ? 'scaleX(1)' : 'scaleX(0)',
                backgroundColor: '#fff !important', // Force white color
              }
            }}>
              Home
            </Typography>
          </Box>

          {currentPage === 'landing' && (
            <>
              {navItems.map((item) => (
                <Box 
                  key={item.id}
                  onClick={() => scrollToSection(item.sectionId || `${item.id}-section`, item.id)}
                  sx={{ 
                    textDecoration: 'none',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <Typography sx={{
                    ...linkStyle,
                    fontSize: { xs: '0.9rem', sm: '1rem' },
                    '&::after': {
                      ...linkStyle['&::after'],
                      transform: activeSection === item.id ? 'scaleX(1)' : 'scaleX(0)',
                    }
                  }}>
                    {item.label}
                  </Typography>
                </Box>
              ))}
            </>
          )}

          <Link 
            to="/about-us"
            onClick={() => setActiveSection('about')}
            style={{ 
              textDecoration: 'none',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography sx={{
              ...linkStyle,
              fontSize: { xs: '0.9rem', sm: '1rem' },
              whiteSpace: 'nowrap',
              '&::after': {
                ...linkStyle['&::after'],
                transform: activeSection === 'about' ? 'scaleX(1)' : 'scaleX(0)',
              }
            }}>
              About
            </Typography>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default AuthHeader;