import React, { useState } from 'react';
import { ImagePath } from '../../Constant/ImgPath';
import { useNavigate } from 'react-router-dom';
import PasswordInput from '../../Components/PasswordInput';
import API_BASE_URL from '../../apiConfig';
import { Typography } from "@mui/material";

function ResetPassword() {
    const [isChecked, setChecked] = useState(false);
    const [newPass, setNewPass] = useState("");
    const [confirmPass, setConfirmPass] = useState("");
    const navigate = useNavigate();
    const handleCheckboxChange = () => {
        setChecked(!isChecked);
    };
    const NavigatePages = (data) => {
        navigate(data);
    }
    const comparePsss = () => {
        if (newPass === confirmPass){
            resetAPI()
        }
        else {
            console.log("Passwords not matched")
        }
    }
    const resetAPI = () => {
        const apiUrl = `${API_BASE_URL}/auth/reset_password`;
        const requestData = {
            email: localStorage.getItem("userEmail"),
            new_password: newPass
        }
        const headers = {
            'Content-Type': 'application/json',
        };
        fetch(apiUrl, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(requestData),
        })
            .then(response => response.json())
            .then(data => {
                console.log(data)
                localStorage.clear()
                NavigatePages("/login")
            })
            .catch(error => {
                console.error('Error fetching API:', error)
            });

    }
    return (
        <>
            <div className="main-section" style={{ 
                backgroundColor: 'black', 
                minHeight: '100vh', 
                display: 'flex', 
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                margin: 0,
                padding: '1rem',
                boxSizing: 'border-box'
            }}>
                <div className="sign-left" style={{ 
                    width: '100%',
                    maxWidth: '400px', 
                    margin: '0 auto', 
                    backgroundColor: 'black', 
                    padding: '1rem',
                    boxSizing: 'border-box'
                }}>
                    <div className="sign" style={{ width: '100%' }}>
                        <div className="sign-text" style={{ 
                            marginBottom: '2rem', 
                            width: '100%', 
                            textAlign: 'center',
                            padding: '0 1rem'
                        }}>
                            <Typography 
                                variant="h3" 
                                component="h1"
                                sx={{
                                    fontWeight: 'bold',
                                    color: 'white',
                                    fontFamily: 'Roboto, sans-serif',
                                    fontSize: '2.0rem',
                                }}
                            >
                                Reset Password
                            </Typography>
                            <Typography 
                                variant="body1"
                                sx={{
                                    fontSize: '0.875rem', 
                                    fontWeight: '400', 
                                    color: 'white',
                                    marginTop: '0.5rem',
                                }}
                            >
                                Please enter your new password
                            </Typography>
                        </div>

                        <div style={{ 
                            background: 'white',
                            borderRadius: '12px',
                            padding: '1.25rem',
                            boxShadow: '0px 8px 30px rgba(255, 255, 255, 0.5)',
                            width: '100%',
                            boxSizing: 'border-box'
                        }}>
                            <div className='email-area create-inputs'>
                                <PasswordInput placeHolder='New Password' setpassword={(v) => { setNewPass(v)}} />
                                <PasswordInput placeHolder='Confirm Password' setpassword={(v) => { setConfirmPass(v)}} />
                            </div>
                            <div style={{ margin: "22px auto", width: "72%", fontSize: "15px", fontWeight: "400", textDecoration: "underline" }}>
                                <button 
                                    onClick={() => { comparePsss() }}
                                    className='login-btn Btns-hover-nav'
                                    style={{
                                        width: '100%',
                                        padding: '0.75rem',
                                        backgroundColor: '#000',
                                        color: 'white',
                                        border: 'none',
                                        borderRadius: '8px',
                                        fontSize: '1rem',
                                        fontWeight: '500',
                                        cursor: 'pointer',
                                        marginTop: '1rem'
                                    }}
                                >
                                    Reset Password
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='sign-right'>
                
                </div>
            </div>
        </>
    );
}

export default ResetPassword;
