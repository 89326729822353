import { combineReducers } from "redux";
import { userProfileReducer } from "./userProfileReducer";
import { amountReducer } from "./amountValuesReducer";

let allReducers = {
  userProfileReducer: userProfileReducer,
  amount: amountReducer,
};

export default combineReducers(allReducers);
