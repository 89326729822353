import React, { useState, useEffect } from 'react';
import PayPalButton from '../Components/PayPalPayment';
import API_BASE_URL from '../apiConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";

function CheckoutForm() {
    const [loading, setLoading] = useState(false);
    const [planDetails, setPlanDetails] = useState([]);
    const navigate = useNavigate();
    const notify = (message) => toast.error(message, {
        position: 'top-right'
    });
    const showToastMessage = (message) => {
        toast.info(message, {
          position: 'top-right'
        });
    };

    useEffect(() => {
        getPlans();
    }, []);

    const getPlans = async () => {
        try {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("access_token"),
            };
            const response = await fetch(`${API_BASE_URL}/payment/subscriptions/plans`, {
                method: 'GET',
                headers: headers
            });
            const data = await response.json();
            setPlanDetails(data);
        } catch (error) {
            console.log(error);
            notify('Failed to fetch plans. Please try again later.');
        }
    };

    const handleBack = () => {
        navigate('/dashboard');
    };

    return (
        <div style={{ width: '80%', margin: '0 auto', padding: '20px' }}>
            <h2 style={{ textAlign: 'center', marginBottom: '30px' }}>Choose Your Subscription</h2>
            
            {planDetails.length > 0 ? (
                <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', flexWrap: 'wrap' }}>
                    {planDetails.map((plan) => (
                        <div key={plan.id} style={{
                            border: '1px solid #ddd',
                            borderRadius: '8px',
                            padding: '20px',
                            width: '300px',
                            boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                        }}>
                            <h3>{plan.name}</h3>
                            <p>${plan.price} {plan.interval}</p>
                            <p>{plan.description}</p>
                            
                            <PayPalButton plan={plan.paypal_plan_id} setBackfunc={handleBack} />
                        </div>
                    ))}
                </div>
            ) : (
                <p style={{ textAlign: 'center' }}>Loading plans...</p>
            )}
            
            <button 
                onClick={handleBack}
                style={{
                    display: 'block',
                    margin: '30px auto',
                    padding: '10px 20px',
                    backgroundColor: '#f0f0f0',
                    border: '1px solid #ddd',
                    borderRadius: '4px',
                    cursor: 'pointer'
                }}
            >
                Back to Dashboard
            </button>
            
            <ToastContainer />
        </div>
    );
}

export default CheckoutForm;
