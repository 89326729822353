import React, { useMemo } from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Divider
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBookOpen,
  faAngleDown as angleDown,
  faAngleLeft as angleLeft
} from '@fortawesome/free-solid-svg-icons';

const TableOfContents = ({
  drawerWidth,
  groupedHeadings,
  currentPage,
  expandedSections,
  toggleSection,
  setCurrentPage
}) => {
  // The headings are already processed by the backend
  // We can use them directly without any mapping
  
  return (
    <Box sx={{ width: drawerWidth, p: 2 }} className="library-arabic-text">
      <Typography variant="h6" sx={{ 
        mb: 2, 
        fontWeight: 600, 
        display: 'flex', 
        alignItems: 'center', 
        gap: 1, 
        color: 'primary.main',
        fontFamily: '"Amiri", "Noto Naskh Arabic", serif'
      }}>
        <FontAwesomeIcon icon={faBookOpen} />
        <span>فهرس الكتاب</span>
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <List sx={{ maxHeight: 'calc(100vh - 200px)', overflow: 'auto' }}>
        {groupedHeadings.length > 0 ? (
          groupedHeadings.map((section) => (
            <React.Fragment key={section.id}>
              <ListItem 
                disablePadding
                sx={{ mb: 1 }}
              >
                <ListItemButton 
                  onClick={() => {
                    setCurrentPage(section.page);
                    toggleSection(section.id);
                  }}
                  selected={currentPage === section.page}
                  sx={{
                    borderRadius: '4px',
                    '&.Mui-selected': {
                      backgroundColor: 'rgba(25, 118, 210, 0.08)',
                      borderRight: '3px solid #1976d2'
                    },
                    '&:hover': {
                      backgroundColor: 'rgba(25, 118, 210, 0.04)'
                    }
                  }}
                >
                  <ListItemText 
                    primary={<>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Box sx={{ flex: 1 }}>{section.title}</Box>
                        <Box sx={{ 
                          display: 'flex', 
                          alignItems: 'center', 
                          gap: 1,
                          color: 'text.secondary',
                          fontSize: '0.85rem',
                          fontWeight: 400
                        }}>
                          <span>ص {section.page}</span>
                          {section.children.length > 0 && (
                            <FontAwesomeIcon 
                              icon={expandedSections[section.id] ? angleDown : angleLeft} 
                              style={{ fontSize: '0.8rem' }} 
                            />
                          )}
                        </Box>
                      </Box>
                    </>}
                    primaryTypographyProps={{
                      sx: { 
                        fontSize: 17,
                        fontWeight: 600,
                        direction: 'rtl',
                        textAlign: 'right',
                        fontFamily: '"Amiri", "Noto Naskh Arabic", serif'
                      }
                    }}
                  />
                </ListItemButton>
              </ListItem>
              
              {/* Subsections (only shown when expanded) */}
              {expandedSections[section.id] && section.children.map((subheading) => (
                <ListItem 
                  key={subheading.id} 
                  disablePadding
                  sx={{ pl: 2 }}
                >
                  <ListItemButton 
                    onClick={() => setCurrentPage(subheading.page)}
                    selected={currentPage === subheading.page}
                    sx={{
                      borderRadius: '4px',
                      '&.Mui-selected': {
                        backgroundColor: 'rgba(25, 118, 210, 0.04)',
                        borderRight: '2px solid #1976d2'
                      }
                    }}
                  >
                    <ListItemText 
                      primary={<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Box sx={{ flex: 1 }}>{subheading.title}</Box>
                        <Box sx={{ 
                          color: 'text.secondary',
                          fontSize: '0.8rem',
                          fontWeight: 400
                        }}>
                          ص {subheading.page}
                        </Box>
                      </Box>}
                      primaryTypographyProps={{
                        sx: { 
                          fontSize: 15,
                          fontWeight: 400,
                          direction: 'rtl',
                          textAlign: 'right',
                          fontFamily: '"Amiri", "Noto Naskh Arabic", serif',
                          color: 'text.secondary'
                        }
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </React.Fragment>
          ))
        ) : (
          <Typography variant="body2" sx={{ 
            textAlign: 'center', 
            color: 'text.secondary', 
            p: 2, 
            fontFamily: '"Amiri", "Noto Naskh Arabic", serif' 
          }}>
            لا يوجد فهرس لهذا الكتاب
          </Typography>
        )}
      </List>
    </Box>
  );
};

export default TableOfContents;