import React, { useState, useEffect } from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_BASE_URL from '../apiConfig';

function Message({ content }) {
  return <p>{content}</p>;
}

function PayPalButton({ plan,setBackfunc }) {
  const initialOptions = {
    "client-id": "AX7gSuEe4p1BXhiZvPkqQ9T44-8-zDXaXPxIeqBhcpQY7DxaXMjegtJCLPl-0TLkCE2I2K9eQIw9HvGZ",
    "enable-funding": "paylater,card",
    "disable-funding": "",
    "data-sdk-integration-source": "integrationbuilder_sc",
    vault: "true",
    intent: "subscription",
  };
  const notify = (message) => toast.error(message, {
    position: 'top-right'
});
const showToastMessage = (message) => {
    toast.info(message, {
      position: 'top-right'
    });
  };
  const [message, setMessage] = useState("");

  return (
    <div style={{paddingTop:"10%",paddingBottom:"10%",width:"50%",display:"block",margin:"0 auto"}} className="App">
      <PayPalScriptProvider options={initialOptions}>
        <PayPalButtons
          style={{
            shape: "pill",
            layout: "vertical",
          }}
          createSubscription={async () => {
            try {
              const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem("access_token"),
                  };
              const response = await fetch(`${API_BASE_URL}/payment/paypal/create-subscription`, {
                method: "POST",
                headers: headers,
                body: JSON.stringify({ userAction: "SUBSCRIBE_NOW", plan: plan }),
              });
              const data = await response.json();
              if (data?.id) {
                // showToastMessage("Kindly wait few minutes. Your plan will be updated after verification.");
                // setMessage(`Successful subscription...`);
                return data.id;
              } else {
                console.error(
                  { callback: "createSubscription", serverResponse: data },
                  JSON.stringify(data, null, 2),
                );
                // (Optional) The following hides the button container and shows a message about why checkout can't be initiated
                const errorDetail = data?.details?.[0];
                setMessage(
                  `Could not initiate PayPal Subscription...<br><br>${errorDetail?.issue || ""
                  } ${errorDetail?.description || data?.message || ""} ` +
                  (data?.debug_id ? `(${data.debug_id})` : ""),
                  { hideButtons: true },
                );
              }
            } catch (error) {
              notify("Could not initiate PayPal Subscription");
              console.error(error);
              setMessage(`Could not initiate PayPal Subscription...${error}`);
            }
          }}
          onApprove={async (data, actions) => {
            /*
              No need to activate manually since SUBSCRIBE_NOW is being used.
              Learn how to handle other user actions from our docs:
              https://developer.paypal.com/docs/api/subscriptions/v1/#subscriptions_create
            */
            if (data.orderID) {
              setMessage(
                `You have successfully subscribed to the plan. Your subscription id is: ${data.subscriptionID}`,
              );
              setBackfunc();
            } else {
              setMessage(
                `Failed to activate the subscription: ${data.subscriptionID}`,
              );
            }
          }}
        />
      </PayPalScriptProvider>
      {/* <Message content={message} /> */}
      <ToastContainer />
    </div>
  );
}

export default PayPalButton;
