import React, { useEffect,useState } from 'react';
import AdminSidebar from '../../Components/Sidebar';
import User from '../../Components/User';
import Chart from '../../Components/Chart';
import Nav from '../../Components/Nav'
import { useNavigate } from 'react-router-dom';
function AdminDashboard() {
    // sidebar start
    const navigate = useNavigate();
    const [sidebar, setsidebar] = useState(false);
    const sidemenufun = () => {
        setsidebar(!sidebar);
    }
    // sidebar end
    const AdminDashboard = {
        h4: {
            fontSize: "24px",
            fontWeight: "600",
            margin: "0px"
        },
        padding: {
            padding: "40px",
            width: "80%",
        }
    }
    const [loginFalse, setLoginFalse] = useState(false)

    useEffect(() => {
        localStorage.getItem("login");
        if(localStorage.getItem("login") == "true"){
            setLoginFalse(true);
        }else{
            navigate("/login");
        }
    }, [localStorage.getItem("login")])

    return (
        <>
            {loginFalse && <><Nav headervalue={"nav3"} sidemenufun={sidemenufun} />
            <div className='main-section'>
                <AdminSidebar sidebar={sidebar}  sidebaracc={'AdminSidebar'}/>
                <div className='user-reponsive' style={AdminDashboard.padding}>
                    <h4 style={AdminDashboard.h4}>Dashboard</h4>
                    <div className='admin-user-chart'>
                        <Chart />
                    </div>
                    <h4 style={AdminDashboard.h4}>Latest Users</h4>
                    <User />
                </div>
            </div></>}
        </>
    );
}

export default AdminDashboard;
