import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Nav from '../Components/Nav';
import Footer from '../Components/Footer';
import Sidebar from '../Components/Sidebar';

const LessonCard = ({ id, title, enabled, onClick, isComplete }) => {
  const cardStyle = {
    border: '1px solid #ddd',
    borderRadius: '8px',
    padding: '20px',
    margin: '10px',
    textAlign: 'center',
    width: '180px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    cursor: enabled ? 'pointer' : 'not-allowed',
    backgroundColor: enabled ? 'white' : '#f0f0f0',
    opacity: enabled ? 1 : 0.6,
    transition: 'transform 0.2s, box-shadow 0.2s',
    position: 'relative',
    overflow: 'hidden'
  };

  const hoverStyle = {
    transform: 'translateY(-3px)',
    boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
  };

  const [isHovered, setIsHovered] = useState(false);

  // Style for the Complete tag - Improved
  const completeTagStyle = {
    position: 'absolute',
    top: '12px',          // Slightly lower
    right: '-34px',         // Adjust position with width
    width: '130px',         // Give it definite width
    backgroundColor: '#10b981', // Tailwind Emerald 500 - A vibrant green
    color: 'white',
    padding: '5px 0',       // Vertical padding
    fontSize: '0.7rem',     // Keep it small but readable
    fontWeight: '600',      // Semi-bold
    textAlign: 'center',
    textTransform: 'uppercase', // Uppercase for emphasis
    letterSpacing: '0.5px',   // Slight spacing
    transform: 'rotate(45deg)',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)', // Slightly stronger shadow
    zIndex: 1              // Ensure it's visually on top
  };

  return (
    <div 
      style={{ ...cardStyle, ...(isHovered && enabled ? hoverStyle : {}) }}
      onClick={enabled ? () => onClick(id) : null}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isComplete && (
        <div style={completeTagStyle}>✓ Complete</div>
      )}

      {/* Adjust top margin slightly more if complete tag is present */}
      <h3 style={{ 
        fontSize: '1.2rem', 
        marginBottom: '10px', 
        color: '#333', 
        marginTop: isComplete ? '25px' : '15px' // Add more space if tag is shown
      }}>Lesson {id}</h3>
      <p style={{ fontSize: '1rem', fontWeight: 'bold', color: '#555' }}>{title}</p>
      {!enabled && (
        <span style={{ fontSize: '0.8rem', color: '#aaa', display: 'block', marginTop: '10px' }}>
          (Under Construction)
        </span>
      )}
    </div>
  );
};

const LessonsPage = () => {
  const [sidebar, setSidebar] = useState(false);
  const navigate = useNavigate();
  const [completedLessons, setCompletedLessons] = useState({}); // State for completion data

  // Key for localStorage
  const completionStorageKey = 'lessonCompletionStatus';

  // Load completion status on mount and when returning to the page
  useEffect(() => {
    const loadCompletionStatus = () => {
      try {
        const status = JSON.parse(localStorage.getItem(completionStorageKey) || '{}');
        setCompletedLessons(status);
      } catch (error) {
        console.error("Error reading completion status:", error);
        setCompletedLessons({});
      }
    };
    
    loadCompletionStatus();
    
    // Optional: Add event listener if completion can happen elsewhere
    // window.addEventListener('storage', loadCompletionStatus);
    // return () => window.removeEventListener('storage', loadCompletionStatus);

  }, []); // Runs on mount

  const sidemenufun = () => {
    setSidebar(!sidebar);
  };

  const handleLessonClick = (lessonId) => {
    navigate(`/lesson/${lessonId}`);
  };

  const handleBackToDashboard = () => {
    navigate('/dashboard');
  };

  const backButtonStyle = {
    display: 'inline-block',
    marginBottom: '20px',
    padding: '8px 15px',
    backgroundColor: '#6c757d',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '0.9rem',
    textDecoration: 'none',
    transition: 'background-color 0.2s'
  };

  const backButtonHoverStyle = {
    backgroundColor: '#5a6268'
  };

  const [isBackButtonHovered, setIsBackButtonHovered] = useState(false);

  const lessons = Array.from({ length: 10 }, (_, i) => {
    const lessonId = i + 1;
    let title = `Lesson ${lessonId} Title`; // Default title
    let enabled = false; // Default disabled

    if (lessonId === 1) {
      title = 'Introduction to Nahw';
      enabled = true;
    } else if (lessonId === 2) {
      title = 'اﻟﻜﻠﻤﺔ and اﻟﻜﻼم'; // Use the Arabic title for Lesson 2
      enabled = true; // Enable Lesson 2
    } // Add more else if blocks for future lessons

    return {
      id: lessonId,
      title: title,
      enabled: enabled,
      isComplete: !!completedLessons[lessonId] // Check completion status from state
    };
  });

  return (
    <>
      <Nav headervalue={"nav2"} sidemenufun={sidemenufun} />
      <Sidebar sidebar={sidebar} sidebaracc={"Mobile-Sidebar"} sidemenufun={sidemenufun} />
      <div className="lessons-page-container" style={{ padding: '20px', paddingTop: '20px', background: '#f9f9f9', minHeight: 'calc(100vh - 120px)' }}>
        <button 
          style={{ 
            ...backButtonStyle, 
            ...(isBackButtonHovered ? backButtonHoverStyle : {}), 
            marginTop: '15px'
          }}
          onClick={handleBackToDashboard}
          onMouseEnter={() => setIsBackButtonHovered(true)}
          onMouseLeave={() => setIsBackButtonHovered(false)}
        >
          &larr; Back to Dashboard
        </button>
        
        <h1 style={{ textAlign: 'center', marginBottom: '30px', color: '#2c3e50', fontWeight: 600, marginTop: '20px' }}>Nahw Fundamentals</h1>
        <div className="lesson-cards-grid" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '15px', maxWidth: '1000px', margin: '0 auto' }}>
          {lessons.map((lesson) => (
            <LessonCard
              key={lesson.id}
              id={lesson.id}
              title={lesson.title}
              enabled={lesson.enabled}
              onClick={handleLessonClick}
              isComplete={lesson.isComplete} // Pass completion status to card
            />
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LessonsPage; 