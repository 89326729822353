import React, { useState,useEffect } from 'react';
import Nav from '../Components/Nav';
import Footer from '../Components/Footer';
import { ImagePath } from '../Constant/ImgPath';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, } from '@fortawesome/free-solid-svg-icons';
import { faAngleLeft, faTrash, faAngleRight, faCheck } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import AdminSidebar from '../Components/Sidebar';
import { useNavigate } from 'react-router-dom';
function SentenceVoice() {
    const navigate = useNavigate();
    const [isActive, setIsActive] = useState(false);
    const location = useLocation();
    const receivedWord = location.state && location.state;
    const handleClick = () => {
        setIsActive(!isActive);
    };
    // sidebar start
    const [sidebar, setsidebar] = useState(false);
    const sidemenufun = () => {
        setsidebar(!sidebar);
    }

    const [loginFalse, setLoginFalse] = useState(false)

    useEffect(() => {
        localStorage.getItem("login");
        if(localStorage.getItem("login") == "true"){
            setLoginFalse(true);
        }else{
            navigate("/login");
        }
    }, [localStorage.getItem("login")])
    // sidebar end
    const SentenceVoice = {
        bg: {
            // background: "rgb(244, 245, 246)",
            padding: "33px 24px"
        },
        h4: {
            fontSize: "18px",
            fontWeight: "500",
            margin: "0 16px",
            border: "1px solid #ADADAD",
            padding: "8px 40px",
            background: "#fff",
            borderTopLeftRadius: "12px",
            borderTopRightRadius: "12px",
        },
        mainHeading: {
            width: "94%", marginBottom: "25px", display: "flex", justifyContent: "end"
        },
        mainBox: {
            borderRadius: "12px",
            border: "1px solid #ADADAD",
            padding: "30px 33px",
            background: "#fff",
            boxShadow: "rgba(0, 0, 0, 0.3) 0px 3px 5px 3px",
            width: "82%",
            margin: "0px auto",
            minWidth: "513px"
        },
        heading: {
            color: "#818181",
            fontSize: "22px",
            fontWeight: "400",
            textAlign: "center"
        },
        voiceDiv: {
            display: "flex",
            alignItems: "center",
            gap: "20px",
            justifyContent: "center",
            height: "257px"
        },
        h4: {
            color: "#000",
            fontSize: "80px",
            fontWeight: "500",
            fontFamily: "uth"
        },
        button: {
            borderRadius: "14px",
            background: "#E8E8E8",
            padding: "11px 20px",
            border: "transparent",
            cursor: "pointer",
            margin: "0"
        },
        buttonDiv: {
            border: "2px solid rgb(232, 232, 232)",
            height: "45px",
            width: "170px",
            display: "flex",
            alignItems: "center",
            borderRadius: "7px",
            justifyContent: "space-between",
            padding: "11px",
            cursor: "pointer"
        }
    };
    return (
        <>
            {loginFalse && <div>
                <Nav headervalue={"nav2"} sidemenufun={sidemenufun} />
                <AdminSidebar sidebar={sidebar} sidebaracc={'Mobile-Sidebar'} />
                <div style={SentenceVoice.bg}>
                    <div className='mobile-view-pages'>
                        <div style={SentenceVoice.mainBox}>
                            <h4 style={SentenceVoice.heading}>What does this word mean?</h4>
                            <div style={SentenceVoice.voiceDiv}>
                                {/* <img style={{ width: 'auto', height: '35px' }} src={ImagePath.voice} alt='logo' /> */}
                                <h4 style={SentenceVoice.h4}>{receivedWord}</h4>
                            </div>
                            {/* <div style={{display:"flex", gap:"11px"}}>
                                <button style={SentenceVoice.button}>Skip</button>
                                <div style={SentenceVoice.buttonDiv}>
                                    <p style={{margin:"0"}}>Flip card</p>
                                    <img style={{ width: 'auto', height: '35px', cursor:"pointer"}} src={ImagePath.skipbtn} alt='logo' />
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <Footer />
            </div>}
        </>
    );
}

export default SentenceVoice;
