import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useWebSocket } from './websoket';

// Dynamic imports from src/assets using require.context
const importAll = (r) => {
  let images = {};
  r.keys().forEach((item) => {
    images[item.replace('./', '')] = r(item);
  });
  return images;
};

const images = importAll(require.context('../../src/assets', false, /\.(png|jpe?g|svg)$/));

const NotificationModal = () => {
    const socket = useWebSocket();
    const [modals, setModals] = useState([]); // Multiple modals track karne ke liye
    const [shownBadges, setShownBadges] = useState(new Set()); // Already shown badges track karega

    useEffect(() => {
        if (socket) {
            socket.on('new_badge', (data) => {
                const userData = localStorage.getItem("userData");
                const parsedUserData = userData ? JSON.parse(userData) : null;
                const currentUserName = parsedUserData?.user_info?.username;

                const badges = Array.isArray(data) ? data : [data];

                // Filter: Sirf matching user ka badge_result set karo
                const matchedBadges = badges
                    .filter(user => user.user_name === currentUserName)
                    .flatMap(user => user.bade_result);

                // **Filter out already shown badges**
                const newBadges = matchedBadges.filter(badge => !shownBadges.has(badge.badge_name));

                if (newBadges.length > 0) {
                    // Har naye badge ka ek modal create karo
                    setModals(prev => [...prev, ...newBadges]);
                    setShownBadges(prev => new Set([...prev, ...newBadges.map(b => b.badge_name)]));
                }
            });

            return () => {
                socket.off('new_badge');
            };
        }
    }, [socket, shownBadges]);

    // Modal close karne ka function
    const handleClose = (index) => {
        setModals(prev => prev.filter((_, i) => i !== index)); // Specific modal remove karega
    };

    return (
        <>
            {modals.map((data, index) => {
                const badgeSrc = images[data.badge_image];
                return (
                    <Modal key={index} show={true} onHide={() => handleClose(index)}>
                        <Modal.Header closeButton>
                            <div style={{ 
                                textAlign: "center", 
                                width: "100%", 
                                fontWeight: "bold", 
                                fontSize: "28px", 
                                marginBottom: "0" 
                            }}>
                                New Badge Notification
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <h5 style={{ 
                                textAlign: "center", 
                                marginBottom: "25px", 
                                marginTop: "15px", 
                                fontSize: "25px", 
                                fontWeight: "bold", 
                                textTransform: "uppercase" 
                            }}>
                                {data.badge_name}
                            </h5>
                            {badgeSrc ? (
                                <div style={{ position: "relative", width: "fit-content", margin: "0 auto" }}>
                                    <img
                                        src={badgeSrc}
                                        alt={data.badge_name}
                                        style={{
                                            position: "relative",
                                            textAlign: "center",
                                            width: "150px",
                                            fontWeight: "bold",
                                            fontSize: "28px",
                                            marginBottom: "0"
                                        }}
                                    />
                                    {data.badge_image === 'Badge.png' && (
                                        <span style={{
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                            color: "white",
                                            fontSize: "40px",
                                            fontWeight: "bold",
                                            textShadow: "1px 1px 4px rgba(0, 0, 0, 0.7)"
                                        }}>
                                            {data.badge_points}
                                        </span>
                                    )}
                                </div>
                            ) : null}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => handleClose(index)}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                );
            })}
        </>
    );
};

export default NotificationModal;
