import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import paypalLogo from "../assets/paypal.svg";
import visaLogo from '../assets/visa.svg'
import debitLogo from "../assets/debit.svg";
import API_BASE_URL from "../apiConfig";
import { useSelector } from "react-redux";

function CustomPayPalButton({ isSubscription = true }) {
  const selectedAmount = useSelector((state) => state.amount.selectedAmount);
  const customAmount = useSelector((state) => state.amount.customAmount);
  const [standardPlan, setStandardPlan] = useState(null);
  const [loadingStates, setLoadingStates] = useState({
    paypal: false,
    visa: false,
    debit: false
  });
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 700);
  const [data, setData] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 700);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Only fetch subscription plans if we're in subscription mode
    if (isSubscription) {
      fetch(`${API_BASE_URL}/payment/subscriptions/plans`, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": localStorage.getItem("access_token"),
        }
      })
      .then(res => res.json())
      .then(data => {
        const plan = data.plans?.find(p => p.id === 6);
        setStandardPlan(plan);
      })
      .catch(err => console.error("Error fetching plan:", err));
    }
  }, [isSubscription]);

  const handlePaymentClick = async (paymentType) => {
    try {
      setLoadingStates(prev => ({ ...prev, [paymentType]: true }));
      
      if (isSubscription) {
        await handleSubscription(paymentType);
      } else {
        await handleOneTimePayment(paymentType);
      }
    } catch (error) {
      console.error(`Error processing ${isSubscription ? 'subscription' : 'payment'}:`, error);
      setLoadingStates(prev => ({ ...prev, [paymentType]: false }));
      toast.error(`Could not process ${isSubscription ? 'subscription' : 'payment'}. Please try again.`);
    }
  };

  const handleSubscription = async (paymentType) => {
    if (!standardPlan) {
      console.error("Standard plan not found");
      toast.error("Subscription plan not available. Please try again later.");
      setLoadingStates(prev => ({ ...prev, [paymentType]: false }));
      return;
    }

    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      };
      const response = await fetch(
        `${API_BASE_URL}/payment/paypal/create-subscription`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            plan_id: standardPlan.paypal_plan_id
          }),
        }
      );
      const data = await response.json();
      if (data?.id) {
        window.location.href = data?.links?.[0]?.href;
      } else {
        console.error(
          { callback: "createSubscription", serverResponse: data },
          JSON.stringify(data, null, 2)
        );
        setLoadingStates(prev => ({ ...prev, [paymentType]: false }));
        toast.error("Could not initiate subscription. Please try again later.");

        const errorDetail = data?.details?.[0];
        console.error(
          `Subscription error: ${errorDetail?.issue || ""} ${errorDetail?.description || data?.message || ""} ` +
            (data?.debug_id ? `(${data.debug_id})` : "")
        );
      }
    } catch (error) {
      throw error;
    }
  };

  const handleOneTimePayment = async (paymentType) => {
    try {
      // Fix the null selectedAmount error by adding proper fallbacks
      const amountToUse = customAmount || (selectedAmount ? selectedAmount.toString() : "25");
      
      if (!amountToUse || isNaN(parseFloat(amountToUse)) || parseFloat(amountToUse) <= 0) {
        toast.error("Please enter a valid amount.");
        setLoadingStates(prev => ({ ...prev, [paymentType]: false }));
        return;
      }

      const headers = {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      };
      
      console.log(`Processing one-time payment for amount: $${amountToUse}`);

      // Use the existing single_payment endpoint instead of create-order
      const response = await fetch(
        `${API_BASE_URL}/payment/paypal/single_payment`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            amount: parseFloat(amountToUse).toFixed(2)
          }),
        }
      );
      
      const data = await response.json();
      
      if (data.approval_url) {
        // Redirect to the approval URL
        window.location.href = data.approval_url;
      } else if (data.links && Array.isArray(data.links)) {
        // Find the approve link in the returned links array (fallback)
        const approveLink = data.links.find(link => link.rel === "approve");
        if (approveLink) {
          window.location.href = approveLink.href;
        } else {
          throw new Error("No approval link found in response");
        }
      } else {
        console.error(
          { callback: "singlePayment", serverResponse: data },
          JSON.stringify(data, null, 2)
        );
        setLoadingStates(prev => ({ ...prev, [paymentType]: false }));
        toast.error("Could not process payment. Please try again later.");

        const errorDetail = data?.details?.[0];
        console.error(
          `Payment error: ${errorDetail?.issue || ""} ${errorDetail?.description || data?.message || ""} ` +
            (data?.debug_id ? `(${data.debug_id})` : "")
        );
      }
    } catch (error) {
      throw error;
    }
  };

  return (
    <div
      style={{
        paddingTop: isMobile ? "3%" : "5%",
        paddingBottom: isMobile ? "5%" : "10%",
        display: "block",
        margin: "0 auto",
        width: '100%'
      }}
      className="App payment-buttons"
    >
      <button
        className="paypal-btn"
        style={{
          width: '100%',
          minHeight: isMobile ? '50px' : '55px',
          backgroundColor: '#0070ba',
          color: 'white',
          border: 'none',
          borderRadius: '6px',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.08)',
          transition: 'all 0.2s ease',
          marginBottom: '12px',
          '&:hover': {
            backgroundColor: '#003087'
          }
        }}
        onClick={() => handlePaymentClick('paypal')}
      >
        {loadingStates.paypal ? (
          "Loading..."
        ) : (
          <img
            src={paypalLogo}
            alt="PayPal"
            style={{ height: isMobile ? "24px" : "28px" }}
          />
        )}
      </button>
      <ToastContainer />
      <button
        className="mt-3 visa-btn"
        style={{
          width: '100%',
          minHeight: isMobile ? '50px' : '55px',
          backgroundColor: '#142787',
          color: 'white',
          border: 'none',
          borderRadius: '6px',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.08)',
          transition: 'all 0.2s ease',
          marginBottom: '12px'
        }}
        onClick={() => handlePaymentClick('visa')}
      >
        {loadingStates.visa ? (
          <span style={{ color: "white" }}> Loading...</span>
        ) : (
          <img
            src={visaLogo}
            alt="Visa"
            style={{ height: isMobile ? "24px" : "28px" }}
          />
        )}
      </button>
      <button
        className="mt-3 debit-btn"
        style={{
          fontSize: isMobile ? '42px' : '1.8rem',
          padding: '0',
          width: '100%',
          minHeight: isMobile ? '50px' : '55px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          lineHeight: 'normal',
          backgroundColor: '#ffffff',
          border: '1px solid #ddd',
          borderRadius: '6px',
          cursor: 'pointer',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
          transition: 'all 0.2s ease'
        }}
        onClick={() => handlePaymentClick('debit')}
      >
        {loadingStates.debit ? (
          <span style={{ color: "#333", fontSize: isMobile ? "16px" : "18px" }}> Loading...</span>
        ) : (
          <img
            src={debitLogo}
            alt="Debit"
            style={{ 
              height: isMobile ? "35px" : "44px"
            }}
            className="debit-card"
          />
        )}
      </button>
    </div>
  );
}

export default CustomPayPalButton;