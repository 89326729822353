import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faLock, faBook } from '@fortawesome/free-solid-svg-icons';
import '../Styles/BookCard.css';

const BookCard = ({ title, description, icon, stats, onClick, disabled, comingSoon, categories }) => {
  return (
    <div 
      className={`book-card ${disabled ? 'book-card-disabled' : ''} ${categories ? 'book-card-with-categories' : ''}`} 
      onClick={disabled ? undefined : onClick}
    >
      <div className="book-icon">
        <img src={icon} alt={title} className="book-logo" style={disabled ? { opacity: 0.5 } : {}} />
      </div>
      <div className="book-info">
        <h2>{title}</h2>
        <p>{description}</p>
        {stats && (
          <div className="book-stats">
            {stats.map((stat, index) => (
              <React.Fragment key={stat}>
                <span>{stat}</span>
                {index < stats.length - 1 && <span className="dot">•</span>}
              </React.Fragment>
            ))}
          </div>
        )}
        {categories && (
          <div className="book-categories">
            <h4>Popular Categories:</h4>
            <div className="categories-grid">
              {categories.map((category, index) => (
                <div key={index} className="category-item">
                  <FontAwesomeIcon icon={faBook} className="category-icon" />
                  <div className="category-info">
                    <span className="category-name">{category.name}</span>
                    <span className="category-count">{category.count} books</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {comingSoon && (
          <div className="coming-soon-badge">
            <FontAwesomeIcon icon={faLock} /> Under Construction
          </div>
        )}
      </div>
      <FontAwesomeIcon icon={faCaretRight} className="book-arrow" style={disabled ? { opacity: 0.3 } : {}} />
    </div>
  );
};

export default BookCard;
