import React from 'react';
import { Card, CardContent, CardActionArea, Typography, Box } from '@mui/material';
import { Grid } from '@mui/material';

const LibraryBookCard = ({ book, onClick }) => {
  // Extract author and page count from info
  const getBookDetails = (info) => {
    if (!info) return { author: null, pages: null };
    
    const lines = info.split('\n');
    const authorLine = lines.find(line => line.startsWith('المؤلف:'));
    const pagesLine = lines.find(line => line.startsWith('عدد الصفحات:'));
    
    let author = null;
    if (authorLine) {
      author = authorLine.replace('المؤلف:', '').trim();
      // Extract name before dates if they exist
      const nameMatch = author.match(/(.*?)(?:\s*\(|$)/);
      if (nameMatch) {
        author = nameMatch[1].trim();
      }
    }
    
    let pages = null;
    if (pagesLine) {
      pages = pagesLine.replace('عدد الصفحات:', '').trim();
    }
    
    return { author, pages };
  };

  const { author, pages } = getBookDetails(book.info);

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Card 
        sx={{ 
          height: '100%', 
          display: 'flex', 
          flexDirection: 'column',
          transition: 'all 0.2s ease-in-out',
          borderRadius: 2,
          overflow: 'hidden',
          boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
          '&:hover': {
            transform: 'translateY(-4px)',
            boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
          }
        }}
        className="library-arabic-text"
      >
        <CardActionArea 
          onClick={onClick}
          sx={{ 
            flexGrow: 1, 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'stretch',
            height: '100%',
            p: 1
          }}
        >
          <CardContent sx={{ 
            flexGrow: 1, 
            display: 'flex', 
            flexDirection: 'column', 
            gap: 1.5,
            p: 2 
          }}>
            <Typography 
              gutterBottom 
              variant="h6" 
              component="div" 
              align="right"
              dir="rtl"
              lang="ar"
              sx={{
                fontFamily: '"Amiri", "Noto Naskh Arabic", serif',
                fontSize: '1.3rem',
                fontWeight: 600,
                mb: 1.5,
                lineHeight: 1.5,
                color: '#1a237e'
              }}
            >
              {book.name}
            </Typography>
            
            {author && (
              <Typography 
                variant="body2" 
                color="text.secondary" 
                align="right"
                dir="rtl"
                lang="ar"
                sx={{
                  fontFamily: '"Amiri", "Noto Naskh Arabic", serif',
                  fontSize: '1rem',
                  fontWeight: 500,
                  color: '#455a64',
                  lineHeight: 1.4
                }}
              >
                {author}
              </Typography>
            )}
            
            <Box 
              sx={{ 
                display: 'flex', 
                justifyContent: 'flex-end', 
                alignItems: 'center',
                mt: 'auto',
                pt: 1.5,
                borderTop: '1px solid',
                borderColor: 'rgba(0, 0, 0, 0.08)',
                gap: 2
              }}
            >
              {pages && (
                <Typography 
                  variant="caption" 
                  color="text.secondary"
                  align="right"
                  dir="rtl"
                  lang="ar"
                  sx={{
                    fontFamily: '"Amiri", "Noto Naskh Arabic", serif',
                    fontSize: '0.9rem',
                    color: '#546e7a'
                  }}
                >
                  {pages} صفحة
                </Typography>
              )}
              <Typography 
                variant="caption" 
                color="text.secondary"
                align="right"
                dir="rtl"
                lang="ar"
                sx={{
                  fontFamily: '"Amiri", "Noto Naskh Arabic", serif',
                  fontSize: '0.9rem',
                  color: '#546e7a',
                  backgroundColor: 'rgba(84, 110, 122, 0.1)',
                  px: 1,
                  py: 0.5,
                  borderRadius: 1
                }}
              >
                {book.type === 1 ? 'كتاب مطبوع' : 'كتاب مخطوط'}
              </Typography>
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default LibraryBookCard;
