import { USER_PROFILE_DATA } from "../types/type";

// User Profile Data
export const setUserProfileData = (data) => {
  const action = {
    type: USER_PROFILE_DATA,
    payload: data,
  };
  return action;
};
