import React, { useState } from 'react';
import SimpleFlashCard from './SimpleFlashCard';
import API_BASE_URL from '../../apiConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeadphones, faPlay, faPause, faStop } from '@fortawesome/free-solid-svg-icons';

const AdvancedAudio = ({
  showAdvancedAudio,
  setShowAdvancedAudio,
  isPlayingFullSurah,
  setIsPlayingFullSurah,
  currentAudioId,
  surahDetail
}) => {
  const [fullSurahAudio] = useState(new Audio());
  const [surahAudioQueue, setSurahAudioQueue] = useState([]);
  const [currentAyahIndex, setCurrentAyahIndex] = useState(0);
  const [showingFlashcard, setShowingFlashcard] = useState(false);
  const [currentFlashcardWords, setCurrentFlashcardWords] = useState([]);
  const [currentFlashcardIndex, setCurrentFlashcardIndex] = useState(0);
  const [shownFlashcards, setShownFlashcards] = useState(new Set());
  const [isAudioLoading, setIsAudioLoading] = useState(false);
  const [audioLoadRetries, setAudioLoadRetries] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  // Function to handle transitioning to the next flashcard or next verse
  const handleNextFlashcard = (status) => {
    console.log(`Flashcard clicked: ${currentFlashcardIndex + 1}/${currentFlashcardWords.length}`);
    
    // Add word to shown flashcards to avoid duplicates
    const currentWord = currentFlashcardWords[currentFlashcardIndex];
    if (currentWord && currentWord.word_key) {
      console.log(`Marking flashcard as shown: ${currentWord.word_key}`);
      setShownFlashcards(prev => new Set([...prev, currentWord.word_key]));
    }
    
    const nextIndex = currentFlashcardIndex + 1;
    if (nextIndex < currentFlashcardWords.length) {
      // Continue showing flashcards for the current verse
      console.log(`Moving to next flashcard: ${nextIndex + 1}/${currentFlashcardWords.length}`);
      setCurrentFlashcardIndex(nextIndex);
    } else {
      // All flashcards for current verse are done
      console.log('All flashcards for current verse are done');
      
      // First reset flashcard state BEFORE moving to next verse
      setShowingFlashcard(false);
      setCurrentFlashcardWords([]);
      setCurrentFlashcardIndex(0);
      
      // After all flashcards for this verse are shown, move to the next verse
      const nextAyahIndex = currentAyahIndex + 1;
      if (nextAyahIndex < surahAudioQueue.length) {
        console.log(`All flashcards done, moving to next verse ${nextAyahIndex + 1}`);
        
        // CRITICAL: First update the current ayah index
        setCurrentAyahIndex(nextAyahIndex);
        
        // Load and play the next verse's audio immediately
        console.log('Flashcards dismissed, immediately loading and playing next verse audio');
        loadAndPlayAudio(nextAyahIndex, surahAudioQueue);
      } else {
        console.log('Reached end of surah, stopping playback');
        stopFullSurahAudio();
      }
    }
  };
  
  // New dedicated function to load and play audio AFTER flashcards are dismissed
  const loadAndPlayAudio = async (index, audioQueue) => {
    try {
      console.log(`STEP 1: Loading audio for verse ${index + 1}`);
      
      // Get the audio URL for this verse
      const currentVerse = audioQueue[index];
      const audioUrl = currentVerse?.audio_url;
      
      if (!audioUrl) {
        console.error(`No audio URL for verse ${index + 1}`);
        // If no audio URL, try to move to the next verse instead of just returning
        const nextIndex = index + 1;
        if (nextIndex < audioQueue.length) {
          console.log(`No audio for verse ${index + 1}, trying next verse ${nextIndex + 1}`);
          setCurrentAyahIndex(nextIndex);
          loadAndPlayAudio(nextIndex, audioQueue);
        }
        return;
      }
      
      // Completely reset the audio element
      fullSurahAudio.pause();
      fullSurahAudio.src = '';
      fullSurahAudio.currentTime = 0;
      fullSurahAudio.onended = null;
      fullSurahAudio.onerror = null;
      
      // Now set the new source
      console.log(`STEP 2: Setting source for verse ${index + 1}: ${audioUrl}`);
      fullSurahAudio.src = audioUrl;
      
      // Preload the next audio in the queue to minimize delay
      if (index + 1 < audioQueue.length) {
        const nextAudioUrl = audioQueue[index + 1]?.audio_url;
        if (nextAudioUrl) {
          const nextAudio = new Audio(nextAudioUrl);
          nextAudio.preload = 'auto';
          
          // Also preload the audio after the next one for smoother transitions
          if (index + 2 < audioQueue.length) {
            const nextNextAudioUrl = audioQueue[index + 2]?.audio_url;
            if (nextNextAudioUrl) {
              const nextNextAudio = new Audio(nextNextAudioUrl);
              nextNextAudio.preload = 'auto';
            }
          }
        }
      }
      
      // Wait for audio to load with a more reliable approach
      await new Promise((resolve) => {
        const loadTimeout = setTimeout(() => {
          console.warn(`Audio loading timed out for verse ${index + 1}`);
          resolve();
        }, 5000); // Reduced from 8000ms to 5000ms
        
        // Use both canplaythrough and canplay events to be more robust
        const handleCanPlay = () => {
          clearTimeout(loadTimeout);
          console.log(`STEP 3: Audio loaded for verse ${index + 1}`);
          fullSurahAudio.removeEventListener('canplaythrough', handleCanPlay);
          fullSurahAudio.removeEventListener('canplay', handleCanPlay);
          resolve();
        };
        
        fullSurahAudio.addEventListener('canplaythrough', handleCanPlay);
        fullSurahAudio.addEventListener('canplay', handleCanPlay);
        
        fullSurahAudio.onerror = (e) => {
          clearTimeout(loadTimeout);
          console.error(`Error loading audio for verse ${index + 1}:`, e);
          fullSurahAudio.removeEventListener('canplaythrough', handleCanPlay);
          fullSurahAudio.removeEventListener('canplay', handleCanPlay);
          resolve();
        };
        
        fullSurahAudio.load();
      });
      
      // Set up handler for when this verse's audio finishes
      fullSurahAudio.onended = () => {
        console.log(`Audio ended for verse ${index + 1}`);
        handleVerseAudioEnded(index, audioQueue);
      };
      
      // Finally, play the audio
      console.log(`STEP 4: Playing audio for verse ${index + 1}`);
      try {
        await fullSurahAudio.play();
        console.log(`Successfully started playing verse ${index + 1}`);
      } catch (playError) {
        console.error(`Error playing verse ${index + 1}:`, playError);
        // If play fails, try again immediately
        try {
          console.log(`Retrying playback for verse ${index + 1}`);
          await fullSurahAudio.play();
        } catch (retryError) {
          console.error(`Retry failed for verse ${index + 1}:`, retryError);
        }
      }
    } catch (error) {
      console.error(`Error in loadAndPlayAudio for verse ${index + 1}:`, error);
    }
  };

  const prepareNextVerseWithQueue = async (index, audioQueue) => {
    try {
      console.log(`Preparing next verse: ${index + 1}`);
      // Check if we've reached the end of the playback
      if (!audioQueue || index >= audioQueue.length) {
        console.log("End of taraweeh playback reached");
        stopFullSurahAudio();
        return;
      }
      
      // CRITICAL: Ensure we're using the correct verse index
      const verseIndex = index; // Lock in the correct verse index at the start
      console.log(`Preparing verse ${verseIndex + 1}/${audioQueue.length}`);
      
      // Reset flashcard state to ensure we're starting fresh for this verse
      if (currentFlashcardWords.length > 0) {
        console.log('Resetting flashcard state');
        setCurrentFlashcardWords([]);
        setCurrentFlashcardIndex(0);
      }
      
      // If flashcard is showing from a previous verse, close it
      if (showingFlashcard) {
        console.log('Closing flashcards from previous verse');
        setShowingFlashcard(false);
        
        // If coming from a flashcard, return early - the handleNextFlashcard function 
        // will handle loading and playing the next verse's audio
        return;
      }
      
      // Reset audio states
      if (!fullSurahAudio.paused) {
        console.log('Pausing current audio');
        fullSurahAudio.pause();
      }
      fullSurahAudio.currentTime = 0;
      fullSurahAudio.onended = null;
      fullSurahAudio.onerror = null;
      
      // Only load audio for the current verse if we're NOT showing flashcards
      if (!showingFlashcard) {
        // Get the audio for the current verse
        const currentVerse = audioQueue[index];
        const audioUrl = currentVerse?.audio_url;
        
        if (!audioUrl) {
          console.error(`No audio URL for verse ${index + 1}`);
          // Skip to next verse
          setCurrentAyahIndex(index + 1);
          prepareNextVerseWithQueue(index + 1, audioQueue);
          return;
        }
        
        // Reset and play the audio
        try {
          console.log(`Preparing to play verse ${index + 1} directly`);
          loadAndPlayAudio(index, audioQueue);
        } catch (err) {
          console.error('Error playing audio:', err);
        }
      } // End of if (!showingFlashcard)
    } catch (error) {
      console.error('Error in prepareNextVerseWithQueue:', error);
      // Try to recover by moving to next verse
      setCurrentAyahIndex(index + 1);
      prepareNextVerseWithQueue(index + 1, audioQueue);
    }
  };

  const handleVerseAudioEnded = async (index, audioQueue) => {
    try {
      // Get flashcards from the current verse
      const verseFlashcards = audioQueue[index]?.flashcards || [];
      
      // Show all flashcards for the verse
      if (verseFlashcards.length > 0) {
        // Show flashcards AFTER playing the audio
        setCurrentFlashcardWords(verseFlashcards);
        setCurrentFlashcardIndex(0);
        setShowingFlashcard(true);
      } else {
        // No flashcards for this verse, move to next verse immediately
        const nextIndex = index + 1;
        if (nextIndex < audioQueue.length) {
          setCurrentAyahIndex(nextIndex);
          loadAndPlayAudio(nextIndex, audioQueue);
        } else {
          stopFullSurahAudio();
        }
      }
    } catch (error) {
      console.error('Error in handleVerseAudioEnded:', error);
    }
  };

  const playFullSurahAudio = async () => {
    try {
      // If audio is paused, resume playback
      if (isPaused) {
        console.log('Resuming paused audio');
        setIsPaused(false);
        await fullSurahAudio.play();
        return;
      }
      
      const surahNumber = surahDetail?.current_surah;
      if (!surahNumber) {
        console.error("No surah number available");
        return;
      }
      
      console.log('Starting Taraweeh mode for surah:', surahNumber);
  
      setIsAudioLoading(true);
      setShowAdvancedAudio(true);
      setIsPlayingFullSurah(true);
      
      // Reset audio states
      if (!fullSurahAudio.paused) {
        fullSurahAudio.pause();
      }
      fullSurahAudio.currentTime = 0;
      setSurahAudioQueue([]);
      setCurrentAyahIndex(0);
      
      // Clear previously shown flashcards to ensure a fresh start every time play is clicked
      console.log('Clearing shown flashcards set and resetting all flashcard states');
      setShownFlashcards(new Set());
      
      // Completely reset all flashcard-related state
      setShowingFlashcard(false);
      setCurrentFlashcardWords([]);
      setCurrentFlashcardIndex(0);
      setAudioLoadRetries(0);
      
      // Use the dedicated Taraweeh mode endpoint to get all data at once
      console.log(`Fetching Taraweeh mode data for surah ${surahNumber}`);
      const response = await fetch(`${API_BASE_URL}/user_dashboard/get_taraweeh_mode_data`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem("access_token") || `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          surah_number: surahNumber,
          audio_id: currentAudioId || 1  // Default to 1 if not provided
        }),
      });

      if (!response.ok) throw new Error(`Failed to get taraweeh data: ${response.statusText}`);
      const taraweehData = await response.json();
      
      if (!taraweehData.verses || taraweehData.verses.length === 0) {
        throw new Error("No verses found for taraweeh mode");
      }

      console.log(`Received taraweeh data with ${taraweehData.verses.length} verses`);

      // Create audio queue from the taraweeh data
      const audioQueue = taraweehData.verses.map(verse => ({
        audio_url: verse.audio_url,
        duration: verse.duration,
        segments: verse.segments,
        ayah_number: verse.ayah_number,
        verse_key: verse.verse_key,
        flashcards: verse.flashcards || []
      }));
      
      // Count total flashcards for debugging
      const totalFlashcards = audioQueue.reduce((total, verse) => {
        if (verse.flashcards && verse.flashcards.length > 0) {
          console.log(`Verse ${verse.verse_key} has ${verse.flashcards.length} flashcards:`, verse.flashcards);
          return total + verse.flashcards.length;
        }
        return total;
      }, 0);
      
      console.log(`Processed ${audioQueue.length} verses with ${totalFlashcards} total flashcards`);
      setSurahAudioQueue(audioQueue);
      
      // Preload the first few audio files to minimize delays
      console.log('Preloading first few audio files');
      const preloadCount = Math.min(5, audioQueue.length);
      for (let i = 0; i < preloadCount; i++) {
        const audioUrl = audioQueue[i]?.audio_url;
        if (audioUrl) {
          const preloadAudio = new Audio(audioUrl);
          preloadAudio.preload = 'auto';
          preloadAudio.load();
        }
      }

      // Start playback with the first verse
      const firstAudioUrl = audioQueue[0]?.audio_url;
      if (!firstAudioUrl) {
        throw new Error("No audio URL for first verse");
      }
      
      console.log(`Loading first audio: ${firstAudioUrl}`);
      fullSurahAudio.src = firstAudioUrl;
      
      await new Promise((resolve, reject) => {
        const loadTimeout = setTimeout(() => {
          console.warn("Audio loading timed out, continuing anyway");
          resolve();
        }, 5000); // Reduced from 8000ms to 5000ms
        
        fullSurahAudio.oncanplaythrough = () => {
          clearTimeout(loadTimeout);
          console.log("First audio loaded successfully");
          resolve();
        };
        
        fullSurahAudio.onerror = (e) => {
          clearTimeout(loadTimeout);
          console.error("Audio loading error:", e);
          resolve();
        };
        
        fullSurahAudio.load();
      });
      
      // Set up handler for when first verse audio ends
      fullSurahAudio.onended = () => {
        handleVerseAudioEnded(0, audioQueue);
      };
      
      setIsAudioLoading(false);
      
      console.log("Starting playback of first verse");
      await fullSurahAudio.play();
      
    } catch (error) {
      console.error("Error in playFullSurahAudio:", error);
      
      if (audioLoadRetries < 2) {
        console.log(`Retrying playback (attempt ${audioLoadRetries + 1})`);
        setAudioLoadRetries(prev => prev + 1);
        setTimeout(() => playFullSurahAudio(), 1000);
      } else {
        setIsAudioLoading(false);
        stopFullSurahAudio();
        alert("Sorry, there was an error playing the audio. Please try again.");
      }
    }
  };

  const pauseFullSurahAudio = () => {
    if (fullSurahAudio && !fullSurahAudio.paused) {
      console.log('Pausing audio playback');
      fullSurahAudio.pause();
      setIsPaused(true);
    }
  };

  const stopFullSurahAudio = () => {
    fullSurahAudio.pause();
    fullSurahAudio.currentTime = 0;
    setIsPlayingFullSurah(false);
    setShowAdvancedAudio(false);
    setCurrentAyahIndex(0);
    setShowingFlashcard(false);
    setCurrentFlashcardWords([]);
    setCurrentFlashcardIndex(0);
    setIsPaused(false);
  };

  if (!showAdvancedAudio) {
    return (
      <button
        onClick={() => setShowAdvancedAudio(true)}
        style={{
          padding: '8px 16px',
          borderRadius: '20px',
          background: '#FFA500',
          color: 'white',
          border: 'none',
          cursor: 'pointer',
          fontSize: '14px',
          fontWeight: '500',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          transition: 'all 0.2s ease',
        }}
      >
        <FontAwesomeIcon icon={faHeadphones} /> Taraweeh Mode
      </button>
    );
  }

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'black',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1000,
    }}>
      <div style={{
        color: 'white',
        textAlign: 'center',
        marginBottom: '2rem'
      }}>
        <h2 style={{ marginBottom: '1rem' }}>Taraweeh Mode</h2>
        <p>The audio plays the full surah and integrates your dictionary words into flashcards.</p>
        
        {isAudioLoading && (
          <div style={{ marginTop: '1rem' }}>
            <div style={{ 
              display: 'inline-block',
              width: '30px',
              height: '30px',
              margin: '0 auto',
              border: '4px solid rgba(255,255,255,0.3)',
              borderRadius: '50%',
              borderTopColor: 'white',
              animation: 'spin 1s ease-in-out infinite'
            }} />
            <p style={{ marginTop: '0.5rem' }}>Loading audio...</p>
            <style>{`
              @keyframes spin {
                from { transform: rotate(0deg); }
                to { transform: rotate(360deg); }
              }
            `}</style>
          </div>
        )}
      </div>
      
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '1rem' }}>
        {isPlayingFullSurah && !isPaused ? (
          <>
            <button
              onClick={pauseFullSurahAudio}
              disabled={isAudioLoading}
              style={{
                padding: '12px 24px',
                borderRadius: '25px',
                background: isAudioLoading ? '#666' : '#FFA500',
                color: 'white',
                border: 'none',
                cursor: isAudioLoading ? 'default' : 'pointer',
                fontSize: '16px',
                fontWeight: '500',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                opacity: isAudioLoading ? 0.7 : 1,
                marginRight: '10px'
              }}
            >
              <FontAwesomeIcon icon={faPause} />
              Pause
            </button>
            <button
              onClick={stopFullSurahAudio}
              disabled={isAudioLoading}
              style={{
                padding: '12px 24px',
                borderRadius: '25px',
                background: isAudioLoading ? '#666' : '#FF4444',
                color: 'white',
                border: 'none',
                cursor: isAudioLoading ? 'default' : 'pointer',
                fontSize: '16px',
                fontWeight: '500',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                opacity: isAudioLoading ? 0.7 : 1,
              }}
            >
              <FontAwesomeIcon icon={faStop} />
              Stop
            </button>
          </>
        ) : (
          <div style={{ display: 'flex', gap: '10px' }}>
            <button
              onClick={playFullSurahAudio}
              disabled={isAudioLoading}
              style={{
                padding: '12px 24px',
                borderRadius: '25px',
                background: isAudioLoading ? '#666' : '#4CAF50',
                color: 'white',
                border: 'none',
                cursor: isAudioLoading ? 'default' : 'pointer',
                fontSize: '16px',
                fontWeight: '500',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                opacity: isAudioLoading ? 0.7 : 1,
              }}
            >
              <FontAwesomeIcon icon={faPlay} />
              {isPaused ? 'Resume' : 'Play'}
            </button>
            
            {isPaused && (
              <button
                onClick={stopFullSurahAudio}
                disabled={isAudioLoading}
                style={{
                  padding: '12px 24px',
                  borderRadius: '25px',
                  background: isAudioLoading ? '#666' : '#FF4444',
                  color: 'white',
                  border: 'none',
                  cursor: isAudioLoading ? 'default' : 'pointer',
                  fontSize: '16px',
                  fontWeight: '500',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  opacity: isAudioLoading ? 0.7 : 1,
                }}
              >
                <FontAwesomeIcon icon={faStop} />
                Stop
              </button>
            )}
          </div>
        )}
      </div>

      <button
        onClick={() => {
          if (isPlayingFullSurah) {
            stopFullSurahAudio();
          }
          setShowAdvancedAudio(false);
        }}
        style={{
          padding: '8px 16px',
          background: 'transparent',
          color: 'white',
          border: '1px solid white',
          borderRadius: '20px',
          cursor: 'pointer',
          fontSize: '14px'
        }}
      >
        Close
      </button>

      {showingFlashcard && currentFlashcardWords.length > 0 && (
        <SimpleFlashCard
          word={currentFlashcardWords[currentFlashcardIndex]}
          onClose={() => {
            console.log('Flashcard closed early, proceeding to current verse audio');
            setShowingFlashcard(false);
            setCurrentFlashcardWords([]);
            setCurrentFlashcardIndex(0);
            
            // Now play the audio for this verse since flashcards are done
            console.log('Playing audio for verse after flashcard was closed');
            if (fullSurahAudio && fullSurahAudio.readyState >= 2) {
              fullSurahAudio.play().catch(err => console.error('Error playing audio:', err));
            } else {
              console.log('Audio not ready yet, will retry');
              setTimeout(() => {
                if (fullSurahAudio && fullSurahAudio.readyState >= 2) {
                  fullSurahAudio.play().catch(err => console.error('Error playing audio after delay:', err));
                }
              }, 500);
            }
          }}
          onSelect={handleNextFlashcard}
        />
      )}
    </div>
  );
};

export default AdvancedAudio;