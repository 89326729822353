import React, { useEffect, useState } from 'react';
import { ImagePath } from '../Constant/ImgPath';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import API_BASE_URL from '../apiConfig';

const User = () => {
    const [UserData, setUserData] = useState();

    const UserCalling = async () => {
        const UserUrl = `${API_BASE_URL}/admin/admin_dashboard`
        await fetch(UserUrl, {
            method:  'GET',
            headers: {Authorization: `${localStorage.getItem('access_token')}`}
        }).then((response) => response.json()).then((data) => {
            // console.log("data printing",data)
            setUserData(data)

        })
        // console.log("adasdas",UserUrl)
    }
    useEffect(() => {

            UserCalling()

    }, []);

    const User = {
        User: {
            width: "95%",
        },
        arrows: {
            borderRadius: "10px",
            border: "1px solid rgb(89 89 89 / 59%)9",
            background: "#D9D9D9",
            padding: "10px",
            color: "#595959",
            cursor: "pointer"
        },
        arrowstext: {
            borderRadius: "10px",
            border: "1px solid #091B2E",
            background: "#091B2E",
            padding: "10px",
            color: "#fff",
            width: "40px",
            textAlign: "center"
        }
    }

    return (
        <div style={User.User} className='user-responsive'>
            <div className='admin-user'>
                <div className='d-flex w-100 ' style={{ padding: "44px 20px 0" }} >
                    <div className='w-50 m-0 text-center d-flex'>
                        <input type='checkbox' />
                        <p className='w-50 m-0 text-center' >Name </p>
                    </div>
                    <p className='w-50 m-0 text-center' >Email Address</p>
                    <p className='w-50 m-0 text-center' >Joining Date</p>
                    <p className='w-50 m-0 text-center' >Username</p>
                    <p className='w-50 m-0 text-center' >Plan</p>
                </div>
                <hr />
                {UserData?.map((user, index) => (
                    <div key={index} className='d-flex align-items-center font-user' style={{ padding: "10px 20px" }}>
                        <input type='checkbox' />
                        <div className='user-profile-name align-items-center'>
                            {/* <img src={user.imagePath.user} alt="Image" /> */}
                            <div>
                            {user.username}
                            </div>
                        </div>
                        <div className='w-50 m-0 text-left'>{user.email}</div>
                        <div className='w-50 m-0 text-center'>{user.joining_date.slice(0, 16)}</div>
                        <div className='w-50 m-0 text-center'>{user.username}</div>
                        <div className='w-50 m-0 text-center'>{user.plan_name}</div>
                        

        
                    </div>
                ))}
                <div>
                    <div style={{ display: "flex", gap: "11px", marginTop: "40px",  padding: "0 40px 35px", justifyContent:"end"}}>
                        <div style={User.arrows}><FontAwesomeIcon icon={faAngleLeft} /></div>
                        <div style={User.arrowstext}>1</div>
                        <div style={User.arrows}><FontAwesomeIcon icon={faAngleRight} /></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default User;
