import React from 'react';
import ButtonGroup from './ButtonGroup';
import ArabicVerse from './ArabicVerse';
import { useVersesAudio } from './useVersesAudio';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark as fasBookmark } from '@fortawesome/free-solid-svg-icons';
import { faBookmark as farBookmark } from '@fortawesome/free-regular-svg-icons';
import API_BASE_URL from "../../apiConfig";
import './VerseContainer.css';

const VerseContainer = ({
  verses,
  pageNumber,
  startingVerseNumber = 0,
  currentlyPlayingVerse,
  currentWordIndex,
  hideDefinition,
  includeLearned,
  localStorageVal,
  surahDetail,
  showtr,
  setShowtr,
  handleclickcheck,
  getFullSurahAPI,
  markAllWordsAsKnown,
  setIndexnumber,
  setShowBox,
  setSurahpop,
  setWordSelectedindex,
  currentAudioId,
  isMushafView,
  isMarkAllLoading, // Add this prop to receive loading state
  loadingVerseIndex // Add this prop to track which verse is loading
}) => {
  const [bookmarks, setBookmarks] = React.useState({});

  // Add function to fetch bookmarks
  const fetchBookmarks = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/user_dashboard/verse_bookmarks`, {
        headers: {
          'Authorization': localStorage.getItem("access_token"),
        }
      });

      if (response.ok) {
        const data = await response.json();
        const bookmarksMap = {};
        data.forEach(bookmark => {
          bookmarksMap[bookmark.verse_key] = bookmark;
        });
        setBookmarks(bookmarksMap);
      }
    } catch (error) {
      console.error('Error fetching bookmarks:', error);
    }
  };

  // Add function to toggle bookmark
  const toggleBookmark = async (verseKey, surahId, pageNumber) => {
    try {
      const existingBookmark = bookmarks[verseKey];
      
      if (existingBookmark) {
        // Delete bookmark
        const response = await fetch(`${API_BASE_URL}/user_dashboard/verse_bookmarks/${existingBookmark.id}`, {
          method: 'DELETE',
          headers: {
            'Authorization': localStorage.getItem("access_token"),
          }
        });

        if (response.ok) {
          const newBookmarks = { ...bookmarks };
          delete newBookmarks[verseKey];
          setBookmarks(newBookmarks);
        }
      } else {
        // Add bookmark
        const response = await fetch(`${API_BASE_URL}/user_dashboard/verse_bookmarks`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem("access_token"),
          },
          body: JSON.stringify({
            surah_id: surahId,
            verse_key: verseKey,
            page_number: pageNumber
          })
        });

        if (response.ok) {
          const data = await response.json();
          setBookmarks({
            ...bookmarks,
            [verseKey]: {
              id: data.id,
              surah_id: surahId,
              verse_key: verseKey,
              page_number: pageNumber
            }
          });
        }
      }
    } catch (error) {
      console.error('Error toggling bookmark:', error);
    }
  };

  // Add useEffect to fetch bookmarks on mount
  React.useEffect(() => {
    fetchBookmarks();
  }, []);

  const handlePlayStart = (verseIndex) => {
    setIndexnumber(verseIndex);
  };

  const handlePlayEnd = () => {
    setIndexnumber(-1);
  };

  const handleWordChange = (wordIndex) => {
    setWordSelectedindex(wordIndex);
  };

  const audioStates = useVersesAudio({
    verses,
    startingVerseNumber,
    surahNumber: surahDetail.current_surah,
    currentlyPlayingVerse,
    onPlayStart: handlePlayStart,
    onPlayEnd: handlePlayEnd,
    onWordChange: handleWordChange,
    currentAudioId // Pass the currentAudioId to useVersesAudio
  });

  // Group words by line number for Mushaf view
  const getWordsGroupedByLine = () => {
    const lineGroups = {};
    verses.forEach((verse) => {
      verse.forEach((word) => {
        if (!lineGroups[word.line]) {
          lineGroups[word.line] = [];
        }
        lineGroups[word.line].push(word);
      });
    });
    return Object.entries(lineGroups).sort((a, b) => parseInt(a[0]) - parseInt(b[0]));
  };

  if (isMushafView) {
    const lineGroups = getWordsGroupedByLine();
    return (
      <div id={`page-${pageNumber}`} className="page-section" style={{ scrollMarginTop: '100px' }}>
        {pageNumber > 1 && (
          <div className="page-divider" style={{ 
            position: 'absolute',
            top: '1rem',
            left: 0,
            right: 0,
            height: '2px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <hr style={{ 
              width: '100%',
              border: 'none',
              height: '2px',
              backgroundColor: '#eee',
              margin: 0
            }} />
            <div style={{
              position: 'absolute',
              backgroundColor: 'white',
              padding: '0.5rem 1rem',
              borderRadius: '4px',
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
              color: '#666'
            }}>
              Page {pageNumber}
            </div>
          </div>
        )}
        
        {lineGroups.map(([lineNumber, words]) => (
          <div key={lineNumber} className="mushaf-line" style={{
            direction: 'rtl',
            marginBottom: '1.5rem',
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            gap: '0.5rem',
            alignItems: 'center'
          }}>
            {words.map((word, idx) => (
              <div 
                key={`${word.key}-${idx}`}
                onClick={() => {
                  setShowBox(true);
                  setSurahpop(word);
                  const verseIndex = verses.findIndex(v => v.some(w => w.key === word.key));
                  setIndexnumber(verseIndex);
                  const wordIndex = verses[verseIndex].findIndex(w => w.key === word.key);
                  setWordSelectedindex(wordIndex);
                }}
                style={{
                  cursor: 'pointer',
                  padding: '0.25rem',
                  borderRadius: '4px',
                  background: word.status === "Learned" ? "#fcd966" : "",
                  transition: 'all 0.2s ease'
                }}
              >
                <div className="arabic-verse__text" style={{
                  fontSize: '28px',
                  fontFamily: 'ayat-font',
                  textAlign: 'center'
                }}>
                  {word.word}
                </div>
                {/* Handle translations differently for Learned vs non-Learned words */}
                {word.status === "Learned" ? (
                  // For Learned words, only show if Hide Learning is off
                  !hideDefinition && !includeLearned && (
                    <div style={{
                      fontSize: '11px',
                      textAlign: 'center',
                      color: '#666'
                    }}>
                      {word.translation}
                    </div>
                  )
                ) : (
                  // For non-Learned words, follow hideDefinition (Hide/Display word-by-word)
                  !hideDefinition && (
                    <div style={{
                      fontSize: '11px',
                      textAlign: 'center',
                      color: '#666'
                    }}>
                      {word.translation}
                    </div>
                  )
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  }

  return (
    <div 
      id={`page-${pageNumber}`}
      key={pageNumber} 
      className="page-section" 
      style={{ 
        position: 'relative', 
        paddingTop: pageNumber > 1 ? '3rem' : 0,
        scrollMarginTop: '100px'
      }}
    >
      {pageNumber > 1 && (
        <div className="page-divider" style={{ 
          position: 'absolute',
          top: '1rem',
          left: 0,
          right: 0,
          height: '2px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <hr style={{ 
            width: '100%',
            border: 'none',
            height: '2px',
            backgroundColor: '#eee',
            margin: 0
          }} />
          <div style={{
            position: 'absolute',
            backgroundColor: 'white',
            padding: '0.5rem 1rem',
            borderRadius: '4px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            color: '#666'
          }}>
            Page {pageNumber}
          </div>
        </div>
      )}
      
      {verses.map((ayahWords, idx) => {
        const absoluteVerseNumber = startingVerseNumber + idx + 1;
        const { isPlaying, togglePlay } = audioStates[idx];
        const verseKey = ayahWords[0]?.key;
        
        return (
          <div key={idx} className="verse-container" style={{ 
            marginBottom: "2rem",
            position: "relative" 
          }}>
            <div style={{
              position: "absolute",
              right: "-40px",
              top: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "8px"
            }}>
              <div className="verse-number-container" style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "8px",
                padding: "8px",
                backgroundColor: "#fff",
                borderRadius: "8px",
                boxShadow: "0 2px 4px rgba(0,0,0,0.05)"
              }}>
                <div style={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  backgroundColor: "#f0f0f0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "14px",
                  color: "#666",
                  fontWeight: "500"
                }}>
                  {absoluteVerseNumber}
                </div>
                <FontAwesomeIcon
                  icon={bookmarks[verseKey] ? fasBookmark : farBookmark}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleBookmark(verseKey, surahDetail.current_surah, pageNumber);
                  }}
                  style={{
                    cursor: 'pointer',
                    fontSize: '16px',
                    color: bookmarks[verseKey] ? '#338FFF' : '#666',
                    transition: 'all 0.2s ease'
                  }}
                />
              </div>
            </div>
            
            <div className="ayah-content">
              <ArabicVerse 
                words={ayahWords}
                showTranslation={!hideDefinition}
                includeLearned={includeLearned}
                onWordClick={(data, wordIndex) => {
                  setShowBox(true);
                  setSurahpop(data);
                  setWordSelectedindex(wordIndex);
                  setIndexnumber(absoluteVerseNumber - 1);
                }}
                verseKey={ayahWords[0]?.key}
                currentWordIndex={currentlyPlayingVerse === absoluteVerseNumber - 1 ? currentWordIndex : -1}
              />
            </div>

            {showtr[ayahWords[0]?.key] && surahDetail?.[localStorageVal]?.[ayahWords[0]?.key] && (
              <div className="ayah-translation" style={{
                margin: "1rem 0",
                textAlign: "center",
                color: "#666"
              }}>
                {surahDetail[localStorageVal][ayahWords[0]?.key]}
              </div>
            )}
            
            <ButtonGroup 
              player={!isPlaying}
              togglePlay={togglePlay}
              knowWords={ayahWords.every(word => 
                word.status === "Known" || 
                word.status === "Familiar" || 
                word.status === "Learned"
              )}
              handleMoveToKnown={(e) => {
                // Prevent default behavior
                e && e.preventDefault();
                // Set the current verse index before marking words as known
                setIndexnumber(idx);
                // Call the markAllWordsAsKnown function with the current verse and its index
                markAllWordsAsKnown(ayahWords, idx);
              }}
              onViewModeToggle={() => {
                const translationKey = ayahWords[0]?.key;
                if (translationKey) {
                  setShowtr(prev => ({
                    ...prev,
                    [translationKey]: !prev[translationKey]
                  }));
                }
              }}
              newWordsCount={ayahWords.filter(word => 
                word.status === "New" || 
                word.status === "Unknown"
              ).length}
              isSentenceView={!showtr[ayahWords[0]?.key]}
              isMarkAllLoading={isMarkAllLoading} // Global loading state for all buttons
              isActiveLoading={isMarkAllLoading && loadingVerseIndex === idx} // Track which button is actively loading
              verseIndex={idx} // Pass the verse index
            />
          </div>
        );
      })}
    </div>
  );
};

export default VerseContainer;