import React, { useMemo } from 'react';
import { Box, Typography, CircularProgress, Button } from '@mui/material';

// Add the missing components
const LoadingComponent = () => (
  <Box sx={{ 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    minHeight: '50vh' 
  }}>
    <CircularProgress />
  </Box>
);

const ErrorComponent = ({ error, onRetry }) => (
  <Box sx={{ 
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'center', 
    gap: 2, 
    minHeight: '50vh',
    justifyContent: 'center'
  }}>
    <Typography color="error" sx={{ textAlign: 'center' }}>
      {error}
    </Typography>
    <Button variant="contained" onClick={onRetry}>
      Try Again
    </Button>
  </Box>
);

const NoContentComponent = () => (
  <Box sx={{ 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    minHeight: '50vh' 
  }}>
    <Typography sx={{ 
      color: 'text.secondary',
      fontFamily: '"Noto Naskh Arabic", serif'
    }}>
      لا يوجد محتوى لهذه الصفحة
    </Typography>
  </Box>
);

const BookPageContent = ({ 
  isLoading,
  error,
  book,
  pageContents,
  currentPage,
  handlePageChange,
  handleWordSelect,
  learningWords
}) => {
  // Get the current page content
  const rawContent = pageContents[currentPage]?.text;
  
  // Process the content and mark title portions
  const processedContent = useMemo(() => {
    if (!rawContent) return { lines: [] };
    
    // First, normalize the content by handling escaped newlines
    const normalizedContent = rawContent.replace(/\\n/g, '\n');
    
    // Insert special markers around span content to keep track of what was in spans
    let markedContent = normalizedContent;
    
    // Replace span tags with markers - use markers that won't appear in Arabic text
    markedContent = markedContent.replace(/<span.*?>([\s\S]*?)<\/span>/g, '§TITLE_START§$1§TITLE_END§');
    
    // Split into lines
    const processedLines = markedContent.split('\n').map(line => {
      // Process each line to create spans
      let result = [];
      let currentIndex = 0;
      let inTitle = false;
      let currentWord = '';
      
      // Handle the case where a title starts in a previous line
      if (line.indexOf('§TITLE_START§') === -1 && line.indexOf('§TITLE_END§') !== -1) {
        inTitle = true;
      }
      
      for (let i = 0; i < line.length; i++) {
        const char = line[i];
        
        // Check for title start marker
        if (line.slice(i, i + 13) === '§TITLE_START§') {
          // Push current word if any
          if (currentWord) {
            result.push({ text: currentWord, isTitle: inTitle });
            currentWord = '';
          }
          inTitle = true;
          i += 12; // Skip the marker
          continue;
        }
        
        // Check for title end marker
        if (line.slice(i, i + 11) === '§TITLE_END§') {
          // Push current word if any
          if (currentWord) {
            result.push({ text: currentWord, isTitle: inTitle });
            currentWord = '';
          }
          inTitle = false;
          i += 10; // Skip the marker
          continue;
        }
        
        // Handle space - push current word and start a new one
        if (char === ' ') {
          if (currentWord) {
            result.push({ text: currentWord, isTitle: inTitle });
            currentWord = '';
          }
          result.push({ text: ' ', isTitle: inTitle });
        } else {
          currentWord += char;
        }
      }
      
      // Push any remaining word
      if (currentWord) {
        result.push({ text: currentWord, isTitle: inTitle });
      }
      
      return result;
    });

    return { lines: processedLines };
  }, [rawContent]);

  if (isLoading) return <LoadingComponent />;
  if (error) return <ErrorComponent error={error} onRetry={() => handlePageChange(null, currentPage)} />;
  if (!rawContent) return <NoContentComponent />;

  return (
    <Box sx={{ 
      lineHeight: 2,
      fontSize: { xs: '1rem', sm: '1.2rem' },
      textAlign: 'justify',
      fontFamily: '"Noto Naskh Arabic", serif',
      '& p': { 
        marginBottom: '1.5rem',
        textIndent: '1rem',
      }
    }}>
      <Box sx={{ 
        fontFamily: '"Noto Naskh Arabic", serif !important',
        fontSize: { xs: '1.2rem', sm: '1.5rem' },
        lineHeight: 1.8,
        letterSpacing: '0.01em',
        '& p': { 
          marginBottom: '1.5rem',
          textIndent: '1rem',
        }
      }}>
        {processedContent.lines.map((lineItems, lineIndex) => (
          <React.Fragment key={lineIndex}>
            {lineItems.length > 0 && (
              <Box 
                sx={{
                  display: 'block',
                  clear: 'both',
                  padding: '0 6px'
                }}
              >
                <Typography 
                  component="div" 
                  dir="rtl"
                  sx={{
                    fontFamily: '"Noto Naskh Arabic", serif !important',
                    fontSize: { xs: '1.2rem', sm: '1.5rem' },
                    lineHeight: 2.9,
                    textAlign: 'justify',
                    '& span': {
                      cursor: 'pointer',
                      '&:hover': {
                        color: 'primary.main',
                      }
                    }
                  }}
                >
                  {lineItems.map((item, itemIndex) => {
                    // Skip spaces in word selection
                    const isSelectableWord = item.text !== ' ';
                    
                    // Check if this is a learned word AND has "Learned" status (not "Known")
                    const learningWord = isSelectableWord && learningWords.find(learningWord => 
                      wordMatches(learningWord.word_original, item.text)
                    );
                    
                    // Only highlight words with "Learned" status
                    const isLearned = learningWord && learningWord.status === 'Learned';

                    if (item.text === ' ') {
                      return <span key={itemIndex}> </span>;
                    }
                    
                    return (
                      <span
                        key={itemIndex}
                        onClick={() => isSelectableWord && handleWordSelect(item.text)}
                        className={isLearned ? 'learned-word' : ''}
                        style={{
                          marginLeft: item.text === ' ' ? 0 : '0.35em',
                          display: 'inline-block',
                          position: 'relative',
                          cursor: isSelectableWord ? 'pointer' : 'default'
                        }}
                      >
                        <span style={{
                          position: 'relative',
                          display: 'inline-block',
                          fontWeight: item.isTitle ? 700 : 'inherit',
                          fontSize: item.isTitle ? '1.2em' : 'inherit',
                          fontFamily: item.isTitle ? '"Amiri", "Noto Naskh Arabic", serif' : '"Noto Naskh Arabic", serif'
                        }}>
                          {item.text}
                          <span style={{
                            position: 'absolute',
                            bottom: '10px',
                            left: 0,
                            right: 0,
                            height: '2px',
                            backgroundColor: isLearned ? '#fcd966' : 'transparent'
                          }}></span>
                        </span>
                        {isLearned && learningWord?.word_english?.[0] && (
                          <span style={{
                            position: 'absolute',
                            top: '100%',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            fontSize: '0.6em',
                            color: '#666',
                            whiteSpace: 'nowrap',
                            fontFamily: 'system-ui, -apple-system, sans-serif',
                            marginTop: '-18px'
                          }}>
                            {learningWord.word_english[0]}
                          </span>
                        )}
                      </span>
                    );
                  })}
                </Typography>
              </Box>
            )}
            {lineIndex < processedContent.lines.length - 1 && lineItems.length > 0 && (
              <div style={{ height: '1.5em' }} />
            )}
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
};

export const wordMatches = (originalWords, targetWord) => {
  if (!originalWords || !Array.isArray(originalWords)) return false;

  // Function to remove diacritics and non-Arabic characters
  const cleanArabicText = (text) => {
    if (!text) return '';
    
    // First remove all diacritics, including fatha, kasra, damma, sukun, shadda
    // Also handle all tanween forms (double damma, double fatha, double kasra)
    // Unicode ranges:
    // - Basic Arabic diacritics: \u064B-\u065F
    // - Superscript alef: \u0670
    // - Arabic comma: \u060C
    // - Arabic semicolon: \u061B
    // - Arabic question mark: \u061F
    // - Arabic full stop: \u06D4
    const withoutDiacriticsAndPunctuation = text.replace(/[\u064B-\u065F\u0670\u060C\u061B\u061F\u06D4]/g, '');
    
    // Then remove non-Arabic characters
    return withoutDiacriticsAndPunctuation.replace(/[^\u0600-\u06FF]/g, '');
  };
  
  // Clean only the target word (from UI) since database words are already clean
  const cleanTarget = cleanArabicText(targetWord);
  
  // For debugging
  console.log('Target word:', targetWord, '→ Cleaned:', cleanTarget);
  
  return originalWords.some(originalWord => {
    // For debugging
    console.log('Comparing with DB word:', originalWord);
    
    // Compare the cleaned target with each original word (which is already clean)
    return originalWord === cleanTarget;
  });
};

export default BookPageContent;
