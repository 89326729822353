import React, { useState } from 'react';
import { ImagePath } from '../Constant/ImgPath';

const Footer = () => {

    const Footer = {
        footerBox: {
            height: "auto",
            background: "#000",
            width: "100%",
            padding: "20px 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        footerLogo: {
            maxWith: "40px",
            height: "40px",
            marginRight: "10px",
            cursor: "pointer"
        },
        footerMedia: {
            margin: "10px 0",
            display: "none"
        },
        footerMediaIcon: {
            height: "20px",
            margin: "0 10px",
            cursor: "pointer"
        },
        h4: {
            color: "#FFF",
            fontSize: "16px",
            fontWeight: "500",
            margin: "10px 0"
        },
        p: {
            color: "#FFF",
            fontSize: "14px",
            fontWeight: "400",
            margin: "5px 0"
        },
        a: {
            color: "#2F7EF5"
        },
        textarea: {
            textAlign: "center",
            marginLeft: "20px",
            maxWidth: "400px"
        },
        CopyrightArea: {
            textAlign: "center",
            marginTop: "10px",
            width: "100%"
        },
        CopyrightAreaP: {
            color: "#FFF",
            fontSize: "14px",
            fontWeight: "400",
            margin: "0"
        }
    }
    return (
        <div style={Footer.footerBox}>
            <img className='footer-logo' style={Footer.footerLogo} src={ImagePath.footerlogo} alt="logo" />
            <div className='CopyrightArea' style={Footer.CopyrightArea}>
                <hr />
                <p style={Footer.CopyrightAreaP}>Copyright © 2024 Link Quran - All Rights Reserved.</p>
            </div>
        </div>
    );
};

export default Footer;