import React from 'react';
import {
  Box,
  Typography,
  Paper,
  Button,
  Chip,
  TextField,
  Dialog,
  IconButton,
  CircularProgress
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBook,
  faSpinner,
  faSave,
  faCheck,
  faUndo,
  faLanguage,
  faEdit
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const BookReaderCard = ({
  selectedWord,
  onClose,
  dictionaryLoading,
  userTranslation,
  setUserTranslation,
  userTranslations,
  setUserTranslations,

  wordLearningStatus,
  isLearningLoading,
  isKnownLoading,
  wordExistsAsLearned,
  markWordAsLearning,
  markWordAsKnown,
  resetWordLearningStatus,
  openKsaaDictionary,
  openDictionary,
  isWordLearned
}) => {
  const navigate = useNavigate();

  // Enhanced version of markWordAsLearning that handles the subscription prompt
  const handleMarkAsLearning = async () => {
    try {
      // Call the original markWordAsLearning function and capture the response
      const result = await markWordAsLearning();
      
      // Comment out these lines to disable premium check
      // if (result && result.status === 403) {
      //   showSubscriptionPrompt();
      // }
    } catch (error) {
      // Comment out these lines to disable premium check
      // if (error && (error.message?.includes('limit') || error.status === 403)) {
      //   showSubscriptionPrompt();
      // } else {
      //   console.error("Error in handleMarkAsLearning:", error);
      // }
      console.error("Error in handleMarkAsLearning:", error);
    }
  };

  // Function to show subscription prompt toast
  const showSubscriptionPrompt = () => {
    toast.error(
      <div style={{ padding: '5px 0' }}>
        <p style={{ marginBottom: '12px', fontWeight: '500' }}>
          You've reached the limit of 10 words. Subscribe to continue saving words.
        </p>
        <button 
          onClick={() => navigate('/subscription-plan')}
          style={{
            background: '#2e7d32',
            color: 'white',
            border: 'none',
            padding: '8px 15px',
            borderRadius: '4px',
            cursor: 'pointer',
            fontSize: '14px',
            fontWeight: 'bold',
            width: '100%',
            textAlign: 'center',
            boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
            transition: 'all 0.2s ease'
          }}
        >
          Subscribe Now
        </button>
      </div>,
      { 
        autoClose: false, // Don't auto close
        className: 'subscription-toast',
        style: { maxWidth: '320px' },
        closeOnClick: false // Don't close when clicked
      }
    );
  };

  return (
    <>
      {/* Apply Amiri font to all Arabic text in the dialog */}
      <style>
        {`
          .MuiDialog-root [dir="rtl"],
          .MuiDialog-root *[dir="rtl"],
          .MuiDialog-root .arabic-text,
          .MuiDialog-root .MuiTypography-root[align="right"],
          .MuiDialog-root *[lang="ar"] {
            font-family: 'Amiri', 'Noto Naskh Arabic', serif !important;
          }
          
          .subscription-toast {
            border-radius: 8px !important;
            box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15) !important;
            padding: 12px !important;
            min-width: 280px !important;
          }
          .subscription-toast .Toastify__toast-body {
            margin: 0 !important;
            padding: 0 !important;
          }
          .subscription-toast .Toastify__toast-icon {
            margin-right: 12px !important;
          }
          .subscription-toast button:hover {
            background: #1b5e20 !important;
            transform: translateY(-1px) !important;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25) !important;
          }
        `}
      </style>
      <Dialog
        open={Boolean(selectedWord)}
        onClose={onClose}
        maxWidth="xs"
        PaperProps={{
          sx: {
            borderRadius: '12px',
            boxShadow: '0 10px 40px rgba(0, 0, 0, 0.1)',
            overflow: 'visible'
          }
        }}
      >
        <Box sx={{ p: 1.5, background: '#f8f9fa', borderRadius: '12px' }}>
          {/* Header with word and POS tag */}
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mb: 1.5,
            pb: 1,
            borderBottom: '1px solid rgba(0, 0, 0, 0.06)'
          }}>
            <Typography 
              variant="h5" 
              dir="rtl" 
              lang="ar" 
              sx={{
                fontFamily: '"Amiri", "Noto Naskh Arabic", serif',
                fontWeight: 700,
                color: '#2e7d32',
                mb: 0.5,
                width: '100%',
                textAlign: 'center'
              }}
            >
              {selectedWord.original || (typeof selectedWord === 'string' ? selectedWord : JSON.stringify(selectedWord))}
            </Typography>

            {/* POS tags container */}
            <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap', justifyContent: 'center' }}>
              {selectedWord.pos && (
                <Chip
                  label={selectedWord.pos}
                  size="small"
                  sx={{
                    textTransform: 'capitalize',
                    fontWeight: 500,
                    backgroundColor: 'rgba(46, 125, 50, 0.1)',
                    color: '#2e7d32',
                    fontSize: '0.75rem',
                    height: '22px',
                    fontFamily: '"Amiri", "Noto Naskh Arabic", serif'
                  }}
                />
              )}
              
              {/* Add verb form chip with Arabic pattern */}
              {selectedWord.verb_form && selectedWord.verb_form !== 'none' && (
                <Chip
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                      <span>{selectedWord.verb_form}</span>
                      <span dir="rtl" lang="ar" style={{ 
                        fontFamily: '"Amiri", "Noto Naskh Arabic", serif',
                        fontSize: '0.85rem' 
                      }}>
                        {(() => {
                          // Map form numbers to their Arabic patterns
                          const formPatterns = {
                            'Form I': 'فَعَلَ',
                            'Form II': 'فَعَّلَ',
                            'Form III': 'فَاعَلَ',
                            'Form IV': 'أَفْعَلَ',
                            'Form V': 'تَفَعَّلَ',
                            'Form VI': 'تَفَاعَلَ',
                            'Form VII': 'اِنْفَعَلَ',
                            'Form VIII': 'اِفْتَعَلَ',
                            'Form IX': 'اِفْعَلَّ',
                            'Form X': 'اِسْتَفْعَلَ'
                          };
                          return ` (${formPatterns[selectedWord.verb_form] || ''})`;
                        })()}
                      </span>
                    </Box>
                  }
                  size="small"
                  sx={{
                    textTransform: 'capitalize',
                    fontWeight: 500,
                    backgroundColor: 'rgba(46, 125, 50, 0.1)',
                    color: '#2e7d32',
                    fontSize: '0.75rem',
                    height: '24px', // Slightly increased height to accommodate Arabic
                    fontFamily: '"Amiri", "Noto Naskh Arabic", serif',
                    '& .MuiChip-label': {
                      padding: '0 8px'
                    }
                  }}
                />
              )}
              
              {/* Add noun number chip if it exists and is not 'none' */}
              {selectedWord.noun_number && selectedWord.noun_number !== 'none' && (
                <Chip
                  label={selectedWord.noun_number}
                  size="small"
                  sx={{
                    textTransform: 'capitalize',
                    fontWeight: 500,
                    backgroundColor: 'rgba(46, 125, 50, 0.1)',
                    color: '#2e7d32',
                    fontSize: '0.75rem',
                    height: '22px',
                    fontFamily: '"Amiri", "Noto Naskh Arabic", serif'
                  }}
                />
              )}
            </Box>
          </Box>

          {/* Word details - Root and Lemma */}
          <Box sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 1.5,
            mb: 1.5
          }}>
            {/* Lemma changing to diacritized form */}
            {selectedWord.diacritized_form && (
              <Paper elevation={0} sx={{
                p: 1,
                flex: '1 0 45%',
                minWidth: '120px',
                backgroundColor: '#e8f5e9',
                borderRadius: '8px',
                border: 'none'
              }}>
                <Typography variant="caption" sx={{
                  display: 'block',
                  mb: 0.25,
                  color: '#43a047',
                  fontWeight: 600,
                  fontSize: '0.7rem',
                  textTransform: 'uppercase',
                  letterSpacing: '0.5px'
                }}>
                  Diacritized
                </Typography>
                <Typography 
                  variant="body2" 
                  dir="rtl" 
                  sx={{
                    fontFamily: '"Amiri", "Noto Naskh Arabic", serif',
                    color: '#2e7d32',
                    direction: 'rtl',
                    fontWeight: 600,
                    fontSize: '1rem',
                    textAlign: 'center'
                  }}
                >
                  {selectedWord.diacritized_form}
                </Typography>
              </Paper>
            )}

            {/* Root */}
            {selectedWord.root && (
              <Paper elevation={0} sx={{
                p: 1,
                flex: '1 0 45%',
                minWidth: '120px',
                backgroundColor: '#f1f8e9',
                borderRadius: '8px',
                border: 'none'
              }}>
                <Typography variant="caption" sx={{
                  display: 'block',
                  mb: 0.25,
                  color: '#558b2f',
                  fontWeight: 600,
                  fontSize: '0.7rem',
                  textTransform: 'uppercase',
                  letterSpacing: '0.5px'
                }}>
                  Root
                </Typography>
                <Typography 
                  variant="body2" 
                  dir="rtl"
                  sx={{
                    fontFamily: '"Amiri", "Noto Naskh Arabic", serif',
                    color: '#33691e',
                    direction: 'rtl',
                    fontWeight: 600,
                    fontSize: '1rem',
                    textAlign: 'center'
                  }}
                >
                  {typeof selectedWord.root === 'string' ? selectedWord.root : selectedWord.root.root_form}
                </Typography>
              </Paper>
            )}
          </Box>

          {/* Translation from OpenAI - Moved here */}
          {selectedWord.translation && (
            <Paper elevation={0} sx={{
              p: 1.25,
              mb: 1.5,
              backgroundColor: '#ffffff',
              borderRadius: '8px',
              border: '1px solid rgba(0, 0, 0, 0.05)',
              boxShadow: 'none'
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
                <FontAwesomeIcon icon={faLanguage} style={{ color: '#2e7d32', marginRight: '8px', fontSize: '14px' }} />
                <Typography variant="subtitle2" sx={{
                  color: '#2e7d32',
                  fontWeight: 600,
                  fontSize: '0.75rem',
                  textTransform: 'uppercase',
                  letterSpacing: '0.5px'
                }}>
                  AI Translation
                </Typography>
              </Box>
              <Typography variant="body1" sx={{
                fontWeight: 500,
                color: '#263238',
                fontSize: '0.9rem',
                ml: 1.75
              }}>
                {selectedWord.translation}
              </Typography>
            </Paper>
          )}

          {/* Translation input section */}
          <Box sx={{ mb: 1.5 }}>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              mb: 1,
              justifyContent: 'space-between'
            }}>
              {/* Remove the "Your Translation" label */}
            </Box>

            {/* Display existing translations as chips */}
            {userTranslations.length > 0 && (
              <Box sx={{ mb: 1, display: 'flex', flexWrap: 'wrap', gap: 0.75 }}>
                {userTranslations.map((translation, index) => (
                  <Chip
                    key={index}
                    label={translation}
                    color="success"
                    variant="outlined"
                    onDelete={() => {
                      const newTranslations = [...userTranslations];
                      newTranslations.splice(index, 1);
                      setUserTranslations(newTranslations);
                    }}
                    sx={{
                      borderRadius: '4px',
                      backgroundColor: 'rgba(46, 125, 50, 0.08)',
                      borderColor: '#2e7d32',
                      color: '#2e7d32',
                      fontSize: '0.85rem',
                      fontWeight: 500,
                      py: 0.5,
                      '& .MuiChip-label': {
                        px: 1.25
                      },
                      '& .MuiChip-deleteIcon': {
                        color: '#2e7d32',
                        '&:hover': { color: '#d32f2f' }
                      }
                    }}
                  />
                ))}
              </Box>
            )}

            {/* Translation input field */}
            {(
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.25, mb: 1 }}>
                <Typography variant="caption" sx={{ fontSize: '0.7rem', color: '#666', pl: 0.5 }}>
                  Type your translation below
                </Typography>

                {/* Pre-fill suggestion */}
                {userTranslations.length === 0 && !userTranslation && selectedWord?.translation && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, pl: 0.5, mb: 0.25 }}>
                    <Typography variant="caption" sx={{ fontSize: '0.7rem', color: '#666', fontStyle: 'italic' }}>
                      Suggested: "{selectedWord.translation}"
                    </Typography>
                    <Button
                      size="small"
                      variant="text"
                      color="success"
                      onClick={() => setUserTranslation(selectedWord.translation)}
                      sx={{
                        minWidth: 'auto',
                        fontSize: '0.7rem',
                        padding: '0px 5px',
                        color: '#2e7d32',
                        textTransform: 'none'
                      }}
                    >
                      Use
                    </Button>
                  </Box>
                )}

                <Box sx={{ display: 'flex', gap: 1, alignItems: 'flex-start' }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Type your translation here"
                    value={userTranslation}
                    onChange={(e) => setUserTranslation(e.target.value)}
                    size="small"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '8px',
                        backgroundColor: '#ffffff',
                        fontSize: '0.9rem',
                        '&.Mui-focused fieldset': {
                          borderColor: '#2e7d32',
                          borderWidth: '1px'
                        }
                      }
                    }}
                  />
                </Box>
              </Box>
            )}

            {/* Action buttons */}
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 1
            }}>
              <Button
                variant="contained"
                color="success"
                onClick={handleMarkAsLearning}
                size="small"
                startIcon={isLearningLoading ?
                  <CircularProgress size={16} color="inherit" /> :
                  <FontAwesomeIcon icon={isWordLearned ? faEdit : faSave} size="sm" />
                }
                sx={{
                  borderRadius: '8px',
                  flex: 1,
                  padding: '4px 10px',
                  fontSize: '0.75rem',
                  fontWeight: 600,
                  textTransform: 'none',
                  boxShadow: 'none',
                  backgroundColor: isWordLearned ? '#43a047' : '#66bb6a',
                  '&:hover': {
                    backgroundColor: isWordLearned ? '#388e3c' : '#4caf50',
                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)'
                  }
                }}
                disabled={isLearningLoading}
              >
                {isWordLearned ? 'Update' : 'Learn'}
              </Button>

              <Button
                variant="contained"
                color="success"
                onClick={markWordAsKnown}
                size="small"
                startIcon={isKnownLoading ?
                  <CircularProgress size={16} color="inherit" /> :
                  <FontAwesomeIcon icon={faCheck} size="sm" />
                }
                sx={{
                  borderRadius: '6px',
                  flex: 1,
                  padding: '4px 10px',
                  fontSize: '0.75rem',
                  fontWeight: 600,
                  textTransform: 'none',
                  boxShadow: '0 1px 2px rgba(0, 0, 0, 0.08)',
                  backgroundColor: '#43a047',
                  border: '1px solid rgba(0, 0, 0, 0.12)',
                  '&:hover': {
                    backgroundColor: '#388e3c',
                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.12)',
                    border: '1px solid rgba(0, 0, 0, 0.2)'
                  },
                  '&.Mui-disabled': {
                    opacity: 0.6,
                    backgroundColor: '#e0e0e0',
                    color: '#757575',
                    border: '1px solid rgba(0, 0, 0, 0.08)'
                  }
                }}
                disabled={(!(isWordLearned || wordExistsAsLearned)) || wordLearningStatus === 'known' || isKnownLoading}
                title={isWordLearned || wordExistsAsLearned ? "Move word to Known" : "Please mark as Learning first"}
              >
                Known
              </Button>

              <IconButton
                size="small"
                onClick={resetWordLearningStatus}
                sx={{
                  border: '1px solid rgba(0, 0, 0, 0.12)',
                  borderRadius: '8px',
                  padding: '4px'
                }}
              >
                <FontAwesomeIcon icon={faUndo} size="sm" style={{ fontSize: '12px', color: '#2e7d32' }} />
              </IconButton>
            </Box>
            
            {/* Dictionary buttons - Moved here */}
            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 1.5,
              mb: 0.5,
              gap: 1,
              flexWrap: 'wrap'
            }}>
              <Button
                variant="contained"
                color="success"
                onClick={openKsaaDictionary}
                startIcon={<FontAwesomeIcon icon={faBook} size="sm" />}
                size="small"
                sx={{
                  borderRadius: '8px',
                  boxShadow: 'none',
                  padding: '4px 10px',
                  fontWeight: 600,
                  textTransform: 'none',
                  fontSize: '0.75rem',
                  backgroundColor: '#43a047',
                  '&:hover': {
                    backgroundColor: '#388e3c',
                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)'
                  }
                }}
                disabled={dictionaryLoading}
              >
                {dictionaryLoading ? (
                  <CircularProgress size={16} color="inherit" sx={{ mr: 1 }} />
                ) : null}
                KSAA
              </Button>

              <Button
                variant="contained"
                color="success"
                onClick={openDictionary}
                startIcon={<FontAwesomeIcon icon={faBook} size="sm" />}
                size="small"
                sx={{
                  borderRadius: '8px',
                  boxShadow: 'none',
                  padding: '4px 10px',
                  fontWeight: 600,
                  textTransform: 'none',
                  fontSize: '0.75rem',
                  backgroundColor: '#66bb6a',
                  '&:hover': {
                    backgroundColor: '#4caf50',
                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)'
                  }
                }}
                disabled={dictionaryLoading}
              >
                {dictionaryLoading ? (
                  <CircularProgress size={16} color="inherit" sx={{ mr: 1 }} />
                ) : null}
                Ejtaal
              </Button>
            </Box>

            {/* Close button */}
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1.5 }}>
              <Button
                variant="outlined"
                color="success"
                onClick={onClose}
                size="small"
                sx={{
                  borderRadius: '8px',
                  padding: '4px 14px',
                  fontWeight: 500,
                  textTransform: 'none',
                  fontSize: '0.8rem',
                  borderColor: '#2e7d32',
                  color: '#2e7d32',
                  '&:hover': {
                    borderColor: '#1b5e20',
                    backgroundColor: 'rgba(46, 125, 50, 0.04)'
                  }
                }}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default BookReaderCard;