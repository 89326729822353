import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ImagePath } from "../Constant/ImgPath";
import AuthHeader from '../Components/AuthHeader';

function AboutUs() {
  const navigate = useNavigate();

  return (
    <Box sx={styles.root}>
      <AuthHeader currentPage="about" />

      {/* Content */}
      <Container maxWidth="sm" sx={styles.container}>
        <Typography variant="h2" component="h1" sx={styles.title}>
          About Us
        </Typography>
        
        <Box sx={styles.content}>
          <Typography sx={styles.text}>
            This platform is built by aspiring students of knowledge who found a gap in modern resources 
            in their journey of studying Classical Arabic (Fusha). This platform was developed to 
            accelerate studying, acquisition of Quranic vocabulary by modern tools and to accelerate 
            one's journey towards appreciating the Quran at a deeper level. We decided to fulfill this 
            need in the community with LinkQuran.
          </Typography>

          <Box sx={styles.noteBox}>
            <Typography variant="h6" sx={styles.noteTitle}>
              Note:
            </Typography>
            <Typography sx={styles.noteText}>
              One should note that the translation of the Quran, is not the Quran itself. Consider 
              this process to help you slowly transition away from your native language, towards the 
              wonderful world of Classical Arabic (fusha).
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

// =============== Component Styles ===============
const styles = {
  root: {
    minHeight: '100vh',
    backgroundColor: 'black',
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    py: 4,
  },
  title: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
    mb: 4,
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
    fontSize: { xs: '2rem', md: '2.5rem' },
    letterSpacing: '2px',
  },
  content: {
    backgroundColor: 'white',
    borderRadius: '20px',
    p: { xs: 3, md: 4 },
    maxWidth: '600px',
    position: 'relative',
    boxShadow: `
      0px 0px 15px rgba(255, 255, 255, 0.3),
      0px 0px 30px rgba(255, 255, 255, 0.2),
      0px 0px 45px rgba(255, 255, 255, 0.1)
    `,
    transition: 'all 0.3s ease',
    '&:hover': {
      transform: 'translateY(-5px)',
      boxShadow: `
        0px 0px 20px rgba(255, 255, 255, 0.4),
        0px 0px 40px rgba(255, 255, 255, 0.3),
        0px 0px 60px rgba(255, 255, 255, 0.2)
      `,
    },
  },
  text: {
    color: '#1a1a1a',
    fontSize: { xs: '0.9rem', md: '1rem' },
    lineHeight: 1.7,
    mb: 3,
    textAlign: 'justify',
  },
  noteBox: {
    backgroundColor: '#f5f5f5',
    borderRadius: '15px',
    p: { xs: 2, md: 3 },
    boxShadow: `
      0px 8px 20px rgba(0, 0, 0, 0.3),
      0px 4px 10px rgba(0, 0, 0, 0.2)
    `,
  },
  noteTitle: {
    color: '#1a1a1a',
    fontWeight: 'bold',
    mb: 1,
    fontSize: { xs: '1rem', md: '1.1rem' },
  },
  noteText: {
    color: '#333',
    fontSize: { xs: '0.9rem', md: '1rem' },
    lineHeight: 1.7,
    textAlign: 'justify',
  },
};

export default AboutUs; 