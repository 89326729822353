import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import CustomPayPalButton from "../Components/CustomPayPalButton";
import Footer from "../Components/Footer";
import Nav from "../Components/Nav";
import AdminSidebar from "../Components/Sidebar";
import { saveSelectedAmount, saveCustomAmount } from "../redux/actions/amountValues";

const SubscriptionPlan = () => {
  const [sidebar, setsidebar] = useState(false);
  const [isMonthly, setIsMonthly] = useState(true);
  const [amount, setAmount] = useState(2.99);
  const [customAmount, setCustomAmount] = useState('');
  const [showCustomInput, setShowCustomInput] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 700);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // One-time payment amount options
  const oneTimeAmounts = [5, 10, 25, 50, 100];

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 700);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Set the amount in Redux when component mounts or amount changes
    if (isMonthly) {
      dispatch(saveSelectedAmount(amount));
      dispatch(saveCustomAmount(''));
    } else {
      if (showCustomInput && customAmount) {
        // When using custom amount, pass it to both Redux states
        dispatch(saveSelectedAmount(parseFloat(customAmount) || 25));
        dispatch(saveCustomAmount(customAmount));
      } else {
        // When using preset amount
        dispatch(saveSelectedAmount(amount));
        dispatch(saveCustomAmount(''));
      }
    }
  }, [amount, isMonthly, customAmount, showCustomInput, dispatch]);

  const handleOptionSelect = (isMonthlyOption) => {
    setIsMonthly(isMonthlyOption);
    setShowCustomInput(false);
    
    if (isMonthlyOption) {
      // For monthly, always set to 2.99
      setAmount(2.99);
      dispatch(saveSelectedAmount(2.99));
      dispatch(saveCustomAmount(''));
    } else {
      // For one-time, default to 25
      setAmount(25);
      dispatch(saveSelectedAmount(25));
      dispatch(saveCustomAmount(''));
    }
  };

  const handleOneTimeAmountSelect = (selectedAmount) => {
    if (selectedAmount === 'other') {
      setShowCustomInput(true);
      // Keep the current amount until user enters a custom one
    } else {
      setAmount(selectedAmount);
      setShowCustomInput(false);
      dispatch(saveSelectedAmount(selectedAmount));
      dispatch(saveCustomAmount(''));
    }
  };

  const handleCustomAmountChange = (e) => {
    const value = e.target.value;
    setCustomAmount(value);
    
    // Update Redux immediately when custom amount changes
    if (value && !isNaN(parseFloat(value)) && parseFloat(value) > 0) {
      dispatch(saveSelectedAmount(parseFloat(value)));
      dispatch(saveCustomAmount(value));
    } else {
      // If invalid, keep the last valid amount
      dispatch(saveCustomAmount(value));
    }
  };

  const sidemenufun = () => {
    setsidebar(!sidebar);
  };

  // Get the current amount to display
  const getDisplayAmount = () => {
    if (isMonthly) {
      return amount;
    } else {
      return showCustomInput && customAmount ? parseFloat(customAmount) : amount;
    }
  };

  return (
    <>
      <Nav headervalue={"nav2"} sidemenufun={sidemenufun} />
      <AdminSidebar sidebar={sidebar} sidebaracc={"Mobile-Sidebar"} />

      <div className="checkout-container" style={{
        maxWidth: '1200px',
        margin: '0 auto',
        padding: isMobile ? '12px' : '24px'
      }}>
        <div style={{
          textAlign: 'center',
          marginBottom: isMobile ? '16px' : '24px'
        }}>
          <h1 style={{
            fontSize: isMobile ? '1.75rem' : '2rem',
            marginBottom: '8px',
            color: '#1a1a1a'
          }}>Choose Your Plan</h1>
          <p style={{
            fontSize: isMobile ? '0.9rem' : '1rem',
            color: '#666',
            maxWidth: '500px',
            margin: '0 auto',
            lineHeight: '1.4'
          }}>
            Subscribe to contribute monthly or make a one-time payment to support our mission.
          </p>
        </div>

        <div style={{
          display: 'flex',
          flexDirection: 'column',
          gap: isMobile ? '16px' : '24px',
          alignItems: 'center'
        }}>
          {/* Simple toggle between Monthly and One-Time */}
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '10px',
            margin: '10px 0 20px'
          }}>
            <button 
              onClick={() => handleOptionSelect(true)}
              style={{
                padding: '10px 20px',
                borderRadius: '6px',
                background: isMonthly ? '#2e7d32' : '#f5f5f5',
                color: isMonthly ? 'white' : '#333',
                border: isMonthly ? 'none' : '1px solid #ddd',
                cursor: 'pointer',
                fontWeight: '600',
                transition: 'all 0.2s ease'
              }}
            >
              Monthly
            </button>
            <button 
              onClick={() => handleOptionSelect(false)}
              style={{
                padding: '10px 20px',
                borderRadius: '6px',
                background: !isMonthly ? '#2e7d32' : '#f5f5f5',
                color: !isMonthly ? 'white' : '#333',
                border: !isMonthly ? 'none' : '1px solid #ddd',
                cursor: 'pointer',
                fontWeight: '600',
                transition: 'all 0.2s ease'
              }}
            >
              One-Time
            </button>
          </div>

          {/* One-time payment amount options */}
          {!isMonthly && (
            <div style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              gap: '8px',
              maxWidth: '500px',
              margin: '0 auto 20px'
            }}>
              {oneTimeAmounts.map(amt => (
                <button
                  key={amt}
                  onClick={() => handleOneTimeAmountSelect(amt)}
                  style={{
                    minWidth: '60px',
                    height: '60px',
                    borderRadius: '10px',
                    background: amount === amt && !showCustomInput ? '#2e7d32' : 'white',
                    color: amount === amt && !showCustomInput ? 'white' : '#333',
                    border: `1px solid ${amount === amt && !showCustomInput ? '#2e7d32' : '#ddd'}`,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    fontWeight: amount === amt && !showCustomInput ? '600' : '400',
                    fontSize: '1rem',
                    transition: 'all 0.2s ease',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                    padding: '0 15px'
                  }}
                >
                  ${amt}
                </button>
              ))}
              <button
                onClick={() => handleOneTimeAmountSelect('other')}
                style={{
                  minWidth: '60px',
                  height: '60px',
                  borderRadius: '10px',
                  background: showCustomInput ? '#2e7d32' : 'white',
                  color: showCustomInput ? 'white' : '#333',
                  border: `1px solid ${showCustomInput ? '#2e7d32' : '#ddd'}`,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  fontWeight: showCustomInput ? '600' : '400',
                  fontSize: '0.9rem',
                  transition: 'all 0.2s ease',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                  padding: '0 15px'
                }}
              >
                Other
              </button>
            </div>
          )}

          {/* Custom amount input */}
          {!isMonthly && showCustomInput && (
            <div style={{ 
              width: '100%', 
              maxWidth: '200px',
              margin: '0 auto 20px'
            }}>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                width: '100%'
              }}>
                <span style={{
                  position: 'absolute',
                  left: '10px',
                  fontSize: '1.2rem',
                  color: '#666'
                }}>
                  $
                </span>
                <input
                  type="number"
                  value={customAmount}
                  onChange={handleCustomAmountChange}
                  placeholder="Enter amount"
                  min="1"
                  step="0.01"
                  style={{
                    width: '100%',
                    padding: '10px 10px 10px 25px',
                    fontSize: '1.2rem',
                    borderRadius: '6px',
                    border: '1px solid #ddd',
                    outline: 'none',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
                  }}
                />
              </div>
            </div>
          )}

          {/* Simple message about what the user is paying for */}
          <div style={{
            textAlign: 'center',
            marginBottom: '20px',
            padding: '15px',
            backgroundColor: '#f9f9f9',
            borderRadius: '8px',
            maxWidth: '500px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
          }}>
            <p style={{ margin: 0, fontSize: '1rem' }}>
              <strong>${getDisplayAmount()}</strong>{isMonthly ? ' monthly subscription' : ' one-time payment'}
            </p>
          </div>

          {/* Checkout Section */}
          <div style={{
            width: '100%',
            maxWidth: '400px',
            background: 'white',
            borderRadius: '8px',
            padding: '24px',
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.08)',
            marginTop: '20px'
          }}>
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '16px',
              paddingBottom: '12px',
              borderBottom: '1px solid #eee'
            }}>
              <span style={{ 
                color: '#666',
                fontSize: '0.9rem'
              }}>
                {isMonthly ? 'Monthly Subscription' : 'One-Time Payment'}
              </span>
              <span style={{ 
                fontSize: '1.1rem',
                fontWeight: '600',
                color: '#1a1a1a'
              }}>
                ${getDisplayAmount()}
                {isMonthly && <span style={{ fontSize: '0.8rem', color: '#666', fontWeight: '400' }}>/month</span>}
              </span>
            </div>
            
            <CustomPayPalButton isSubscription={isMonthly} />
          </div>

          <button
            onClick={() => navigate('/dashboard')}
            style={{
              padding: '8px 20px',
              borderRadius: '6px',
              border: '1px solid #eee',
              background: 'white',
              cursor: 'pointer',
              fontSize: '0.8rem',
              color: '#666',
              marginTop: '20px'
            }}
          >
            Back to Dashboard
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SubscriptionPlan;