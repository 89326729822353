import React, { useState } from 'react';
import { ImagePath } from '../../Constant/ImgPath';
import { useNavigate } from 'react-router-dom';
import API_BASE_URL from '../../apiConfig';
import { Typography } from "@mui/material";

function OTPVerification() {
    const navigate = useNavigate();
    const NavigatePages = (data) => {
        navigate(data);
    }
    const [checkOTP, setCheckOTP] = useState(false)
    const [inputValues, setInputValues] = useState({
        value1: '',
        value2: '',
        value3: '',
        value4: '',
        value5: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (/^\d*$/.test(value)) {
            setInputValues({
                ...inputValues,
                [name]: value.slice(0, 1),
            });

            // Auto-focus next input
            if (value && e.target.nextElementSibling) {
                e.target.nextElementSibling.focus();
            }
        }
    };

    const handleKeyDown = (e, index) => {
        // Handle backspace to move to previous input
        if (e.key === 'Backspace' && !e.target.value && e.target.previousElementSibling) {
            e.target.previousElementSibling.focus();
        }
    };

    const otpAPI = () => {
        const apiUrl = `${API_BASE_URL}/auth/forgot_password`;
        const requestData = {
            email: localStorage.getItem("userEmail")
        }
        const headers = {
            'Content-Type': 'application/json',
        };
        fetch(apiUrl, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(requestData),
        })
            .then(response => response.json())
            .then(data => {
                console.log("RESEND", data)
                localStorage.setItem("otp", data?.["OTP"])
            })
            .catch(error => {
                console.error('Error fetching API:', error)
            });
    }

    const handleSubmit = () => {
        const otp = inputValues.value1 + inputValues.value2 + inputValues.value3 + inputValues.value4 + inputValues.value5;
        console.log('Input values:', otp);
        if (otp === localStorage.getItem("otp")) {
            console.log("Correct OTP")
            NavigatePages("/resetpassword")
        } else {
            console.log("Incorrect OTP")
        }
    };

    return (
        <>
            <div className="main-section" style={{ 
                backgroundColor: 'black', 
                minHeight: '100vh', 
                display: 'flex', 
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                margin: 0,
                padding: '1rem',
                boxSizing: 'border-box'
            }}>
                <div className="sign-left" style={{ 
                    width: '100%',
                    maxWidth: '400px', 
                    margin: '0 auto', 
                    backgroundColor: 'black', 
                    padding: '1rem',
                    boxSizing: 'border-box'
                }}>
                    <div className="sign" style={{ width: '100%' }}>
                        <div className="sign-text" style={{ 
                            marginBottom: '2rem', 
                            width: '100%', 
                            textAlign: 'center',
                            padding: '0 1rem'
                        }}>
                            <Typography 
                                variant="h3" 
                                component="h1"
                                sx={{
                                    fontWeight: 'bold',
                                    color: 'white',
                                    fontFamily: 'Roboto, sans-serif',
                                    fontSize: '2.0rem',
                                }}
                            >
                                OTP Verification
                            </Typography>
                            <Typography 
                                variant="body1"
                                sx={{
                                    fontSize: '0.875rem', 
                                    fontWeight: '400', 
                                    color: 'white',
                                    marginTop: '0.5rem',
                                }}
                            >
                                <span style={{ color: 'white' }}>Please enter the OTP sent to your email</span>
                            </Typography>
                        </div>

                        <div style={{ 
                            background: 'white',
                            borderRadius: '12px',
                            padding: '1.25rem',
                            boxShadow: '0px 8px 30px rgba(255, 255, 255, 0.5)',
                            width: '100%',
                            boxSizing: 'border-box'
                        }}>
                            <div className="email-area login-create-inputs" style={{ width: '100%', marginBottom: '1rem' }}>
                                <div className="OPT-boxes" style={{ 
                                    display: 'flex',
                                    gap: '12px',
                                    justifyContent: 'center',
                                    marginTop: '0.5rem'
                                }}>
                                    {[1, 2, 3, 4, 5].map((num, index) => (
                                        <input
                                            key={num}
                                            type="text"
                                            name={`value${num}`}
                                            value={inputValues[`value${num}`]}
                                            onChange={handleInputChange}
                                            onKeyDown={(e) => handleKeyDown(e, index)}
                                            maxLength="1"
                                            style={{
                                                width: '45px',
                                                height: '45px',
                                                textAlign: 'center',
                                                fontSize: '18px',
                                                borderRadius: '8px',
                                                border: '1px solid #ddd',
                                                backgroundColor: 'white',
                                                outline: 'none',
                                                boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)'
                                            }}
                                        />
                                    ))}
                                </div>
                            </div>

                            <button 
                                onClick={handleSubmit}
                                className="login-btn"
                                style={{
                                    width: '100%',
                                    padding: '0.75rem',
                                    backgroundColor: '#000',
                                    color: 'white',
                                    border: '1px solid #000',
                                    borderRadius: '8px',
                                    fontSize: '1rem',
                                    fontWeight: '500',
                                    cursor: 'pointer',
                                    height: '45px',
                                    marginTop: '1rem',
                                    transition: 'all 0.2s ease'
                                }}
                            >
                                Verify
                            </button>

                            <div style={{ 
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: '0.5rem',
                                marginTop: '1rem'
                            }}>
                                <p style={{ 
                                    fontSize: '14px',
                                    color: '#666',
                                    margin: 0
                                }}>
                                    Didn't receive the code?{' '}
                                    <button
                                        onClick={otpAPI}
                                        style={{ 
                                            background: 'none',
                                            border: 'none',
                                            color: '#000',
                                            fontWeight: '500',
                                            cursor: 'pointer',
                                            padding: 0
                                        }}
                                    >
                                        Resend
                                    </button>
                                </p>
                                <button
                                    onClick={() => NavigatePages("/login")}
                                    style={{ 
                                        background: 'none',
                                        border: 'none',
                                        color: '#000',
                                        fontWeight: '500',
                                        cursor: 'pointer',
                                        padding: 0,
                                        fontSize: '14px',
                                        marginTop: '0.5rem'
                                    }}
                                >
                                    Back to Login
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default OTPVerification;