
import React, { useState, useEffect } from 'react';
import { ImagePath } from '../Constant/ImgPath';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import "../index.css"
import API_BASE_URL from '../apiConfig';
const SubscriptionComponent = () => {
    const [paymentdata, setPaymentdata] = useState([]);
    const subscription = {
        User: {
            width: "95%",
        },
        arrows: {
            borderRadius: "10px",
            border: "1px solid rgb(89 89 89 / 59%)9",
            background: "#D9D9D9",
            padding: "10px",
            color: "#595959",
            cursor: "pointer"
        },
        arrowstext: {
            borderRadius: "10px",
            border: "1px solid #091B2E",
            background: "#091B2E",
            padding: "10px",
            color: "#fff",
            width: "40px",
            textAlign: "center"
        }
    }
    const subscriptionPlan = [
        {

            PlanName: 'Free',
            Duration: 'Unlimited',
            Price: '$0.00mo',
            imagePath: {
                optionsbtn: ImagePath.optionsbtn
            }
        },
        {

            PlanName: 'Monthly',
            Duration: '1 Month',
            Price: '$6.99mo',
            imagePath: {
                optionsbtn: ImagePath.optionsbtn
            }
        },
        {

            PlanName: 'Annual',
            Duration: '12 Month',
            Price: '$4.99mo',
            imagePath: {
                optionsbtn: ImagePath.optionsbtn
            }
        },
    ];

    const getData = () => {
        let access_token = localStorage.getItem("access_token");
        let apiUrl = `${API_BASE_URL}/admin/get_plans`;

        const headers = {
            "Content-Type": "application/json",
            "Authorization": access_token
        };
        fetch(apiUrl, {
            method: "GET",
            headers: headers,
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then((data) => {
                // console.log(data);
                setPaymentdata(data);
            })
            .catch((error) => {
                console.error("Error fetching API:", error);
            });
    };

    const handleData = (id,key) => {
        let access_token = localStorage.getItem("access_token");
        let apiUrl = `${API_BASE_URL}/admin/update_plan`;

        const requestData = {
            plan_id: id,
            new_price: paymentdata[key].price
        };
        const headers = {
            "Content-Type": "application/json",
            "Authorization": access_token
        };
        fetch(apiUrl, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(requestData),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then((data) => {
                // console.log(data);
                getData();
            })
            .catch((error) => {
                console.error("Error fetching API:", error);
            });
    };

    useEffect(() => {
        getData();
    }, []);
    return (
        <div style={subscription.User} className='user-responsive'>
            <div className='admin-user'>
                <div className='d-flex w-100 ' style={{ padding: "44px 20px 0" }} >
                    <p className='w-50 m-0 text-center' >Plan Name</p>
                    <p className='w-50 m-0 text-center' >Duration</p>
                    <p className='w-50 m-0 text-center' >Price</p>
                    <p className='w-50 m-0 text-center' >Action</p>
                </div>
                <hr />
                {paymentdata.map((user, index) => (
                    <div key={index} className='d-flex align-items-center font-user' style={{ padding: "10px 20px" }}>
                        <div className='w-50 m-0 text-center'>{user.plan_name}</div>
                        <div className='w-50 m-0 text-center'>{user.duration}</div>
                        <input disabled={index == 0} className='w-50 m-0 text-center' style={{ padding: "10px", border: "none" }}
                            value={user.price}
                            type='number'
                            onChange={(e) => {
                                const updatedPaymentData = [...paymentdata]; // Copy the array
                                updatedPaymentData[index].price = parseFloat(e.target.value) || 0; // Update the price
                                setPaymentdata(updatedPaymentData); // Update the state with the new array
                            }}
                        />
                        <div className='w-50 m-0 text-center'>
                            {index > 0 &&<button onClick={()=>{
                                handleData(user.plan_id,index)
                            }} style={{
                                backgroundColor: "#e89b2f",
                                color: "#fff",
                                padding: "7px 20px",
                                borderRadius: "10px"
                            }}>Update</button>}
                        </div>
                    </div>
                ))}
                <div>
                    <div style={{ display: "flex", gap: "11px", marginTop: "140px", padding: "0 40px 35px", justifyContent: "end" }}>
                        <div style={subscription.arrows}><FontAwesomeIcon icon={faAngleLeft} /></div>
                        <div style={subscription.arrowstext}>1</div>
                        <div style={subscription.arrows}><FontAwesomeIcon icon={faAngleRight} /></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubscriptionComponent;
