import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Dot } from 'recharts';

const data = [
  { name: 'Jan', uv: 4000, pv: 2400, amt: 2400 },
  { name: 'Feb', uv: 3000, pv: 1398, amt: 2210 },
  { name: 'Mar', uv: 2000, pv: 9800, amt: 2290 },
  { name: 'Apr', uv: 2780, pv: 3908, amt: 2000 },
  { name: 'May', uv: 1890, pv: 4800, amt: 2181 },
  { name: 'Jun', uv: 2390, pv: 3800, amt: 2500 },
  { name: 'Jul', uv: 3490, pv: 4300, amt: 2100 },
  { name: 'Aug', uv: 4590, pv: 7400, amt: 9300 },
  { name: 'Sep', uv: 2690, pv: 3500, amt: 3400 },
  { name: 'Oct', uv: 6790, pv: 6600, amt: 5500 },
  { name: 'Nov', uv: 3890, pv: 5700, amt: 7600 },
  { name: 'Dec', uv: 8990, pv: 2800, amt: 2700 },
];

const CustomDot = (props) => {
  const { cx, cy, stroke, payload, value } = props;

  if (value > 3000) {
    return <Dot cx={cx} cy={cy} r={6} fill="red" stroke={stroke} strokeWidth={2} />;
  }

  return <Dot cx={cx} cy={cy} r={6} fill="green" stroke={stroke} strokeWidth={2} />;
};

const PointStylingChart = () => {
  return (
    <LineChart className='chart-resposive' width={800} height={350} data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Line type="monotone" dataKey="pv" stroke="#8884d8" dot={<CustomDot />} />
      <Line type="monotone" dataKey="uv" stroke="#82ca9d" dot={<CustomDot />} />
    </LineChart>
  );
};

export default PointStylingChart;
