
import React, { useState, useEffect } from 'react';
import { ImagePath } from '../Constant/ImgPath';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import API_BASE_URL from '../apiConfig';

const TransactionsComponent = () => {

    const User = {
        User: {
            width: "95%",
        },
        arrows: {
            borderRadius: "10px",
            border: "1px solid rgb(89 89 89 / 59%)9",
            background: "#D9D9D9",
            padding: "10px",
            color: "#595959",
            cursor: "pointer"
        },
        arrowstext: {
            borderRadius: "10px",
            border: "1px solid #091B2E",
            background: "#091B2E",
            padding: "10px",
            color: "#fff",
            width: "40px",
            textAlign: "center"
        }
    }
    const transactions = [
        {
            name: 'Salman',
            date: '19th January,2024',
            email: 'Salman123@gmail.com',
            PaymentMethod: 'Paypal  ',
            imagePath: {
                user: ImagePath.user,
            },
            Payment: "50$"
        },
        {
            name: 'Salman',
            date: '19th January,2024',
            email: 'Salman123@gmail.com',
            PaymentMethod: 'Card',
            imagePath: {
                user: ImagePath.user,
            },
            Payment: "50$"
        },
        {
            name: 'Salman',
            date: '19th January,2024',
            email: 'Salman123@gmail.com',
            PaymentMethod: 'Paypal',
            imagePath: {
                user: ImagePath.user,
            },
            Payment: "50$"
        },
        {
            name: 'Salman',
            date: '19th January,2024',
            email: 'Salman123@gmail.com',
            PaymentMethod: 'Card',
            imagePath: {
                user: ImagePath.user,
            },
            Payment: "50$"
        },
        {
            name: 'Salman',
            date: '19th January,2024',
            email: 'Salman123@gmail.com',
            PaymentMethod: 'Paypal',
            imagePath: {
                user: ImagePath.user,
            },
            Payment: "50$"
        },
        {
            name: 'Salman',
            date: '19th January,2024',
            email: 'Salman123@gmail.com',
            PaymentMethod: 'card',
            imagePath: {
                user: ImagePath.user,
            },
            Payment: "50$"
        },
        {
            name: 'Salman',
            date: '19th January,2024',
            email: 'Salman123@gmail.com',
            PaymentMethod: 'Paypal',
            imagePath: {
                user: ImagePath.user,
            },
            Payment: "50$"
        }
    ];
    const [datainfo, setDatainfo] = useState([]);

    const getData = () => {
        let access_token = localStorage.getItem("access_token");
        let apiUrl = `${API_BASE_URL}/admin/get_user_transactions`;

        const headers = {
            "Content-Type": "application/json",
            "Authorization": access_token
        };
        fetch(apiUrl, {
            method: "GET",
            headers: headers,
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then((data) => {
                // console.log(data);
                setDatainfo(data);
            })
            .catch((error) => {
                console.error("Error fetching API:", error);
            });
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <div style={User.User} className='user-responsive'>
            <div className='admin-user'>
                <div className='d-flex w-100 ' style={{ padding: "44px 20px 0" }} >
                    <div className='w-50 m-0 text-center d-flex'>
                        <input type='checkbox' />
                        <p className='w-50 m-0 text-center' >Name</p>
                    </div>
                    <p className='w-50 m-0 text-center' >Email Address</p>
                    {/* <p className='w-50 m-0 text-center' >Payment Method </p> */}
                    <p className='w-50 m-0 text-center' >Payment</p>
                    <p className='w-50 m-0 text-center' >Date</p>
                </div>
                <hr />
                {datainfo.map((user, index) => (
                    <div key={index} className='d-flex align-items-center font-user' style={{ padding: "10px 20px" }}>
                        <input type='checkbox' />
                        <div className='user-profile-name align-items-center'>
                            <img style={{
                                borderRadius: "20px",
                                height: "35px",
                                width: "35px"
                            }} src={ImagePath.profileava} alt="Image" />
                            <div>{user.username}</div>
                        </div>
                        <div className='w-50 m-0 text-center'>{user.email}</div>
                        {/* <div className='w-50 d-flex justify-content-center'>{user.PaymentMethod}</div> */}
                        <div className='w-50 m-0 text-center'>{user.payment}</div>
                        <div className='w-50 m-0 text-center'>{user.payment_date}</div>
                    </div>
                ))}
                <div>
                    <div style={{ display: "flex", gap: "11px", marginTop: "40px", padding: "0 40px 35px", justifyContent: "end" }}>
                        <div style={User.arrows}><FontAwesomeIcon icon={faAngleLeft} /></div>
                        <div style={User.arrowstext}>1</div>
                        <div style={User.arrows}><FontAwesomeIcon icon={faAngleRight} /></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TransactionsComponent;
