import React, { useState, useEffect } from 'react';

const SimpleFlashCard = ({ word, onSelect, onClose }) => {
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [showFeedback, setShowFeedback] = useState(false);
  const [practiceOptions, setPracticeOptions] = useState([]);

  // Generate options when word changes
  useEffect(() => {
    if (word) {
      generateOptions(word);
    }
  }, [word]);

  // Add debugging for the word prop
  useEffect(() => {
    // console.log("Flashcard word data:", word);
  }, [word]);

  const generateOptions = (word) => {
    if (!word) return;
    const allOptions = [...word.other_translations, word.translation];
    setPracticeOptions(allOptions.sort(() => Math.random() - 0.5));
  };

  const handleSelect = (answer) => {
    setSelectedAnswer(answer);
    setShowFeedback(true);
    
    setTimeout(() => {
      setShowFeedback(false);
      setSelectedAnswer(null);
      onSelect(answer === word.translation ? "Learned" : "Unknown");
    }, 1500);
  };

  return (
    <div className="flashcard-overlay" style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.85)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1000,
    }}>
      <div className="flashcard" style={{
        backgroundColor: '#1a1a1a',
        borderRadius: '16px',
        padding: '2rem',
        width: '90%',
        maxWidth: '400px',
        position: 'relative',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      }}>
        <button 
          onClick={onClose}
          style={{
            position: 'absolute',
            top: '1rem',
            right: '1rem',
            background: 'none',
            border: 'none',
            color: '#999',
            fontSize: '1.5rem',
            cursor: 'pointer',
            padding: '0.5rem',
          }}
        >
          ×
        </button>

        <div style={{
          fontSize: '3rem',
          color: '#fff',
          textAlign: 'center',
          marginBottom: '2rem',
          fontFamily: 'uth',
          direction: 'rtl',
          lineHeight: 1.5,
        }}>
          {word?.word_uthmani || word?.word}
        </div>

        <div style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: '1rem',
          marginTop: '2rem',
        }}>
          {practiceOptions.map((answer, index) => (
            <button
              key={index}
              onClick={() => !showFeedback && handleSelect(answer)}
              disabled={showFeedback}
              style={{
                padding: '1rem',
                border: 'none',
                borderRadius: '8px',
                cursor: showFeedback ? 'default' : 'pointer',
                fontSize: '0.9rem',
                backgroundColor: showFeedback
                  ? answer === word.translation
                    ? '#4CAF50' // Correct answer
                    : selectedAnswer === answer
                      ? '#f44336' // Selected wrong answer
                      : '#333' // Unselected answers
                  : '#333',
                color: '#fff',
                transition: 'all 0.2s ease',
                opacity: showFeedback && answer !== word.translation && answer !== selectedAnswer ? 0.5 : 1,
              }}
            >
              {answer}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SimpleFlashCard;