import React, { useState, useEffect } from 'react';
import Nav from '../Components/Nav';
import Footer from '../Components/Footer';
import SearchBar from '../Components/SearchBar';
import { Button, Form } from 'react-bootstrap';
import { ImagePath } from '../Constant/ImgPath';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, } from '@fortawesome/free-solid-svg-icons';
import { faAnglesLeft, faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import AdminSidebar from '../Components/Sidebar';
import API_BASE_URL from '../apiConfig';

function Ranking() {
    const [isActive, setIsActive] = useState(false);
    const [ranking, setRanking] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const recordsPerPage = 50;
    // sidebar start
    const [sidebar, setsidebar] = useState(false);
    const sidemenufun = () => {
        setsidebar(!sidebar);
    }
    // sidebar end
    const [loginFalse, setLoginFalse] = useState(false)
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        localStorage.getItem("login");
        if (localStorage.getItem("login") == "true") {
            setLoginFalse(true);
        } else {
            navigate("/login");
        }
    }, [localStorage.getItem("login")])

    useEffect(() => {
        getRankingAPI();
    }, [])

    useEffect(() => {
        const userData = localStorage.getItem("userData");
        if (userData) {
            const parsedData = JSON.parse(userData);
            setCurrentUser(parsedData?.user_info?.username);
        }
    }, []);

    useEffect(() => {
        if (currentUser && ranking.length > 0) {
            const userIndex = ranking.findIndex(user => user.username === currentUser);
            if (userIndex !== -1) {
                const userPage = Math.ceil((userIndex + 1) / recordsPerPage);
                setCurrentPage(userPage);
            }
        }
    }, [currentUser, ranking]);

    const navigate = useNavigate();
    const NavigatePages = (data, user) => {
        navigate(data, { state: user });
    }
    const getRankingAPI = () => {
        const apiUrl = `${API_BASE_URL}/community/get_users_stats`;
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem("access_token"),
        };
        fetch(apiUrl, {
            method: 'GET',
            headers: headers,
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                // Sort users by total_links (descending) and then by joining_date (descending)
                const sortedData = data.sort((a, b) => {
                    if (a.total_links === b.total_links) {
                        // If total_links are equal, sort by joining_date (newest first)
                        return new Date(b.joining_date) - new Date(a.joining_date);
                    }
                    // Otherwise sort by total_links (highest first)
                    return b.total_links - a.total_links;
                });
                setRanking(sortedData);
                localStorage.setItem('localdata', "");
            })
            .catch(error => {
                console.error('Error fetching API:', error);
            });
    }
    const handleClick = () => {
        setIsActive(!isActive);
    };
    const [isActive1, setIsActive1] = useState(false);
    const handleClick1 = () => {
        setIsActive1(!isActive1);
    };
    const [isActive2, setIsActive2] = useState(false);
    const handleClick2 = () => {
        setIsActive2(!isActive2);
    };
    const [isActive3, setIsActive3] = useState(false);
    const handleClick3 = () => {
        setIsActive3(!isActive3);
    };
    const Ranking = {
        bg: {
            padding: "10px 24px",
            '@media (max-width: 768px)': {
                padding: "5px 12px"
            }
        },
        h4: {
            fontSize: "24px",
            fontWeight: "600",
            margin: "0",
            '@media (max-width: 768px)': {
                fontSize: "20px"
            }
        },
        mainHeading: {
            margin: "0 auto", width: "70%", display: "flex", textAlign: "center"
        },
        selectButton: {
            borderRadius: "12px",
            border: "1px solid #676767",
            padding: "8px 18px",
            display: "flex",
            gap: "8px",
            cursor: "pointer",
            background: "transparent",
            width: "14%"
        },
        mainBox: {
            borderRadius: "12px",
            border: "1px solid #676767",
            padding: "15px 33px",
            background: "#fff",
            boxShadow: "rgba(0, 0, 0, 0.3) 0px 3px 5px 3px",
            width: "82%",
            margin: "10px auto",
            minHeight: "600px",
            '@media (max-width: 768px)': {
                padding: "10px 15px",
                width: "90%",
                margin: "5px auto"
            }
        },
        rankingList: {
            minHeight: "500px"
        },
        heading: {
            display: "flex",
            alignItems: "center",
            gap: "11px",
            color: "#3E3E3E",
            fontSize: "18px",
            fontWeight: "400",
        },
        ButtonsDiv: {
            borderRadius: "10px",
            background: "#D9D9D9",
            margin: "0 auto",
            width: "100%",
            padding: "13px",
            display: "flex",
            justifyContent: "space-between"
        },
        P: {
            color: "#000",
            fontSize: "18px",
            fontWeight: "500",
            margin: "0"
        },
        button: {
            color: isActive ? "#fff" : "#000",
            borderRadius: "12px",
            border: isActive ? "1px solid transparent" : "1px solid #fff",
            background: isActive ? "#000" : "#FFF",
            fontSize: "16px",
            fontWeight: "400",
            padding: "11px",
            width: "18%",
            cursor: "pointer",
            height: "43px",
            display: "flex", justifyContent: "center", alignItems: "center",
            margin: "0"
        },
        button1: {
            color: isActive1 ? "#fff" : "#000",
            borderRadius: "12px",
            border: isActive1 ? "1px solid transparent" : "1px solid #fff",
            background: isActive1 ? "#000" : "#FFF",
            fontSize: "16px",
            fontWeight: "400",
            padding: "11px",
            width: "22%",
            cursor: "pointer",
            height: "43px",
            display: "flex", justifyContent: "center", alignItems: "center",
            margin: "0"
        },
        button2: {
            color: isActive2 ? "#fff" : "#000",
            borderRadius: "12px",
            border: isActive2 ? "1px solid transparent" : "1px solid #fff",
            background: isActive2 ? "#000" : "#FFF",
            fontSize: "16px",
            fontWeight: "400",
            padding: "11px",
            width: "20%",
            cursor: "pointer",
            height: "43px",
            display: "flex", justifyContent: "center", alignItems: "center",
            margin: "0"
        },
        button3: {
            color: isActive3 ? "#fff" : "#000",
            borderRadius: "12px",
            border: isActive3 ? "1px solid transparent" : "1px solid #fff",
            background: isActive3 ? "#000" : "#FFF",
            fontSize: "16px",
            fontWeight: "400",
            padding: "11px",
            width: "20%",
            cursor: "pointer",
            height: "43px",
            display: "flex", justifyContent: "center", alignItems: "center",
            margin: "0"
        },
        rankingDetail: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px 0"
        },
        rankingDetailProfile: {
            display: "flex",
            gap: "15px",
            alignItems: "center",
            cursor: "pointer",
            width: "60%"
        },
        arrows: {
            borderRadius: "10px",
            border: "1px solid rgb(89 89 89 / 59%)9",
            background: "#D9D9D9",
            padding: "10px",
            color: "#595959",
            cursor: "pointer"
        },
        arrowstext: {
            borderRadius: "10px",
            border: "1px solid #091B2E",
            background: "#091B2E",
            padding: "10px",
            color: "#fff",
            width: "40px",
            textAlign: "center"
        }
    };
    const rankingData = [
        { id: 1, name: "Kate61", score: 1587, image: ImagePath.Ellipse },
        { id: 2, name: "Kate61", score: 1587, image: ImagePath.Ellipse2 },
        { id: 3, name: "Kate61", score: 1587, image: ImagePath.Ellipse },
        { id: 4, name: "Kate61", score: 1587, image: ImagePath.Ellipse2 },
        { id: 5, name: "Kate61", score: 1587, image: ImagePath.Ellipse },
        { id: 6, name: "Kate61", score: 1587, image: ImagePath.Ellipse },
        { id: 7, name: "Kate61", score: 1587, image: ImagePath.Ellipse },
        { id: 8, name: "Kate61", score: 1587, image: ImagePath.Ellipse },
        { id: 9, name: "Kate61", score: 1587, image: ImagePath.Ellipse },
        { id: 10, name: "Kate61", score: 1587, image: ImagePath.Ellipse },
    ];

    // Add resize listener
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Calculate pagination
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = ranking.slice(indexOfFirstRecord, indexOfLastRecord);
    const totalPages = Math.ceil(ranking.length / recordsPerPage);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <>
            {loginFalse && <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
                <Nav headervalue={"nav2"} sidemenufun={() => sidemenufun()} />
                <AdminSidebar sidebar={sidebar} sidebaracc={'Mobile-Sidebar'} />
                <div className='container' style={{
                    padding: isMobile ? "10px 12px" : "20px 24px",
                    flex: 1
                }}>
                    <div className='mobile-view-pages'>
                        <div style={{ 
                            display: "flex", 
                            justifyContent: "space-between", 
                            width: isMobile ? "95%" : "88%", 
                            margin: "0 auto",
                            marginBottom: isMobile ? "10px" : "20px",
                            marginTop: isMobile ? "10px" : "20px"
                        }}>
                            <h4 style={{
                                fontSize: isMobile ? "20px" : "24px",
                                fontWeight: "600",
                                margin: 0
                            }}>RANKINGS</h4>
                        </div>
                        <div style={{
                            borderRadius: "12px",
                            border: "1px solid #676767",
                            padding: isMobile ? "10px 15px" : "15px 33px",
                            background: "#fff",
                            boxShadow: "rgba(0, 0, 0, 0.3) 0px 3px 5px 3px",
                            width: isMobile ? "95%" : "82%",
                            margin: "0 auto",
                            minHeight: "600px"
                        }} className='ranking-main-box'>
                            <div style={Ranking.ButtonsDiv}>
                                <button className='ranking-button1' onClick={handleClick} style={{
                                    ...Ranking.button,
                                    width: '25%',
                                    justifyContent: 'center',
                                    padding: '15px 30px',
                                    margin: '0 10px'
                                }}>Rank</button>
                                <button className='ranking-button2' onClick={handleClick1} style={{
                                    ...Ranking.button1,
                                    width: '25%',
                                    justifyContent: 'center',
                                    padding: '15px 30px',
                                    margin: '0 10px'
                                }}>Username</button>
                                <button className='ranking-button3' onClick={handleClick2} style={{
                                    ...Ranking.button2,
                                    width: '25%',
                                    justifyContent: 'center',
                                    padding: '15px 30px',
                                    margin: '0 10px'
                                }}>Total Words</button>
                                <button className='ranking-button4' onClick={handleClick3} style={{
                                    ...Ranking.button3,
                                    width: '25%',
                                    justifyContent: 'center',
                                    padding: '15px 30px',
                                    margin: '0 10px'
                                }}>Joined</button>
                            </div>
                            <div style={Ranking.rankingList}>
                                {currentRecords.map((user, index) => {
                                    const isCurrentUser = user?.username === currentUser;
                                    return (
                                    <div className='ranking-responsive' key={index} style={{
                                        borderBottom: "solid 1px #c7c8c9",
                                        padding: "10px 0",
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        backgroundColor: isCurrentUser ? '#e6ffe6' : 'transparent',
                                        transition: 'background-color 0.3s ease'
                                    }}>
                                        <div style={{ 
                                            width: '25%', 
                                            textAlign: 'center'
                                        }}>
                                            <p style={{ ...Ranking.P, margin: 0 }}>{indexOfFirstRecord + index + 1}</p>
                                        </div>
                                        <div style={{ 
                                            width: '25%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            cursor: 'pointer'
                                        }} onClick={() => { NavigatePages("/communityprofile", user) }}>
                                            <p style={{ ...Ranking.P, margin: 0 }}>{user?.username}</p>
                                        </div>
                                        <div style={{ 
                                            width: '25%', 
                                            textAlign: 'center'
                                        }}>
                                            <p style={{ ...Ranking.P, margin: 0 }}>{user?.total_links}</p>
                                        </div>
                                        <div style={{ 
                                            width: '25%', 
                                            textAlign: 'center'
                                        }}>
                                            <p style={{ ...Ranking.P, margin: 0 }}>{user?.joining_date ? new Date(user.joining_date).toLocaleDateString() : 'N/A'}</p>
                                        </div>
                                    </div>
                                )})}
                            </div>
                            {/* Pagination Controls */}
                            <div style={{ 
                                display: 'flex', 
                                justifyContent: 'center', 
                                alignItems: 'center', 
                                marginTop: '20px',
                                gap: '10px',
                                padding: '20px 0'
                            }}>
                                <button 
                                    onClick={() => paginate(currentPage - 1)}
                                    disabled={currentPage === 1}
                                    style={{
                                        padding: '8px 16px',
                                        border: '1px solid #ddd',
                                        borderRadius: '4px',
                                        background: currentPage === 1 ? '#f5f5f5' : 'white',
                                        cursor: currentPage === 1 ? 'not-allowed' : 'pointer'
                                    }}
                                >
                                    Previous
                                </button>
                                <span>Page {currentPage} of {totalPages}</span>
                                <button 
                                    onClick={() => paginate(currentPage + 1)}
                                    disabled={currentPage === totalPages}
                                    style={{
                                        padding: '8px 16px',
                                        border: '1px solid #ddd',
                                        borderRadius: '4px',
                                        background: currentPage === totalPages ? '#f5f5f5' : 'white',
                                        cursor: currentPage === totalPages ? 'not-allowed' : 'pointer'
                                    }}
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>}
        </>
    );
}

export default Ranking;
