import React, { useState } from 'react';
import { RiEyeFill, RiEyeOffFill } from 'react-icons/ri';

const PasswordInput = ({placeHolder = 'Enter your password', setpassword}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handlePassword = (e) => {
    setpassword(e.target.value);
  }

  return (
    <div style={{ width: '100%' }}>
      <div style={{ position: 'relative' }}>
        <input
          type={showPassword ? 'text' : 'password'}
          placeholder={placeHolder}
          name='password'
          onChange={handlePassword}
          style={{
            width: '100%',
            padding: '12px',
            height: '44px',
            paddingRight: '40px',
            borderRadius: '8px',
            border: '1px solid #ddd',
            fontSize: '14px',
            transition: 'all 0.2s ease',
            boxSizing: 'border-box'
          }}
        />
        <div
          style={{
            position: 'absolute',
            top: '50%',
            right: '12px',
            transform: 'translateY(-50%)',
            cursor: 'pointer',
            color: '#999',
            display: 'flex',
            alignItems: 'center',
            padding: '4px',
            transition: 'color 0.2s ease'
          }}
          onClick={handleTogglePassword}
          onMouseEnter={e => e.currentTarget.style.color = '#666'}
          onMouseLeave={e => e.currentTarget.style.color = '#999'}
        >
          {showPassword ? <RiEyeOffFill size={20} /> : <RiEyeFill size={20} />}
        </div>
      </div>
    </div>
  );
};

export default PasswordInput;
