import { useState, useEffect, useRef } from 'react';
import API_BASE_URL from '../../apiConfig';

export const useVersesAudio = ({ 
  verses,
  startingVerseNumber,
  surahNumber,
  currentlyPlayingVerse,
  onPlayStart,
  onPlayEnd,
  onWordChange,
  currentAudioId
}) => {
  const [audioStates, setAudioStates] = useState(
    verses.map(() => ({ isPlaying: false }))
  );
  const audioRefs = useRef(verses.map(() => new Audio()));
  const rafRefs = useRef(verses.map(() => null));
  const segmentsRefs = useRef(verses.map(() => []));

  useEffect(() => {
    return () => {
      // Cleanup on unmount
      audioRefs.current.forEach(audio => {
        audio.pause();
        audio.currentTime = 0;
      });
      rafRefs.current.forEach(rafId => {
        if (rafId) cancelAnimationFrame(rafId);
      });
    };
  }, []);

  const handleAudioEnded = (index) => {
    setAudioStates(prev => {
      const newStates = [...prev];
      newStates[index] = { ...newStates[index], isPlaying: false };
      return newStates;
    });
    onPlayEnd();
    if (rafRefs.current[index]) {
      cancelAnimationFrame(rafRefs.current[index]);
    }
  };

  const togglePlay = async (index) => {
    const absoluteVerseNumber = startingVerseNumber + index + 1;
    const audio = audioRefs.current[index];

    // If this verse is already playing, stop it
    if (audioStates[index].isPlaying) {
      audio.pause();
      audio.currentTime = 0;
      setAudioStates(prev => {
        const newStates = [...prev];
        newStates[index] = { ...newStates[index], isPlaying: false };
        return newStates;
      });
      onPlayEnd();
      if (rafRefs.current[index]) {
        cancelAnimationFrame(rafRefs.current[index]);
      }
      return;
    }

    // Stop any other playing verse
    audioRefs.current.forEach((otherAudio, i) => {
      if (i !== index && audioStates[i].isPlaying) {
        otherAudio.pause();
        otherAudio.currentTime = 0;
        setAudioStates(prev => {
          const newStates = [...prev];
          newStates[i] = { ...newStates[i], isPlaying: false };
          return newStates;
        });
        if (rafRefs.current[i]) {
          cancelAnimationFrame(rafRefs.current[i]);
        }
      }
    });

    try {
      const response = await fetch(`${API_BASE_URL}/user_dashboard/get_audio_timing`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("access_token"),
        },
        body: JSON.stringify({
          surah_number: surahNumber,
          ayah_number: absoluteVerseNumber,
          audio_id: currentAudioId || 1 // Use passed currentAudioId or default to 1
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      
      // Parse segments if they come as a string
      let parsedSegments = data.segments;
      if (typeof data.segments === 'string') {
        try {
          parsedSegments = JSON.parse(data.segments);
        } catch (e) {
          console.error('Error parsing segments:', e);
          parsedSegments = [];
        }
      }
      segmentsRefs.current[index] = parsedSegments;

      // Set up audio
      audio.src = data.audio_url;
      audio.preload = "auto";
      audio.currentTime = 0;
      audio.onended = () => handleAudioEnded(index);
      audio.onerror = (e) => {
        console.error('Audio loading error:', e);
        handleAudioEnded(index);
      };

      // Start playing
      await audio.play();
      setAudioStates(prev => {
        const newStates = [...prev];
        newStates[index] = { ...newStates[index], isPlaying: true };
        return newStates;
      });
      onPlayStart(absoluteVerseNumber - 1);

      // Set up word highlighting based on segments
      const checkTime = () => {
        if (!audio || audio.paused) {
          onWordChange(-1);
          return;
        }
        
        const currentTime = audio.currentTime * 1000;
        let currentWordIdx = -1;
        
        for (let i = 0; i < segmentsRefs.current[index].length; i++) {
          const startTime = segmentsRefs.current[index][i][2];
          const endTime = segmentsRefs.current[index][i][3];
          
          if (currentTime >= startTime && currentTime <= endTime) {
            currentWordIdx = i;
            break;
          }
        }
        
        onWordChange(currentWordIdx);
        
        if (!audio.paused) {
          rafRefs.current[index] = requestAnimationFrame(checkTime);
        } else {
          onWordChange(-1);
        }
      };
      
      rafRefs.current[index] = requestAnimationFrame(checkTime);

    } catch (error) {
      console.error('Audio/timing data fetch error:', error);
      handleAudioEnded(index);
    }
  };

  return verses.map((_, index) => ({
    isPlaying: audioStates[index].isPlaying,
    togglePlay: () => togglePlay(index)
  }));
};