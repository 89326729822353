import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";

const PaymentInfoModal = ({ handleClose, open }) => {
  const navigate = useNavigate();
  
  const closeModal = () => {
    if (typeof handleClose === 'function') {
      handleClose();
    } else {
      // Fallback if handleClose is not provided
      navigate("/dashboard");
    }
  };

  return (
    <Dialog
      open={open !== false}
      onClose={closeModal}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        style: { borderRadius: "12px", padding: "20px 50px" },
      }}
    >
      <DialogContent>
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "12px", // This will handle the spacing between children
            marginTop: "16px",
          }}
        >
          <Typography
            variant="h6"
            align="center"
            sx={{ color: "#000", fontSize: "28px", fontWeight: "600" }}
          >
            Welcome to Link Quran
          </Typography>
          <Typography
            variant="h6"
            align="center"
            sx={{ color: "#000", fontSize: "20px" }}
          >
            Your Payment is Successful!
          </Typography>
        </Box>
        <DialogActions
          sx={{ display: "flex", justifyContent: "center", marginTop: "8px" }}
        >
          <Button
            variant="contained"
            size="small"
            sx={{
              borderRadius: "100px",
              textTransform: "none",
              padding: "2px 16px",
              fontSize: "20px !important",
              backgroundColor: "#000000 !important",
              color: "white !important",
              border: "1px solid #000000 !important",
              fontWeight: "500 !important",
              "&:hover": {
                backgroundColor: "#FFFFFF",
              },
            }}
            onClick={closeModal}
          >
            Continue
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default PaymentInfoModal;
