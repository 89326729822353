import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Router from "./Router/Router";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ReactGA from 'react-ga4';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from "./redux/store";

// Initialize GA4 with your Measurement ID
ReactGA.initialize('G-Y6LJ9FE883');
ReactGA.send('pageview');

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <GoogleOAuthProvider clientId="747230895933-ejf4t7ulpb2equsa8tglafdtidkoe9b2.apps.googleusercontent.com">
          <Router />
        </GoogleOAuthProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
