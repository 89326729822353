import React, { useState } from 'react';
import { ImagePath } from '../../Constant/ImgPath';
import { useNavigate } from 'react-router-dom';
import API_BASE_URL from '../../apiConfig';
import { Typography } from "@mui/material";

function ForgotPassword() {
    const navigate = useNavigate();
    const NavigatePages = (data) => {
        navigate(data);
    }
    const [email, setEmail] = useState("")
    const handleEmail = (e) => {
        setEmail(e.target.value)
    }
    const handleAPI = () => {
        const apiUrl = `${API_BASE_URL}/auth/forgot_password`;
        const requestData = {
            email: email
        }
        const headers = {
            'Content-Type': 'application/json',
        };
        fetch(apiUrl, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(requestData),
        })
            .then(response => response.json())
            .then(data => {
                console.log(data)
                localStorage.setItem("userEmail", email)
                localStorage.setItem("otp", data?.["OTP"])
                NavigatePages("/otpverification")
            })
            .catch(error => {
                console.error('Error fetching API:', error)
            });

    }
    return (
        <>
            <div className="main-section" style={{ 
                backgroundColor: 'black', 
                minHeight: '100vh', 
                display: 'flex', 
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                margin: 0,
                padding: '1rem',
                boxSizing: 'border-box'
            }}>
                <div className="sign-left" style={{ 
                    width: '100%',
                    maxWidth: '400px', 
                    margin: '0 auto', 
                    backgroundColor: 'black', 
                    padding: '1rem',
                    boxSizing: 'border-box'
                }}>
                    <div className="sign" style={{ width: '100%' }}>
                        <div className="sign-text" style={{ 
                            marginBottom: '2rem', 
                            width: '100%', 
                            textAlign: 'center',
                            padding: '0 1rem'
                        }}>
                            <Typography 
                                variant="h3" 
                                component="h1"
                                sx={{
                                    fontWeight: 'bold',
                                    color: 'white',
                                    fontFamily: 'Roboto, sans-serif',
                                    fontSize: '2.0rem',
                                }}
                            >
                                Forgot Password?
                            </Typography>
                            <Typography 
                                variant="body1"
                                sx={{
                                    fontSize: '0.875rem', 
                                    fontWeight: '400', 
                                    color: 'white',
                                    marginTop: '0.5rem',
                                }}
                            >
                                <span style={{ color: 'white' }}>
                                    Not to worry! Just enter your email and we'll help you reset your password.
                                </span>
                            </Typography>
                        </div>

                        <div style={{ 
                            background: 'white',
                            borderRadius: '12px',
                            padding: '1.25rem',
                            boxShadow: '0px 8px 30px rgba(255, 255, 255, 0.5)',
                            width: '100%',
                            boxSizing: 'border-box'
                        }}>
                            <div className="email-area login-create-inputs" style={{ width: '100%', marginBottom: '1rem' }}>
                                <label style={{ 
                                    display: 'block', 
                                    marginBottom: '4px',
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    color: '#000',
                                    textAlign: 'left'
                                }}>
                                    Email
                                </label>
                                <input 
                                    name="email" 
                                    onChange={handleEmail} 
                                    type="email" 
                                    placeholder="Enter your email"
                                    style={{
                                        width: '100%',
                                        padding: '12px',
                                        borderRadius: '8px',
                                        border: '1px solid #ddd',
                                        fontSize: '14px',
                                        boxSizing: 'border-box',
                                        height: '45px'
                                    }}
                                />
                            </div>

                            <button 
                                onClick={handleAPI}
                                className="login-btn"
                                style={{
                                    width: '100%',
                                    padding: '0.75rem',
                                    backgroundColor: '#000',
                                    color: 'white',
                                    border: 'none',
                                    borderRadius: '8px',
                                    fontSize: '1rem',
                                    fontWeight: '500',
                                    cursor: 'pointer',
                                    height: '45px',
                                    marginTop: '4px'
                                }}
                            >
                                Reset Password
                            </button>

                            <div style={{ 
                                display: 'flex',
                                justifyContent: 'center',
                                gap: '1.5rem',
                                marginTop: '1rem'
                            }}>
                                <button
                                    onClick={() => NavigatePages("/login")}
                                    style={{ 
                                        background: 'none',
                                        border: 'none',
                                        color: '#000',
                                        textDecoration: 'none',
                                        fontWeight: '500',
                                        cursor: 'pointer',
                                        padding: 0,
                                        fontSize: '14px'
                                    }}
                                >
                                    Back to Login
                                </button>
                                <button
                                    onClick={() => NavigatePages("/signup")}
                                    style={{ 
                                        background: 'none',
                                        border: 'none',
                                        color: '#000',
                                        textDecoration: 'none',
                                        fontWeight: '500',
                                        cursor: 'pointer',
                                        padding: 0,
                                        fontSize: '14px'
                                    }}
                                >
                                    Create New Account
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ForgotPassword;
