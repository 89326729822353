import React, { useEffect, useState, useRef } from "react";
import { ImagePath } from "../../Constant/ImgPath";
import ArabicVerse from './ArabicVerse';

const Surah = ({ selectWord, surah, showdefination, getFullSurahAPI, currentlyPlayingVerse, currentWordIndex }) => {
  const [allAyat, setAllAyat] = useState([]);
  const [surahDetail, setSurahDetail] = useState({});
  const surahContainerRef = useRef(null);

  useEffect(() => {
    if (surah) {
      setSurahDetail(surah);
      setAllAyat(surah.words);
    }
  }, [surah]);

  return (
    <div
      style={{
        direction: "rtl",
      }}
      className="no-scrollbar"
    >
      {allAyat?.map((ayah, index) => {
        const key = ayah?.[0]?.key;
        return (
          <div key={index}>
            <ArabicVerse
              words={ayah}
              showTranslation={showdefination}
              onWordClick={(word, subIndex) => selectWord(word, index, subIndex)}
              verseKey={key}
              endIcon={surah?.ayat?.[key]?.[3]}
              currentWordIndex={currentlyPlayingVerse === index ? currentWordIndex : -1}
            />
          </div>
        );
      })}
    </div>
  );
};

export default Surah;
