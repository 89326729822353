import React, { useState, useRef, useEffect } from 'react';
import { Box, Button, Card, Typography, Container, Collapse, IconButton, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ImagePath } from '../Constant/ImgPath';
import AuthHeader from '../Components/AuthHeader';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import wordcard from '../assets/homepage/new.png';
import wordcardAddToDict from '../assets/homepage/learn.png';
import wordcardUnlocked from '../assets/homepage/known.png';
import ayatWithDict from '../assets/homepage/ayatwithworddictionary.png';
import taraweehMode from '../assets/homepage/taraweehmode.png';
import taraweehFlashcard from '../assets/homepage/taraweehmodeflashcard.png';
import dictionary from '../assets/homepage/dictionary.png';
import progressLinks from '../assets/homepage/linksunlockedprogress.png';
import progressDict from '../assets/homepage/dictionarygrowthprogress.png';
import AIDictionary from '../assets/homepage/AIDictionary.jpg';
import NahwAnalysis1 from '../assets/homepage/NahwAnalysis1.jpg';
import NahwAnalysis2 from '../assets/homepage/NahwAnalysis2.jpg';
import bookReader from '../assets/homepage/bookreader.jpg';
import bookReaderCard from '../assets/homepage/bookreadercard.jpg';
import './landingpage.css';

function FaqItem({ question, answer, isOpen, onClick }) {
  return (
    <Box sx={styles.faqItem}>
      <Button
        onClick={onClick}
        variant="contained"
        disableElevation
        disableRipple
        sx={{
          padding: '16px 20px',
          textAlign: 'left',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          color: 'white',
          fontFamily: 'inter',
          textTransform: 'none',
          backgroundColor: isOpen ? '#FF8C00 !important' : '#FFA500',
          '&:hover': {
            backgroundColor: '#FF8C00 !important',
          },
        }}
      >
        <Typography sx={styles.faqQuestion}>{question}</Typography>
        <IconButton 
          sx={{ 
            color: 'white',
            transition: 'transform 0.3s ease',
            transform: isOpen ? 'rotate(180deg)' : 'rotate(0)',
            padding: 0,
            marginLeft: 1,
          }}
        >
          <KeyboardArrowDownIcon />
        </IconButton>
      </Button>
      <Collapse in={isOpen}>
        <Box sx={styles.faqAnswer}>
          <Typography>{answer}</Typography>
        </Box>
      </Collapse>
    </Box>
  );
}

function LandingPage() {
  const [currentCard, setCurrentCard] = useState(0);
  const [currentNahwCard, setCurrentNahwCard] = useState(0);
  const cardImages = [
    { src: wordcard, alt: 'Word card' },
    { src: wordcardAddToDict, alt: 'Add to dictionary' },
    { src: wordcardUnlocked, alt: 'Unlocked word' },
  ];
  
  const nahwImages = [
    { src: AIDictionary, alt: 'AI Dictionary' },
    { src: NahwAnalysis1, alt: 'Nahw Analysis 1' },
    { src: NahwAnalysis2, alt: 'Nahw Analysis 2' },
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentCard((prev) => (prev + 1) % cardImages.length);
    }, 3000);
    return () => clearInterval(timer);
  }, [cardImages.length]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentNahwCard((prev) => (prev + 1) % nahwImages.length);
    }, 4000);
    return () => clearInterval(timer);
  }, [nahwImages.length]);

  const handleCardClick = (direction) => {
    if (direction === 'next') {
      setCurrentCard((prev) => (prev + 1) % cardImages.length);
    } else {
      setCurrentCard((prev) => (prev - 1 + cardImages.length) % cardImages.length);
    }
  };

  const handleNahwCardClick = (direction) => {
    if (direction === 'next') {
      setCurrentNahwCard((prev) => (prev + 1) % nahwImages.length);
    } else {
      setCurrentNahwCard((prev) => (prev - 1 + nahwImages.length) % nahwImages.length);
    }
  };

  const navigate = useNavigate();
  const [openPricing, setOpenPricing] = useState(null);
  const videoRef = useRef(null);
  const [videoLoaded, setVideoLoaded] = useState(false);

  const handleLogin = () => {
    navigate('/login');
  };

  const handleSignUp = () => {
    navigate('/signup');
  };

  const handlePricingClick = (index) => {
    setOpenPricing(openPricing === index ? null : index);
  };

  const handleVideoLoaded = () => {
    setVideoLoaded(true);
  };

  const pricingData = [
    {
      title: "Free",
      subtitle: "Quran Access",
      features: [
        "Full Quran access",
        "Basic word lookup",
        "Standard translations",
        "Community features"
      ],
      price: "$0",
      cta: "Sign Up"
    },
    {
      title: "Premium",
      subtitle: "Islamic Library",
      features: [
        "Access to 2500+ Islamic books",
        "AI integrated word lookup",
        "Personal dictionary & word tracking",
        "Enhanced translations",
        "Unlimited saved words"
      ],
      price: "$9.99",
      period: "month",
      cta: "Sign Up"
    }
  ];

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      const headerOffset = 0; 
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  return (
    <Box 
      sx={{
        minHeight: '100vh',
        width: '100%',
        margin: 0,
        padding: 0,
        backgroundColor: 'black',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        overflowX: 'hidden'
      }}
    >
      <AuthHeader currentPage="landing" />
      
      {/* Hero Section with Updated Layout */}
      <Box sx={styles.heroSection}>
        {/* Hero Content */}
        <Container 
          maxWidth="lg" 
          sx={{
            ...styles.heroContent,
            position: 'relative',
            minHeight: { xs: '100vh', md: '90vh' },
          }}
        >
          <Box sx={styles.logoContainer}>
            {/* Updated to match the color of the text */}
            <img
              src={ImagePath.logo}
              alt="LinkQuran Logo"
              style={{ width: '70px', height: '70px', filter: 'brightness(0) invert(1)' }}
            />
            <Typography variant="h1" sx={styles.heroTitle}>
              LinkQuran
            </Typography>
          </Box>

          <Typography variant="h2" sx={styles.heroSubtitle}>
            Your Home For Learning Arabic
          </Typography>

          {/* New AI Premium Banner */}
          {/* <Box sx={{
            background: 'linear-gradient(135deg, rgba(255,255,255,0.03) 0%, rgba(255,255,255,0.08) 100%)',
            border: '1px solid rgba(255,255,255,0.1)',
            borderRadius: '8px',
            padding: '8px 16px',
            minHeight: '32px',
            marginBottom: { xs: 2, md: 2 },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '400px',
            width: 'auto',
            backdropFilter: 'blur(10px)',
            boxShadow: `
              0 4px 12px rgba(0, 149, 255, 0.1),
              0 0 20px rgba(255, 255, 255, 0.05),
              inset 0 0 8px rgba(255, 255, 255, 0.05)
            `,
            animation: 'fadeIn 0.5s ease-in-out',
            position: 'relative',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: -1,
              left: -1,
              right: -1,
              bottom: -1,
              borderRadius: '9px',
              background: 'linear-gradient(135deg, rgba(0,149,255,0.2), rgba(255,255,255,0.1))',
              zIndex: -1,
              filter: 'blur(8px)',
              opacity: 0.5,
            },
            '&:hover': {
              background: 'linear-gradient(135deg, rgba(255,255,255,0.05) 0%, rgba(255,255,255,0.1) 100%)',
              boxShadow: `
                0 4px 15px rgba(0, 149, 255, 0.15),
                0 0 25px rgba(255, 255, 255, 0.08),
                inset 0 0 10px rgba(255, 255, 255, 0.08)
              `,
            }
          }}>
            <Typography sx={{
              background: 'linear-gradient(135deg, #FFFFFF 0%, #0095FF 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              fontFamily: 'inter',
              fontSize: { xs: '0.8rem', md: '0.9rem' },
              fontWeight: 600,
              letterSpacing: '0.3px',
              lineHeight: 1.2,
              textAlign: 'center',
            }}>
              New: AI Writing Analysis, checks spelling, grammar, and irab.
            </Typography>
          </Box> */}
          
          {/* Video placed between title and buttons */}
          <Box sx={styles.videoContainer}>
            <video
              ref={videoRef}
              autoPlay
              muted
              loop
              playsInline
              onLoadedData={handleVideoLoaded}
              style={{
                width: '100%',
                height: { xs: 'auto', md: '460px' },   // Increased height for medium screens
                maxHeight: { xs: '250px', md: '460px' },  // Max height for small screens
                objectFit: 'contain',  // Changed from cover to contain
                borderRadius: '12px',
                opacity: videoLoaded ? 1 : 0,
                transition: 'opacity 1s ease-in-out',
                boxShadow: '0 10px 30px rgba(255, 165, 0, 0.3)',
              }}
            >
              <source src={require('../assets/video/linkquran7.mp4')} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Box>
          
          <Box sx={styles.heroButtonsContainer}>
            <Button
              variant="contained"
              sx={styles.loginButton}
              onClick={handleLogin}
            >
              Login
            </Button>
            <Button
              variant="contained"
              sx={styles.signupButton}
              onClick={handleSignUp}
            >
              Sign Up
            </Button>
          </Box>
          
          <Box sx={styles.scrollIndicator} onClick={() => scrollToSection('features-section')}>
            <Typography sx={styles.scrollText}>Explore Features</Typography>
            <KeyboardArrowDownIcon sx={{ 
              color: 'white', 
              fontSize: '2rem', 
              animation: 'bounce 2s infinite',
              opacity: { xs: 1, md: 0.8 },
              '&:hover': {
                opacity: 1
              }
            }} />
          </Box>
        </Container>
      </Box>

      <Container 
        maxWidth="lg"
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
          padding: 2,
          margin: '0 auto',
        }}
      >
        {/* Main Content Area */}
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {/* Features Section */}
          <Box id="features-section" sx={styles.featuresSection}>
            <Typography variant="h2" sx={{ ...styles.sectionTitle, mt: { xs: 6, md: 8 } }}>
              Features
            </Typography>

            <Grid container spacing={4}>
              {/* Interactive Word Cards Feature */}
              <Grid item xs={12}>
                <Box sx={styles.featureCard}>
                  <Box sx={styles.featureContent}>
                    <Typography variant="h4" sx={styles.featureTitle}>Interactive Word Cards</Typography>
                    <Typography sx={styles.featureDescription}>
                      Master Quranic vocabulary through interactive word cards. Create links between words and their meanings, building a personalized learning experience.
                    </Typography>
                  </Box>
                  <Box sx={styles.featureImagesWrapper}>
                    <Box sx={styles.featureImageRow}>
                      <img 
                        src={ayatWithDict} 
                        alt="Ayat view" 
                        style={{...styles.featureImage, maxWidth: '450px', maxHeight: '290px'}} 
                      />
                    </Box>
                    <Box sx={styles.cardSlider}>
                      <Box sx={styles.cardsContainer}>
                        {cardImages.map((image, index) => (
                          <Box
                            key={index}
                            sx={{
                              ...styles.cardSlide,
                              opacity: currentCard === index ? 1 : 0,
                              transform: `translateX(${(index - currentCard) * 100}%)`,
                            }}
                          >
                            <img 
                              src={image.src} 
                              alt={image.alt} 
                              style={{...styles.featureImage, height: '400px', width: 'auto'}} 
                            />
                          </Box>
                        ))}
                      </Box>
                      <Box sx={{...styles.navigationButtons, marginTop: '20px', display: 'flex', justifyContent: 'center', gap: '20px' }}>
                        <Box 
                          onClick={() => handleCardClick('prev')} 
                          sx={styles.sliderButton}
                        >
                          ‹
                        </Box>
                        <Box 
                          onClick={() => handleCardClick('next')} 
                          sx={styles.sliderButton}
                        >
                          ›
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>

              {/* Comprehensive Dictionary */}
              {/* <Grid item xs={12}>
                <Box sx={styles.featureCard}>
                  <Box sx={styles.featureContent}>
                    <Typography variant="h4" sx={styles.featureTitle}>Comprehensive Dictionary</Typography>
                    <Typography sx={styles.featureDescription}>
                      Access 32 integrated dictionaries including Hans Wehr and Lane's Lexicon. Your complete Arabic learning resource.
                    </Typography>
                  </Box>
                  <Box sx={styles.featureImagesContainer}>
                    <img 
                      src={dictionary} 
                      alt="Dictionary interface" 
                      style={{width: 'auto', maxWidth: '450px', height: 'auto', maxHeight: '490px', objectFit: 'contain'}} 
                    />
                  </Box>
                </Box>
              </Grid> */}

              {/* Progress Tracking */}
              {/* <Grid item xs={12}>
                <Box sx={styles.featureCard}>
                  <Box sx={styles.featureContent}>
                    <Typography variant="h4" sx={styles.featureTitle}>Track Your Progress</Typography>
                    <Typography sx={styles.featureDescription}>
                      Monitor your learning journey with detailed progress tracking. Watch your vocabulary grow and track your unlocked word connections.
                    </Typography>
                  </Box>
                  <Box sx={styles.featureImagesContainer}>
                    <img src={progressLinks} alt="Links progress" style={styles.featureImage} />
                    <img src={progressDict} alt="Dictionary progress" style={styles.featureImage} />
                  </Box>
                </Box>
              </Grid> */}

              {/* Taraweeh Mode Feature */}
              <Grid item xs={12}>
                <Box sx={styles.featureCard}>
                  <Box sx={styles.featureContent}>
                    <Typography variant="h4" sx={styles.featureTitle}>Taraweeh Mode</Typography>
                    <Typography sx={styles.featureDescription}>
                      Practice your listening skills with integrated Quran recitation and flashcards to help listening and memorization.
                    </Typography>
                  </Box>
                  <Box sx={styles.featureImagesContainer}>
                    <img src={taraweehMode} alt="Taraweeh mode" style={styles.featureImage} />
                    <img src={taraweehFlashcard} alt="Taraweeh flashcards" style={styles.featureImage} />
                  </Box>
                </Box>
              </Grid>

              {/* AI Dictionary/Nahw Analysis Feature */}
              {/* <Grid item xs={12}>
                <Box sx={styles.featureCard}>
                  <Box sx={styles.featureContent}>
                    <Typography variant="h4" sx={styles.featureTitle}>AI Dictionary/Nahw Analysis</Typography>
                    <Typography sx={styles.featureDescription}>
                      Our AI-powered dictionary and Nahw analysis tools help you understand Arabic words and grammar. Currently in beta-testing.
                    </Typography>
                  </Box>
                  <Box sx={styles.featureImagesWrapper}>
                    <Box sx={styles.cardSlider}>
                      <Box sx={styles.cardsContainer}>
                        {nahwImages.map((image, index) => (
                          <Box
                            key={index}
                            sx={{
                              ...styles.cardSlide,
                              opacity: currentNahwCard === index ? 1 : 0,
                              transform: `translateX(${(index - currentNahwCard) * 100}%)`,
                            }}
                          >
                            <img 
                              src={image.src} 
                              alt={image.alt} 
                              style={{...styles.featureImage, height: '400px', width: 'auto', objectFit: 'contain'}} 
                            />
                          </Box>
                        ))}
                      </Box>
                      <Box sx={{...styles.navigationButtons, marginTop: '20px', display: 'flex', justifyContent: 'center', gap: '20px' }}>
                        <Box 
                          onClick={() => handleNahwCardClick('prev')} 
                          sx={styles.sliderButton}
                        >
                          ‹
                        </Box>
                        <Box 
                          onClick={() => handleNahwCardClick('next')} 
                          sx={styles.sliderButton}
                        >
                          ›
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid> */}

              {/* Islamic Library Feature */}
              {/* <Grid item xs={12}>
                <Box sx={styles.featureCard}>
                  <Box sx={styles.featureContent}>
                    <Typography variant="h4" sx={styles.featureTitle}>Islamic Library - 2500+ Books</Typography>
                    <Typography sx={styles.featureDescription}>
                      Access a vast collection of over 2,500 Islamic books with word-by-word dictionary integration. Our library includes ejtaal integration, AI translation, and a personal dictionary for word tracking that updates throughout the page.
                    </Typography>
                  </Box>
                  <Box sx={styles.featureImagesContainer}>
                    <img 
                      src={bookReader} 
                      alt="Book Reader" 
                      style={{...styles.featureImage, maxWidth: '450px', maxHeight: '360px'}} 
                    />
                    <img 
                      src={bookReaderCard} 
                      alt="Book Reader Dictionary Card" 
                      style={{...styles.featureImage, maxWidth: '380px', maxHeight: '360px'}} 
                    />
                  </Box>
                </Box>
              </Grid> */}
            </Grid>
          </Box>

          {/* Pricing Section */}
          {/* <Box id="pricing-section" sx={styles.pricingSection}>
            <Typography variant="h2" sx={{ ...styles.sectionTitle, mt: { xs: 6, md: 8 } }}>
              Pricing Plans
            </Typography>
            <Box sx={styles.pricingContainer}>
              {pricingData.map((plan, index) => (
                <Card key={index} sx={{
                  width: '100%',
                  maxWidth: { xs: '280px', md: '320px' },
                  borderRadius: '16px',
                  overflow: 'hidden',
                  backgroundColor: 'white',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  boxShadow: index === 1 ? 
                    '0 0 25px rgba(72, 133, 237, 0.6), 0 0 15px rgba(255, 255, 255, 0.3)' :
                    '0 0 15px rgba(255, 255, 255, 0.3)',
                  border: index === 1 ? '2px solid rgba(72, 133, 237, 0.7)' : 'none',
                  transform: index === 1 ? 'scale(1.05)' : 'scale(1)',
                  '&:hover': {
                    transform: 'translateY(-10px)',
                    boxShadow: index === 1 ? 
                      '0 10px 30px rgba(72, 133, 237, 0.7), 0 5px 20px rgba(255, 255, 255, 0.4)' :
                      '0 10px 30px rgba(255, 255, 255, 0.4)'
                  },
                }}>
                  <Box sx={{
                    bgcolor: index === 1 ? '#4885ed' : '#f8f8f8',
                    p: 3,
                    textAlign: 'center'
                  }}>
                    <Typography 
                      variant="h5" 
                      sx={{ 
                        fontWeight: 700, 
                        color: index === 1 ? 'white' : '#333',
                        mb: 0.5
                      }}
                    >
                      {plan.title}
                    </Typography>
                    <Typography 
                      variant="subtitle1" 
                      sx={{ 
                        fontWeight: 500, 
                        color: index === 1 ? 'rgba(255, 255, 255, 0.9)' : '#666',
                        mb: 1.5,
                        fontStyle: 'italic'
                      }}
                    >
                      {plan.subtitle}
                    </Typography>
                    <Typography 
                      variant="h3" 
                      sx={{ 
                        fontWeight: 800, 
                        color: index === 1 ? 'white' : '#333',
                        display: 'flex',
                        alignItems: 'baseline',
                        justifyContent: 'center'
                      }}
                    >
                      {plan.price}
                      {plan.period && (
                        <Typography component="span" sx={{ fontWeight: 400, fontSize: '1rem', ml: 0.5, opacity: 0.8 }}>
                          /{plan.period}
                        </Typography>
                      )}
                    </Typography>
                  </Box>
                  
                  <Box sx={{ p: 3 }}>
                    <Box sx={{ mb: 3 }}>
                      {plan.features.map((feature, i) => (
                        <Box key={i} sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
                          <Typography
                            component="span"
                            sx={{
                              display: 'inline-flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              width: 20,
                              height: 20,
                              borderRadius: '50%',
                              bgcolor: index === 1 ? '#4885ed' : '#4CAF50',
                              color: 'white',
                              fontSize: '0.75rem',
                              fontWeight: 'bold',
                              mr: 1.5
                            }}
                          >
                            ✓
                          </Typography>
                          <Typography variant="body2" sx={{ color: '#333' }}>
                            {feature}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                    
                    <Button
                      variant="contained"
                      fullWidth
                      sx={{
                        bgcolor: index === 1 ? '#4885ed' : '#4CAF50',
                        color: 'white',
                        py: 1.5,
                        textTransform: 'none',
                        fontSize: '1rem',
                        fontWeight: 600,
                        '&:hover': {
                          bgcolor: index === 1 ? '#3b76d9' : '#388E3C',
                        }
                      }}
                      onClick={() => handleSignUp()}
                    >
                      {plan.cta}
                    </Button>
                  </Box>
                </Card>
              ))}
            </Box>
          </Box> */}

          {/* Support Section */}
          <Box id="support-section" sx={styles.pricingSection}>
            <Typography variant="h2" sx={styles.sectionTitle}>
              Support
            </Typography>
            <Card 
              sx={{
                p: 4,
                width: '100%',
                maxWidth: 600,
                backgroundColor: 'white',
                boxShadow: `
                  0px 0px 15px rgba(255, 255, 255, 0.3),
                  0px 0px 30px rgba(255, 255, 255, 0.2),
                  0px 0px 45px rgba(255, 255, 255, 0.1)
                `,
                borderRadius: '20px',
                transition: 'all 0.3s ease',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: `
                    0px 0px 20px rgba(255, 255, 255, 0.4),
                    0px 0px 40px rgba(255, 255, 255, 0.3),
                    0px 0px 60px rgba(255, 255, 255, 0.2)
                  `,
                },
                mb: 4,
              }}
            >
              <Typography
                sx={{
                  color: '#333',
                  fontSize: '1rem',
                  lineHeight: 1.6,
                  fontFamily: 'inter',
                  textAlign: 'center',
                }}
              >
                We offer free access to to the Quran upon sign-up. The platform is sustained by optional monthly and one-time payments. Contact us anytime with questions or feedback.
              </Typography>
            </Card>
          </Box>
          
          <Typography 
            variant="body2" 
            color="white"
            sx={{ 
              mt: 4,
              mb: 8,
              fontFamily: 'inter',
              textAlign: 'center'
            }}
          >
            Copyright {new Date().getFullYear()} LinkQuran. All rights reserved.
            <div>Contact: linkquran@protonmail.com</div>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}

// Updated styles to include new video container position
const styles = {
  navigation: {
    display: 'flex',
    justifyContent: 'center',
    gap: '20px',
    padding: '20px 0',
    backgroundColor: 'black',
    position: 'sticky',
    top: 0,
    zIndex: 1000,
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
  },
  navButton: {
    color: 'white',
    textTransform: 'none',
    fontSize: '1rem',
    fontFamily: 'inter',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
  },
  featuresSection: {
    padding: { xs: '10px 10px', md: '20px 20px' },
    backgroundColor: '#000',
    color: '#fff',
    width: '100%',
  },
  featureCard: {
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
    borderRadius: '16px',
    padding: { xs: '16px', md: '24px' },
    marginBottom: { xs: '16px', md: '24px' },
    border: '1px solid rgba(255, 255, 255, 0.1)',
    transition: 'transform 0.3s ease, border-color 0.3s ease',
    '&:hover': {
      transform: 'translateY(-5px)',
      borderColor: 'rgba(255, 255, 255, 0.2)',
    },
  },
  featureContent: {
    marginBottom: { xs: '8px', md: '12px' },
    textAlign: 'center',
  },
  featureTitle: {
    color: '#fff',
    fontSize: { xs: '1.25rem', md: '1.75rem' },
    marginBottom: { xs: '12px', md: '16px' },
    fontFamily: 'inter',
    fontWeight: 600,
  },
  featureDescription: {
    color: '#fff',
    fontSize: { xs: '0.9rem', md: '1rem' },
    opacity: 0.8,
    fontFamily: 'inter',
    maxWidth: '800px',
    margin: '0 auto',
  },
  featureImagesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: '16px', md: '20px' },
    width: '100%',
  },
  featureImageRow: {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    gap: { xs: '16px', md: '20px' },
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardSlider: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '15px',
    width: '100%',
    position: 'relative',
    padding: '20px 0',
  },
  navigationButtons: {
    display: 'flex',
    justifyContent: 'center',
    gap: '20px',
    width: '100%',
    marginBottom: '10px',
  },
  sliderButton: {
    cursor: 'pointer',
    fontSize: '2.5rem',
    color: 'white',
    padding: '5px 15px',
    userSelect: 'none',
    transition: 'all 0.3s ease',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '20px',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    border: '1px solid rgba(255, 255, 255, 0.3)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      border: '1px solid rgba(255, 255, 255, 0.5)',
      transform: 'scale(1.05)',
    },
  },
  cardsContainer: {
    position: 'relative',
    width: { xs: '330px', md: '320px' },
    height: { xs: '370px', md: '350px' },
    // overflow: 'hidden',
    borderRadius: '8px',
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
  },
  cardSlide: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.5s cubic-bezier(0.4, 0, 0.2, 1)',
    opacity: 0,
    '&:hover': {
      transform: 'scale(1.05) !important',
    },
  },
  featureImagesContainer: {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    gap: { xs: '16px', md: '20px' },
    padding: '10px 0',
    justifyContent: 'center',
    alignItems: 'center',
  },
  featureImage: {
    width: '100%',
    maxWidth: { xs: '300px', md: 'auto' },
    height: { xs: 'auto', md: '200px' },
    objectFit: 'contain',
    borderRadius: '8px',
    transition: 'transform 0.3s ease',
  },
  heroSection: {
    position: 'relative',
    minHeight: { xs: '100vh', md: '90vh' }, // Reduced height slightly
    width: '100%',
    overflow: 'visible', // Changed from 'hidden' to allow Learn More to be visible
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    backgroundColor: 'black',
    paddingTop: { xs: '80px', md: '60px' }, // Reduced top padding
  },
  heroContent: {
    position: 'relative',
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    textAlign: 'center',
    padding: { xs: '16px', md: '24px' }, // Reduced padding
    gap: { xs: 1, md: 3 }, // Further reduced gap for mobile
    height: '100%',
    maxWidth: '1200px',
    margin: '0 auto',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: { xs: 1, md: 2 },
    gap: 2,
  },
  heroTitle: {
    fontWeight: 700,
    color: 'white',
    fontFamily: 'inter',
    fontSize: { xs: '2.5rem', sm: '3rem', md: '3.5rem' },
    textShadow: '0 0 30px rgba(255, 165, 0, 0.7)',
    letterSpacing: '1px',
  },
  heroSubtitle: {
    color: 'white',
    fontFamily: 'inter',
    fontSize: { xs: '1.1rem', sm: '1.3rem', md: '1.5rem' },
    fontWeight: 400,
    maxWidth: '600px',
    textShadow: '0 0 20px rgba(255, 255, 255, 0.7)',
    marginBottom: { xs: 2, md: 3 },
  },
  videoContainer: {
    width: '100%',
    maxWidth: { xs: '300px', md: '450px' },  // Reduced max width for smaller screens
    height: { xs: 'auto', md: '450px' },   // Increased height for medium screens
    marginBottom: { xs: 2, md: 0 },
    borderRadius: '12px',
    overflow: 'hidden',
    position: 'relative',
  },
  heroButtonsContainer: {
    display: 'flex',
    gap: { xs: 2, md: 3 },
    marginTop: { xs: 2, md: 2 },
    marginBottom: { xs: 4, md: 4 },
    justifyContent: 'center',
    flexDirection: { xs: 'row', sm: 'row' },
    width: '100%',
    maxWidth: { xs: '100%', sm: 'none' },
    position: 'relative',
    zIndex: 3,
  },
    scrollIndicator: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      cursor: 'pointer',
      animation: 'fadeIn 2s ease-in-out',
      marginTop: 0,  // Add margin top instead of absolute positioning
      padding: '10px 20px',
      borderRadius: '20px',
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      backdropFilter: 'blur(5px)',
      transition: 'all 0.3s ease',
      '&:hover': {
        backgroundColor: 'rgba(128, 128, 128, 0.7)',
        transform: 'translateY(-5px)',
        boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)'
      },
  },
  loginButton: { 
    minWidth: { xs: '120px', md: '140px' },
    backgroundColor: 'white !important',
    color: 'black !important',
    '&:hover': { 
      backgroundColor: 'black !important',
      color: 'white !important',
    },
    borderRadius: '10px',
    height: { xs: '40px', md: '50px' },
    fontFamily: 'inter',
    fontWeight: 600,
    fontSize: { xs: '0.9rem', md: '1rem' },
    transition: 'all 0.3s ease-in-out',
    boxShadow: '0 0 20px rgba(255, 255, 255, 0.3)',
    '&:hover': {
      backgroundColor: 'black !important',
      color: 'white !important',
      boxShadow: '0 0 25px rgba(255, 255, 255, 0.5)',
    },
  },
  signupButton: { 
    minWidth: { xs: '120px', md: '140px' },
    backgroundColor: 'white !important',
    color: 'black !important',
    border: '2px solid transparent',
    '&:hover': { 
      backgroundColor: 'black !important',
      color: 'white !important',
      borderColor: 'white',
      boxShadow: '0 0 25px rgba(255, 255, 255, 0.5)',
    },
    borderRadius: '10px',
    height: { xs: '40px', md: '50px' },
    fontFamily: 'inter',
    fontWeight: 600,
    fontSize: { xs: '0.9rem', md: '1rem' },
    transition: 'all 0.3s ease-in-out',
    boxShadow: '0 0 20px rgba(255, 255, 255, 0.3)',
  },
  pricingSection: {
    width: '100%',
    maxWidth: 600,
    mb: 16,
    mt: 8,
    scrollMarginTop: '100px',
  },
  pricingContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: { xs: '30px', md: '40px' },
    position: 'relative',
    zIndex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '60px'
  },
  sectionTitle: {
    color: 'white',
    fontSize: { xs: '1.75rem', md: '2rem' },
    fontWeight: 'bold',
    textAlign: 'center',
    mb: 4,
    fontFamily: 'inter',
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
  },
  scrollText: {
    color: 'white',
    fontFamily: 'inter',
    fontSize: '0.9rem',
    marginBottom: 1,
    textShadow: '0 0 10px rgba(255, 255, 255, 0.5)',
    fontWeight: 500,
    letterSpacing: '0.5px',
  },
};

export default LandingPage;
