import React, { useEffect, useState } from "react";
import { Select } from "antd";

const { Option } = Select;

const VoiceAndTranslatorSettings = ({
  handleChangeVoice,
  handleChangeTranslator,
}) => {
  const [selectedVoice, setSelectedVoice] = useState(() => 
    localStorage.getItem("selectedVoice") || "yasserAlDosari"
  );
  const [selectedTranslator, setSelectedTranslator] = useState(() =>
    localStorage.getItem("selectedTranslator") || "saheehinternational"
  );

  useEffect(() => {
    // If no voice is set in localStorage, set yasserAlDosari as default
    if (!localStorage.getItem("selectedVoice")) {
      localStorage.setItem("selectedVoice", "yasserAlDosari");
    }
    // Set the fetched values as the default selected values
    setSelectedVoice(localStorage.getItem("selectedVoice"));
    setSelectedTranslator(localStorage.getItem("selectedTranslator"));
  }, []);

  return (
    <div style={{ marginTop: "1.5rem" }}>
      <p className="info-heading">
        Compiled English Words by Dr. Shehnaz Shaikh & Ms. Kausar Khatri
      </p>
      <div className="compile-info">
        <p className="info-heading">Voice</p>
        <Select
          value={selectedVoice}
          style={{
            width: "200px",
            height: "40px",
            backgroundColor: "#f0f0f0",
            borderRadius: "5px",
            outline: "none",
          }}
          onChange={(value) => {
            handleChangeVoice(value);
            setSelectedVoice(value);
            localStorage.setItem("selectedVoice", value);
          }}
        >
          <Option value="yasserAlDosari">Yasser Al-Dosari</Option>
        </Select>

        <p className="info-heading">Translator</p>
        <Select
          value={selectedTranslator}
          style={{
            width: "200px",
            height: "40px",
            backgroundColor: "#f0f0f0",
            borderRadius: "5px",
            outline: "none",
          }}
          onChange={(value) => {
            handleChangeTranslator(value);
            setSelectedTranslator(value);
            localStorage.setItem("selectedTranslator", value);
          }}
        >
          <Option value="">
            <em>Select Translator</em>
          </Option>
          <Option value="muftitaqiusmani">Mufti Taqi Usmani</Option>
          <Option value="saheehinternational">Saheeh International</Option>
        </Select>
      </div>
    </div>
  );
};

export default VoiceAndTranslatorSettings;
