import React, { useRef, useState, useEffect } from 'react'
import Nav from '../Components/Nav'
import Footer from '../Components/Footer'
import { Container, Row, Col, Dropdown } from 'react-bootstrap';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
// import faker from 'faker';
import { faker } from '@faker-js/faker';
import profileimg from '../assets/AG.png'
import Flag from '../assets/flag.png';
import BadgeOne from '../assets/400.png';
import BadgeTwo from '../assets/known-words-19000.png';
import BadgeThree from '../assets/known-words-50000.png';
import Overlay from 'react-bootstrap/Overlay';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import ProgressImg from '../assets/progressimg.png';
import GirlAvatar from '../assets/girlavatar.png';
import { useLocation } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';
import API_BASE_URL from '../apiConfig';


export const Popupstatetwo = ({ headerData }) => {
    const value = 0.66;
    const location = useLocation();
    const receivedData = headerData;
    const [userStats, setUserStats] = useState({})
    const [selectedValue, setSelectedValue] = useState('Known')
    const [show, setShow] = useState(false);
    const [popup, setPopup] = useState(false);
    const target = useRef(null);
    const navigate = useNavigate();
    useEffect(() => {
        console.log("In UseEffect")
        getStatsAPI();
    }, [])
    const handleDropdownSelect = (selectedItem) => {
        setSelectedValue(selectedItem);
    };
    const getStatsAPI = () => {
        const apiUrl = `${API_BASE_URL}/user_dashboard/get_user_stats`;
        const requestData = {}
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem("access_token"),
        };
        fetch(apiUrl, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(requestData),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                console.log("API_Data", data)
                setUserStats(data)
                localStorage.setItem('localdata', "");
            })
            .catch(error => {
                console.error('Error fetching API:', error);
            });
    }
    console.log("stats", userStats);
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
    );

    const options = {
        responsive: true,
        plugins: {

            title: {
                display: false,
                text: 'Cumulative',
            },
        },
    };

    const weekData = {
        labels: userStats?.["weekly"] ? Object.keys(userStats["weekly"]) : [],
        datasets: [
            {
                label: `${selectedValue} Words`,
                data: userStats?.["weekly"]
                    ? Object.values(userStats["weekly"]).map(day => day?.[selectedValue] || 0)
                    : [],
                backgroundColor: '#BFBFBF',
            },
        ],
    };

    const monthData = {
        labels: userStats?.["monthly"] ? Object.keys(userStats["monthly"]) : [],
        datasets: [
            {
                label: `${selectedValue} Words`,
                data: userStats?.["monthly"]
                    ? Object.values(userStats["monthly"]).map(day => day?.[selectedValue] || 0)
                    : [],
                backgroundColor: '#BFBFBF',
            },
        ],
    };
    const stats = {

        bg: {
            // background: "rgb(244, 245, 246)",
            padding: "33px 24px"
        },


    };

    const NavigatePages = (data, header) => {
        navigate(data, { state: header });
    }
    return (
        <div>

            <div style={{
                width: "28%", float: "right",
                position: "relative",
                right: "12%",
                top: "55px"
            }} className='points-section'>
                <div className='mx-auto circular-progress mt-5' style={{ width: "180px" }}>
                    <CircularProgressbarWithChildren max={receivedData?.["user_info"]?.["level_max"]} value={receivedData?.["user_info"]?.["points"]}>
                        <div className='progress-content'>
                            <img src={ProgressImg} />
                            <div className='link-text text-center mb-4'>
                                <span style={{ color: "#02A723" }}>L</span>
                                <span style={{ color: "#02A723" }}>I</span>
                                <span style={{ color: "#02A723" }}>N</span>
                                <span style={{ color: "#FFE500" }}>K</span>
                                <span style={{ color: "#000000" }}>Q</span>
                                <span style={{ color: "#000000" }}>U</span>
                                <span style={{ color: "#000000" }}>R</span>
                                <span style={{ color: "#000000" }}>A</span>
                                <span style={{ color: "#000000" }}>N</span>
                            </div>
                        </div>
                    </CircularProgressbarWithChildren>
                </div>
                <div className='total-points'>
                    {receivedData?.["user_info"]?.["points"]}/{receivedData?.["user_info"]?.["level_max"]} Points
                </div>
                <div className='total-points' style={{ fontSize: "14px", marginTop: "20px", marginBottom: "30px" }}>
                    {receivedData?.["user_info"]?.["streak"]} Day Streak
                </div>
                <div className="days-progress-bar d-flex align-items-center justify-content-center" style={{ gap: "20px" }}>
                    {userStats["week_score"] && Object.entries(userStats["week_score"]).map(([date, value]) => (
                        <div style={{ width: "35px" }}>
                            <CircularProgressbarWithChildren
                                max={receivedData?.["user_info"]?.["level_max"]}
                                value={value}
                                styles={{
                                    path: {
                                        // Path color
                                        stroke: `#E89B2F`
                                    },

                                }}
                            >
                                <div className='progress-content'>
                                    <div className='link-text text-center mb-1'>
                                        <svg width="15" height="20" viewBox="0 0 20 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.99957 29C7.99569 28.9998 6.03808 28.3907 4.38054 27.2515C2.72301 26.1124 1.44201 24.4957 0.70364 22.6113C-0.0347318 20.7268 -0.196421 18.6614 0.239536 16.6829C0.675493 14.7043 1.68899 12.9039 3.14862 11.515C4.93802 9.81144 9.33288 6.74419 8.66618 0C16.6665 5.39535 20.6667 10.7907 12.6664 18.8837C13.9997 18.8837 15.9998 18.8837 19.3333 15.5521C19.6933 16.5947 20 17.7156 20 18.8837C20 21.5667 18.9464 24.1398 17.0709 26.037C15.1955 27.9342 12.6519 29 9.99957 29Z" fill="#BFBFBF" />
                                        </svg>

                                    </div>
                                </div>
                            </CircularProgressbarWithChildren>
                            <div className='progress-days text-center'>{date}</div>
                        </div>
                    ))}
                </div>

                <div className='community-progress'>

                    <div className='w-100 progress-bar-community'>
                        <progress max={receivedData?.["user_info"]?.["level_max"]} value={receivedData?.["user_info"]?.["known_words"]} style={{ accentColor: "#E89B2F" }}></progress>
                    </div>

                </div>

                <div className='known-words-to-level'><span style={{ color: "black", fontWeight: "700" }}>{(receivedData?.["user_info"]?.["level_max"] - receivedData?.["user_info"]?.["known_words"])}</span> Known Words to {userStats?.["next_level"]}: <span style={{ color: "black", fontWeight: "700" }}>Q</span></div>


                <div className='daily-goal-box d-flex align-items-center justify-content-between'>
                    <div className='text-center'>
                        <div className='daily-goal-heading'>50</div>
                        <div className='daily-goal-text'>Daily Goals</div>
                    </div>
                    <div className='text-center'>
                        <div className='daily-goal-heading'>50</div>
                        <div className='daily-goal-text'>Daily Goals</div>
                    </div>
                    <div className='text-center'>
                        <div className='daily-goal-heading'>50</div>
                        <div className='daily-goal-text'>Daily Goals</div>
                    </div>
                </div>

                <Row>
                    <Col md={8}><div className='known-words-50' style={{ marginLeft: "12px" }}>KNOWN WORDS</div></Col>
                    <Col md={4}><div className='known-words-50'>{receivedData?.["user_info"]?.["known_words"]}</div></Col>
                </Row>
                <Row>
                    <Col md={8}><div className='known-words-50' style={{ marginLeft: "12px" }}>NEW WORDS</div></Col>
                    <Col md={4}><div className='known-words-50'>{receivedData?.["user_info"]?.["new_words"]}</div></Col>
                </Row>
                <Row>
                    <Col md={8}><div className='known-words-50' style={{ marginLeft: "12px" }}>LEARNED WORDS</div></Col>
                    <Col md={4}><div className='known-words-50'>{receivedData?.["user_info"]?.["learned_words"]}</div></Col>
                </Row>
                <Row>
                    <Col md={8}><div className='known-words-50' style={{ marginLeft: "12px" }}>RECOGNIZED WORDS</div></Col>
                    <Col md={4}><div className='known-words-50'>{receivedData?.["user_info"]?.["recognized_words"]}</div></Col>
                </Row>
                <Row>
                    <Col md={8}><div className='known-words-50' style={{ marginLeft: "12px" }}>FAMILIAR WORDS</div></Col>
                    <Col md={4}><div className='known-words-50'>{receivedData?.["user_info"]?.["familiar_words"]}</div></Col>
                </Row>
                <div className='badges d-flex align-items center justify-content-center' style={{ gap: "50px", marginTop: "40px" }}>
                    {userStats && userStats?.["user_badges"]?.slice(0, 3)?.map((badge, index) => (<div>
                        <img style={{ width: "70px" }} src={badge?.["badge_image"]} />
                    </div>))}
                </div>
                <div onClick={() => { NavigatePages("/statistics", headerData) }} style={{ fontSize: "14px", cursor: "pointer" }} className='share-your-progress'>
                    View all statistics
                </div>
            </div>
        </div>
    )
}

export default Popupstatetwo