import React, { useEffect, useState, useRef } from "react";
import { ImagePath } from "../Constant/ImgPath";
import Logout from "./../Components/Logout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Userimg from "../assets/profile-avatar.png";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import FirstTimeLoginModal from '../Pages/FirstTimeLoginModal';

import PopupCom from "../Pages/popupstatetwo";

import "../index.css";
import { useDispatch } from "react-redux";
import { setUserProfileData } from "../redux/actions/userProfileAction";
import API_BASE_URL from "../apiConfig";
import MyChart from "./../Components/SemiCircularProgress";
import WordProgressIndicator from './WordProgressIndicator';

function MainNav({ headervalue, sidemenufun, refesh, refreshHeader = 1 }) {
  const [showPopup, setShowPopup] = useState(false);
  const [hoverLearning, setHoverLearning] = useState(false);
  const [hoverUnlocked, setHoverUnlocked] = useState(false);
  const navigate = useNavigate();
  const [headerData, setHeaderData] = useState({});
  const [userStats, setUserStats] = useState({
    learning_count: 0,
    known_count: 0
  });

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  //Dispatch Data
  const dispatch = useDispatch();

  const popupRef = useRef(null);

  const [isModalOpen, setModalOpen] = useState(false);

  const [learningCount, setLearningCount] = useState(0);

  useEffect(() => {
    console.log('Nav useEffect triggered, headervalue:', headervalue);
    if (headervalue === "nav2") {
      console.log('headervalue is nav2, calling fetchLearningCount');
      fetchLearningCount();
    }

    // Add event listener for word status changes
    const handleWordStatusChange = () => {
      console.log('Word status changed, refreshing learning count');
      fetchLearningCount();
    };

    window.addEventListener('wordStatusChanged', handleWordStatusChange);

    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowPopup(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener('wordStatusChanged', handleWordStatusChange);
    };
  }, [headervalue]);

  // sidebar start
  const sidemenufunction = () => {
    sidemenufun();
  };

  // sidebar end
  // useEffect(() => {
  //     const handleClickOutside = (event) => {
  //         if (popupRef.current && !popupRef.current.contains(event.target)) {
  //             setPopupUpload(false);
  //         }
  //     };
  //     document.addEventListener('mousedown', handleClickOutside);
  // }, []);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const NavigatePages = (data, header) => {
    navigate(data, { state: header });
  };
  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const navStyle = {
    navLandingPage: {
      width: "100%",
      height: "80px",
      background: "#FFFFFF",
      borderBottom: "1px solid #818181",
      color: "#fff",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    logoImage: {
      maxWidth: "60px",
      marginLeft: "25px",
      cursor: "pointer",
    },
    Buttons: {
      display: "flex",
      gap: "14px",
      marginRight: "14px",
    },
    loginBtn: {
      width: "92px",
      height: "44px",
      borderRadius: "37.5px",
      background: "none",
      color: "black",
      fontSize: "16px",
      fontWeight: "600",
      border: "transparent",
      cursor: "pointer",
    },
    dashboard: {
      width: "100%",
      height: "80px",
      background: "#fff",
      color: "#fff",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      border: "1px solid rgba(45, 80, 43, 0.2)",
      // boxShadow: "rgba(45, 80, 43, 0.2) 0px 4px 10px",
      // position: "fixed",
      // zIndex: "999",
    },
    dashboarda: {
      fontSize: "16px",
      fontWeight: "600",
      color: "black",
      marginTop: "10px",
      textDecoration: "none",
      cursor: "pointer",
    },
    hamburgerMenu: {
      display: "none", // Hide by default on larger screens
      "@media (max-width: 768px)": {
        display: "block", // Show only on mobile screens
      }
    },
    dashboardboxes: {
      // width: "47%",
      height: "44px",
      borderRadius: "37.5px",
      background: "#CCC",
      display: "flex",
      gap: "5px",
      padding: "8px 8px",
      alignItems: "center",
    },
    dashboardboxeFirst: {
      display: "flex",
      color: "black",
      fontWeight: "600",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      height: "100%",
      gap: "5px",
      padding: "4px 0",
    },
    NavFireImg: {
      width: "36px",
      height: "36px",
      margin: 0,
      display: "block",
    },
    dashboardboxes2: {
      height: "44px",
      borderRadius: "37.5px",
      background: "#CCC",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "0 8px",
    },
    dashboardboxes3: {
      // width: "28%",
      height: "44px",
      borderRadius: "37.5px",
      background: "#CCC",
      display: "flex",
      gap: "5px",
      padding: "8px",
    },
    NavP: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "67px",
      margin: "0",
    },
    NavFireImg5: {
      width: "35px",
      height: "35px",
      borderRadius: "40px",
    },
    popupProfile: {
      borderRadius: "17px",
      background: "#FFF",
      boxShadow: "0px 4px 10px 4px rgba(0, 0, 0, 0.50)",
      width: "157px",
      height: "auto",
      padding: "12px",
      position: "absolute",
      right: "34px",
      top: "69px",
    },
    popuptextIcon: {
      display: "flex",
      alignItems: "center",
      gap: "11px",
      cursor: "pointer",
    },
    popupP: {
      fontSize: "18px",
      fontWeight: "400",
      margin: "6px 0",
    },
    dashboardboxeFirstp: {
      margin: 0,
      padding: 0,
      display: "flex",
      alignItems: "center",
      fontSize: "16px",
      lineHeight: 1,
      transform: "translateY(-4px)",
    },
    hamburgerImg: {
      width: "30px",
      cursor: "pointer",
    },
    headerstyle: {
      // position: "fixed",
      // width: "100%",
      // zIndex: "9999",
    },
  };

  const headerAPI = () => {
    const apiUrl = `${API_BASE_URL}/user_dashboard/header`;
    // const requestData = {}
    const headers = {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("access_token"),
    };
    fetch(apiUrl, {
      method: "GET",
      headers: headers,
      // body: JSON.stringify(requestData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        // console.log("setDataHeaders======>>>>>> ", data);
        setHeaderData(data);
        dispatch(setUserProfileData(data));
        localStorage.setItem("userData", JSON.stringify(data));
      })
      .catch((error) => {
        console.error("Error fetching API:", error);
      });
  };

  const SetPopupFunction = (data) => {
    // NavigatePages("/dashboard-stats-two", headerData)
    setShowPopup(!showPopup);
  };

  const fetchLearningCount = async () => {
    const token = localStorage.getItem("access_token");
    if (!token) return;

    try {
        const response = await fetch(`${API_BASE_URL}/user_dashboard/get_learning_count`, {
            method: 'GET',
            headers: {
                'Authorization': token
            }
        });
        if (!response.ok) throw new Error('Failed to fetch learning count');
        const data = await response.json();
        setLearningCount(data.learning_count);
    } catch (error) {
        console.error('Error fetching learning count:', error);
    }
  };

  const final_val = userStats.total_learned;
  const remain_val = userStats.count_remaining_next_level;
  const next_level_total = userStats.next_level_total;
  const remaining_percentage = Math.round((final_val / next_level_total) * 100) || 0;
  

  const toggleModal = () => setModalOpen(prev => !prev);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <PopupCom headerData={headerData} />
      </Modal>
      <nav key={refreshHeader}>
        {headervalue == "nav1" && (
          <div style={navStyle.navLandingPage}>
            <img style={navStyle.logoImage} src={ImagePath.logo} alt="logo" />
            <div style={navStyle.Buttons}>
              <button
                className="login-hover"
                onClick={() => {
                  NavigatePages("/login");
                }}
                style={navStyle.loginBtn}
              >
                Log In
              </button>
              <button
                className="login-hover"
                onClick={() => {
                  NavigatePages("/signup");
                }}
                style={navStyle.loginBtn}
              >
                Sign up
              </button>
            </div>
          </div>
        )}
        {headervalue == "nav2" && (
          <div style={navStyle.dashboard}>
            <div className="nav2-left-area">
              <img
                onClick={() => {
                  NavigatePages("/dashboard");
                }}
                style={navStyle.logoImage}
                src={ImagePath.logo}
                alt="logo"
              />
              
              {/* Instructions Button - hidden on smaller screens */}
              <div 
                onClick={toggleModal}
                style={{
                  ...navStyle.dashboarda,
                  paddingRight: '15px',
                  '@media (max-width: 768px)': {
                    display: 'none'
                  }
                }}
                className="hide-on-mobile"
              >
                Instructions
              </div>

              {/* Progress Button */}
              <div 
                onClick={() => {
                  NavigatePages("/progress");
                }}
                style={{
                  ...navStyle.dashboarda,
                  paddingRight: '5px'
                }}
                className="progress-nav-link"
              >
                Progress
              </div>

              {/* Rest of the existing navigation items */}
              {/* <a
                style={navStyle.dashboarda}
                onClick={() => {
                  NavigatePages("/ranking");
                }}
              >
                Rankings
              </a> */}
              {/* <a
                style={navStyle.dashboarda}
                onClick={() => {
                  NavigatePages("/statistics");
                }}
              >
                Statistics
              </a> */}
            </div>
            <div style={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: '16px',
              marginLeft: 'auto'
            }}>
              {/* Word Stats */}
              <div style={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                justifyContent: 'center'
              }}>
                {/* Learning Words Count */}
                <div
                  style={{
                    ...navStyle.dashboardboxes2,
                    background: hoverLearning ? '#e0e0e0' : '#f0f0f0',
                    transition: 'all 0.3s ease',
                    cursor: 'pointer',
                    minWidth: 'auto',
                    padding: '4px 6px',
                  }}
                  onClick={() => navigate('/progress')}
                  onMouseEnter={() => setHoverLearning(true)}
                  onMouseLeave={() => setHoverLearning(false)}
                  aria-label="View learning progress"
                  role="button"
                >
                  <div style={{
                    ...navStyle.dashboardboxeFirst,
                    padding: '0 8px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%'
                  }}>
                    <span style={{
                      fontSize: '13px',
                      fontWeight: '600',
                      margin: 0,
                      lineHeight: '24px',
                      display: 'inline-block',
                      verticalAlign: 'middle'
                    }}>
                      Learning:
                    </span>
                    <span style={{
                      fontSize: '13px',
                      fontWeight: '700',
                      margin: 0,
                      lineHeight: '24px',
                      display: 'inline-block',
                      color: '#4CAF50',
                      marginLeft: '3px',
                      verticalAlign: 'middle'
                    }}>
                      {learningCount}
                    </span>
                  </div>
                </div>
                
                {/* Word Progress Indicator */}
                <div style={{ margin: '0 2px' }}>
                  <WordProgressIndicator />
                </div>

                {/* Unlocked Words Count */}
                {/* <div
                  style={{
                    ...navStyle.dashboardboxes2,
                    background: hoverUnlocked ? '#e0e0e0' : '#f0f0f0',
                    transition: 'all 0.3s ease',
                    cursor: 'pointer',
                    minWidth: 'auto',
                    padding: '4px 6px',
                  }}
                  onClick={() => navigate('/progress')}
                  onMouseEnter={() => setHoverUnlocked(true)}
                  onMouseLeave={() => setHoverUnlocked(false)}
                  aria-label="View unlocked words progress"
                  role="button"
                >
                  <div style={{
                    ...navStyle.dashboardboxeFirst,
                    padding: '0 8px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%'
                  }}>
                    <span style={{
                      fontSize: '13px',
                      fontWeight: '600',
                      margin: 0,
                      lineHeight: '24px',
                      display: 'inline-block',
                      verticalAlign: 'middle'
                    }}>
                      Links:
                    </span>
                    <span style={{
                      fontSize: '13px',
                      fontWeight: '700',
                      margin: 0,
                      lineHeight: '24px',
                      display: 'inline-block',
                      color: '#4CAF50',
                      marginLeft: '3px',
                      verticalAlign: 'middle'
                    }}>
                      {Array.isArray(userStats.unlocked_words) ? userStats.unlocked_words.length : 0}
                    </span>
                  </div>
                </div> */}
              </div>
              
              {/* MyChart Component */}
              {/* <div className="ms-auto">
                <MyChart
                  final_val={final_val}
                  remain_val={remain_val}
                  target_val={remaining_percentage}
                />
              </div> */}

              {/* Vocabulary Count - Temporarily Hidden
              <div
                className="Btns-hover-nav"
                onClick={() => {
                  NavigatePages("/sentenceview");
                }}
                style={{
                  ...navStyle.dashboardboxes2,
                  background: '#f0f0f0',  // Lighter background
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    background: '#e0e0e0',
                    transform: 'translateY(-2px)'
                  }
                }}
              >
                <div style={{
                  ...navStyle.dashboardboxeFirst,
                  gap: '8px',  // Increased gap between text and number
                  padding: '0 12px'  // Added horizontal padding
                }}>
                  <p style={{
                    ...navStyle.dashboardboxeFirstp,
                    fontSize: '14px',
                    fontWeight: '600',
                    margin: 0,
                    transform: 'translateY(1px)', 
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                    Vocab: 
                  </p>
                  <p style={{
                    ...navStyle.dashboardboxeFirstp,
                    fontSize: '14px',
                    fontWeight: '700',
                    margin: 0,
                    display: 'flex',
                    alignItems: 'center',
                    color: '#4CAF50'  // Green color for the number
                  }}>
                    {headerData?.["user_info"]?.["vocabulary_count"]}
                  </p>
                </div>
              </div>
              */}

              {/* Hamburger Menu */}
              <div 
                className="hamburger-menu"
                style={{ 
                  marginRight: '20px',
                  cursor: 'pointer',
                  transition: 'color 0.3s',
                  color: '#000', // Set initial color to black
                }}
                onMouseEnter={(e) => e.currentTarget.style.color = '#FFA500'}
                onMouseLeave={(e) => e.currentTarget.style.color = '#000'}
              >
                <FontAwesomeIcon
                  icon={faBars}
                  style={{ width: "30px", height: "30px" }}
                  onClick={() => {
                    sidemenufunction();
                  }}
                />
              </div>
            </div>
          </div>
        )}
        {headervalue == "nav3" && (
          <nav style={navStyle.headerstyle}>
            <div style={navStyle.dashboard}>
              <div className="search-bar-nav3">
                <img
                  className="searchbar-responsive"
                  onClick={() => {
                    NavigatePages("/dashboard");
                  }}
                  style={navStyle.logoImage}
                  src={ImagePath.logo}
                  alt="logo"
                />
                <div className="hamburger-menu hide-on-desktop">
                  <FontAwesomeIcon
                    icon={faBars}
                    style={{ color: "#000", width: "30px", height: "30px" }}
                    onClick={() => {
                      sidemenufunction();
                    }}
                  />
                </div>
                <div className="community-search searchbar-responsive">
                  <input type="text" placeholder="Search" />
                  <img src={ImagePath.search} />
                </div>
              </div>
              <div
                className="nav2-mobile"
                style={{ display: "flex", gap: "8px", marginRight: "34px" }}
              >
                <div>
                  <div
                    onClick={() => setShowPopup(!showPopup)}
                    style={navStyle.dashboardboxeFirst}
                  >
                    <img
                      style={navStyle.NavFireImg5}
                      src={ImagePath.adminprofile}
                    />{" "}
                    <p style={navStyle.NavP}>
                      {/* {headerData?.["user_info"]?.["first_name"]} {headerData?.["user_info"]?.["last_name"]} */}
                      Admin
                    </p>
                    <FontAwesomeIcon icon={faBars} />
                  </div>
                </div>
              </div>
            </div>
          </nav>
        )}
        {showPopup && (
          <div className="blur-background">
            <div ref={popupRef} style={navStyle.popupProfile}>
              <div>
                <div onClick={handleClosePopup} style={navStyle.popuptextIcon}>
                  <FontAwesomeIcon icon={faBars} />
                  <p style={navStyle.popupP}>Notifications</p>
                </div>
              </div>
              <div
                onClick={() => {
                  NavigatePages("/account");
                }}
                style={navStyle.popuptextIcon}
              >
                <FontAwesomeIcon icon={faBars} />
                <p style={navStyle.popupP}>Settings</p>
              </div>
              <div>
                <div onClick={handleClosePopup} style={navStyle.popuptextIcon}>
                  <FontAwesomeIcon icon={faBars} />
                  <p style={navStyle.popupP}>Log out</p>
                </div>
              </div>
            </div>
          </div>
        )}
        {isModalOpen && <FirstTimeLoginModal open={isModalOpen} handleClose={toggleModal} />}
      </nav>
    </>
  );
}

export default MainNav;
