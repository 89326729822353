import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Login from "../Pages/Authentication/Login";
import SignUp from "../Pages/Authentication/SignUp";
import ForgotPassword from "../Pages/Authentication/ForgotPassword";
import ResetPassword from "../Pages/Authentication/ResetPassword";
import OTPVerification from "../Pages/Authentication/OTPVerification";
import LandingPage from "../Pages/LandingPage";
import Dashboard from "../Pages/Dashboard";
import OpenLessons from "../Pages/OpenLessons";
import TransactionHistory from "../Pages/TransactionHistory";
import Ranking from "../Pages/Ranking";
import SentenceView from "../Pages/SentenceView";
import SentenceVoice from "../Pages/SentenceVoice";
import SurahPage from "../Pages/SurahPage";
import PageView from "../Pages/PageView";
import DashboardStatsPage from "../Pages/DashboardStatsPage";
import Statistics from "../Pages/Statistics";
import AdminDashboard from "../Pages/Admin/AdminDashboard";
import UserManagement from "../Pages/Admin/UserManagement";
import Transactions from "../Pages/Admin/Transactions";
import SubscriptionPlans from "../Pages/Admin/SubscriptionPlans";
import CommunityProfile from "../Pages/CommunityProfile";
import EditProfile from "../Pages/EditProfile";
import CheckoutForm from "../Pages/Payment1";
import PlanPage from "../Pages/PlansPage";
import { WebSocketProvider } from "../Pages/websoket";
import NotificationModal from "../Pages/notification";
import SubscriptionPlane from "../Pages/SubscriptionPlan";
import SubscriptionStarter from "../Pages/SubscriptionStarter";
import AboutUs from "../Pages/AboutUs";
import UserStats from "../Pages/UserStats";
import LibraryView from "../Pages/LibraryView";
import BookReader from "../Pages/BookReader";
import ArabicDictionary from "../Pages/ArabicDictionary";
import AuthInitializer from "../Components/AuthInitializer";
import LessonsPage from "../Pages/LessonsPage";
import LessonDetail from "../Pages/LessonDetail";



export default function App() {
  return (
    <WebSocketProvider>
      <Router>
        <AuthInitializer />
        <NotificationModal />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/otpverification" element={<OTPVerification />} />
          <Route path="/" element={<LandingPage />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/openlessons" element={<OpenLessons />} />
          <Route path="/account" element={<SubscriptionStarter />} />
          <Route path="/transactionhistory" element={<TransactionHistory />} />
          <Route path="/ranking" element={<Ranking />} />
          <Route path="/sentenceview" element={<SentenceView />} />
          <Route path="/sentencevoice" element={<SentenceVoice />} />
          <Route path="/surah-page" element={<SurahPage />} />
          <Route path="/page-view" element={<PageView />} />
          <Route path="/dashboard-stats" element={<DashboardStatsPage />} />
          {/* <Route path="/community" element={<Community />} /> */}
          <Route
            path="/progress"
            element={<Statistics />}
          />
          <Route path="/adminlogin" element={<Login />} />
          <Route path="/admin-dashboard" element={<AdminDashboard />} />
          <Route path="/user-management" element={<UserManagement />} />
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/subscription" element={<SubscriptionPlans />} />
          <Route path="/payment" element={<CheckoutForm />} />
          <Route path="/communityprofile" element={<CommunityProfile />} />
          <Route path="/edit-profile" element={<EditProfile />} />
          <Route path="/plan-page" element={<PlanPage />} />
          <Route path="/subscription-plan" element={<SubscriptionPlane />} />
          <Route path="/user-stats" element={<UserStats />} />
          <Route path="/library" element={<LibraryView />} />
          <Route path="/library/book/:bookId" element={<BookReader />} />
          <Route path="/arabic-dictionary" element={<ArabicDictionary />} />
          <Route path="/lessons" element={<LessonsPage />} />
          <Route path="/lesson/:lessonId" element={<LessonDetail />} />
        </Routes>
      </Router>
    </WebSocketProvider>
  );
}
