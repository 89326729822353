// storageHelper.js

const STORAGE_KEY_SURAH_HISTORY = "sura_history";

/**
 * Fetches the data from localStorage.
 * @returns {Array} Parsed array of surah history or an empty array if not found.
 */
export const getSurahHistory = () => {
    const data = localStorage.getItem(STORAGE_KEY_SURAH_HISTORY);
    return data ? JSON.parse(data) : [];
};

/**
 * Updates the surah history in localStorage.
 * @param {Array} newData - The new surah history data to be stored.
 */
export const updateSurahHistory = (surah) => {
    let current_status = getSurahHistory();
    
    // Only check for duplicate using id
    if (current_status.some(item => item.id === surah.id)) {
        return;
    }

    // Only store id and name_en
    const surahInfo = {
        id: surah.id,
        name_en: surah.name_en, // Changed from english_name to name_en
        start_page: surah.start_page
    };

    if (current_status.length > 3) {
        current_status.pop();  // Remove the last item
    }

    current_status.unshift(surahInfo);  // Add to beginning of array

    localStorage.setItem(STORAGE_KEY_SURAH_HISTORY, JSON.stringify(current_status));
};
