import { applyMiddleware, createStore, compose } from 'redux';
import { thunk } from 'redux-thunk'; // Use named import
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage
import rootReducer from '../redux/reducers/index';

// Persist configuration
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['userProfileReducer'] // Only persist user data
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const initialState = {};

const middleware = [thunk]; // Array of middlewares
export const store = createStore(
  persistedReducer, // Use the persisted reducer
  initialState,
  compose(
    applyMiddleware(...middleware) // Apply middleware correctly
  )
);

export const persistor = persistStore(store);

export default store;
