import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Nav from '../Components/Nav';
import Footer from '../Components/Footer';
import Maskgroup from './../assets/profile.png';
import { ImagePath } from '../Constant/ImgPath';
import { Form } from 'react-bootstrap';
import CountrySelect from '../Components/Countryselect';
import { useNavigate } from 'react-router-dom';
import API_BASE_URL from '../apiConfig';


function EditProfile() {
    const fileInputRef = useRef(null);
    const [imageSrc, setImageSrc] = useState(null);
    const [UserProfileData, setUserProfileData] = useState();
    const navigate = useNavigate();
    const [userData, setUserData] = useState({
        name: "",
        lastname:"",
        bio: "",
    });
    const handleClick = (e) => {
        e.preventDefault()
        fileInputRef.current.click();
    };
    const NavigatePages = (path) => {
        navigate(path);
    };
    const [loginFalse, setLoginFalse] = useState(false)

    useEffect(() => {
        localStorage.getItem("login");
        if(localStorage.getItem("login") == "true"){
            setLoginFalse(true);
        }else{
            navigate("/login");
        }
    }, [localStorage.getItem("login")])
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        console.log('asdasdsadas');
        if (file) {
            const reader = new FileReader();
            console.log(reader);
            reader.onloadend = () => {
                setImageSrc(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const UserProfileCalling = async () => {
        const UserProfileUrl = `${API_BASE_URL}/user_dashboard/header`
        await fetch(UserProfileUrl, {
            method: 'GET',
            headers: { Authorization: `${localStorage.getItem('access_token')}` }
        }).then((response) => response.json()).then((data) => {
            console.log("user profile linking", data)
            setUserProfileData(data)
            setUserData({
                name: data.user_info.first_name + ' ' + data.user_info.last_name,
                bio: data.user_info.bio,
            })

        })
    }
    useEffect(() => {

        UserProfileCalling()

    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData({
            ...userData,
            [name]: value
        })
    }

    const SubmitFormDetails = async (e) => {
        e.preventDefault()
        const UserProfileUrl = `${API_BASE_URL}/user_dashboard/set_user_info`
        await fetch(UserProfileUrl, {
            method: 'POST',
            headers: { Authorization: `${localStorage.getItem('access_token')}` },
            body: JSON.stringify(userData)
        }).then((response) => response.json()).then((data) => {
            console.log("user profile update", data)
            

        })

    }

    const Profile = {

        bg: {
            // background: "rgb(244, 245, 246)",
            padding: "33px 45px"
        },
        h4: {
            fontSize: "24px",
            fontWeight: "600",
        },
        p: {
            color: "#4B4B4B",
            fontSize: "17px",
            fontWeight: "400",
        },
        label: {
            color: "#000",
            fontSize: "18px",
            fontWeight: "600",
            width: "19%",
            display: "flex",
            gap: "6px"
        },
        label2: {
            color: "#000",
            fontSize: "18px",
            fontWeight: "600",
            width: "16%",
            display: "flex",
            gap: "6px"
        },
        input: {
            borderRadius: "7px",
            border: "1px solid rgb(187 185 185)",
            background: "#FFF",
            padding: "8px 11px",
            height: "47px",
        },
        buttonlesson: {
            borderRadius: "12px",
            border: "1px solid #5F5F5F",
            padding: "8px 18px",
            cursor: "pointer",
            color: "#313131",
            background: "#fff",
            margin: "13px 8px",
            width: "78%"
        },
        ProfileButton: {
            borderRadius: "9px",
            border: "1px solid #5F5F5F",
            padding: "8px 18px",
            cursor: "pointer",
            color: "#313131",
            background: "#fff",
            width: "15%",
            minWidth: "174px",
            display: "flex", gap: "6px", justifyContent: "center"
        },
        Imgbackground: {
            borderRadius: "29px",
            background: "#E9E9E9",
            width: "214px",
            height: "224px"
        },
        Profileinput: {
            borderRadius: "7px",
            border: "1px solid rgb(187 185 185)",
            background: "#FFF",
            padding: "8px 11px",
            height: "14px",
            margin: "13px auto",
            width: "60%",
            height: "47px"
        }
    };
    return (
        <>
            {loginFalse && <div >
                <Nav headervalue={"nav2"} />
                <div style={Profile.bg}>
                    <div className='mobile-view-pages'>
                        <Form>
                            <h5 style={Profile.h4}>Edit PROFILE</h5>
                            <div className='country-input d-flex align-item- center gap-3 mt-5' >
                                <p className='responsive-text' style={Profile.label}>First Name</p>
                                {/* <input style={Profile.input} type='text' placeholder='Name' /> */}
                                <Form.Control type="text" value={userData.name} name='name' onChange={handleChange} />


                            </div>
                            <div className='country-input d-flex align-item- center gap-3  mt-3' >
                                <p className='responsive-text' style={Profile.label}>Last Name</p>
                                {/* <input style={Profile.input} type='text' placeholder='Name' /> */}
                                <Form.Control type="text" value={userData.lastname} name='name' onChange={handleChange} />


                            </div>
                            <div className='country-input d-flex align-item- center gap-3 mt-3' >
                                <p className='responsive-text' style={Profile.label2}>Country:</p>
                                <CountrySelect />
                                <p className='responsive-text' style={Profile.label}>{UserProfileData?.user_info.country}</p>
                            </div>
                            <div className='country-input d-flex align-item- center gap-3 mt-3' >
                                <p className='responsive-text' style={Profile.label2} >Username</p>
                                {/* <input style={Profile.input} type='text' placeholder='Name' /> */}
                                <p className='responsive-text' style={Profile.label}>{UserProfileData?.user_info.username}</p>
                            </div>
                            <div className='country-input d-flex align-item- center gap-3 mt-3' >
                                <p style={Profile.label}>Bio </p>
                                {/* <input style={Profile.input} type='text' placeholder='Name' /> */}
                                <Form.Control type="text" value={userData.bio} name='bio' onChange={handleChange} />

                            </div>
                            <div className='d-flex align-item- center mt-3'>
                                <p className='responsive-text' style={Profile.label}>Image</p>
                            </div>
                            <div style={{ display: "flex", gap: "11px", flexWrap: "wrap" }}>
                                <div style={{ width: "19%", minWidth: "238px" }}>
                                    <div style={Profile.Imgbackground}>
                                        <div>
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                <img className='upload-img' src={imageSrc} alt="Image" />
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    style={{ display: 'none' }}
                                                    ref={fileInputRef}
                                                    onChange={handleFileChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <button className='btn-hover' onClick={handleClick} style={Profile.buttonlesson}>Change Picture</button>
                                </div>


                            </div>

                            <div className='edit-profile-btn btn-hover' onClick={SubmitFormDetails}>Update Profile</div>
                        </Form>
                    </div>
                </div>
                <Footer />
            </div>}
        </>
    );
}

export default EditProfile;
