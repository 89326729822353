import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, Typography, Select, MenuItem, IconButton, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faChevronRight, faChevronLeft, faBookmark as faBookmarkSolid } from '@fortawesome/free-solid-svg-icons';
import { faBookmark as faBookmarkOutline } from '@fortawesome/free-regular-svg-icons';
import API_BASE_URL from '../../apiConfig';

const BookNavigationControls = ({
  currentPage,
  pageInputValue,
  setPageInputValue,
  handlePageChange,
  totalPages,
  isLoading,
  showPdf,
  setShowPdf,
  book,
  bookId
}) => {
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [bookmarkId, setBookmarkId] = useState(null);
  const [isBookmarkLoading, setIsBookmarkLoading] = useState(false);

  // Check if the current page is bookmarked when the page changes
  useEffect(() => {
    const checkBookmark = async () => {
      try {
        if (!bookId || !currentPage) return;
        
        const token = localStorage.getItem('access_token');
        if (!token) return;

        setIsBookmarkLoading(true);
        const response = await fetch(
          `${API_BASE_URL}/user-stats/bookmarks/check?book_id=${bookId}&page_number=${currentPage}`, 
          {
            headers: {
              'Authorization': token
            }
          }
        );

        if (response.ok) {
          const data = await response.json();
          setIsBookmarked(data.exists);
          if (data.exists) {
            setBookmarkId(data.bookmark_id);
          }
        }
      } catch (error) {
        console.error('Error checking bookmark:', error);
      } finally {
        setIsBookmarkLoading(false);
      }
    };

    checkBookmark();
  }, [bookId, currentPage]);

  // Handle toggling bookmark
  const handleToggleBookmark = async () => {
    try {
      setIsBookmarkLoading(true);
      const token = localStorage.getItem('access_token');
      
      if (!token) {
        alert('Please log in to bookmark pages');
        return;
      }

      if (isBookmarked && bookmarkId) {
        // Remove bookmark
        const response = await fetch(`${API_BASE_URL}/user-stats/bookmarks/${bookmarkId}`, {
          method: 'DELETE',
          headers: {
            'Authorization': token
          }
        });

        if (response.ok) {
          setIsBookmarked(false);
          setBookmarkId(null);
        }
      } else {
        // Add bookmark
        const response = await fetch(`${API_BASE_URL}/user-stats/bookmarks`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token
          },
          body: JSON.stringify({
            book_id: bookId,
            page_number: currentPage
          })
        });

        if (response.ok) {
          const data = await response.json();
          setIsBookmarked(true);
          setBookmarkId(data.bookmark_id);
        }
      }
    } catch (error) {
      console.error('Error toggling bookmark:', error);
    } finally {
      setIsBookmarkLoading(false);
    }
  };

  // Get the navigation data from the book object with enhanced error handling
  const navigationData = React.useMemo(() => {
    // Default navigation data - used if anything is missing
    const defaultNavData = {
      available_pages: [],
      first_page: 1,
      last_page: totalPages || 100
    };

    // Comprehensive null/undefined checks
    if (!book || !book.indexes) {
      console.warn('Book or book.indexes is missing');
      return defaultNavData;
    }

    try {
      // Handle page_map specifically - it's the key data we need
      let pageMap = book.indexes.page_map;
      
      // Handle if page_map is null, undefined, or not an array
      if (!pageMap) {
        console.warn('page_map is missing in book.indexes');
        // Generate a sequential array of pages as fallback
        const generatedPages = Array.from({ length: totalPages || 100 }, (_, i) => i + 1);
        return {
          available_pages: generatedPages,
          first_page: 1,
          last_page: totalPages || 100
        };
      }
      
      // Filter out null values from the array
      if (Array.isArray(pageMap)) {
        pageMap = pageMap.filter(page => page !== null && page !== undefined);
      }
      
      // Convert to array if it's somehow not one
      if (!Array.isArray(pageMap)) {
        console.warn('page_map is not an array, attempting to convert');
        try {
          // Try to convert if it's a string representing an array
          if (typeof pageMap === 'string') {
            pageMap = JSON.parse(pageMap);
          }
          // If it's still not an array after conversion, create a sequential one
          if (!Array.isArray(pageMap)) {
            const generatedPages = Array.from({ length: totalPages || 100 }, (_, i) => i + 1);
            pageMap = generatedPages;
          }
        } catch (error) {
          console.error('Failed to convert page_map to array:', error);
          const generatedPages = Array.from({ length: totalPages || 100 }, (_, i) => i + 1);
          pageMap = generatedPages;
        }
      }
      
      // If we have no pages after all processing, create a sequential array
      if (pageMap.length === 0) {
        console.warn('page_map is empty after processing, creating sequential pages');
        const generatedPages = Array.from({ length: totalPages || 100 }, (_, i) => i + 1);
        pageMap = generatedPages;
      }
      
      // At this point we have a valid array of pages
      return {
        available_pages: pageMap,
        first_page: pageMap[0],
        last_page: pageMap[pageMap.length - 1]
      };
    } catch (error) {
      console.error('Error processing navigation data:', error);
      // Create a sequential array of pages as fallback
      const generatedPages = Array.from({ length: totalPages || 100 }, (_, i) => i + 1);
      return {
        available_pages: generatedPages,
        first_page: 1,
        last_page: totalPages || 100
      };
    }
  }, [book, totalPages]);

  // Find the current page index in the available pages array with error handling
  const currentPageIndex = React.useMemo(() => {
    if (!navigationData.available_pages || navigationData.available_pages.length === 0) {
      return -1;
    }
    return navigationData.available_pages.indexOf(currentPage);
  }, [navigationData.available_pages, currentPage]);
  
  // Calculate previous and next pages with error handling and fallbacks for sequential navigation
  // If we can't find the page in the array, we'll use simple +1/-1 navigation
  const prevPage = React.useMemo(() => {
    // If we found the page in the array, use the previous page in the array
    if (currentPageIndex > 0) {
      return navigationData.available_pages[currentPageIndex - 1];
    }
    // Otherwise fall back to simple decrement (but not below 1)
    return currentPage > 1 ? currentPage - 1 : null;
  }, [currentPageIndex, navigationData.available_pages, currentPage]);

  const nextPage = React.useMemo(() => {
    // If we found the page in the array, use the next page in the array
    if (currentPageIndex !== -1 && currentPageIndex < navigationData.available_pages.length - 1) {
      return navigationData.available_pages[currentPageIndex + 1];
    }
    // Otherwise fall back to simple increment (but not above max pages)
    const maxPage = totalPages || navigationData.last_page || 100;
    return currentPage < maxPage ? currentPage + 1 : null;
  }, [currentPageIndex, navigationData.available_pages, currentPage, totalPages, navigationData.last_page]);

  // Make sure current page is valid
  useEffect(() => {
    if (navigationData.available_pages.length > 0 && currentPageIndex === -1) {
      // Find closest available page
      try {
        const closestPage = navigationData.available_pages.reduce((prev, curr) => {
          // Handle string values that might need to be parsed
          const prevNum = typeof prev === 'string' ? parseInt(prev.split(',')[1] || prev) : prev;
          const currNum = typeof curr === 'string' ? parseInt(curr.split(',')[1] || curr) : curr;
          const currentNum = typeof currentPage === 'string' ? parseInt(currentPage.split(',')[1] || currentPage) : currentPage;
          
          // Safety check for valid numbers
          if (isNaN(prevNum) || isNaN(currNum) || isNaN(currentNum)) {
            return prev; // Keep previous if any are not valid numbers
          }
          
          return Math.abs(currNum - currentNum) < Math.abs(prevNum - currentNum) ? curr : prev;
        });
        console.log(`Current page ${currentPage} not found in available pages. Navigating to closest page: ${closestPage}`);
        handlePageChange(null, closestPage);
      } catch (error) {
        console.error('Error finding closest page:', error);
        // Fallback to first page if available
        if (navigationData.available_pages.length > 0) {
          console.log(`Fallback to first available page: ${navigationData.available_pages[0]}`);
          handlePageChange(null, navigationData.available_pages[0]);
        }
      }
    }
  }, [navigationData.available_pages, currentPage, currentPageIndex, handlePageChange]);

  // Calculate display value for the dropdown (defaulting to current page if not found)
  const displayValue = currentPageIndex !== -1 ? currentPage : 
                       (navigationData.available_pages.length > 0 ? navigationData.available_pages[0] : currentPage);

  // Debug logging
  console.log('Navigation state:', {
    currentPage,
    displayValue,
    currentPageIndex,
    prevPage,
    nextPage,
    availablePages: navigationData.available_pages.length
  });

  return (
    <Box sx={{ 
      mt: 4,
      mb: 2,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 2
    }}>
      <Box sx={{ 
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2,
        width: '100%'
      }}>
        {/* NEXT button - LEFT arrow - should appear on left side of dropdown */}
        <Button
          variant="outlined"
          disabled={(currentPage >= (totalPages || 100)) || isLoading}
          onClick={() => {
            console.log('Navigating to next page:', nextPage);
            if (nextPage) {
              handlePageChange(null, nextPage);
            } else if (currentPage < (totalPages || 100)) {
              // Fallback to simple increment navigation
              handlePageChange(null, currentPage + 1);
            }
          }}
          sx={{ 
            minWidth: '40px',
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            padding: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'primary.main',
            transition: 'all 0.2s',
            '&:hover': {
              backgroundColor: 'rgba(25, 118, 210, 0.04)'
            }
          }}
        >
          <FontAwesomeIcon icon={faChevronLeft} size="sm" />
        </Button>
        
        {/* Page selector */}
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          gap: 1
        }}>
          {navigationData.available_pages.length > 0 ? (
            <Select
              size="small"
              value={displayValue}
              onChange={(e) => {
                const newPage = parseInt(e.target.value);
                console.log('Selected page from dropdown:', newPage);
                handlePageChange(null, newPage);
              }}
              sx={{ 
                minWidth: 120,
                fontFamily: '"Amiri", "Noto Naskh Arabic", serif'
              }}
              disabled={isLoading}
              dir="rtl"
              lang="ar"
            >
              {navigationData.available_pages.map(page => (
                page !== null && page !== undefined ? (
                  <MenuItem key={page} value={page} sx={{ fontFamily: '"Amiri", "Noto Naskh Arabic", serif' }} dir="rtl" lang="ar">
                    {page}
                  </MenuItem>
                ) : null
              ))}
            </Select>
          ) : (
            <TextField
              size="small"
              type="number"
              value={pageInputValue}
              onChange={(e) => setPageInputValue(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  const newPage = parseInt(pageInputValue);
                  if (!isNaN(newPage) && newPage > 0 && newPage <= (totalPages || 100)) {
                    handlePageChange(null, newPage);
                  }
                }
              }}
              InputProps={{
                sx: { textAlign: 'center', minWidth: '80px' }
              }}
              disabled={isLoading}
            />
          )}
        </Box>
        
        {/* PREVIOUS button - RIGHT arrow - should appear on right side of dropdown */}
        <Button
          variant="outlined"
          disabled={currentPage <= 1 || isLoading}
          onClick={() => {
            console.log('Navigating to previous page:', prevPage);
            if (prevPage) {
              handlePageChange(null, prevPage);
            } else if (currentPage > 1) {
              // Fallback to simple decrement navigation
              handlePageChange(null, currentPage - 1);
            }
          }}
          sx={{ 
            minWidth: '40px',
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            padding: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'primary.main',
            transition: 'all 0.2s',
            '&:hover': {
              backgroundColor: 'rgba(25, 118, 210, 0.04)'
            }
          }}
        >
          <FontAwesomeIcon icon={faChevronRight} size="sm" />
        </Button>
        
        {/* Bookmark button */}
        <Tooltip title={isBookmarked ? "Remove bookmark" : "Add bookmark"}>
          <IconButton
            onClick={handleToggleBookmark}
            disabled={isBookmarkLoading}
            sx={{
              color: isBookmarked ? 'primary.main' : 'text.secondary',
              '&:hover': {
                color: isBookmarked ? 'error.main' : 'primary.main'
              }
            }}
          >
            <FontAwesomeIcon icon={isBookmarked ? faBookmarkSolid : faBookmarkOutline} />
          </IconButton>
        </Tooltip>
      </Box>
      
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        {book?.meta?.pdf_links?.files && (
          <Button
            variant="text"
            color="primary"
            onClick={() => setShowPdf(!showPdf)}
            startIcon={<FontAwesomeIcon icon={faFilePdf} />}
            sx={{
              fontFamily: '"Amiri", "Noto Naskh Arabic", serif',
              fontSize: '0.9rem'
            }}
          >
            {showPdf ? 'إخفاء ملف PDF' : 'عرض ملف PDF'}
          </Button>
        )}
      </Box>
      
      {showPdf && book?.meta?.pdf_links?.files && (
        <Box sx={{ width: '100%', height: '600px', mt: 2, border: '1px solid #ddd' }}>
          {(() => {
            let pdfPageNumber = 1; // Default to page 1
            
            try {
              if (book?.indexes?.print_pg_to_pg) {
                // Find the corresponding volume,page entry in print_pg_to_pg
                const pageKey = book.indexes.page_map.find(p => {
                  try {
                    if (typeof p !== 'string') return false;
                    const parts = p.split(',');
                    if (parts.length < 2) return false;
                    const pageNum = parseInt(parts[1]);
                    return !isNaN(pageNum) && pageNum === currentPage;
                  } catch (e) {
                    console.error('Error parsing page key:', e);
                    return false;
                  }
                });
                
                if (pageKey && book.indexes.print_pg_to_pg[pageKey]) {
                  pdfPageNumber = book.indexes.print_pg_to_pg[pageKey];
                }
              }
              
              if (!pdfPageNumber || isNaN(pdfPageNumber)) {
                // Fallback calculation
                const coverOffset = parseInt(book.meta.pdf_links.cover) || 0;
                pdfPageNumber = Math.max(1, currentPage - coverOffset);
              }
            } catch (error) {
              console.error('Error calculating PDF page number:', error);
              pdfPageNumber = 1; // Fallback to page 1
            }
            
            return (
              <iframe
                key={currentPage}
                src={`${book.meta.pdf_links.files[0]}#page=${pdfPageNumber}`}
                width="100%"
                height="100%"
                title={`${book.meta.name} - PDF View - Page ${currentPage}`}
                style={{ border: 'none' }}
              />
            );
          })()}
        </Box>
      )}
    </Box>
  );
};

export default BookNavigationControls;
