// WordCard.js
import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faSpinner } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "@mui/material/Tooltip";
import "./WordCard.css";

const WordCard = ({
  word,
  onClose,
  onStatusChange,
}) => {
  const [showDictionary, setShowDictionary] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMoveToKnownLoading, setIsMoveToKnownLoading] = useState(false);
  const [isResetLoading, setIsResetLoading] = useState(false);
  const iframeRef = useRef(null);
  const [updatedWord, setUpdatedWord] = useState(word);

  // Reset state when word changes
  useEffect(() => {
    setUpdatedWord(word);
  }, [word]);

  // Function to close the dictionary popup
  const closeDictionary = () => {
    setShowDictionary(false);
    if (iframeRef.current) {
      iframeRef.current.src = 'about:blank';
    }
  };
  
  // Handle iframe load event
  const handleIframeLoad = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };
  
  // Function to transliterate Arabic characters to English
  const transliterateArabicToEnglish = (arabicText) => {
    if (!arabicText) return '';
    
    const arabicToEnglishMap = {
      'ا': 'a', 'أ': 'a', 'إ': 'a', 'آ': 'a',
      'ب': 'b', 'ت': 't', 'ث': 'th', 'ج': 'j',
      'ح': 'H', 'خ': 'kh', 'د': 'd', 'ذ': 'dh',
      'ر': 'r', 'ز': 'z', 'س': 's', 'ش': 'sh',
      'ص': 'S', 'ض': 'D', 'ط': 'T', 'ظ': 'Z',
      'ع': '3', 'غ': 'gh', 'ف': 'f', 'ق': 'q',
      'ك': 'k', 'ل': 'l', 'م': 'm', 'ن': 'n',
      'ه': 'h', 'و': 'w', 'ي': 'y', 'ى': 'y',
      'ئ': 'y', 'ء': '\''
    };
    
    // Remove diacritics and get clean characters
    const chars = [...arabicText].filter(char => !/[\u064B-\u0652]/.test(char));
    
    // Check if the last two characters are the same
    const hasRepeatedLastLetter = chars.length >= 2 && chars[chars.length - 1] === chars[chars.length - 2];
    
    // Convert each Arabic character to its English equivalent
    let result = '';
    for (let i = 0; i < chars.length; i++) {
      // Skip the last character if it's repeated
      if (hasRepeatedLastLetter && i === chars.length - 1) continue;
      
      const char = chars[i];
      result += arabicToEnglishMap[char] || char;
    }
    
    return result;
  };

  // Function to open Hans Wehr dictionary with a specific word
  const openHansWehr = () => {
    try {
      // First show the dictionary and set loading
      setShowDictionary(true);
      setIsLoading(true);

      // Small delay to ensure state is updated
      setTimeout(() => {
        let searchTerm;

        // Try root_word first
        if (word?.word_root) {
          const transliteratedRoot = transliterateArabicToEnglish(word.word_root);
          if (transliteratedRoot) {
            searchTerm = transliteratedRoot;
            // console.log('Using transliterated root word:', { 
            //   original: word.word_root, 
            //   transliterated: transliteratedRoot 
            // });
          }
        }

        // Fallback to word_key if root_word failed
        if (!searchTerm && word?.word_key) {
          const transliteratedKey = transliterateArabicToEnglish(word.word_key);
          if (transliteratedKey) {
            searchTerm = transliteratedKey;
            console.log('Using transliterated word_key:', { 
              original: word.word_key, 
              transliterated: transliteratedKey 
            });
          }
        }

        // Last resort fallback
        if (!searchTerm) {
          searchTerm = 'a';
          console.log('No valid word found, using default search "a"');
        }

        // Set the URL directly on the iframe
        if (iframeRef.current) {
          const url = `https://ejtaal.net/aa/#q=${searchTerm}`;
          console.log('Opening dictionary URL:', url);
          iframeRef.current.src = url;
        }
      }, 100);
      
    } catch (error) {
      console.error('Error opening dictionary:', error);
      setIsLoading(false);
    }
  };
  
  const handleHover = (event) => {
    event.target.style.border = "1px solid black";
  };

  const handleLeave = (event) => {
    event.target.style.border = "1px solid transparent";
  };



  // Update word status and handle the response
  const updateStatus = async (newStatus) => {
    try {
      const wordToUpdate = {...updatedWord};
      
      console.log('Updating word status:', {
        word: wordToUpdate?.word,
        dictionary_lookup: wordToUpdate?.dictionary_lookup,
        word_lemma: wordToUpdate?.word_lemma,
        word_uthmani_no_diacritics: wordToUpdate?.word_uthmani_no_diacritics,
        newStatus
      });

      const result = await onStatusChange(wordToUpdate?.dictionary_lookup || wordToUpdate?.word_lemma || wordToUpdate?.word_uthmani_no_diacritics, null, newStatus);
      
      const color = newStatus === "Known" ? "#93C47D" : 
                   newStatus === "Learned" ? "#FBD866" : 
                   newStatus === "Familiar" ? "#8CF485" : "#93C47D";
      
      // Let backend handle time fields
      const updatedWordCopy = { 
        ...updatedWord, 
        status: newStatus, 
        color: color
      };
      setUpdatedWord(updatedWordCopy);

      // Trigger a refresh of the learning count in the Nav component
      // We'll use a custom event that Nav can listen to
      window.dispatchEvent(new CustomEvent('wordStatusChanged'));
      
      return result;
    } catch (error) {
      console.error('Error updating status:', error);
      throw error;
    }
  };

  return (
    <>
      <div className="word-card-overlay" onClick={onClose}></div>
      <div className="word-card">
        <div className="word-card__header"></div>
        {updatedWord?.word_root && (
          <div style={{ 
            textAlign: 'center', 
            fontSize: '1.25rem', 
            fontFamily: 'uth',
            color: '#666',
            margin: '0 0 5px 0'
          }}>
            <span style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>
              {Array.from(updatedWord.word_root).join(' - ')}
            </span>
          </div>
        )}

        {/* Centered Word and Translation */}
        <div style={{ textAlign: 'center', margin: '15px 0' }}> 
          {/* Arabic Word */}
          <h4 className="word-card__arabic" style={{ marginBottom: '5px' }}> 
            {updatedWord?.word}
          </h4>
          {/* English Translation (Larger) */}
          <span className="word-card__translation" style={{ fontSize: '1.1rem' }}> 
            {updatedWord?.translation}
          </span>
          {updatedWord?.repetition !== undefined && (
            <p style={{ color: 'grey', fontSize: '0.9rem', marginTop: '8px', marginBottom: '0' }}>
              Occurs {updatedWord.repetition} times
            </p>
          )}
        </div>

        <div className="word-card__actions" style={{ 
          display: 'flex', 
          flexDirection: 'row', 
          gap: '10px',
          justifyContent: 'center'
        }}>
          {/* New Button */}
          <Tooltip title="Reset word status" placement="top">
            <div
              onMouseEnter={handleHover}
              onClick={async () => {
                if (isResetLoading) return;
                setIsResetLoading(true);
                try {
                  await updateStatus("New");
                } catch (error) {
                  console.error('Error resetting status:', error);
                } finally {
                  setIsResetLoading(false);
                }
              }}
              className="word-card__action-btn learning"
              onMouseLeave={handleLeave}
              style={{ 
                minWidth: '80px',
                width: '65px',  
                backgroundColor: updatedWord?.status !== "Learned" && updatedWord?.status !== "Known" ? '#6BB2F8' : 'transparent',
                color: 'black',
                border: '1px solid #6BB2F8',
                padding: '4px 0',  
                fontSize: '0.9rem',
                textAlign: 'center'  
              }}
            >
              {isResetLoading ? (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px' }}>
                  <FontAwesomeIcon icon={faSpinner} spin style={{ fontSize: '16px' }} />
                </div>
              ) : (
                "New"
              )}
            </div>
          </Tooltip>

          {/* Learn Button */}
          <Tooltip title="Add to Dictionary" placement="top">
            <div
              onMouseEnter={handleHover}
              onClick={async () => {
                if (isLoading) return;
                setIsLoading(true);
                try {
                  await updateStatus("Learned");
                } catch (error) {
                  console.error('Error adding to dictionary:', error);
                } finally {
                  setIsLoading(false);
                }
              }}
              className="word-card__action-btn"
              onMouseLeave={handleLeave}
              style={{ 
                minWidth: '80px',
                backgroundColor: updatedWord?.status === "Learned" ? '#fcd966' : 'transparent',
                color: 'black',
                border: '1px solid #fcd966',
                padding: '4px 0',
                fontSize: '0.9rem',
                textAlign: 'center'
              }}
            >
              {isLoading ? (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px' }}>
                  <FontAwesomeIcon icon={faSpinner} spin style={{ fontSize: '16px' }} />
                </div>
              ) : (
                "Learn"
              )}
            </div>
          </Tooltip>

          {/* Known Button */}
          <Tooltip title="Move word to Known status" placement="top">
            <div
              onMouseEnter={handleHover}
              onClick={async () => {
                if (isMoveToKnownLoading) return;
                setIsMoveToKnownLoading(true);
                try {
                  await updateStatus("Known");
                } catch (error) {
                  console.error('Error moving to known:', error);
                } finally {
                  setIsMoveToKnownLoading(false);
                }
              }}
              className="word-card__action-btn"
              onMouseLeave={handleLeave}
              style={{ 
                minWidth: '80px',
                backgroundColor: updatedWord?.status === "Known" ? '#93C47D' : 'transparent',
                color: 'black',
                border: '1px solid #93C47D',
                padding: '4px 0',
                fontSize: '0.9rem',
                textAlign: 'center'
              }}
            >
              {isMoveToKnownLoading ? (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px' }}>
                  <FontAwesomeIcon icon={faSpinner} spin style={{ fontSize: '16px' }} />
                </div>
              ) : (
                "Known"
              )}
            </div>
          </Tooltip>
        </div>
        
        {/* Hans Wehr Dictionary Button - Always shown */}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Tooltip title="View Ejtaal (32 Dictionaries)" placement="top">
            <div
              onMouseEnter={handleHover}
              onClick={openHansWehr}
              className="word-card__action-btn"
              onMouseLeave={handleLeave}
              style={{ 
                backgroundColor: '#4A86E8', 
                color: 'white',
                marginTop: '10px', 
                padding: '6px 16px', 
                borderRadius: '4px', 
                fontSize: '0.85rem', 
                cursor: 'pointer', 
                display: 'inline-block', 
                textAlign: 'center', 
                border: '1px solid #444', 
                boxShadow: '0 1px 3px rgba(0,0,0,0.1)', 
                transition: 'all 0.2s ease'
              }}
            >
              <FontAwesomeIcon icon={faBook} style={{ marginRight: '8px' }} />
              Search in Dictionary
            </div>
          </Tooltip>
        </div>
        
        {/* Hans Wehr Dictionary Modal */}
        {showDictionary && (
          <div className="dictionary-modal-overlay">
            <div className="dictionary-modal">
              <div className="dictionary-modal-header">
                <h3>Ejtaal (32 Dictionaries)</h3>
                <button onClick={closeDictionary} className="dictionary-close-btn">
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13 1L1 13M1 1L13 13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
                  </svg>
                </button>
              </div>
              <div className="dictionary-container">
                {isLoading && (
                  <div className="dictionary-loading" style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    padding: '20px',
                    borderRadius: '8px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    zIndex: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                    <FontAwesomeIcon icon={faSpinner} spin style={{ fontSize: '24px', marginBottom: '10px', color: '#4A86E8' }} />
                    <div>Loading Ejtaal dictionaries...</div>
                  </div>
                )}
                <iframe 
                  ref={iframeRef}
                  src="about:blank"
                  className="dictionary-iframe"
                  onLoad={handleIframeLoad}
                  title="Ejtaal (32 Dictionaries)"
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  allowFullScreen
                  sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-modals allow-top-navigation allow-popups-to-escape-sandbox"
                />
              </div>
            </div>
          </div>
        )}
        
        {/* New Close button at the bottom */}
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
          <div
            onMouseEnter={handleHover}
            onClick={onClose}
            onMouseLeave={handleLeave}
            style={{ 
              backgroundColor: '#555', 
              color: 'white',
              padding: '6px 16px',
              borderRadius: '4px',
              fontSize: '0.85rem',
              cursor: 'pointer',
              display: 'inline-block',
              textAlign: 'center',
              border: '1px solid #444',
              boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
              transition: 'all 0.2s ease',
            }}
          >
            Close
          </div>
        </div>
      </div>
    </>
  );
};

export default WordCard;