import React, { useState, useEffect } from "react";
import Nav from "../Components/Nav";
import Footer from "../Components/Footer";
import { ImagePath } from "../Constant/ImgPath";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import QuranImage from "../assets/quran title image.png";
import BismillahImage from "../assets/bismillahtitle.png";
import API_BASE_URL from "../apiConfig";

function PlanPage() {
  const [plans, setPlans] = useState("");
  const [fatihah, setFatihah] = useState([]);
  const [naas, setNaas] = useState([]);
  useEffect(() => {
    localStorage.clear();
    getMainAPI();
  }, []);
  const getMainAPI = (course) => {
    const apiUrl = `${API_BASE_URL}/page/main_page`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("access_token"),
    };
    fetch(apiUrl, {
      method: "GET",
      headers: headers,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setPlans(data?.plans);
        setFatihah(data?.sura?.[1]);
        setNaas(data?.sura?.[114]);
      })
      .catch((error) => {
        console.error("Error fetching API:", error);
      });
  };
  console.log(fatihah);
  console.log(naas);
  const navigate = useNavigate();
  const NavigatePages = (data) => {
    if (data.name == "Free Plan") {
      navigate("/login");
    } else if (data.name == "Monthly") {
      navigate("/login", { state: { plan: "monthly" } });
    } else if (data.name == "Best value") {
      navigate("/login", { state: { plan: "yearly" } });
    }
  };
  const landing = {
    landing2: {
      maxWidth: "100%",
      maxHeight: "350px",
    },
    mainHeading: {
      display: "flex",
      padding: "96px 24px",
      flexDirection: "column",
      gap: "19px",
    },
    connect: {
      width: "100%",
      background: "black",
      textAlign: "center",
      padding: "30px 0",
      marginTop: "40px",
      color: "white",
      marginBottom: "10px",
    },
    connecth3: {
      fontSize: "30px",
      fontStyle: "normal",
      fontWeight: "600",
      margin: "0",
    },
    landingBoxs: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      marginTop: "30px",
    },
    bg: {
      background: "white",
    },
    h4: {
      fontSize: "34px",
      fontWeight: "600",
      margin: "0 auto",
    },
    p: {
      fontSize: "24px",
      fontWeight: "400",
      margin: "0 auto",
    },
    button: {
      background: "rgb(232, 155, 47)",
      fontWeight: "500",
      fontSize: "24px",
      lineHeight: "42px",
      color: "#FFFFFF",
    },
  };
  const subscriptionPlans = [
    {
      id: 1,
      name: "Free Plan",
      price: "$" + plans?.["Free Plan"]?.price + "/month",
      access: "Access: Course 1",
      time: "Unlimited",
    },
    {
      id: 2,
      name: "Monthly",
      price: "$" + plans?.["Monthly Plan"]?.price + "/month",
      access: "Access: All Content",
      time: "1 Month",
    },
    {
      id: 3,
      name: "Best value",
      price: "$" + plans?.["Yearly Plan"]?.price + "/year",
      access: "Access: All Content",
      time: "12 months",
    },
    { id: 4, name: "Scholarship", access: "Coming Soon" },
  ];
  return (
    <>
      <div>
        <Nav headervalue={"nav1"} />
        <div style={landing.bg}>
          <div style={{ padding: "14px 24px" }}>
            <div>
              <div className="Subscription-box">
                <h4 className="connecth3-responsive">Subscription Plan</h4>
                <div
                  style={{
                    display: "flex",
                    gap: "35px",
                    marginTop: "24px",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    marginBottom: "20px",
                  }}
                >
                  {plans && (
                    <>
                      {subscriptionPlans.map((plan) => (
                        <div key={plan.id} className="Subscription-card">
                          <div className="header-Subscription">
                            <h3>{plan.name}</h3>
                          </div>
                          <h5>{plan.price}</h5>

                          <div style={{ width: "90%", margin: "0 auto" }}>
                            <p
                              style={{ textAlign: "center" }}
                            >{`${plan.access}`}</p>
                            <hr />
                            <h6>{plan.time}</h6>
                          </div>
                          {plan.access != "Coming Soon" && (
                            <button
                              className="card-btn"
                              onClick={() => {
                                NavigatePages(plan);
                              }}
                            >
                              Login
                            </button>
                          )}
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default PlanPage;
