import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const HeroSection = ({ userDetails }) => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("userData"));
  const userName = user?.user_info?.username;
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false);
  
  // Check for active subscription when component mounts or userDetails changes
  useEffect(() => {
    const checkSubscriptionStatus = () => {
      //console.log("Checking subscription status with userDetails:", userDetails);
      
      // Check for status at the root level of userDetails (this is what the API returns)
      if (userDetails && userDetails.status) {
        const status = userDetails.status;
        console.log("Found status:", status);
        
        if (status === "Active" || status === "Pending" || status === "suspend" ||
            status.toLowerCase() === "active" || status.toLowerCase() === "pending" || status.toLowerCase() === "suspend") {
          console.log("Setting active subscription to TRUE based on userDetails.status:", status);
          setHasActiveSubscription(true);
          return;
        }
      }
      
      // Fallback to checking cached user data
      try {
        const cachedUserData = localStorage.getItem("cached_user_data");
        if (cachedUserData) {
          const parsedData = JSON.parse(cachedUserData);
          console.log("Checking cached user data:", parsedData);
          
          // Check for status at the root level of cached data
          if (parsedData && parsedData.status) {
            const status = parsedData.status;
            console.log("Found cached status:", status);
            
            if (status === "Active" || status === "Pending" || status === "suspend" ||
                status.toLowerCase() === "active" || status.toLowerCase() === "pending" || status.toLowerCase() === "suspend") {
              console.log("Setting active subscription to TRUE based on cached data status:", status);
              setHasActiveSubscription(true);
              return;
            }
          }
        }
        
        // Set to false if no active subscription is found
        console.log("No active subscription found, setting to FALSE");
        setHasActiveSubscription(false);
      } catch (error) {
        console.error("Error checking subscription status:", error);
        setHasActiveSubscription(false);
      }
    };
    
    checkSubscriptionStatus();
  }, [userDetails]);
  
  // Handle button click based on subscription status
  const handlePremiumButtonClick = () => {
    if (hasActiveSubscription) {
      navigate("/account");
    } else {
      navigate("/subscription-plan");
    }
  };
  
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "start",
        fontFamily: "mui-font-family !important",
        marginTop: "0",
        padding: '10px 0', // Adjust padding to reduce white space
      }}
      className="hero-mobile-view"
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            variant="h6"
            component="div"
            className="welcome-typography"
            sx={{ mr: 2, fontWeight: "500" }}
          >
            {`Welcome, ${userName}`}
          </Typography>
          <Typography sx={{ fontSize: "20px", color: "#000000B2" }}>
            Welcome to LinkQuran
          </Typography>
        </Box>
      </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handlePremiumButtonClick}
          sx={{
            borderRadius: "18px",
            textTransform: "none",
            padding: "2px 16px",
            fontSize: "18px !important",
            backgroundColor: "#000000 !important",
            color: "white !important",
            border: "1px solid #000000 !important",
            fontWeight: "500 !important",
            marginTop: "12px !important",
            "&:hover": {
              backgroundColor: "#FFFFFF",
            },
            display: "flex",
            alignItems: "center",
            gap: "6px"
          }}
        >
          {hasActiveSubscription && (
            <span style={{
              backgroundColor: "#4CAF50",
              color: "white",
              borderRadius: "50%",
              width: "16px",
              height: "16px",
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "11px",
              fontWeight: "bold",
              marginRight: "4px"
            }}>
              ✓
            </span>
          )}
          Contribute
        </Button>
    </Box>
  );
};

export default HeroSection;
