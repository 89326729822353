import React, { useState,useEffect } from 'react';
import Nav from '../Components/Nav';
import Footer from '../Components/Footer';
import AdminSidebar from '../Components/Sidebar';
import { useNavigate } from 'react-router-dom';
function TransactionHistory() {
    // sidebar start
    const navigate = useNavigate();
    const [sidebar, setsidebar] = useState(false);
    const sidemenufun = () => {
        setsidebar(!sidebar);
    }
    const [loginFalse, setLoginFalse] = useState(false)

    useEffect(() => {
        localStorage.getItem("login");
        if(localStorage.getItem("login") == "true"){
            setLoginFalse(true);
        }else{
            navigate("/login");
        }
    }, [localStorage.getItem("login")])
    // sidebar end
    const History = {

        bg: {
            // background: "rgb(244, 245, 246)",
            padding: "33px 24px"
        },
        h4: {
            fontSize: "24px",
            fontWeight: "600",
            textAlign: "center"
        },
        mainHeading: {
            margin: "0 auto", width: "70%", display: "flex", textAlign: "center"
        },
        heading: {
            width: "40%",
            color: "black",
            fontSize: "17px",
            fontWeight: "600",
        },
        P: {
            width: "40%",
            color: "#818181",
            fontSize: "17px",
            fontWeight: "500",
        },

    };
    const historyData = [
        { date: '13/05/2024', amount: '100.0$', description: 'Lessons' },
        { date: '13/05/2024', amount: '100.0$', description: 'Lessons' },
        { date: '13/05/2024', amount: '100.0$', description: 'Lessons' },
        { date: '13/05/2024', amount: '100.0$', description: 'Lessons' },
        { date: '13/05/2024', amount: '100.0$', description: 'Lessons' },
        { date: '13/05/2024', amount: '100.0$', description: 'Lessons' },
        { date: '13/05/2024', amount: '100.0$', description: 'Lessons' },
    ];
    return (
        <>
           {loginFalse && <div >
                <Nav headervalue={"nav2"} sidemenufun={sidemenufun} />
                <AdminSidebar sidebar={sidebar} sidebaracc={'Mobile-Sidebar'} />
                <div style={History.bg}>
                    <div className='mobile-view-pages'>
                        <h5 style={History.h4}>TRANSACTION HISTORY</h5>
                        <div style={History.mainHeading}>
                            <div style={History.heading}>
                                <p>Date</p>
                            </div>
                            <div style={History.heading}>
                                <p>Amount</p>
                            </div>
                            <div style={History.heading}>
                                <p>Description</p>
                            </div>
                        </div>
                        <hr style={{ margin: "0 auto", width: "70%" }} />
                        {historyData.map((item, index) => (
                            <div key={index} style={History.mainHeading}>
                                <div style={History.P}><p>{item.date}</p></div>
                                <div style={History.P}><p>{item.amount}</p></div>
                                <div style={History.P}><p>{item.description}</p></div>
                            </div>
                        ))}
                    </div>
                </div>
                <Footer />
            </div>}
        </>
    );
}

export default TransactionHistory;
