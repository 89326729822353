import React, { useState } from "react";
import Nav from "../Components/Nav";
import Footer from "../Components/Footer";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import search from "../assets/search.png";
import profileAvatar from "../assets/profile-avatar.png";
import ayat from "../assets/ayat.png";
import { useLocation } from "react-router-dom";
import AdminSidebar from "../Components/Sidebar";
import BadgeOne from "../assets/400.png";
import BadgeTwo from "../assets/known-words-19000.png";
import BadgeThree from "../assets/known-words-50000.png";
import API_BASE_URL from "../apiConfig";

export const CommunityProfile = () => {
  const location = useLocation();
  const receivedData = location.state && location.state;
  const [isFollow, SetIsFollow] = useState(receivedData?.is_following);
  // sidebar start
  const [sidebar, setsidebar] = useState(false);
  const sidemenufun = () => {
    setsidebar(!sidebar);
  };
  // sidebar end
  const stats = {
    bg: {
      // background: "rgb(244, 245, 246)",
      padding: "33px 24px",
    },
  };
  const progressContent = [
    {
      // week: '50',
      text: "known words",
      // progress: '25',
      goal: receivedData?.known_words,
      // backgroundColor: '#338FFF'
    },
    {
      // week: '1',
      text: "new words",
      // progress: '100',
      goal: receivedData?.new_words,
      // backgroundColor: '#0AAA2A'
    },
    {
      // week: '0',
      text: "learned words",
      // progress: '100',
      goal: receivedData?.learned_words,
      // backgroundColor: '#0AAA2A'
    },
    {
      // week: '0.00 +',
      text: "recognized words",
      // progress: '0',
      goal: receivedData?.recognized_words,
      // backgroundColor: '#338FFF'
    },
    {
      // week: '68 +',
      text: "familiar words",
      // progress: '100',
      goal: receivedData?.familiar_words,
      // backgroundColor: '#0AAA2A'
    },
  ];
  console.log(receivedData);
  const setFollow = (user_id) => {
    const apiUrl = `${API_BASE_URL}/community/set_following`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("access_token"),
    };
    const requestData = {
      id: user_id,
    };
    fetch(apiUrl, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        localStorage.setItem("localdata", "");
        SetIsFollow(true);
      })
      .catch((error) => {
        console.error("Error fetching API:", error);
      });
  };
  const setUnfollow = (user_id) => {
    const apiUrl = `${API_BASE_URL}/community/set_unfollow`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("access_token"),
    };
    const requestData = {
      id: user_id,
    };
    fetch(apiUrl, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        localStorage.setItem("localdata", "");
        SetIsFollow(false);
      })
      .catch((error) => {
        console.error("Error fetching API:", error);
      });
  };
  return (
    <>
      <Nav headervalue={"nav2"} sidemenufun={sidemenufun} />
      <AdminSidebar sidebar={sidebar} sidebaracc={"Mobile-Sidebar"} />
      <div className="container">
        <div style={stats.bg}>
          <div className="mobile-view-pages">
            <div className="community-search">
              <input type="text" placeholder="Search" />
              <img
                style={{ cursor: "pointer", cursor: "pointer" }}
                src={search}
              />
            </div>
            <Row>
              <Col md={7}>
                <div className="community-section mt-4">
                  {isFollow ? (
                    <button
                      className="community-follow btn-hover"
                      onClick={() => {
                        setUnfollow(receivedData?.user_id);
                      }}
                    >
                      Unfollow
                    </button>
                  ) : (
                    <button
                      className="community-follow btn-hover"
                      onClick={() => {
                        setFollow(receivedData?.user_id);
                      }}
                    >
                      Follow
                    </button>
                  )}
                  <div className="community-content">
                    <img
                      src={
                        receivedData?.user_profile
                          ? receivedData?.user_profile
                          : profileAvatar
                      }
                    />
                    <h3>{receivedData?.username}</h3>
                    <div className="community-text">
                      <img className="m-0" src={ayat} />
                      <p>{receivedData?.known_words}</p>
                    </div>
                    <div className="community-description mt-3">
                      {receivedData?.user_bio}
                    </div>
                    <div className="community-description">
                      Member Since {receivedData?.joining_date}
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={5}>
                <div className="community-goal mt-4">
                  <div className="arabic-words">
                    <img src={ayat} />
                    <p>
                      Quran Unique Words
                      {/* (23402 Known Words) */}
                    </p>
                  </div>
                  <div className="mt-4">
                    {progressContent.map((item, index) => {
                      return (
                        <div className="community-progress" key={index}>
                          {/* <div className='progress-text m-0'>
                                                {item.week}
                                            </div> */}
                          <div className="w-100 progress-bar-community">
                            <div>{item.text}</div>
                            {/* <progress value={item.progress} max="100" style={{ accentColor: item.backgroundColor }}></progress> */}
                          </div>
                          <div className="progress-text m-0">{item.goal}</div>
                        </div>
                      );
                    })}
                    {/* <div className='text-center community-coins'>
                                    Coins earned last 30 days: 9863
                                </div> */}

                    <div
                      className="badges d-flex align-items center justify-content-center"
                      style={{
                        gap: "50px",
                        marginTop: "30px",
                        paddingBottom: "30px",
                      }}
                    >
                      {receivedData?.badges.map((badge, index) => {
                        return (
                          <div key={index}>
                            <img style={{ width: "70px" }} src={badge} />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            {/* <Row>
                            <Col md={12}>
                                <div className='community-wall mt-5 mb-5'>
                                    <h3>Wall</h3>
                                    <div className='community-wall-card'>
                                        <div className='wall-profile'>AG</div>
                                        <textarea placeholder='Post a comment'></textarea>
                                    </div>
                                </div>
                            </Col>
                        </Row> */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CommunityProfile;
