// Function to transliterate Arabic characters to English
export const transliterateArabicToEnglish = (arabicText) => {
  if (!arabicText) return '';
  
  const textWithoutDots = arabicText.replace(/\./g, '');
  const cleanText = textWithoutDots.replace(/#/g, '');
  
  const arabicToEnglishMap = {
    'ا': 'a', 'أ': 'a', 'إ': 'a', 'آ': 'a',
    'ب': 'b', 'ت': 't', 'ث': 'th', 'ج': 'j',
    'ح': 'H', 'خ': 'kh', 'د': 'd', 'ذ': 'dh',
    'ر': 'r', 'ز': 'z', 'س': 's', 'ش': 'sh',
    'ص': 'S', 'ض': 'D', 'ط': 'T', 'ظ': 'Z',
    'ع': '3', 'غ': 'gh', 'ف': 'f', 'ق': 'q',
    'ك': 'k', 'ل': 'l', 'م': 'm', 'ن': 'n',
    'ه': 'h', 'و': 'w', 'ي': 'y', 'ى': 'y',
    'ئ': 'y', 'ء': '\''
  };
  
  const chars = [...cleanText].filter(char => !/[\u064B-\u0652]/.test(char));
  const hasRepeatedLastLetter = chars.length >= 2 && chars[chars.length - 1] === chars[chars.length - 2];
  
  let result = '';
  for (let i = 0; i < chars.length; i++) {
    if (hasRepeatedLastLetter && i === chars.length - 1) continue;
    const char = chars[i];
    result += arabicToEnglishMap[char] || char;
  }
  
  return result.replace(/-/g, '').replace(/\s+/g, '');
};

// Clean Arabic word (remove punctuation)
export const cleanArabicWord = (word) => {
  if (!word) return '';
  
  // First remove all diacritics and punctuation marks including:
  // - Arabic diacritics: \u064B-\u065F
  // - Superscript alef: \u0670
  // - Arabic comma, semicolon, question mark, etc.: \u060C, \u061B, \u061F, \u06D4
  // - Arabic tatweel (kashida): \u0640
  // - French quotation marks: «» (\u00AB, \u00BB)
  // - Left/right quotation marks: "" (\u201C, \u201D)
  // - Common punctuation: .,:;!?()[]{}"'
  return word.replace(/[\u060C\u061B\u061F\u06D4\u0640\u064B-\u065F\u0670\u00AB\u00BB\u201C\u201D.,;:!?"'[\](){}<>]/g, '');
};
